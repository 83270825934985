import { combineReducers } from 'redux'

import {
  TARIFFS_SURCHARGES,
  TARIFFS_SURCHARGES_NEW,
  TARIFFS_SURCHARGES_SHOW,
  TARIFFS_SURCHARGES_CREATE,
  TARIFFS_SURCHARGES_UPDATE,
  TARIFFS_SURCHARGES_DESTROY
} from  '../../root/action-types'

const INITIAL_INDEX = { data: [], total: 0, count: 0, loading: true }

const addRate = (s) => {
  if (s.flat_rate) { s.rate = `€${s.flat_rate.toFixed(2)}` }
  else if (s.percentage) { s.rate = `${s.percentage}%` }
  else if (s.per_100kg) { s.rate = `€${s.per_100kg.toFixed(2)} per 100kg` }
  else if (s.per_metre) { s.rate = `€${s.per_metre.toFixed(2)} per metre` }
  return s
}

export const index = (state = INITIAL_INDEX, action) => {
  switch (action.type) {
    case `${TARIFFS_SURCHARGES}_START`:
      return {
        ...INITIAL_INDEX,
      }
    case `${TARIFFS_SURCHARGES}_SUCCESS`:
      return {
        ...state,
        data: action.response.data.map(addRate),
        loading: false,
      }
    case `${TARIFFS_SURCHARGES}_FAILURE`:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}

const INITIAL_SHOW = { loading: false, details: {}, status: [] }

export const show = (state = INITIAL_SHOW, action) => {
  switch (action.type) {
    case TARIFFS_SURCHARGES_NEW:
      return { ...INITIAL_SHOW }
    case `${TARIFFS_SURCHARGES_SHOW}_START`:
      return {
        ...INITIAL_SHOW,
        loading: true,
      }
    case `${TARIFFS_SURCHARGES_SHOW}_SUCCESS`:
      return {
        ...state,
        details: action.response.data,
        loading: false,
      }
    case `${TARIFFS_SURCHARGES_CREATE}_START`:
    case `${TARIFFS_SURCHARGES_UPDATE}_START`:
    case `${TARIFFS_SURCHARGES_DESTROY}_START`:
      return {
        ...state,
        loading: true,
        status: [],
        hasErrors: false,
      }
    case `${TARIFFS_SURCHARGES_UPDATE}_SUCCESS`:
      return {
        ...state,
        loading: false,
        details: action.response.data,
        status: ['Surcharge updated successfully!'],
      }
    case `${TARIFFS_SURCHARGES_CREATE}_SUCCESS`:
      return {
        ...state,
        loading: false,
        details: action.response.data,
        status: ['Surcharge created successfully!'],
      }
    case `${TARIFFS_SURCHARGES_SHOW}_FAILURE`:
    case `${TARIFFS_SURCHARGES_CREATE}_FAILURE`:
    case `${TARIFFS_SURCHARGES_UPDATE}_FAILURE`:
    case `${TARIFFS_SURCHARGES_DESTROY}_FAILURE`:
      return {
        ...state,
        loading: false,
        hasErrors: true,
        details: action.response.data.details,
        status: action.response.data.errors,
      }
    case `${TARIFFS_SURCHARGES_DESTROY}_SUCCESS`:
      return {
        ...state,
        loading: false,
        destroyed: true,
      }
    default:
      return state
  }
}

export default combineReducers({
  index,
  show,
})

import React, { Component } from 'react'
import { get, invoke, sum } from 'lodash'

import {
  CheckBox, Field, FieldSection, MoneyInput, InputGroup
} from '../forms/'
import { CompanySelect } from '../forms/select'

import { insuranceSale, insuranceCost } from './insurance'
import RateSelect, { FreightSelect } from './RateSelect'

import './rates-form.scss'

const  DD_TYPES = {
  normal: 'Door Delivery',
  unloading: 'Door Delivery Unloading',
}

export default class RatesForm extends Component {

  state = {
    goodsValue: '',
    nonStandardName: '',
    distribution: get(this.props.distribution, 'rate'),
    supplierId: get(this.props.distribution, 'supplier_id'),
    spot: false,
    surcharges: this.props.surcharges || [],
  }

  componentDidMount() {
    invoke(this.props, 'registerForm', this.formState)
  }

  formState = () => ({
    customerId: this.props.customerId,
    ...this.state,
    locationType: get(this.props.distribution, 'mode'),
    salesLines: this.salesLines(),
    costLines: this.costLines(),
  })

  insuranceSale = () => (
    this.props.insured && insuranceSale(this.state)
  )

  insuranceCost = () => (
    this.props.insured && insuranceCost(this.state)
  )

  setDoorDelivery = (doorDeliveryOption) => {
    this.setState({
      doorDeliveryOption,
      doorDelivery: doorDeliveryOption.value,
      doorDeliveryType: DD_TYPES[doorDeliveryOption.name],
    })
  }

  setFreightMode = (option) => {
    const rate = parseFloat(get(option, 'value.rate'))
    const total = this.costsTotal() + rate
    const freight = (rate + Math.ceil(total) - total).toFixed(2)

    this.setState({
      freight,
      freightMin: get(option, 'value.min'),
      freightOption: option,
    })
  }

  setTotalOverride = ({ target: { value } }) => {
    this.setState({
      totalOverride: value,
      freight: (parseFloat(value) - this.costsTotal()).toFixed(2)
    })
  }

  stateInput = (name) => (
    ({ target: { value } }) => this.setState({ [name]: value })
  )

  formSetters = {
    freight: this.stateInput('freight'),
    goodsValue: this.stateInput('goodsValue'),
    nonStandard: this.stateInput('nonStandard'),
    nonStandardName: this.stateInput('nonStandardName'),
    additionalInformation: this.stateInput('additionalInformation'),
    distribution: this.stateInput('distribution'),
  }

  setSupplierId = ({ value }) => {
    this.setState({ supplierId: value })
  }

  toggleSpot = () => {
    this.setState({ spot: !this.state.spot })
  }

  total = () => (
    Object.values(this.salesLines()).reduce((total, line) => total + (parseFloat(line) || 0), 0)
  )

  distributionSurcharges = () => (
    this.surchargesRate(s => s.amalgamate)
  )

  looseSurcharges = () => (
    this.surchargesRate(s => !s.amalgamate)
  )

  surchargesRate = (filter = () => true) => (
    sum(this.state.surcharges.filter(filter).map(s => parseFloat(s.rate) || 0))
  )

  distributionRate = () => (
    parseFloat(this.state.distribution)
  )

  overseasPlaceholder = () => (
    get(this.props.details, 'incotermType') == 'FOB' ? 'N/A - FOB' : null
  )

  overseasRate = () => {
    const rate = (
      this.distributionRate() +
      this.distributionSurcharges() +
      this.markUp()
    )
    return rate ? rate.toFixed(2) : ''
  }

  transport = () => (
    [
      this.overseasRate(),
      this.state.freight,
      this.state.doorDelivery,
      this.looseSurcharges(),
    ].reduce((total, l) => total + parseFloat(l || 0), 0)
  )

  surchargeLines = () => {
    const lines = {}
    this.state.surcharges.forEach(s => lines[s.name] = s.rate)
    return lines
  }

  costLines = () => ({
    "Distribution": this.distributionRate(),
    "Insurance": this.insuranceCost(),
    [this.state.nonStandardName]: this.state.nonStandard,
    ...this.surchargeLines(),
  })

  salesLines = () => ({
    "Transport": this.transport(),
    "Insurance": this.insuranceSale(),
    [this.state.nonStandardName]: this.state.nonStandard,
  })

  markUp = () => (
    (get(this.props.markup, 'overseas_markup', 5) / 100) *
    (this.distributionRate() + this.distributionSurcharges())
  )

  setSurchargeRate = ({ target: { name, value } }) => {
    const surcharges = [...this.state.surcharges]
    surcharges[name] = { ...surcharges[name], rate: value }
    this.setState({ surcharges })
  }

  renderSurcharges = () => (
    this.state.surcharges.map((s, i) =>
      <Field label={s.name} key={i}>
        <MoneyInput name={i} value={s.rate} onChange={this.setSurchargeRate} />
      </Field>
    )
  )

  formInput = ({ suffix, prefix, name, ...props }) => (
    <InputGroup suffix={suffix} prefix={prefix}>
      <input
        name={name}
        value={this.state[name]}
        onChange={this.formSetters[name]}
        type="number"
        {...props}
      />
    </InputGroup>
  )

  renderTotal = () => (
    this.total() ? (`€${Number.parseFloat(this.total()).toFixed(2)}`) : ("More details required.")
  )

  salesMinimum = () => (
    this.costsTotal() + this.state.freightMin
  )

  costsTotal = () => (
    [
      this.overseasRate(),
      this.looseSurcharges(),
      this.state.doorDelivery,
    ].reduce((total, l) => total + parseFloat(l || 0), 0)
  )

  doorDeliveryOptions = () => (
    Object.entries(this.props.doorDelivery || {}).map(([name, value]) => ({
      name, value, rate: value
    }))
  )

  doorDeliveryField = () => (
    <Field label="Door Delivery" className="double-width">
      <RateSelect
        value={this.state.doorDeliveryOption}
        onChange={this.setDoorDelivery}
        options={this.doorDeliveryOptions()}
      />
    </Field>
  )

  doorDelivery = () => (
    this.props.upToDoor ? this.doorDeliveryField() : null
  )

  // Inputs don't give good UX unless they correspond to direct state
  totalOverride = () => (
    this.state.totalFocus ? this.state.totalOverride : this.total().toFixed(2)
  )

  totalFocus = () => {
    this.setState({ totalFocus: true, totalOverride: this.total().toFixed(2) })
  }

  totalBlur = () => {
    this.setState({ totalFocus: false })
  }

  render = () => (
    <div className="quote-rates form">
      <FieldSection title="Sale">
        <Field label="Overseas">
          <MoneyInput
            value={this.overseasRate()}
            placeholder={this.overseasPlaceholder()}
            disabled
          />
        </Field>
        <div className="toggle-list">
          <CheckBox label="Spot Rate" value={this.state.spot} onChange={this.toggleSpot} />
        </div>
        <Field label="Mode">
          <FreightSelect
            value={this.state.freightOption}
            onChange={this.setFreightMode}
            customerId={this.props.customerId}
          />
        </Field>
        <Field label="Freight">
          <MoneyInput
            value={this.state.freight}
            onChange={this.formSetters.freight}
            step="0.01"
          />
        </Field>
        <this.doorDelivery />
        <this.insurance />
        <Field label="Total">
          <this.formInput
            className="money-input"
            value={this.totalOverride()}
            onChange={this.setTotalOverride}
            onFocus={this.totalFocus}
            onBlur={this.totalBlur}
            prefix="€"
            step="1"
          />
        </Field>
        <Field label="Minimum">
          <MoneyInput value={this.salesMinimum()} disabled />
        </Field>
      </FieldSection>
      <this.spotDetails />
      <div className="total-pane">
        <h3>Total: <span>{this.renderTotal()}</span></h3>
      </div>
    </div>
  )

  insurance = () => (
    this.props.insured ? this.insuranceFields() : null
  )

  insuranceFields = () => (
    <React.Fragment>
      <Field label="Goods Value">
        <MoneyInput
          value={this.state.goodsValue}
          onChange={this.formSetters.goodsValue}
        />
      </Field>
      <Field label="Insurance Rate">
        <MoneyInput value={this.insuranceSale()} disabled placeholder="N/A" />
      </Field>
    </React.Fragment>
  )

  spotDetails = () => (
    this.state.spot ? this.spotSection() : null
  )

  pickupLabel = () => (
    this.props.direction == 'Export' ? 'Distribution' : 'Pickup'
  )

  spotSection = () => (
    <FieldSection title="Spot Rate">
      <CompanySelect
        label="Supplier"
        value={this.state.supplierId}
        onChange={this.setSupplierId}
      />
      <Field label={this.pickupLabel()}>
        <MoneyInput
          value={this.state.distribution}
          onChange={this.formSetters.distribution}
        />
      </Field>
      <Field label="Markup">
        <MoneyInput value={this.markUp()} disabled />
      </Field>
      {this.renderSurcharges()}
      <div className="advanced-breaker" />
      <Field label="Non Standard Description">
        <this.formInput
          name="nonStandardName"
          placeholder="Charge description..."
          type="text"
        />
      </Field>
      <Field label="Non Standard Rate">
        <this.formInput
          name="nonStandard"
          placeholder="If applicable"
          prefix="€"
        />
      </Field>
      <Field label="Additional Information" className="double-width">
        <this.formInput
          name="additionalInformation"
          placeholder="If applicable"
          type="text"
        />
      </Field>
    </FieldSection>
  )

}


import React from 'react'
import { useSelector } from 'react-redux'

import Api from '../api/call'
import { COMPANIES_LABEL } from '../root/action-types'


// Company names are unlikely to change (can always reload page for edge case)
// so we only need to request the labels for them once.
// We need to a module level var because redux actions are async, and in
// situations like the billing tab, there can be 10+ repeated requests for
// each line item when the page mounts.
const labelRequestIds = []

const CompanyName = ({ id }) => {
  const { labels } = useSelector(r => r.companies.search)
  if (id && !labels[id] && !labelRequestIds.includes(id)) {
    labelRequestIds.push(id)
    Api({ request: COMPANIES_LABEL, params: { id } })
  }

  return <>{labels[id] || ''}</>
}

export default CompanyName

import React, { Component } from 'react'
import { get } from 'lodash'

import { EMAILS_CREATE, EMAILS_UPDATE } from '../root/action-types'
import Api from '../api/call'

import AccordionItem from '../generic/accordion/AccordionItem'
import DisplayEmail from './DisplayEmail'
import EditEmail from './EditEmail'
import moment from 'moment'

import './emails-tab.scss'

export const BLANK_EMAIL = {
  id: '',
  recipient: '',
  subject: '',
  body: '',
  date: moment(Date.now()),
}

export default class Email extends Component {

  createEmailApiRequest = (attributes) => (
    Api({
      request: EMAILS_CREATE,
      data: {
        parentId: this.props.parentId,
        ...attributes,
      }
    })
  )

  updateEmailApiRequest = (attributes) => (
    Api({
      request: EMAILS_UPDATE,
      id: get(this.props.email, 'id'),
      data:  attributes,
    })
  )

  resetEmailUnderEdit = () => (
    this.props.setEmailUnderEdit('')
  )

  renderEditMode = (props) => (
    <EditEmail {...props} />
  )

  emailItemName = () => (
    <span className="email-item">
      <span className='item-subject'><b>{this.props.email.subject }</b></span>
      <span className="item-date">{moment(this.props.email.date).format('LL')}</span>
      <span className='item-recipient'>{this.props.email.recipient}</span>
    </span>
  )

  renderEmailDetails = () => {
    const { editMode, email, newEmail, setEmailUnderEdit } = this.props
    if (editMode) {
      return (
        <this.renderEditMode
          email={email}
          newEmail={newEmail}
          apiRequest={newEmail ? this.createEmailApiRequest : this.updateEmailApiRequest}
          resetEmailUnderEdit={this.resetEmailUnderEdit}
        />
      )
    }
    return (
      <DisplayEmail
        email={email}
        setEmailUnderEdit={setEmailUnderEdit}
        updateEmailApiRequest={this.updateEmailApiRequest}
        resetEmailUnderEdit={this.resetEmailUnderEdit}
      />
    )
  }

  render = () => (
    <AccordionItem
      name={this.emailItemName()}
      open={this.props.open}
    >
      <this.renderEmailDetails />
    </AccordionItem>
  )
}


import React, { Component } from 'react'
import { IconButton } from '../generic/Buttons'
import { Link } from 'react-router-dom'
import CompaniesIndex from './CompaniesIndex'

import "./ViewAllCompanies.scss"

class ViewAllCompanies extends Component {

  render = () => {
    return (<div className="view-all-companies-container ">
      <div className="index-controls">
        <h1>Companies</h1>
        <Link to="/companies/new">
          <IconButton className="primary" icon="plus-circle">
            New Company
          </IconButton>
        </Link>
      </div>
      <CompaniesIndex/>
    </div>)
  }
}

export default ViewAllCompanies

import React from 'react'
import { startCase } from 'lodash'

import ModelIndexTable from '../generic/indexTable/ModelIndexTable'

import { COMPANIES } from '../root/action-types'
import { COMPANIES_INDEX_COLUMNS } from '../root/local-storage-keys'
import { countryName } from '../addresses/countries'

import './CompaniesIndex.scss'


const SORT_OPTIONS = [
  'name',
  'status',
].map(o => ({ value: o, label: startCase(o) }))
const DEFAULT_SORT = SORT_OPTIONS[0]
const DEFAULT_SORT_ASCENDING = false
const CATEGORIES = [
  'customer',
  'supplier',
  'pickupAndDistribution',
  'freightForwarder',
]
const COLUMNS = [
  'name',
  'mainAddress',
  'country',
  'postcode',
  'status',
]
const DEFAULT_COLUMNS = COLUMNS.slice(0, 5)
const COLUMN_FLEX = {
  'mainAddress': 3,
}


function CompaniesIndex() {
  const dataMap = (row) => (
    { ...row, country: countryName(row.countryCode) }
  )

  return (
    <ModelIndexTable
      model={'companies'}
      dataMap={dataMap}
      requestAction={COMPANIES}
      LOCAL_STORAGE_KEY={COMPANIES_INDEX_COLUMNS}
      COLUMNS={COLUMNS}
      DEFAULT_COLUMNS={DEFAULT_COLUMNS}
      COLUMN_FLEX={COLUMN_FLEX}
      CATEGORIES={CATEGORIES}
      SORT_OPTIONS={SORT_OPTIONS}
      DEFAULT_SORT={DEFAULT_SORT}
      DEFAULT_SORT_ASCENDING={DEFAULT_SORT_ASCENDING}
    />
  )
}

export default CompaniesIndex

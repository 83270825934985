import React, { Component } from 'react'
import { get, startCase } from 'lodash'

import { Field } from '../forms/Field'
import Toggle from 'react-toggle'

import './contact-form.scss'

export default class ContactForm extends Component {

  formInError = () => (
    this.props.formError
  )

  formInput = ({ name, label, error=false, required=false }) => (
    <Field
      label={label || startCase(name)}
      error={error}
      forceError={this.formInError() && required}
      required={required}
    >
      <input
        name={name}
        value={this.props.contact[name] || ''}
        onChange={this.props.onChange}
        type="text"
        placeholder={`Enter ${label}`}
      />
    </Field>
  )

  toggle = ({name, label}) => (
    <Field label={label}>
      <Toggle
        name={name}
        checked={this.props.contact[name]}
        onChange={this.props.onChangeToggle}
        icons={false}
      />
    </Field>
  )

  render = () => (
    <div className="contact-form">

      <div className="double-input">
        <this.formInput name={'prefix'} label={'Prefix'} />
        <this.formInput
          name={'firstName'}
          label={'First Name'}
          error={get(this.props.contact, 'firstName') == ''}
          required={true}
        />
      </div>
      <this.formInput name={'lastName'} label={'Last Name'} />

      <div className="double-input">
        <this.formInput name={'jobTitle'} label={'Job Title'} />
        <this.formInput name={'jobRole'} label={'Job Role'} />
      </div>

      <div className="double-input">
        <this.toggle name={'accounts'} label={'Accounts?'} />
        <this.toggle name={'sales'} label={'Sales?'} />
      </div>
      <this.formInput name="telephone" label="Telephone" />
      <this.formInput name="email" label="Email" />

    </div>
  )
}

import { combineReducers } from 'redux'

import {
  standardForCollections,
  standardSearch,
  standardShow,
  standardModelCreateUpdateDestroy
} from '../api/standard-reducers'
import contacts from '../contacts/reducers.js'
import addresses from '../addresses/reducers.js'
import { COMPANIES_DETAILS } from '../root/action-types'

const INITIAL_DETAILS = { details: {} }

const details = (state = INITIAL_DETAILS, action) => {
  switch (action.type) {
    case `${COMPANIES_DETAILS}_START`:
      return {
        ...state,
        loading: true,
      }
    case `${COMPANIES_DETAILS}_SUCCESS`: {
      const { data } = action.response
      const details = { ...state.details }
      details[data.id] = data
      return {
        ...state,
        loading: false,
        details
      }
    }
    case `${COMPANIES_DETAILS}_FAILURE`:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}

export default combineReducers({
  index: standardForCollections('COMPANIES'),
  search: standardSearch('COMPANIES'),
  details,
  show: standardShow('COMPANIES_SHOW'),
  company: standardModelCreateUpdateDestroy('company', 'COMPANIES'),
  contacts,
  addresses,
})

import React, { Component } from 'react'

import { get } from 'lodash'
import { Field } from '../forms/Field'

import SlateEditor from './SlateEditor'

import './email-form.scss'

export default class EmailForm extends Component {

  onChange = (editorState) => {
    this.props.onEditorChange(editorState.value)
  }

  formInError = () => (
    this.props.formError
  )

  formInput = ({ name, label, error=false, required=false, ...props }) => (
    <Field
      label={label}
      error={error}
      forceError={this.formInError() && required}
      required={required}
    >
      <input
        name={name}
        value={this.props.email[name] || ''}
        onChange={this.props.onChange}
        type="text"
        placeholder={`${label}`}
        {...props}
      />
    </Field>
  )

  render = () => (
    <div className="email-form">
      <this.formInput
        name={'recipient'}
        label={'Recipients'}
        error={get(this.props.email, 'recipient') == ''}
        required={true}
      />

      <this.formInput
        name={'subject'}
        label={'Subject'}
        error={get(this.props.email, 'subject') == ''}
        required={true}
      />

      <SlateEditor
        emailBody={this.props.emailBody}
        onChange={this.onChange}
      />
    </div>
  )

}

import React from 'react'
import { useSelector } from 'react-redux'
import Select from 'react-select'

import Api from '../api/call'
import { PACKAGE_TYPES } from '../root/action-types'

const PackageTypeSelect = ({ onChange, valueName, ...props }) => {
  const { details, loading } = useSelector(r => r.packageTypes.index)
  const options = selectData(details || {})
  if (!details && !loading) { Api({ request: PACKAGE_TYPES }) }

  return (
    <Select
      className="select"
      classNamePrefix="select"
      options={options}
      value={options && options.find(o => o.label == valueName)}
      onChange={o => onChange(o && o.value)}
      isDisabled={props.disabled}
      isLoading={loading}
      isClearable
      {...props}
    />
  )
}

const selectData = (byId) => (
  Object.values(byId).map(c => ({ value: c, label: c.type }))
)

export default PackageTypeSelect


import React, { Component } from 'react'

import ModelActions from '../../generic/ModelActions'

import './Controls.scss'


export default class Controls extends Component {

  render = () => (
    <div className="groupage-controls">
      <div className="left">

      </div>
      <div className="right">
        <ModelActions
          modelName={'Groupage'}
          edit={this.props.edit}
          editAction={this.props.editAction}
          deleteAction={this.props.destroyAction}
        />
      </div>
    </div>
  )
}

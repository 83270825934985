import React from 'react'
import { useSelector } from 'react-redux'

import Api from '../../../api/call'
import { GROUPAGE_SEND_JOB_INVOICES } from '../../../root/action-types'

import { markedForInvoicingSelector } from './redux-selectors'


const sendJobInvoices = (ids) => {
  Api({
    request: GROUPAGE_SEND_JOB_INVOICES,
    data: { ids },
  })
}

export const SendInvoices = () => {
  const marked = useSelector(markedForInvoicingSelector)
  const ids = Object.keys(marked).filter(id => marked[id])

  const onClick = () => ids.length > 0 && sendJobInvoices(ids)

  return (
    <button
      disabled={ids.length === 0}
      onClick={onClick}
      className="primary send-invoices"
    >
      Send Invoices
    </button>
  )
}

export default SendInvoices

import React, { Component } from 'react'
import { startCase } from 'lodash'

import { Field } from '../../../forms/Field'

import './NoteForm.scss'


export default class NoteForm extends Component {

  FormField = ({ name, error=false, required=false, children }) => (
    <Field
      label={startCase(name)}
      error={error}
      forceError={this.formInError() && required}
      required={required}
    >
      {children}
    </Field>
  )

  formInError = () => (
    this.props.formError
  )

  FormInput = ({ name, required }) => {
    const { note, onChange } = this.props

    return (
      <this.FormField name={name} error={!note[name]} required={required}>
        <input
          name={name}
          value={note[name] || ''}
          onChange={onChange}
          type="text"
          placeholder={`${startCase(name)}`}
        />
      </this.FormField>
    )
  }

  FormTextArea= ({ name, required }) => {
    const { note, onChange } = this.props

    return (
      <this.FormField name={name} required={required}>
        <textarea
          name={name}
          value={note[name] || ''}
          onChange={onChange}
          type="text"
          placeholder={`${startCase(name)}`}
        />
      </this.FormField>
    )
  }

  render = () => (
    <div className="note-form">
      <this.FormInput name={'title'} required={true} />
      <this.FormTextArea name={'content'} />
    </div>
  )
}

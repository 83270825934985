import React, { Component } from 'react'
import { get } from 'lodash'
import { connect } from 'react-redux'
import ModalWindow from '../../layout/ModalWindow'

import { QUOTES_SEND_EMAIL } from '../../root/action-types'

import { Status } from '../../forms'

import SlateEditor from '../../emails/SlateEditor'
import { html } from '../../emails/SlateEditorRules'

import RecipientSelect from '../../emails/RecipientSelect'

import Api from '../../api/call'

import './email-quote-button.scss'


export class EmailQuoteButton extends Component {

  state = {
    modalOpen: this.props.modalOpen,
    emailBody: html.deserialize(this.props.email.body),
    recipients: [this.props.email.recipient],
    confirm: false,
  }

  onChange = (editorState) => (
    this.setState({ emailBody: editorState.value})
  )

  onRecipientChange = r => {
    r ? this.setState({recipients: r.map(x => x.value)}) : this.setState({recipients: []})
  }

  toggleEditModal = () => (
    this.setState(prev => ({ modalOpen: !prev.modalOpen, confirm: false, }))
  )

  toggleConfirm = () => (
    this.setState(prev => ({ confirm: !prev.confirm }))
  )

  modalIfOpen = () => this.state.modalOpen ? this.renderEditModal() : null

  renderNoContact = () => (<Status isError>No Primary Email Address!</Status>)

  sendEmail = () => {
    Api({
      request: QUOTES_SEND_EMAIL,
      params: {
        id: this.props.parentId,
        body: html.serialize(this.state.emailBody),
        recipientArray: this.state.recipients,
      },
    })
    this.toggleEditModal()
  }

  getPrimaryEmailAddress = () => {
    const { details, customerId } = this.props
    return get(details[customerId], 'primaryContact.email')
  }

  renderEditModal = () => (
    <ModalWindow
      className="email-modal"
      isOpen={this.state.modalOpen}
      closeModal={this.toggleEditModal} >
      {this.props.email.recipient? <this.emailContent /> : <this.renderNoContact />}
    </ModalWindow>
  )

  emailContent = () => (
    <React.Fragment>
      <h4>{this.props.email.subject}</h4>

      <RecipientSelect
        recipient={this.props.email.recipient}
        companyId={this.props.customerId}
        onChange={this.onRecipientChange} />

      <SlateEditor
        emailBody={this.state.emailBody}
        onChange={this.onChange} />

      {this.state.confirm ? <this.confirmOptions /> : <this.options />}

    </React.Fragment>
  )

  options = () => (
    <div className="options">
      <button
        className="danger"
        onClick={this.toggleEditModal}>
        Cancel
      </button>
      <button
        className="primary"
        onClick={this.toggleConfirm}>
        Send Email
      </button>
    </div>
  )

  confirmOptions = () => (
    <div className="after-options">
      <span>Are you sure?</span>
      <button className="primary" onClick={this.sendEmail}>
        Yes, Send it
      </button>
      <button className="danger" onClick={this.toggleConfirm}>
        Cancel
      </button>
    </div>
  )

  render = () => (
    <React.Fragment>
      {this.modalIfOpen()}
      <button
        className="primary"
        onClick={this.toggleEditModal}>
        Email Quote
      </button>
    </React.Fragment>
  )
}

const mapStateToProps = ({ companies: { details } }) => ({
  details: get(details, 'details', {})
})

export default connect(mapStateToProps)(EmailQuoteButton)


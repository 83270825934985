import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
  OPENING_TIMES_DESTROY, OPENING_TIMES_CREATE, OPENING_TIMES_UPDATE,
  OPENING_TIMES_RESET_STORE,
} from '../../root/action-types'
import Api from '../../api/call'

import OpeningTimeForm from './OpeningTimeForm'

const stringifyOpeningTimeDays = (
  {monday, tuesday, wednesday, thursday, friday, saturday, sunday}
) => {

  const week = [
    monday, tuesday, wednesday, thursday, friday, saturday, sunday
  ]

  const dayLabels = {
    0: 'Mon', 1: 'Tue', 2: 'Wed', 3: 'Thu', 4: 'Fri', 5: 'Sat', 6: 'Sun'
  }

  const weekLength = 7
  var dayRanges = []

  for (let d = 0; d < weekLength; d++) {
    const start = d
    var end = -1

    while (d < weekLength) {
      if (week[d] == true) {
        end = d++
      } else {
        break
      }
    }

    if (start == end) {
      dayRanges.push(`${dayLabels[start]}`)
    } else if (start < end) {
      dayRanges.push(`${dayLabels[start]}-${dayLabels[end]}`)
    }
  }

  return `${dayRanges.join(", ")}`
}

const stringifyOpeningTimeHours = ({ startTime, endTime }) => (
  `${startTime} - ${endTime}`
)

export const BLANK_OPENING_TIME = {
  monday: true,
  tuesday: true,
  wednesday: true,
  thursday: true,
  friday: true,
  saturday: false,
  sunday: false,
  startTime: '09:00',
  endTime: '17:00'
}

export class OpeningTime extends Component {

  state = {
    ...this.props.openingTime
  }

  toggleDay = (e) => {
    const { name } = e.currentTarget
    this.setState(
      prevState => ({ [name]: !prevState[name] })
    )
  }

  setTime = ({ currentTarget: { name, value } }) => {
    this.setState({ [name]: value })
  }

  enterEditMode = () => {
    this.props.setOpeningTimeUnderEdit(this.props.openingTime.id)
    this.setState({ ...this.props.openingTime })
  }

  exitEditMode = () => {
    this.props.setOpeningTimeUnderEdit('')
    this.props.dispatch({ type: OPENING_TIMES_RESET_STORE })
    this.finished = null
  }

  deleteOpeningTime = () => {
    this.finished = true
    Api({ request: OPENING_TIMES_DESTROY, id: this.props.openingTime.id })
  }

  apiRequest = () => {
    const data = { ...this.state, addressId: this.props.addressId }
    const { id } = this.props.openingTime
    this.finished = true
    if (id) {
      Api({ request: OPENING_TIMES_UPDATE, id, data })
    } else {
      Api({ request: OPENING_TIMES_CREATE, data })
    }
  }

  componentDidUpdate() {
    if (this.finished && this.props.success) {
      this.exitEditMode()
    }
  }

  renderEditMode = () => (
    <OpeningTimeForm
      discardChanges={this.exitEditMode}
      openingTime={this.state}
      toggleDay={this.toggleDay}
      setTime={this.setTime}
      apiRequest={this.apiRequest}
    />
  )

  renderOptions = () => (
    <div className="options">
      <button
        className="edit"
        onClick={this.enterEditMode}
      >
        Edit
      </button>
      <button
        className="delete"
        onClick={this.deleteOpeningTime}
        disabled={!this.props.openingTime.id}
      >
        Delete
      </button>
    </div>
  )

  details = () => (
    this.props.editMode ? this.state : this.props.openingTime
  )

  render = () => (
    <div className="opening-time-container">
      <div className="opening-time-header">
        <div className="opening-time">
          <div className="days">
            {stringifyOpeningTimeDays(this.details())}
          </div>
          <div className="time">
            {stringifyOpeningTimeHours(this.details())}
          </div>
        </div>
        {this.props.newOpeningTime ? null : this.renderOptions()}
      </div>
      {this.props.editMode ?  this.renderEditMode() : null}
    </div>
  )

}

const mapStateToProps = ({ openingTimes: { openingTime: { success } } }) => ({
  success
})

export default connect(mapStateToProps)(OpeningTime)

import {
  PLANNER_COLUMNS_SELECT,
  PLANNER_JOB_STATUS_SELECT
} from '../../root/action-types'
import { PLANNER_COLUMNS, PLANNER_JOB_STATUSES } from '../../root/local-storage-keys'
import { COLUMNS as VALID_COLUMNS } from '../../jobs/JobsIndex'
import { STATUS as JOB_STATUS } from '../../jobs/constants'

export const DEFAULT_COLUMNS = [
  'reference',
  'weekNumber',
  'status',
  'priority',
  'customer',
  'packageQuantity',
  'grossWeightKg',
  'cubicMetres',
  'loadingMetres',
  'originLocation',
  'destinationLocation',
  'province',
]


const UNWANTED_JOB_STATUSES = ['Finalised', 'Cancelled']
export const DEFAULT_JOB_STATUS = JOB_STATUS.filter(s => !UNWANTED_JOB_STATUSES.includes(s))

const getOptions = (key, validOptions, defaultOptions) => {
  try {
    return JSON.parse(localStorage.getItem(key))
      .filter(c => validOptions.indexOf(c) > -1)
  }
  catch (e) {
    return defaultOptions
  }
}

const setOptions = (key, options) => {
  localStorage.setItem(key, JSON.stringify(options))
}

const INITIAL_CONFIG = {
  columns: getOptions(PLANNER_COLUMNS, VALID_COLUMNS, DEFAULT_COLUMNS),
  jobStatuses: getOptions(PLANNER_JOB_STATUSES, JOB_STATUS, DEFAULT_JOB_STATUS),
}

export const config = (state = INITIAL_CONFIG, action) => {
  switch (action.type) {
    case PLANNER_COLUMNS_SELECT: {
      setOptions(PLANNER_COLUMNS, action.payload)
      return {
        ...state,
        columns: action.payload,
      }
    }
    case PLANNER_JOB_STATUS_SELECT: {
      setOptions(PLANNER_JOB_STATUSES, action.payload)
      return {
        ...state,
        jobStatuses: action.payload,
      }
    }
    default:
      return state
  }
}

export const TEST_ACCESS = {
  INITIAL_CONFIG,
}

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { pick } from 'lodash'

import { Field, Conditional } from '../forms'
import { XeroContactSelect } from '../forms/select'
import XeroContactDetails from '../xero/ContactDetails'
import XeroExportContact from '../xero/ExportContact'

import './company/general.scss'

export class Financial extends Component {

  state = pick(this.props, ['xeroContactId'])

  setXeroContact = (val) => {
    this.setState({ xeroContactId: val })
  }

  editControls = () => (
    this.props.edit ? <this.controls /> : null
  )

  controls = () => (
    <div className="section controls">
      <button className="submit" onClick={this.save}>Save</button>
    </div>
  )

  save = () => (
    this.props.updateApiRequest(this.state)
  )

  render = () => (
    <div className="company-financial">
      <div className="section xero">
        <h4>Xero Contact</h4>
        <div className="fields">
          <Field label="Name">
            <XeroContactSelect
              value={this.state.xeroContactId}
              onChange={this.setXeroContact}
              disabled={!this.props.edit}
            />
          </Field>
          <Conditional if={!this.state.xeroContactId && this.props.edit}>
            <XeroExportContact companyId={this.props.id} complete={this.setXeroContact} />
          </Conditional>
          <XeroContactDetails id={this.state.xeroContactId} />
        </div>
      </div>
      <this.editControls />
    </div>
  )
}

const mapStateToProps = ({ companies: { show: { data } } }) => (
  pick(data, ['xeroContactId', 'id'])
)

export default connect(mapStateToProps)(Financial)


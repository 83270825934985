import React, { Component } from 'react'
import { connect } from 'react-redux'
import { isEqual } from 'lodash'

import Api from '../../api/call'
import {
  QUICK_FIND,
  QUICK_FIND_RESET_STORE
} from '../../root/action-types'

import QuickFindForm from './QuickFindForm'
import QuickFindRedirect from './QuickFindRedirect'
import QuickFindAlertListener from './QuickFindAlertListener'

import FloatingAlertStream from '../../generic/floating-alert-stream/FloatingAlertStream'


const DEFAULT_STATE = {
  queryType: 'job',
  queryString: '',
}

export class QuickFind extends Component {

  state = {
    ...DEFAULT_STATE
  }

  resultsHaveUpdated = (prevProps) => (
    !isEqual(prevProps.data, this.props.data)
  )

  setQueryType = (type) => (
    this.setState({ queryType: type })
  )

  setQueryString = ({ target: { value } }) => (
    this.setState({ queryString: value })
  )

  resetStore = () => {
    this.props.dispatch({
      type: QUICK_FIND_RESET_STORE,
    })
  }

  apiRequest = () => {
    this.resetStore()
    Api({
      request: QUICK_FIND,
      params: this.state
    })
  }

  onKeyPress = ({ key }) => {
    key == 'Enter'
    && this.state.queryString
    && !this.props.loading
    && this.apiRequest()
  }

  render = () => (
    <div className="quick-find-container">
      <QuickFindRedirect {...this.props} />
      <FloatingAlertStream alertListener={QuickFindAlertListener} />
      <QuickFindForm
        {...this.state}
        setQueryType={this.setQueryType}
        setQueryString={this.setQueryString}
        onKeyPress={this.onKeyPress}
      />
    </div>
  )
}

const mapStateToProps = ({ quickFind }) => ({
  data: quickFind.data,
  loading: quickFind.loading 
})

export default connect(mapStateToProps)(QuickFind)

import React from 'react'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

import { Status } from '../../../forms/Status'

import { jobsSelector, sendEmailsSelector } from './redux-selectors'


const emailErrorsObject = (emailResponses) => {
  const jobs = useSelector(jobsSelector)

  // { jobReference: "error for this job", }
  return (
    Object.keys(emailResponses || [])
      .filter(id => emailResponses[id].error != null)
      .reduce((obj, id) => {
        const job = jobs.find(job => job.id == id)
        obj[job.reference] = emailResponses[id].error
        return obj
      }, {})
  )
}

const renderErrors = (errors) => (
  Object.keys(errors).map(ref =>
    <span key={`e-${ref}`}>
      {`${ref} : ${errors[ref]}`}
    </span>
  )
)

const statusMessage = (errors) => {
  if (isEmpty(errors)) return null
  return <div className="status-messages">{renderErrors(errors)}</div>
}

const EmailRequestStatus = () => {
  const sendEmails = useSelector(sendEmailsSelector)
  const { loading, emailResponses } = sendEmails
  const errors = emailErrorsObject(emailResponses)

  return (
    <Status
      key={`status-loading-${loading}`}
      isError={!isEmpty(errors)}
      className="email-request-status"
    >
      {loading ? 'Loading...' : statusMessage(errors)}
    </Status>
  )
}

export default EmailRequestStatus

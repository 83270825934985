import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class NavItem extends Component {

  itemIsActive = () => {
    return (this.props.path == this.props.currentPath)
  }

  render = () => (
    <Link className={"navbar-item-link"} to={this.props.path}>
      <span className={
        `${this.props.className} ${this.itemIsActive() ? 'navbar-item-selected' : ''}`
      }>
        <span className="navbar-icon-container">
          <FontAwesomeIcon icon={this.props.icon} fixedWidth />
        </span>
        <span className="navbar-label">{this.props.text}</span>
      </span>
    </Link>
  )
}

import React from 'react'
import { useSelector } from 'react-redux'

import Api from '../../../api/call'
import { GROUPAGE_SEND_JOB_EMAILS } from '../../../root/action-types'

import { markedForEmailingSelector } from './redux-selectors'


const sendJobEmails = (ids) => {
  Api({
    request: GROUPAGE_SEND_JOB_EMAILS,
    data: { ids },
  })
}

export const SendEmails = () => {
  const marked = useSelector(markedForEmailingSelector)
  const ids = Object.keys(marked).filter(id => marked[id])

  const onClick = () => ids.length > 0 && sendJobEmails(ids)

  return (
    <button
      disabled={ids.length === 0}
      onClick={onClick}
      className="primary send-emails"
    >
      Send Emails
    </button>
  )
}

export default SendEmails

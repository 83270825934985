import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { get, omit } from 'lodash'

import {
  CONTACTS_DESTROY, CONTACTS_MANAGEMENT_RESET_STORE,
} from '../root/action-types'
import Api from '../api/call'

import { Status } from '../forms/Status'

import ContactForm from './ContactForm'
import ModalWindow from '../layout/ModalWindow'

class EditContact extends Component {

  state = {
    confirmDeleteModalOpen: false,
    ...omit(this.props.contact, 'id'),
  }

  componentDidMount = () => (
    this.dispatchContactStoreReset()
  )

  componentDidUpdate = (prevProps) => {
    const previous = get(prevProps.contactManagement, 'contactHasUpdated')
    const current = get(this.props.contactManagement, 'contactHasUpdated')
    if (current !== previous && current) {
      this.exitEditMode()
    }
  }

  exitEditMode = () => {
    this.dispatchContactStoreReset()
    this.props.resetContactUnderEdit()
    this.props.updateCompany()
  }

  openConfirmDeleteModal = () => (
    this.setState({ confirmDeleteModalOpen: true })
  )

  closeConfirmDeleteModal = () => (
    this.setState({ confirmDeleteModalOpen: false })
  )

  setValue = ({ target: { name, value } }) => {
    this.setState({ [name]: value })
  }

  toggleValue = ({ target: { name } }) => {
    this.setState((prevState) => ({ [name]: !prevState[name] }))
  }

  getAttributes = () => (
    omit(this.state, 'confirmDeleteModalOpen')
  )

  destroyContact = () => (
    Api({
      request: CONTACTS_DESTROY,
      id: get(this.props.contact, 'id'),
    })
  )

  sendUpdate = () => {
    this.props.apiRequest(this.getAttributes())
    this.props.updateCompany()
  }

  dispatchContactStoreReset = () => (
    this.props.dispatch({
      type: CONTACTS_MANAGEMENT_RESET_STORE,
    })
  )

  inError = () => (
    get(this.props.contactManagement, 'errors', []).length != 0
  )

  modalContent = ({error}) => {
    if (error) {
      return (
        <Status isError={this.inError}>
          {get(this.props.contactManagement, 'errors')}
        </Status>
      )
    }

    return (
      <button
        className={"delete-button"}
        onClick={this.destroyContact}
      >
        Delete
      </button>
    )
  }

  renderModal = () => (
    <ModalWindow
      className="contact-delete-modal"
      isOpen={this.state.confirmDeleteModalOpen}
      closeModal={this.closeConfirmDeleteModal}
    >
      <h3>Confirm Contact Deletion</h3>
      <span className={'name'}>
        {this.state.firstName}
      </span>
      <this.modalContent error={this.inError()} />

    </ModalWindow>
  )

  renderOption = ({onClick, icon, label}) => (
    <div>
      <span onClick={onClick}>
        <label>
          <FontAwesomeIcon icon={['fas', icon]} />
        </label>
        {label}
      </span>
    </div>
  )

  renderDestroyOption = () => (
    <this.renderOption
      label={'Delete'}
      onClick={this.openConfirmDeleteModal}
      icon={'exclamation-triangle'}
    />
  )

  render = () => (
    <div className="edit-contact-container">
      <this.renderModal />

      <ContactForm
        key={this.state.id}
        contact={this.state}
        onChange={this.setValue}
        onChangeToggle={this.toggleValue}
        formError={this.inError()}
      />

      <div className="details-options">
        <this.renderOption
          label={'Save Changes'}
          onClick={this.sendUpdate}
          icon={'pencil-alt'} />
        <this.renderOption
          label={'Discard Changes'}
          onClick={this.exitEditMode}
          icon={'trash-alt'} />

        { this.props.newContact ? null : this.renderDestroyOption() }

      </div>
    </div>
  )
}

const mapStateToProps = ({ companies: { contacts: { contactManagement } } }) => (
  { contactManagement }
)

export default connect(mapStateToProps)(EditContact)

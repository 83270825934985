import React, { Component } from 'react'

import ModalWindow from '../../layout/ModalWindow'
import PrioritySwitch from './PrioritySwitch'
import ModelActions from '../../generic/ModelActions'

import InvoiceTemplate from '../../emails/templates/InvoiceTemplate'
import GenericTemplate from '../../emails/templates/GenericTemplate'
import PostInvoiceButton from '../../xero/PostInvoiceButton'

import { DropdownButton, DropdownOptionButton } from '../../generic/DropdownButton'

import './Controls.scss'

const MODAL_STATES = {
  ["priorityModalOpen"]: false,
  ["invoiceModalOpen"]: false,
  ["onfirmModalOpen"]: false,
  ["requestModalOpen"]: false,
  ["supplierModalOpen"]: false,
  ["transportModalOpen"]: false,
  ["hubModalOpen"]: false,
  ["warehouseModalOpen"]: false,
}

export default class Controls extends Component {

  state = {
    priority: this.props.priority,
    ...MODAL_STATES,
  }

  setPriority = (priority) => {
    this.setState({ priority }, this.togglePriorityModal)
  }

  revertPriority = () => {
    this.setState({ priority: this.props.priority }, this.togglePriorityModal)
  }

  modalIfOpen = () => (
    this.state.priorityModalOpen ? this.priorityModal() : null
  )

  modalToggle = (modalState) => {
    this.setState(prev => ({ [modalState] : !prev[modalState] }))
  }

  togglePriorityModal = () => this.modalToggle('priorityModalOpen')
  toggleInvoiceModal = () => this.modalToggle('invoiceModalOpen')
  toggleConfirmModal = () => this.modalToggle('confirmModalOpen')
  toggleRequestModal = () => this.modalToggle('requestModalOpen')
  toggleSupplierModal = () => this.modalToggle('supplierModalOpen')
  toggleTransportModal = () => this.modalToggle('transportModalOpen')
  toggleHubModal = () => this.modalToggle('hubModalOpen')
  toggleWarehouseModal = () => this.modalToggle('warehouseModalOpen')

  priorityModal = () => (
    <ModalWindow
      className="model-actions"
      isOpen={this.state.priorityModalOpen}
      closeModal={this.togglePriorityModal}>
      <h3>Job Priority Change</h3>
      <span>This job is currently marked as
        <strong>{` ${this.props.priority} Priority`}</strong>
        .<br/><br/> Would you like to change this to a
        {` ${this.state.priority} Priority`} job?</span>
      <div className="options">
        <button className="delete-button" onClick={this.revertPriority}>
          Cancel
        </button>
        <button className="cancel-button" onClick={this.save}>
          Confirm
        </button>
      </div>
    </ModalWindow>
  )

  save = () => {
    this.updateApiRequest()
    this.togglePriorityModal()
  }

  updateApiRequest = () => {
    this.props.updateApiRequest(this.state)
  }

  emailDropdown = () => {
    const { customerId, supplierId, shipperId, id, emails } = this.props
    const { invoice, confirm, request, transport,
      supplier, hubMeasurements, warehouseMeasurements } = emails

    return (
      <React.Fragment>
        <InvoiceTemplate
          companyId={customerId} parentId={id} email={invoice}
          isOpen={this.state.invoiceModalOpen}
          resetState={this.toggleInvoiceModal} />
        <GenericTemplate
          companyId={shipperId} parentId={id} email={confirm}
          isOpen={this.state.confirmModalOpen}
          template={"confirm"}
          pdfType={"confirm"}
          resetState={this.toggleConfirmModal} />
        <GenericTemplate
          companyId={supplierId} parentId={id} email={request}
          isOpen={this.state.requestModalOpen}
          template={"request"}
          pdfType={"request"}
          resetState={this.toggleRequestModal} />
        <GenericTemplate
          companyId={shipperId} parentId={id} email={transport}
          isOpen={this.state.transportModalOpen}
          template={"transport"}
          resetState={this.toggleTransportModal} />
        <GenericTemplate
          companyId={customerId} parentId={id} email={supplier}
          isOpen={this.state.supplierModalOpen}
          template={"supplier"}
          resetState={this.toggleSupplierModal} />
        <GenericTemplate
          companyId={customerId} parentId={id} email={hubMeasurements}
          isOpen={this.state.hubModalOpen}
          template={"hubMeasurements"}
          resetState={this.toggleHubModal} />
        <GenericTemplate
          companyId={customerId} parentId={id} email={warehouseMeasurements}
          isOpen={this.state.warehouseModalOpen}
          template={"warehouseMeasurements"}
          resetState={this.toggleWarehouseModal} />
      </React.Fragment>
    )
  }

  emailOptions = () => (
    <div className="email-options">
      <DropdownOptionButton icon="sticky-note"
        label="Invoice"
        onClick={this.toggleInvoiceModal}
        disabled={!this.props.customerId} />

      <DropdownOptionButton icon="sticky-note"
        label="Confirm Consignment"
        onClick={this.toggleConfirmModal}
        disabled={!this.props.shipperId} />

      <DropdownOptionButton icon="sticky-note"
        label="Request Collection & Transport"
        onClick={this.toggleRequestModal}
        disabled={!this.props.supplierId} />

      <DropdownOptionButton icon="sticky-note"
        label="Arranged Transport"
        onClick={this.toggleTransportModal}
        disabled={!this.props.shipperId} />

      <DropdownOptionButton icon="sticky-note"
        label="Arranged Supplier"
        onClick={this.toggleSupplierModal}
        disabled={!this.props.customerId} />

      <DropdownOptionButton icon="sticky-note"
        label="Measurement Change at Italian Hub"
        onClick={this.toggleHubModal}
        disabled={!this.props.customerId} />

      <DropdownOptionButton icon="sticky-note"
        label="Measurement Change at Malta Warehouse"
        onClick={this.toggleWarehouseModal}
        disabled={!this.props.customerId} />
    </div>
  )

  render = () => (
    <div className="job-controls">
      <div className="left">
        <DropdownButton
          className='secondary'
          icon='chevron-down'
          label='Email Templates'
        >
          <this.emailOptions />
        </DropdownButton >
        <PostInvoiceButton id={this.props.id} />
      </div>
      <div className="right">
        <PrioritySwitch
          value={this.state.priority}
          onChange={this.setPriority}
        />
        <ModelActions
          modelName={'Job'}
          edit={this.props.edit}
          editAction={this.props.editAction}
          disableEdit={this.props.disableEdit}
          deleteAction={this.props.destroyAction}
        />
      </div>
      {this.modalIfOpen()}
      {this.emailDropdown()}
    </div>
  )
}

import React, { Component } from 'react'
import { connect } from 'react-redux'


import Api from '../../api/call'
import { ADMIN_SETTINGS, ADMIN_SETTINGS_UPDATE } from '../../root/action-types'

import DefaultSupplierHubOptions from './DefaultSupplierHubOptions'

import './admin-settings.scss'

class AdminSettings extends Component {

  componentDidMount = () => (
    this.fetchSettings()
  )

  fetchSettings = () => {
    Api({ request: ADMIN_SETTINGS })
  }

  updateSettings = (data) => {
    Api({request: ADMIN_SETTINGS_UPDATE, data: data })
  }

  renderDefaultSupplierHubOptions = () => {
    const { defaultSupplierHubOptions, loading } = this.props

    if (loading) return null
    return (
      <DefaultSupplierHubOptions
        options={defaultSupplierHubOptions}
        loading={loading}
        updateRequest={this.updateSettings}
      />
    )
  }

  render = () => (
    <div className="admin-settings-container">
      <div className="main-header">
        <h1>Settings</h1>
      </div>
      <div className="admin-settings-content">
        <div className="double-input">
          {this.renderDefaultSupplierHubOptions()}
          <div></div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ adminSettings: { show } }) => ({
  loading: show.loading,
  defaultSupplierHubOptions: show.details.defaultSupplierHubOptions
})

export default connect(mapStateToProps)(AdminSettings)

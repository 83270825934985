import { getCable } from '../../api/call'
import { store } from '../../root/store'

let channel

export const subscribe = () => {
  channel = getCable().subscriptions.create('PlannerChannel', {
    connected: () => debug('CONNECTED TO PLANNER CHANNEL'),
    disconnected: () => debug('DISCONNECTED FROM PLANNER CHANNEL'),
    received,
  })
}

export const unsubscribe = () => {
  channel && getCable().subscriptions.remove(channel)
  channel = undefined
}

const received = (event) => {
  store.dispatch(event)
}

// eslint-disable-next-line
const debug = console.log

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { startCase } from 'lodash'

import Api from '../api/call'
import { LINE_ITEMS, LINE_ITEMS_MANAGE, XERO_CONFIG } from '../root/action-types'
import { Loading } from '../generic/Loading'
import { FieldSection, FocusStatus, Conditional } from '../forms/'
import Autoline from '../jobs/autoline/Autoline'
import Revise from '../quotes/Revise'
import AutolineGroupage from '../groupages/Autoline'

import Item from './Item'

import './billing.scss'


const DEFAULT_COST_ITEM = {
  category: 'cost',
}

const HEADERS = {
  chargeCode: "Code",
  description: "Description",
  companyId: "Party",
  value: "Value",
  taxRate: "Tax Rate",
}

const Headers = () => (
  <div className="headers">
    {Object.keys(HEADERS).map(h =>
      <div key={h} className={`col ${h}`}>{HEADERS[h]}</div>
    )}
    <div className="col destroy" />
  </div>
)

export class Billing extends Component {

  state = { sales: [], cost: [], unlockedLines: false, revise: false }

  toggleUnlockedLines = () => {
    this.setState(prevState => ({ unlockedLines: !prevState.unlockedLines }))
  }

  componentDidMount() {
    const { parentType, parentId } = this.props
    Api({ request: LINE_ITEMS, params: { parentType, parentId } })
    Api({ request: XERO_CONFIG })
    this.props.edit && this.toggleEdit()
  }

  componentDidUpdate(prevProps) {
    if (this.props.edit && !prevProps.edit) { this.toggleEdit() }

    if (this.saving && this.props.status && prevProps.loading) {
      this.saving = false
      this.toggleEdit()
      this.leaveEdit()
    }
  }

  leaveEdit = () => {
    if (!this.props.hasErrors && this.props.toggleEdit) {
      this.props.toggleEdit()
    }
  }

  revise = () => (
    <Revise
      replaceLineItems={this.replaceItems}
      cancel={this.toggleRevise}
    />
  )

  toggleRevise = () => {
    this.setState({ revise: !this.state.revise })
  }

  render = () => {
    if (this.props.loading) { return <Loading /> }
    if (this.state.revise) { return this.revise() }
    return this.loaded()
  }

  toggleEdit = () => {
    this.setState(this.categorisedItems())
  }

  categorisedItems = () => {
    const items = this.props.data || []
    return {
      sales: items.filter(i => i.category == 'sales'),
      cost: items.filter(i => i.category == 'cost'),
    }
  }

  data = () => (
    this.props.edit ? this.state : this.categorisedItems()
  )

  items = ({ category }) => (
    this.data()[category].map((li, i) =>
      <Item
        {...li}
        key={`${category}:${i}`}
        setter={this.setLineItemValue}
        destroy={this.destroyItem}
        index={`${category}:${i}`}
        edit={this.props.edit}
        unlock={this.state.unlockedLines}
      />
    )
  )

  setLineItemValue = ({ index, newValues }) => {
    const [category, i] = index.split(':')
    const items = [...this.state[category]]
    items[i] = { ...items[i], ...newValues }
    this.setState({ [category]: items })
  }

  clearAndAddItems = (clear, add) => {
    const { parentType, parentId } = this.props
    return [
      ...clear.filter(i => i.id).map(i => ({ ...i, _destroy: true })),
      ...add.map(i => ({ ...i, parentType, parentId }))
    ]
  }

  replaceItems = ({ sales, cost }) => {
    const next = {}
    if (sales) {
      next.sales = this.clearAndAddItems(this.state.sales, sales)
    }
    if (cost) {
      next.cost = this.clearAndAddItems(this.state.cost, cost)
    }
    this.setState(next)
  }

  destroyItem = ({ target: { id } }) => {
    const [category, i] = id.split(':')
    const items = [...this.state[category]]

    if (items[i].id) { items[i]._destroy = !items[i]._destroy }
    else { items.splice(i, 1) }

    this.setState({ [category]: items })
  }

  addNewItem = ({ category, ...item }) => {
    const { parentId, parentType } = this.props
    const items = [ ...this.state[category], { parentId, parentType, category, ...item }]
    this.setState({ [category]: items })
  }

  defaultSalesItem = () => {
    const { defaultSalesChargeCode, defaultSalesTaxRate } = this.props.config || {}
    return {
      category: 'sales',
      chargeCode: defaultSalesChargeCode,
      taxRate: defaultSalesTaxRate ,
    }
  }

  addNewItems = {
    sales: () => this.addNewItem(this.defaultSalesItem()),
    cost: () => this.addNewItem(DEFAULT_COST_ITEM),
  }

  newItem = ({ category }) => (
    <this.ifEditing>
      <div className="controls">
        <button
          className="primary"
          onClick={this.addNewItems[category]}
        >New {startCase(category)} Line Item</button>
        <Conditional if={this.props.parentType == 'Job'}>
          <Autoline
            category={category}
            id={this.props.parentId}
            replaceLineItems={this.replaceItems}
          />
        </Conditional>
      </div>
    </this.ifEditing>
  )

  ifEditing = ({ children }) => (
    this.props.edit ? children : null
  )

  save = () => {
    this.saving = true
    const data = { lineItems: [...this.state.sales, ...this.state.cost] }
    Api({ request: LINE_ITEMS_MANAGE, data })
  }

  section = ({ category }) => (
    <FieldSection title={startCase(category)}>
      <div className="tabular">
        <Headers />
        <this.items category={category} />
      </div>
      <this.newItem category={category} />
    </FieldSection>
  )

  loaded = () => (
    <div className="line-items-billing generic-show">
      <div className="form">
        <FocusStatus
          isError={this.props.hasErrors}
          messages={this.props.status || []}
        />
        <this.section category="sales" />
        <this.section category="cost" />
        <this.ifEditing>
          <div className="section controls">
            <button
              className="danger"
              onClick={this.toggleUnlockedLines}
            >
              {`${this.state.unlockedLines ? 'Lock' : 'Unlock'} Posted Items`}
            </button>
            <Conditional if={this.props.parentType == 'Quote'}>
              <button className="secondary" onClick={this.toggleRevise}>
                Revise
              </button>
            </Conditional>
            <Conditional if={this.props.parentType == 'Groupage'}>
              <AutolineGroupage
                id={this.props.parentId}
                replaceLineItems={this.replaceItems}
              />
            </Conditional>
            <button className="submit" onClick={this.save}>Save</button>
          </div>
        </this.ifEditing>
      </div>
    </div>
  )

}

const mapStateToProps = ({ lineItems: { collection }, xero: { config } }) => ({
  ...collection,
  config
})

export default connect(mapStateToProps)(Billing)

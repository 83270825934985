import React from 'react'
import { connect } from 'react-redux'
import { startCase } from 'lodash'

import Api from '../api/call'
import { XERO_CONTACTS_SHOW } from '../root/action-types'
import { Loading } from '../generic/Loading'
import { boolOrValue } from '../generic/display'

import './contact-detail.scss'

const DETAILS = [
  'accountNumber',
  'contactStatus',
  'isSupplier',
  'isCustomer',
]

const Detail = ({ label, value }) => (
  <div className="xero-detail">
    <label className="label">{label}</label>
    <span className="value">{boolOrValue(value)}</span>
  </div>
)

const XeroContactDetails = (props) => (
  <div className="xero-contact-details">
    {DETAILS.map(d =>
      <Detail key={d} label={startCase(d)} value={props[d]} />
    )}
  </div>
)

const XeroDetailsLoader = ({ fetchRequired, id, ...props }) => {
  if (!id) { return null }
  if (fetchRequired) {
    Api({ request: XERO_CONTACTS_SHOW, id })
    return <Loading />
  }
  return <XeroContactDetails {...props} />
}

const mapStateToProps = ({ xero: { contacts: { byId } } }, { id }) => {
  if (byId && byId[id]) { return byId[id] }
  return { fetchRequired: true, id }
}

export default connect(mapStateToProps)(XeroDetailsLoader)

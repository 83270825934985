import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import ModalWindow from '../layout/ModalWindow'
import { Loading } from '../generic/Loading'
import { Status } from '../forms'
import Api from '../api/call'
import ActionTypes from '../root/action-types'
const { XERO_CONTACTS_IMPORT, CONTACTS, ADDRESSES } = ActionTypes

const complete = (companyId) => {
  Api({ request: CONTACTS, params: { companyId } })
  Api({ request: ADDRESSES, params: { companyId } })
}

const Content = ({ companyId, cancel }) => {
  const { loading, error, result }  = useSelector(redux => redux.xero.imports)
  const [posted, setPosted] = useState(false)
  const post = () => {
    Api({ request: XERO_CONTACTS_IMPORT, data: { companyId } })
    setPosted(true)
  }

  if (posted && result) { complete(companyId) }

  if (!posted) { return <Confirm post={post} cancel={cancel} /> }
  if (loading) { return <Loading /> }
  if (result) { return <Result message={result} /> }
  return <Result error={error || 'Something went wrong...'} />
}

const Confirm = ({ post, cancel }) => (
  <div>
    <span>Are you sure you want to import contacts and addresses from Xero?</span>
    <div className="options">
      <button className="danger" onClick={cancel}>Cancel</button>
      <button className="success" onClick={post}>Confirm</button>
    </div>
  </div>
)

const Result = ({ error, message }) => (
  <Status isError={!!error}>
    {error || message}
  </Status>
)

const Button = (props) => {
  const [modal, showModal] = useState(false)
  const close = () => showModal(false)

  return (
    <div className="xero-import-contacts-button">
      <ModalWindow
        className="import-contacts-modal"
        isOpen={modal}
        closeModal={close}
      >
        <h3>Import From Xero</h3>
        <Content {...props} cancel={close} />
      </ModalWindow>
      <button className="submit primary" onClick={() => showModal(true)}>
        Import From Xero
      </button>
    </div>
  )
}


export default Button

import React, { useState } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'

import Api from '../api/call'
import { CONTACTS } from '../root/action-types'

import './recipient-select.scss'

const RecipientSelect = ({ options, onChange, recipient, companyId }) => {
  const [loaded, setLoaded] = useState(false)
  const getContacts =  () => {
    Api({ request: CONTACTS, params: { companyId } } )
  }

  if (options && loaded === false) {
    getContacts()
    setLoaded(true)
  }
  return (
    <div className="recipient-grid">
      <span>To </span>
      <Select
        className="select"
        classNamePrefix="select"
        options={options}
        placeholder={"Select a recipient..."}
        defaultValue={formatDefault(recipient)}
        onChange={onChange}
        isMulti isSearchable
        isClearable={false}
      />
    </div>
  )
}

const formatDefault = recipient => ({value: recipient, label: recipient})

const filterContacts = contacts => contacts.filter(c => c.email !== null)

const selectData = (index) => (
  Object.values(filterContacts(index)).map(c => ( { value: c.email, label: c.email }))
)

const mapStateToProps = ({ companies: { contacts: { index, contactManagement: { loading } } } } ) => ({
  options: index && selectData(index.data),
  loading,
})

export default connect(mapStateToProps)(RecipientSelect)

import { combineReducers } from 'redux'

import {
  standardForCollections
} from '../api/standard-reducers'
import {
  CONTACTS_CREATE_START,
  CONTACTS_CREATE_SUCCESS,
  CONTACTS_CREATE_FAILURE,
  CONTACTS_UPDATE_START,
  CONTACTS_UPDATE_SUCCESS,
  CONTACTS_UPDATE_FAILURE,
  CONTACTS_DESTROY_START,
  CONTACTS_DESTROY_SUCCESS,
  CONTACTS_DESTROY_FAILURE,
  CONTACTS_MANAGEMENT_RESET_STORE,
} from '../root/action-types'

export const DEFAULT_STATE = {
  loading: false,
  contactHasUpdated: false,
}

const successState = (state, action) => ({
  ...state,
  loading: false,
  contactHasUpdated: true,
  errors: action.response.data.errors,
  data: action.response.data.details,
})

const failureState = (state, action) => ({
  ...state,
  loading: false,
  contactHasUpdated: false,
  errors: action.response.data.errors,
})

export function contactManagement(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case CONTACTS_CREATE_START:
    case CONTACTS_UPDATE_START:
    case CONTACTS_DESTROY_START:
      return {
        ...state,
        loading: true,
        contactHasUpdated: false,
      }
    case CONTACTS_CREATE_SUCCESS:
    case CONTACTS_UPDATE_SUCCESS:
    case CONTACTS_DESTROY_SUCCESS:
      return successState(state, action)
    case CONTACTS_CREATE_FAILURE:
    case CONTACTS_UPDATE_FAILURE:
    case CONTACTS_DESTROY_FAILURE:
      return failureState(state, action)
    case CONTACTS_MANAGEMENT_RESET_STORE:
      return DEFAULT_STATE
    default:
      return state
  }
}

export default combineReducers({
  index: standardForCollections('CONTACTS'),
  contactManagement
})

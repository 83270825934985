import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'

import GenericShow from '../../generic/Show'
import {
  TARIFFS_FREIGHT_SHOW, TARIFFS_FREIGHT_DESTROY, TARIFFS_FREIGHT_NEW
} from '../../root/action-types'
import Form from './Form'

const actions = {
  show: TARIFFS_FREIGHT_SHOW,
  new: TARIFFS_FREIGHT_NEW,
  destroy: TARIFFS_FREIGHT_DESTROY,
}

export class Show extends Component {

  id = () => (
    get(this.props.details, 'id') || get(this.props.match, 'params.id', 'new')
  )

  form = (props) => (
    <Form
      fields={this.props.details}
      key={this.id()}
      {...props}
    />
  )

  render = () => (
    <GenericShow
      actions={actions}
      indexPath="/tariffs/freight"
      modelName="Freight Rate"
      form={this.form}
      {...this.props}
    />
  )

}

const mapStateToProps = ({ tariffs: { freight: { show } } }) => (
  show
)

export default connect(mapStateToProps)(Show)

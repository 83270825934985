import React, { Component } from 'react'
import { connect } from 'react-redux'

import { OPENING_TIMES } from '../../root/action-types'
import Api from '../../api/call'

import OpeningTime, { BLANK_OPENING_TIME } from './OpeningTime'

import './all-opening-times.scss'

const DEFAULT_STATE = {
  openingTimeUnderEdit: -1,
}

class AllOpeningTimes extends Component {

  state = {
    ...DEFAULT_STATE
  }

  componentDidUpdate = (prevProps) => {
    const current = this.props.addressId
    const previous = prevProps.addressId
    if (current && current !== previous) {
      this.componentDidMount()
      this.setOpeningTimeUnderEdit(DEFAULT_STATE.openingTimeUnderEdit)
      this.props.checkForPropChanges()
    }
  }

  componentDidMount() {
    this.props.addressId && Api({
      request: OPENING_TIMES,
      params: { addressId: this.props.addressId }
    })
  }

  setOpeningTimeUnderEdit = (id) => {
    this.setState({ openingTimeUnderEdit: id })
    if (!id) { this.componentDidMount() }
  }

  newOpeningTime = () => (
    this.setOpeningTimeUnderEdit('new')
  )

  renderNewOpeningTime = () => (
    <OpeningTime
      openingTime={BLANK_OPENING_TIME}
      editMode={true}
      addressId={this.props.addressId}
      setOpeningTimeUnderEdit={this.setOpeningTimeUnderEdit}
      updateAddressApiRequest={this.props.updateAddressApiRequest}
      newOpeningTime={true}
      open={true}
    />
  )

  renderOpeningTimes = () => (
    (this.props.data || []).map((o, index) => (
      <OpeningTime
        key={`${index}-${this.state.openingTimeUnderEdit == o.id}`}
        openingTime={o}
        addressId={this.props.addressId}
        editMode={this.state.openingTimeUnderEdit == o.id}
        setOpeningTimeUnderEdit={this.setOpeningTimeUnderEdit}
        updateAddressApiRequest={this.props.updateAddressApiRequest}
      />
    ))
  )

  render = () => (
    <div className={"all-opening-times-container"}>
      <div className="address-string">{this.props.addressString}</div>
      {this.renderOpeningTimes()}
      {this.state.openingTimeUnderEdit == 'new' ? this.renderNewOpeningTime() : null}
      <div className="options">
        <button
          className={'new-opening-times'}
          onClick={this.newOpeningTime}
        >
          Add Opening Hours
        </button>
      </div>
    </div>
  )

}

const mapStateToProps = ({ openingTimes: { index } }) => (
  index
)

export default connect(mapStateToProps)(AllOpeningTimes)

import React from 'react'
import Select from 'react-select'
import { orderBy } from 'lodash'

import countryCodes from '../../addresses/country-codes.json'

import { Field } from '../Field'

import './select.scss'

const options = {}

Object.keys(countryCodes).forEach(code =>
  options[code] = { value: code, label: `${countryCodes[code]} (${code})` }
)

const selectOptions = orderBy(Object.values(options), ['label'])

export const CountrySelect = (props) => {
  const {
    labelPrefix = '',
    value,
    onChange,
    isDisabled,
    required,
    error,
    forceError
  } = props

  const changeValue = (o) => {
    onChange(o.value)
  }

  return (
    <Field
      label={`${labelPrefix}Country`}
      required={required}
      error={error || (required && !value)}
      forceError={forceError}
    >
      <Select
        classNamePrefix="select"
        className="select"
        value={options[value]}
        options={selectOptions}
        isDisabled={isDisabled}
        onChange={changeValue}
      />
    </Field>
  )
}

export default CountrySelect

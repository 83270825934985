import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { startCase } from 'lodash'

import Api from '../api/call'
import { COMPANIES_DETAILS } from '../root/action-types'
import { Loading } from './'

const notesSelector = (
  { companies: { details: { details , loading } } }, id
) => {
  return { loading: loading, ...details[id] }
}

const fetchData = (id) => {
  Api({ request: COMPANIES_DETAILS, id: id })
}

const SidebarCompanyNotes = (props) => {
  const { id, category, companyRole } = props

  useEffect(() => fetchData(id), [])
  const { loading, notes } = useSelector(state =>
    notesSelector(state, id)
  )
  
  if (loading) { return <Loading /> }

  const categoryNotes = () => {
    if (!category) return (notes || [])
    return (notes || []).filter(n => n["category"] == category) 
  }

  const headerText = () => {
    const prefix = [companyRole, category].filter(Boolean).map(startCase).join(' ')
    return prefix ? `${prefix} Notes:` : `Notes:`
  }

  return (
    <div className="details sidebar-company-notes">
      <h4>{headerText()}</h4>
      {
        categoryNotes().map(note => {
          return (
            <div key={`company-note-${note.id}`}>
              <h5>{note.title}</h5>
              <pre>{note.content}</pre>
              <hr />
            </div>
          )
        })
      }
    </div>
  )
}

SidebarCompanyNotes.propTypes = {
  id: PropTypes.string.isRequired,
  category: PropTypes.string,
  companyRole: PropTypes.string,
}

export default SidebarCompanyNotes
import { combineReducers } from 'redux'

import ActionTypes from '../root/action-types'
const {
  XERO_CONTACTS_START,
  XERO_CONTACTS_SUCCESS,
  XERO_CONTACTS_FAILURE,
  XERO_CONTACTS_SHOW_START,
  XERO_CONTACTS_SHOW_SUCCESS,
  XERO_CONTACTS_SHOW_FAILURE,
  XERO_CONTACTS_CREATE_START,
  XERO_CONTACTS_CREATE_SUCCESS,
  XERO_CONTACTS_CREATE_FAILURE,
  XERO_CONTACTS_IMPORT_START,
  XERO_CONTACTS_IMPORT_SUCCESS,
  XERO_CONTACTS_IMPORT_FAILURE,
  XERO_INVOICES_CREATE_START,
  XERO_INVOICES_CREATE_SUCCESS,
  XERO_INVOICES_CREATE_FAILURE,
  XERO_ACCOUNTS_START,
  XERO_ACCOUNTS_SUCCESS,
  XERO_ACCOUNTS_FAILURE,
  XERO_TAX_RATES_START,
  XERO_TAX_RATES_SUCCESS,
  XERO_TAX_RATES_FAILURE,
  XERO_CONFIG_START,
  XERO_CONFIG_SUCCESS,
  XERO_CONFIG_FAILURE,
  SUPPLIER_INVOICES_CREATE_START,
  SUPPLIER_INVOICES_CREATE_SUCCESS,
  SUPPLIER_INVOICES_CREATE_FAILURE,
} = ActionTypes

export const defaultState = {
  byId: null,
}

function contacts(state = defaultState, action) {
  switch (action.type) {
    case XERO_CONTACTS_START:
    case XERO_CONTACTS_SHOW_START:
    case XERO_CONTACTS_CREATE_START:
      return { ...state, loading: true, error: null, createdId: null }
    case XERO_CONTACTS_SUCCESS:
    case XERO_CONTACTS_SHOW_SUCCESS:
      return {
        ...state,
        loading: false,
        byId: { ...state.byId, ...action.response.data },
      }
    case XERO_CONTACTS_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        createdId: action.response.data,
      }
    case XERO_CONTACTS_FAILURE:
    case XERO_CONTACTS_SHOW_FAILURE:
    case XERO_CONTACTS_CREATE_FAILURE:
      return { ...state, loading: false, error: action.response.data }
    default:
      return state
  }
}


const sortAccounts = (accounts) => {
  const sorted = { all: accounts, byCode: {} }
  accounts.forEach(a => {
    sorted[a.type] = sorted[a.type] || []
    sorted[a.type].push(a)
    sorted.byCode[a.code] = a
  })
  return sorted
}

function accounts(state = {}, action) {
  switch (action.type) {
    case XERO_ACCOUNTS_START:
      return { ...state, loading: true, error: null}
    case XERO_ACCOUNTS_SUCCESS:
      return {
        ...state,
        loading: false,
        ...sortAccounts(action.response.data),
      }
    case XERO_ACCOUNTS_FAILURE:
      return { ...state, loading: false, error: action.response.data, all: [] }
    default:
      return state
  }
}

function invoices(state = defaultState, action) {
  switch (action.type) {
    case XERO_INVOICES_CREATE_START:
      return { ...state, loading: true, }
    case XERO_INVOICES_CREATE_SUCCESS:
      return { ...state, loading: false, byId: action.response.data, }
    case XERO_INVOICES_CREATE_FAILURE:
      return { ...state, loading: false, error: action.response.data }
    default:
      return state
  }
}

function supplierInvoices(state = {}, action) {
  switch (action.type) {
    case SUPPLIER_INVOICES_CREATE_START:
      return { ...state, loading: true, }
    case SUPPLIER_INVOICES_CREATE_SUCCESS:
      return { ...state, loading: false, ...action.response.data, }
    case SUPPLIER_INVOICES_CREATE_FAILURE:
      return { ...state, loading: false, ...action.response.data }
    default:
      return state
  }
}

const sortTaxRates = (taxRates) => {
  const sorted = { all: taxRates, sales: [], cost: [] }
  taxRates.forEach(t => {
    if (t.revenue) { sorted['sales'].push(t) }
    if (t.expenses) { sorted['cost'].push(t) }
  })
  return sorted
}

function taxRates(state = {}, action) {
  switch (action.type) {
    case XERO_TAX_RATES_START:
      return { ...state, loading: true, error: null}
    case XERO_TAX_RATES_SUCCESS:
      return {
        ...state,
        loading: false,
        ...sortTaxRates(action.response.data),
      }
    case XERO_TAX_RATES_FAILURE:
      return { ...state, loading: false, error: action.response.data, all: [] }
    default:
      return state
  }
}

function config(state = {}, action) {
  switch (action.type) {
    case XERO_CONFIG_START:
      return { ...state, loading: true }
    case XERO_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.response.data
      }
    case XERO_CONFIG_FAILURE:
      return { ...state, loading: false, error: action.response.data }
    default:
      return state
  }
}

function imports(state = {}, action) {
  switch (action.type) {
    case XERO_CONTACTS_IMPORT_START:
      return { ...state, loading: true, error: null, result: null }
    case XERO_CONTACTS_IMPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.response.data
      }
    case XERO_CONTACTS_IMPORT_FAILURE:
      return { ...state, loading: false, error: action.response.data }
    default:
      return state
  }
}

export default combineReducers({
  contacts,
  invoices,
  supplierInvoices,
  accounts,
  taxRates,
  config,
  imports,
})


import React, { Component } from 'react'
import { get } from 'lodash'

import {
  CONTACTS_CREATE, CONTACTS_UPDATE, COMPANIES_UPDATE, COMPANIES_SHOW,
} from '../root/action-types'
import Api from '../api/call'

import AccordionItem from '../generic/accordion/AccordionItem'
import DisplayContact from './DisplayContact'
import EditContact from './EditContact'

export const BLANK_CONTACT = {
  prefix: '',
  firstName: '',
  lastName: '',
  jobTitle: '',
  jobRole: '',
  accounts: false,
  sales: false,
}

export const constructName = ({prefix, firstName, lastName, jobTitle}) => {
  const titleSuffix = () => jobTitle ? `(${jobTitle})` : ''
  const nameElements = [
    prefix,
    firstName,
    lastName,
    titleSuffix(),
  ]
  return nameElements.join(" ")
}

export default class Contact extends Component {

  createContactApiRequest = (attributes) => (
    Api({
      request: CONTACTS_CREATE,
      data: {
        companyId: this.props.companyId,
        ...attributes
      }
    })
  )

  updateContactApiRequest = (attributes) => (
    Api({
      request: CONTACTS_UPDATE,
      id: get(this.props.contact, 'id'),
      data: attributes,
    })
  )

  setPrimary = () => (
    Api({
      request: COMPANIES_UPDATE,
      id: this.props.companyId,
      data: { primaryContactId: get(this.props.contact, 'id') }
    })
  )

  updateCompany = () => (
    Api({ request: COMPANIES_SHOW, id: this.props.companyId })
  )

  resetContactUnderEdit = () => (
    this.props.setContactUnderEdit('')
  )

  renderContactDetails = () => {
    const { editMode, contact, newContact, setContactUnderEdit } = this.props
    if (editMode) {
      return (
        <EditContact
          contact={contact}
          newContact={newContact}
          apiRequest={newContact ? this.createContactApiRequest : this.updateContactApiRequest}
          updateCompany={this.updateCompany}
          resetContactUnderEdit={this.resetContactUnderEdit}
        />
      )
    }
    return (
      <DisplayContact
        contact={contact}
        setContactUnderEdit={setContactUnderEdit}
        isPrimary={this.props.isPrimary}
        setPrimary={this.setPrimary}
      />
    )
  }

  render = () => (
    <AccordionItem
      name={constructName(this.props.contact)}
      open={this.props.open}
    >
      <this.renderContactDetails />
    </AccordionItem>
  )

}

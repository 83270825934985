import React, { Component } from 'react'

import { Field } from '../../forms/Field'

import './RegNumbers.scss'

export default class RegNumbers extends Component {

  state = {
    ...this.props.regNumbers
  }

  setRegNumber = (e) => (
    this.setState({ [e.currentTarget.name]: e.currentTarget.value })
  )

  saveChanges = () => (
    this.props.updateApiRequest(this.state)
  )

  saveButton = () => (
    <button className="submit" onClick={this.saveChanges}>
      Save
    </button>
  )

  editControls = () => (
    this.props.edit ? <this.saveButton /> : null
  )

  renderInputField = ({name, label}) => (
    <Field label={label}>
      <input
        name={name}
        value={this.state[name]}
        onChange={this.setRegNumber}
        disabled={!this.props.edit}
        type="text"
      />
    </Field>
  )

  render = () => (
    <div className="company-reg-numbers-container">
      <h4>Reg. Numbers</h4>
      <div className="reg-numbers-form">
        <this.renderInputField
          name='companyRegistration'
          label='Company Registration'
        />
        <this.renderInputField
          name='eoriNumber'
          label='EORI Number'
        />
        <this.renderInputField
          name='vatNumber'
          label='VAT Number'
        />
      </div>
      <this.editControls />
    </div>
  )
}

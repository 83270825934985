import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { startCase } from 'lodash'

import { Field } from '../forms/Field'
import { InputGroup } from '../forms/InputGroup'
import { PackageTypeSelect } from '../forms/select'


function DimensionLine(props) {

  const numberField = ({ suffix, name, autoFocus=false }) => {
    const { line, disabled, forceError } = props
    const value = line[name]

    return (
      <Field
        label={startCase(name)}
        className="flex-1"
        error={!(value > 0)}
        forceError={forceError}
      >
        <InputGroup suffix={suffix}>
          <input
            name={name}
            value={value || ''}
            onChange={updateThisLineNumberField}
            disabled={disabled}
            type="number"
            min="0"
            autoFocus={autoFocus}
          />
        </InputGroup>
      </Field>
    )
  }

  const packageTypeField = ({ name }) => {
    const { line, disabled } = props

    return (
      <Field label="Package Type" className="flex-2">
        <PackageTypeSelect
          onChange={updateThisLinePackageType}
          valueName={line[name]}
          disabled={disabled}
        />
      </Field>
    )
  }

  const replaceNegativeValues = ({ target: { name, value } }) => (
    { [name]: value < 0 ? 0 : value }
  )

  const updateThisLineNumberField = (e) => {
    props.updateLine(props.lineIndex, replaceNegativeValues(e))
  }

  const updateThisLinePackageType = (packageType) => {
    props.updateLine(props.lineIndex, packageType)
  }

  const removeThisLine = () => (
    props.removeLine(props.lineIndex)
  )

  const removeLineButton = () => (
    <button
      className="remove-dimension-line"
      onClick={removeThisLine}
      tabIndex="-1"
    >
      <FontAwesomeIcon icon={['fas', 'minus-circle']} className="icon" />
    </button>
  )

  const renderRemoveLineButton = () => (
    !props.disabled ? removeLineButton() : null
  )

  return (
    <div className="dimension-line">
      { numberField({name: 'quantity', autoFocus: true}) }
      { packageTypeField({name: 'type'}) }
      { numberField({suffix: 'cm', name: 'length'}) }
      { numberField({suffix: 'cm', name: 'width'}) }
      { numberField({suffix: 'cm', name: 'height'}) }
      { numberField({suffix: 'kg', name: 'weight'}) }
      { renderRemoveLineButton() }
    </div>
  )

}

export default DimensionLine

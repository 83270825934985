import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import Api from '../../../api/call'
import { GROUPAGE_BULK_INVOICE } from '../../../root/action-types'

import InvoiceRequestStatus from './InvoiceRequestStatus'
import JobRow from './JobRow'
import SelectAllJobs from './SelectAllJobs'
import SendInvoices from './SendInvoices'

import { sendInvoicesSelector, markedForInvoicingSelector } from './redux-selectors'

import './invoices.scss'


const HeaderRows = () => (
  <div className={'job-row header'}>
    <div></div>
    <span>JOB</span>
    <span className="customer-name">CUSTOMER</span>
    <span>CUBE</span>
    <span>WEIGHT</span>
    <span>VALUE</span>
    <span>STATUS</span>
    <span className="invoice-details"></span>
  </div>
)

const sendJobsRequest = ({ groupageData: { id } }) => {
  Api({
    request: GROUPAGE_BULK_INVOICE,
    id
  })
}

const renderJobs = (jobIds) => (
  jobIds.map(id => <JobRow id={id} key={id} />)
)

export const Invoices = (props) => {
  const loadingRequest = useSelector(sendInvoicesSelector)
  useEffect(() => sendJobsRequest(props), [loadingRequest])
  const jobIds = Object.keys(useSelector(markedForInvoicingSelector))

  return (
    <div className="invoices">
      <h4>Sales Invoices</h4>
      <InvoiceRequestStatus />
      <div className="jobs">
        <HeaderRows />
        {renderJobs(jobIds)}
      </div>
      <div className="button-row">
        <SelectAllJobs />
        <SendInvoices />
      </div>
    </div>
  )
}

export default Invoices

import React, { Component } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'

import { countryName } from '../addresses/countries'
import Api from '../api/call'
import { CALCULATOR_COUNTRIES } from '../root/action-types'


class CountrySelect extends Component {

  componentDidMount() {
    if (!this.props.options.length) {
      Api({ request: CALCULATOR_COUNTRIES })
    }
  }

  onChange = ({ value }) => (
    this.props.onChange && this.props.onChange(value)
  )

  onMultiChange = (selectedCountries) => {
    const selectedValues = selectedCountries.map(c => c.value)
    return this.props.onChange && this.props.onChange(selectedValues)
  }

  className = () => `select ${this.props.isMulti ? 'multi' : null}`

  render = () => {
    const { isMulti } = this.props
    const className = this.className()
    const value = isMulti ? findMultiValue(this.props) : findValue(this.props)
    const onChange = isMulti ? this.onMultiChange : this.onChange

    return (
      <Select
        isMulti={isMulti}
        classNamePrefix="select"
        className={className}
        {...this.props}
        value={value}
        onChange={onChange}
      />
    )
  }

}

const findMultiValue = ({value, options}) => (
  value && value.map(v => findValue({ value: v, options }))
)

const findValue = ({ value, options }) => (
  value && options.find(o => o.value == value)
)

const selectOptions = (options) => (
  options.map(c => ({ value: c, label: `${countryName(c)} (${c})` }))
)

const mapStateToProps = ({ calculator: { countries, loadingCountries } }) => ({
  options: selectOptions(countries),
  placeholder: loadingCountries ? 'Loading...' : 'Select country...',
})

export default connect(mapStateToProps)(CountrySelect)

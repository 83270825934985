import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import { MARK_FOR_INVOICING } from '../../../root/action-types'
import { CheckMark } from '../../../forms/CheckMark'

import { markedForInvoicingSelector, jobSelector } from './redux-selectors'
import { jobPreventedFromInvoicingBy } from './invoice-validation'
import { actualMeasurements } from '../../../jobs/job/measurements'

export const JobRow = ({ id }) => {
  const isMarkedForInvoicing = useSelector(markedForInvoicingSelector)[id]
  const jobData = useSelector(state => jobSelector(state, id))
  const dispatch = useDispatch()

  const {
    reference,
    customerId,
    customerName,
    value,
    status,
    postedToXero,
  } = jobData
  const { cubicMetres, grossWeightKg } = actualMeasurements(jobData)

  const onClick = () => {
    dispatch({ type: MARK_FOR_INVOICING, payload: { id } })
  }

  const errors = jobPreventedFromInvoicingBy(jobData)

  const isDisabled = () => postedToXero || errors.length != 0

  const renderDetails = () => (
    <React.Fragment>
      {renderPostedToXeroMessage()}
      {renderErrors()}
    </React.Fragment>
  )

  const renderPostedToXeroMessage = () => (
    postedToXero ? <span className="detail posted">Posted to Xero.</span> : null
  )

  const renderErrors = () => (
    errors.map((reason, index) =>
      <span
        key={`${id}-${index}-${reason}`}
        className="detail"
      >
        {reason}
      </span>
    )
  )

  const formatValue = (value, unit, precision = 2) => (
    value ? `${value.toFixed(precision)} ${unit}` : '?'
  )

  return (
    <div onClick={isDisabled() ? undefined : onClick} className={`job-row`}>
      <CheckMark checked={isMarkedForInvoicing} disabled={isDisabled()} />
      <span>
        <Link to={`/jobs/${id}`}>{reference}</Link>
      </span>
      <span className="customer-name">
        <Link to={`/companies/${customerId}`}>{customerName}</Link>
      </span>
      <span>{formatValue(cubicMetres, 'm³')}</span>
      <span>{formatValue(grossWeightKg, 'kg', 0)}</span>
      <span>{formatValue(value, '€')}</span>
      <span>{status}</span>
      <span className="invoice-details">{renderDetails()}</span>
    </div>
  )
}

export default JobRow

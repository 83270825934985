import React, { Component } from 'react'
import { connect } from 'react-redux'

import SimpleIndexTable from '../../generic/indexTable/Simple'
import { TARIFFS_FREIGHT } from '../../root/action-types'

const COLUMNS = [
  { Header: "Name", accessor: "name" },
  { Header: "Low", accessor: "low" },
  { Header: "Mid", accessor: "mid" },
  { Header: "High", accessor: "high" },
  { Header: "Mid Threshold", accessor: "midThreshold" },
  { Header: "High Threshold", accessor: "highThreshold" },
]

export class Index extends Component {

  render = () => (
    <div className="tariffs-freight-index">
      <SimpleIndexTable
        title="Freight Sales"
        actionType={TARIFFS_FREIGHT}
        model="freight"
        linkString="/tariffs/freight/"
        columns={COLUMNS}
        data={this.props.data}
        history={this.props.history}
      />
    </div>
  )

}

const mapStateToProps = ({ tariffs: { freight: { index } } }) => (
  index
)

export default connect(mapStateToProps)(Index)

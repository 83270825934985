import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import ModalWindow from '../layout/ModalWindow'
import { Loading } from '../generic/Loading'
import { Status } from '../forms'
import Api from '../api/call'
import ActionTypes from '../root/action-types'
const { XERO_CONTACTS_CREATE } = ActionTypes


const Content = ({ companyId, cancel, complete }) => {
  const { loading, error, createdId }  = useSelector(redux => redux.xero.contacts)
  const [posted, setPosted] = useState(false)
  const post = () => {
    Api({ request: XERO_CONTACTS_CREATE, data: { companyId } })
    setPosted(true)
  }

  if (posted && createdId) { setTimeout(() => complete(createdId), 2000) }

  if (!posted) { return <Confirm post={post} cancel={cancel} /> }
  if (loading) { return <Loading /> }
  if (createdId) { return <Result /> }
  return <Result error={error || 'Something went wrong...'} />
}

const Confirm = ({ post, cancel }) => (
  <div>
    <span>Are you sure you want to export this company to Xero?</span>
    <div className="options">
      <button className="danger" onClick={cancel}>Cancel</button>
      <button className="success" onClick={post}>Confirm</button>
    </div>
  </div>
)

const Result = ({ error }) => (
  <Status isError={!!error}>
    {error || "Company exported to Xero successfully!"}
  </Status>
)

const Button = (props) => {
  const [modal, showModal] = useState(false)
  const close = () => showModal(false)

  return (
    <div className="xero-export-contact-button">
      <ModalWindow
        className="export-contact-modal"
        isOpen={modal}
        closeModal={close}
      >
        <h3>Export To Xero</h3>
        <Content {...props} cancel={close} />
      </ModalWindow>
      <button className="submit primary" onClick={() => showModal(true)}>
        Export To Xero
      </button>
    </div>
  )
}


export default Button

import React from 'react'
import { Checkbox } from 'pretty-checkbox-react'

import './pretty-checkbox-styles.scss'


export const CheckMark = ({ label, ...props }) => (
  <div className="checkmark">
    <Checkbox
      color="success-o"
      shape="round"
      {...props}
    >
      {label}
    </Checkbox>
  </div>
)

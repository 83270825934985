import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import MultiSelectTextInput from '../../forms/select/MultiSelectTextInput'

import './default-supplier-hub-options.scss'


export default class DefaultSupplierHubOptions extends Component {

  state = {
    edit: false,
    options: this.props.options || []
  }

  toggleEdit = () => {
    if (this.state.edit) {
      this.updateOptions()
    }
    this.setState(prev => ({ edit: !prev.edit }))
  }

  setOptions = (options) => {
    this.setState({ options })
  }

  updateOptions = () => {
    this.props.updateRequest({
      defaultSupplierHubOptions: this.state.options
    })
  }

  editButton = () => {
    const { edit } = this.state

    return (
      <button
        onClick={this.toggleEdit}
        className={"option-edit-toggle danger"}
      
      >
        {edit ? 'Save' : <FontAwesomeIcon icon={"pencil-alt"} fixedWidth />}
      </button>
    )
  }

  render = () => {
    const { edit, options } = this.state
    const { loading } = this.props

    return (
      <div className="default-supplier-hub-options">
        <MultiSelectTextInput
          label={"Default Supplier Hub Options"}
          placeholder={"Input an option and press enter..."}
          isDisabled={!edit}
          valueArray={options}
          onChange={this.setOptions}
          loading={loading}
        />
        <this.editButton />
      </div>
    )
  }
}

import React, { Component } from 'react'
import { omit } from 'lodash'

import { InputGroup } from './InputGroup'

import './money-input.scss'

const DEFAULT_SYMBOL = '€'

export class MoneyInput extends Component {

  state = {
    focused: false
  }

  currency = () => (
    this.props.prefix || DEFAULT_SYMBOL
  )

  // We want to format value to 2 decimal places, but not while the user is
  // typing, which makes for poor UX
  value = () => {
    if (this.state.focused) {
      return this.props.value
    } else if (!this.props.value) {
      return ''
    }
    return Number(this.props.value).toFixed(2)
  }

  onFocus = () => (
    this.setState({ focused: true })
  )

  onBlur = () => (
    this.setState({ focused: false })
  )

  render = () => (
    <InputGroup prefix={this.currency()} suffix={this.props.suffix}>
      <input
        type="number"
        className="money-input"
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        value={this.value()}
        {...omit(this.props, ['prefix', 'suffix', 'value'])}
      />
    </InputGroup>
  )
}


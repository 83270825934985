import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { startCase } from 'lodash'

import Accordion from '../../../generic/accordion/Accordion'
import Note, { blankNoteWithCategory } from './Note'

import './NotesTab.scss'

const companyDataSelector = ({ companies: { show: { data } } }) => data

const AllNotes = (props) => {
  const companyData = useSelector(companyDataSelector)
  const notes = companyData.notes || []
  const ids = notes.map(n => n["id"]).reverse()

  const [newNote, setNewNote] = useState(false)
  const [noteUnderEdit, setNoteUnderEdit] = useState('')

  const addNote = () => {
    setNewNote(true)
    setNoteUnderEdit('new')
  }

  const resetNoteUnderEdit = () => {
    setNewNote(false)
    setNoteUnderEdit('')
  }

  const noteUpdater = (id) => {
    const updateNote = (note) => {
      const newNotes = notes.filter(note => note["id"] != id).concat(note)
      props.updateApiRequest({ notes: newNotes })
      resetNoteUnderEdit()
    }
    return updateNote
  }

  const destroyNote = (id) => {
    const newNotes = notes.filter(note => note["id"] != id)
    props.updateApiRequest({ notes: newNotes })
  }

  const idRenderList = () => {
    const notesInThisCategory = ids.filter(id => notes.find(n => n["id"] == id)["category"] == props.category)
    return newNote ? ['new', ...notesInThisCategory] : notesInThisCategory
  }

  const newNoteLabel = () => {
    const { category } = props
    return category ? `New ${startCase(category)} Note` : 'New Note'
  }

  return (
    <Accordion
      title={'Further Information'}
      newItemLabel={newNoteLabel()}
      newItemOnClick={addNote}
    >
      {idRenderList().map(id => (
        <Note
          id={id}
          key={id}
          note={notes.find(n => n["id"] == id) || blankNoteWithCategory(props.category)}
          updateNote={noteUpdater(id)}
          open={id == 'new'}
          editMode={id === noteUnderEdit}
          setNoteUnderEdit={setNoteUnderEdit}
          resetNoteUnderEdit={resetNoteUnderEdit}
          destroy={destroyNote}
        />
      ))}
    </Accordion>
  )
}

export default AllNotes


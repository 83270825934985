import { combineReducers } from 'redux'

import { units } from './manage/units'
import { bookings } from './manage/bookings'
import { config } from './manage/config'
import { events } from './manage/events'
import { filter } from './manage/filter'

export default combineReducers({
  units,
  bookings,
  config,
  events,
  filter,
})


import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { get } from 'lodash'

import NavGroup from './NavGroup.jsx'
import NavSubGroup from './NavSubGroup.jsx'
import NavItem from './NavItem.jsx'

import './Navbar.scss'

export class Navbar extends Component {
  state = {
    selectedGroup: '',
    selectedSubGroup: '',
  }

  componentDidMount = () => {
    document.addEventListener('click', this.clickOut)
  }

  componentWillUnmount = () => {
    document.removeEventListener('click', this.clickOut)
  }

  setRef = (node) => {
    this.ref = node
  }

  clickOut = ({ target }) => {
    if (!this.props.expanded && this.ref && !this.ref.contains(target)) {
      this.closeNavbar()
    }
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.expanded) { return }
    if (prevProps.expanded ||
        prevProps.location.pathname != this.props.location.pathname) {
      this.closeNavbar()
    }
  }

  selectGroup = (group) => (
    this.setState(prevState => ({
      selectedGroup: group == prevState.selectedGroup ? '' : group,
      selectedSubGroup: ''
    }))
  )

  closeNavbar = () => {
    this.selectGroup('')
  }

  selectSubGroup = (subgroup) => (
    this.setState(prevState => ({
      selectedSubGroup: subgroup == prevState.selectedSubGroup ? '' : subgroup
    }))
  )

  getCurrentPath = () => (
    get(this.props.location, 'pathname', false)
  )

  navGroup = (props) => (
    <NavGroup
      onClick={this.selectGroup}
      expanded={this.props.expanded}
      {...this.state}
      {...props}
    />
  )

  topLevelItem = (props) => (
    <NavItem
      className="navbar-item"
      currentPath={this.getCurrentPath()}
      {...props}
    />
  )

  itemInNavGroup = (props) => (
    <NavItem
      className="navbar-item-in-group"
      currentPath={this.getCurrentPath()}
      {...props}
    />
  )

  navSubGroup = (props) => (
    <NavSubGroup
      onClick={this.selectSubGroup}
      {...this.state}
      {...props}
    />
  )

  itemInNavSubGroup = (props) => (
    <NavItem className="navbar-item-in-subgroup" {...props} />
  )

  modelLinks = ({ name, path, icon }) => (
    <this.navGroup id={name.toLowerCase()} text={name} icon={icon}>
      <this.itemInNavGroup text="View All" icon={icon} path={path} />
      <this.itemInNavGroup
        text="Create New"
        icon={['far', 'plus-square']}
        path={`${path}/new`}
      />
    </this.navGroup>
  )

  render_navbar = () => (
    <nav ref={this.setRef}>
      <this.topLevelItem
        text={this.props.expanded ? 'Dashboard' : ''}
        icon={['fas', 'tachometer-alt']}
        path="/"
      />

      <this.navGroup id="admin" text="Admin" icon={['fas', 'user-shield']}>
        <this.itemInNavGroup
          text="User Management"
          icon={['fas', 'user-shield']}
          path="/admin/user-management"
        />
        <this.itemInNavGroup
          text="Settings"
          icon={['fas', 'cog']}
          path="/admin/settings"
        />
        <this.itemInNavGroup
          text="Package Types"
          icon="pallet"
          path="/package-types"
        />
      </this.navGroup>

      <this.modelLinks name="Companies" icon={['fas', 'building']} path="/companies" />
      <this.modelLinks name="Quotes" icon={['far', 'comment']} path="/quotes" />
      <this.modelLinks name="Jobs" icon="box-open" path="/jobs" />
      <this.modelLinks name="Groupages" icon="truck-moving" path="/groupages" />

      <this.navGroup id="trailer planner" text="Trailer Planner" icon="solar-panel">
        <this.itemInNavGroup
          text="Trailer Planner"
          icon="solar-panel"
          path="/planner"
        />
      </this.navGroup>

      <this.navGroup id="tariffs" text="Tariffs" icon="comment-dollar">
        <this.itemInNavGroup
          text="Pickup/Distribution"
          icon="truck-loading"
          path="/tariffs/distribution"
        />
        <this.itemInNavGroup
          text="Customer"
          icon="handshake"
          path="/tariffs/customers"
        />
        <this.itemInNavGroup
          text="Surcharges"
          icon="cart-plus"
          path="/tariffs/surcharges"
        />
        <this.itemInNavGroup
          text="Freight Sales"
          icon="truck-moving"
          path="/tariffs/freight"
        />
        <this.itemInNavGroup
          text="Config"
          icon="cog"
          path="/tariffs/config"
        />
      </this.navGroup>
      <this.navGroup id="templates" text="Templates" icon="object-ungroup">
        <this.itemInNavGroup
          text="Groupages"
          icon="truck-moving"
          path="/templates/groupages"
        />
      </this.navGroup>
    </nav>
  )

  render() {
    return (
      <div
        className={
          this.props.expanded ? 'navbar-expanded' : 'navbar-collapsed'
        }
      >
        {this.render_navbar()}
      </div>
    )
  }
}

export default withRouter(Navbar)

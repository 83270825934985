import React, { Component } from 'react'
import { connect } from 'react-redux'

import { IconButton } from '../generic/Buttons'
import { Link } from 'react-router-dom'

import Api from '../api/call'
import {
  PLANNER_WEEK,
} from '../root/action-types'
import ControlBar from './control-bar/ControlBar'
import UnitBlock from './block/UnitBlock'

import filterBookings from './filter-bookings'

import { subscribe, unsubscribe } from './manage/subscription'

import './trailer-planner.scss'


class TrailerPlanner extends Component {

  componentDidMount() {
    Api({ request: PLANNER_WEEK, params: { weekNumber: this.props.weekNumber } })
    Api({ request: 'PLANNER_UNASSIGNED_UNITS' })
    subscribe()
  }

  componentWillUnmount = unsubscribe

  render = () => (
    <div className="trailer-planner">
      <div className="header-row">
        <h1>Trailer Planner</h1>
        <Link to="/groupages/new">
          <IconButton className="primary" icon="plus-circle">
            New Groupage
          </IconButton>
        </Link>
      </div>
      <ControlBar />
      {this.props.blocks.map(b => <UnitBlock id={b} key={b} />)}
    </div>
  )
}

const mapStateToProps = ({ planner: { bookings, filter: { params }, config: { jobStatuses } } }) => ({
  blocks: [...filterBookings(bookings, params), ...jobStatuses],
  weekNumber: params.weekNumber,
})

export default connect(mapStateToProps)(TrailerPlanner)

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { USERS_DESTROY } from '../../root/action-types'
import Api from '../../api/call'
import { userRoleIcons } from '../../users/roles'
import ModalWindow from '../../layout/ModalWindow'

const DEFAULT_USER_ROW_STATE = {
  confirmDeleteModalOpen: false,
}

export class UserRow extends Component {
  state = {
    ...DEFAULT_USER_ROW_STATE
  }

  openConfirmDeleteModal = () => (
    this.setState({ confirmDeleteModalOpen: true })
  )

  closeConfirmDeleteModal = () => (
    this.setState({ confirmDeleteModalOpen: false })
  )

  deleteUser = () => {
    Api({ request: USERS_DESTROY, id: this.props.user.id })
    this.closeConfirmDeleteModal()
  }

  constructNameString = (props) => {
    return (
      (props.firstName || '')
      + ' '
      + (props.lastName || '')
    )
  }

  editUser = () => (
    this.props.editUser(this.props.user)
  )

  renderModal = (props) => (
    <ModalWindow
      className={"user-row-confirm-delete-modal"}
      isOpen={this.state.confirmDeleteModalOpen}
      closeModal={this.closeConfirmDeleteModal}
    >
      <h3>Confirm User Deletion</h3>
      <span className="name">
        <strong>{props.name}</strong>
      </span>
      <span className="email">
        {props.email}
      </span>
      <div className="options">
        <button
          onClick={this.deleteUser}
          className="delete-button"
        >
          Delete User
        </button>
      </div>
    </ModalWindow>
  )

  renderName = (props) => (
    <div className="user-row-name">
      {this.constructNameString(props)}
      <span className="user-row-new-user-label">
        {props.id == this.props.newId ? ' New' : null}
      </span>
    </div>
  )

  getUserIcons = (props) => (
    Object.keys(userRoleIcons).map(role => {
      if (props[role]) {
        return(
          <FontAwesomeIcon icon={userRoleIcons[role]} fixedWidth key={role} />
        )
      }
      return null
    })
  )

  render = () => (
    <div className="user-row">
      <this.renderModal
        name={this.constructNameString(this.props.user)}
        email={this.props.user.email}
      />

      <div className="user-row-left">
        {this.renderName(this.props.user)}
        <span className="user-row-email">{this.props.user.email}</span>
      </div>
      <div className="user-row-right">
        <div className="user-row-permissions">
          {this.getUserIcons(this.props.user)}
        </div>
        <div className="user-row-controls">
          <span
            className="user-row-edit"
            onClick={this.editUser}
          >
            Edit User
          </span>
          <span
            className="user-row-delete"
            onClick={this.openConfirmDeleteModal}
          >
            Delete User
          </span>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ users: { manageUsers } }) => ({
  manageUsers
})

export default connect(mapStateToProps)(UserRow)

import React, { Component } from 'react'
import { get } from 'lodash'

import {
  ADDRESSES_CREATE, ADDRESSES_UPDATE, COMPANIES_UPDATE
} from '../root/action-types'
import Api from '../api/call'

import AccordionItem from '../generic/accordion/AccordionItem'
import DisplayAddress from './DisplayAddress'
import EditAddress from './EditAddress'

export const BLANK_ADDRESS = {
  id: '',
  lines: ['', '', '', ''],
  postcode: '',
  office: false,
  warehouse: false,
  countryCode: '',
}

const constructAddressString = ({ lines, postcode }) => (
  [
    ...(lines || []),
    postcode
  ].filter(Boolean).join(", ")
)

export default class Address extends Component {

  createAddressApiRequest = (attributes) => (
    Api({
      request: ADDRESSES_CREATE,
      data: {
        companyId: this.props.companyId,
        ...attributes,
      }
    })
  )

  updateAddressApiRequest = (attributes) => (
    Api({
      request: ADDRESSES_UPDATE,
      id: get(this.props.address, 'id'),
      data:  attributes,
    })
  )

  setPrimary = () => {
    Api({
      request: COMPANIES_UPDATE,
      id: this.props.companyId,
      data: { primaryAddressId: get(this.props.address, 'id') },
    })
  }

  resetAddressUnderEdit = () => (
    this.props.setAddressUnderEdit('')
  )

  renderEditMode = (props) => (
    <EditAddress {...props} />
  )

  renderAddressDetails = () => {
    const { editMode, address, newAddress, setAddressUnderEdit } = this.props
    if (editMode) {
      return (
        <this.renderEditMode
          address={address}
          newAddress={newAddress}
          apiRequest={newAddress ? this.createAddressApiRequest : this.updateAddressApiRequest}
          resetAddressUnderEdit={this.resetAddressUnderEdit}
        />
      )
    }
    return (
      <DisplayAddress
        address={address}
        addressString={constructAddressString(address)}
        setAddressUnderEdit={setAddressUnderEdit}
        updateAddressApiRequest={this.updateAddressApiRequest}
        resetAddressUnderEdit={this.resetAddressUnderEdit}
        setPrimary={this.setPrimary}
        isPrimary={this.props.isPrimary}
      />
    )
  }

  render = () => (
    <AccordionItem
      name={constructAddressString(this.props.address)}
      open={this.props.open}
    >
      <this.renderAddressDetails />
    </AccordionItem>
  )

}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import DimensionLine from './DimensionLine'
import {
  autoTotalCubicMetres,
  autoTotalLoadingMetres,
  autoPackageLength,
  autoPackageHeight,
  autoPackageWeight,
  autoTotalQuantity,
  autoTotalPackageType,
  autoTotalWeight
} from './auto-total'

import './PackageDimensionLines.scss'


const BLANK_DIMENSION_LINE = {
  measurer: '',
  quantity: '',
  type: '',
  length: '',
  width: '',
  height: '',
  weight: ''
}

export class PackageDimensionLinesCalculator extends Component {

  state = {
    autoTotalHasBeenCalled: false
  }

  componentDidMount = () => {
    const { lines } = this.props

    if (!lines) this.addLine()
  }

  blankLineObject = () => ({
    ...BLANK_DIMENSION_LINE,
    measurer: this.props.measurer,
  })

  addLine = () => {
    this.props.onChange([...this.props.lines, this.blankLineObject()])
  }

  updateLine = (lineIndex, changes) => {
    const next = [...this.props.lines]
    next[lineIndex] = { ...next[lineIndex], ...changes }
    this.props.onChange(next)
  }

  removeLine = (lineIndex) => {
    const next = [...this.props.lines]
    next.splice(lineIndex, 1)
    this.props.onChange(next)
  }

  noLinesPresent = () => (
    this.props.lines.length == 0
  )

  validatedUpdateObject = ({
    cubicMetres, loadingMetres, maxPackageLength, maxPackageHeight, maxPackageWeight,
    packageQuantity, packageType, grossWeightKg
  }) => ({
    ...(cubicMetres > 0 && {cubicMetres}),
    ...(loadingMetres > 0 && {loadingMetres}),
    ...(maxPackageLength > 0 && {maxPackageLength}),
    ...(maxPackageHeight > 0 && {maxPackageHeight}),
    ...(maxPackageWeight > 0 && {maxPackageWeight}),
    ...(packageQuantity > 0 && {packageQuantity}),
    ...(grossWeightKg > 0 && {grossWeightKg}),

    ...(packageType && {packageType})
  })

  autoTotal = () => {
    this.setState({ autoTotalHasBeenCalled: true })
    const { lines, updateFields } = this.props

    const totals = {
      cubicMetres: autoTotalCubicMetres(lines),
      loadingMetres: autoTotalLoadingMetres(lines),
      maxPackageLength: autoPackageLength(lines),
      maxPackageHeight: autoPackageHeight(lines),
      maxPackageWeight: autoPackageWeight(lines),
      packageQuantity: autoTotalQuantity(lines),
      packageType: autoTotalPackageType(lines),
      grossWeightKg: autoTotalWeight(lines),
    }

    updateFields(this.validatedUpdateObject(totals))
  }

  renderLines = () => (
    this.props.lines.map((line, key) => (
      <DimensionLine
        line={line}
        disabled={this.props.disabled}
        updateLine={this.updateLine}
        removeLine={this.removeLine}
        lineIndex={key}
        key={key}
        forceError={this.state.autoTotalHasBeenCalled}
      />
    ))
  )

  controlButton = ({ className, onClick, icon, label, disabled }) => (
    <button
      className={className}
      onClick={onClick}
      disabled={disabled}
    >
      <FontAwesomeIcon icon={['fas', icon]} className="icon" />
      {label}
    </button>
  )

  controlButtonRow = () => (
    <React.Fragment>
      <this.controlButton
        className={"new-dimension-line"}
        onClick={this.addLine}
        icon={'plus-circle'}
        label={'Add Line'}
        disabled={this.props.disabled}
      />
      <this.controlButton
        className={"calculate"}
        onClick={this.autoTotal}
        icon={'calculator'}
        label={'Auto Total'}
        disabled={this.props.disabled || this.noLinesPresent()}
      />
    </React.Fragment>
  )

  renderControlButtons = () => (
    !this.props.disabled ? <this.controlButtonRow /> : null
  )

  noLinesMessage = () => (
    <span className={'message'}>No Lines Present</span>
  )

  linesOrNoLinesMessage = () => {
    if ( this.noLinesPresent() && this.props.disabled ) {
      return <this.noLinesMessage />
    }
    return <this.renderLines />
  }

  wrapper = ({ children }) => (
    <div className="section">
      <h4>Package Dimensions</h4>
      {children}
    </div>
  )

  ifWrapper = ({ children }) => (
    this.props.noWrapper ? children : this.wrapper({ children })
  )

  render() {
    return (
      <this.ifWrapper>
        <div className="package-dimension-lines-calculator">
          <div className="lines-container">
            <this.linesOrNoLinesMessage />
          </div>
          <this.renderControlButtons />
        </div>
      </this.ifWrapper>
    )
  }

}

PackageDimensionLinesCalculator.propTypes = {
  measurer: PropTypes.string.isRequired,
  lines: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,

  updateFields: PropTypes.func,
  disabled: PropTypes.bool,
  noWrapper: PropTypes.bool
}

export default PackageDimensionLinesCalculator

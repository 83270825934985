import React, { Component } from 'react'
import { pick, startCase } from 'lodash'

import {
  Field, SimpleSelect, CountrySelect, UserSelect, DateSelector, TimeSelector,
  InlineLabelledToggle,
} from '../../forms/'

import './General.scss'

export const STATUS = ["Active", "In Transit", "Arrived", "Complete"]

const FIELDS = [
  'originCountryCode',
  'destinationCountryCode',
  'status',
  'ownerId',
  'dateOfUse',
  'trailerNumber',
  'vesselName',
  'agentRef',
  'adr',
  'expectedArrivalDate',
  'expectedArrivalTime',
]

export default class General extends Component {

  state = pick(this.props, FIELDS)

  stateSetters = {}

  stateSetter = (name) => {
    if (!this.stateSetters[name]) {
      this.stateSetters[name] = (val) => this.setState({ [name]: val })
    }
    return this.stateSetters[name]
  }

  setOwnerId = ({ value }) => { this.setState({ ownerId: value })}

  setFormValue = ({ target: { name, value } }) => {
    this.setState({ [name]: value })
  }

  toggleAdr = () => {
    this.setState({ adr: !this.state.adr })
  }

  field = ({ label, name, ...props }) => (
    <Field label={label || startCase(name)}>
      <input
        name={name}
        value={this.state[name] || ''}
        onChange={this.setFormValue}
        disabled={!this.props.edit}
        {...props}
      />
    </Field>
  )

  editControls = () => (
    this.props.edit ? <this.controls /> : null
  )

  controls = () => (
    <div className="section controls">
      <button className="submit" onClick={this.save}>Save</button>
    </div>
  )

  save = () => (
    this.props.save(this.state)
  )

  render = () => (
    <div className="groupage-general">
      <div className="section">
        <h4>Groupage Details</h4>
        <SimpleSelect
          label="Groupage Status"
          options={STATUS}
          onChange={this.stateSetter('status')}
          value={this.state.status}
          isDisabled={!this.props.edit}
        />
        <UserSelect
          label={'Groupage Owner'}
          value={this.state.ownerId}
          onChange={this.setOwnerId}
          isDisabled={!this.props.edit}
        />
        <CountrySelect
          labelPrefix={'Origin '}
          value={this.state.originCountryCode}
          onChange={this.stateSetter('originCountryCode')}
          isDisabled={!this.props.edit}
          forceError={this.props.formError}
          required={true}
        />
        <CountrySelect
          labelPrefix={'Destination '}
          value={this.state.destinationCountryCode}
          onChange={this.stateSetter('destinationCountryCode')}
          isDisabled={!this.props.edit}
          forceError={this.props.formError}
          required={true}
        />
        <this.field name="trailerNumber" />
        <this.field name="vesselName" />
        <this.field name="agentRef" />
        <DateSelector
          label={'Date of Use'}
          value={this.state.dateOfUse}
          onChange={this.stateSetter('dateOfUse')}
          isDisabled={!this.props.edit}
        />
        <DateSelector
          label="Expected Arrival Date"
          value={this.state.expectedArrivalDate}
          onChange={this.stateSetter('expectedArrivalDate')}
          isDisabled={!this.props.edit}
        />
        <TimeSelector
          label="Expected Arrival Time"
          value={this.state.expectedArrivalTime}
          onChange={this.stateSetter('expectedArrivalTime')}
          isDisabled={!this.props.edit}
        />
      </div>
      <div className="section">
        <h4>Trailer Details</h4>
        <div className="form-fields">
          <InlineLabelledToggle
            name="hazardous"
            value={!!this.state.adr}
            onChange={this.toggleAdr}
            isDisabled={!this.props.edit}
          />
        </div>
      </div>
      <this.editControls />
    </div>
  )
}


import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faArchive,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faBalanceScale,
  faBan,
  faBars,
  faBell,
  faBold,
  faBoxes,
  faBoxOpen,
  faBuilding,
  faBriefcase,
  faCalculator,
  faCarCrash,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faCartPlus,
  faCreditCard,
  faChartBar,
  faCheck,
  faChessKing,
  faChessQueen,
  faChevronDown,
  faChevronRight,
  faClock,
  faCommentAlt,
  faCommentDollar,
  faCode,
  faCog,
  faCogs,
  faColumns,
  faCopy,
  faEllipsisH,
  faEnvelope,
  faFileExcel,
  faExclamationCircle,
  faExclamationTriangle,
  faExpand,
  faEye,
  faFax,
  faGlobeAmericas,
  faHammer,
  faHandshake,
  faHeading,
  faIndent,
  faItalic,
  faInfoCircle,
  faKey,
  faList,
  faListOl,
  faMap,
  faMinusCircle,
  faObjectUngroup,
  faOutdent,
  faPallet,
  faPauseCircle,
  faPencilAlt,
  faPhone,
  faPlusCircle,
  faQuestion,
  faRedo,
  faRetweet,
  faSearch,
  faShuttleVan,
  faSignOutAlt,
  faStickyNote,
  faStrikethrough,
  faSolarPanel,
  faSortAlphaUp,
  faSortAlphaDown,
  faSpinner,
  faTachometerAlt,
  faTag,
  faTimes,
  faTrash,
  faTrashAlt,
  faTruckLoading,
  faTruckMoving,
  faUnderline,
  faUndo,
  faUser,
  faUsers,
  faUsersCog,
  faUserShield,
  faUserTie,
  faWarehouse,
} from '@fortawesome/free-solid-svg-icons'

import {
  faCaretSquareDown,
  faCalendarAlt,
  faComment,
  faComments,
  faPlusSquare,
} from '@fortawesome/free-regular-svg-icons'

library.add(
  // fas
  faArchive,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faBalanceScale,
  faBan,
  faBars,
  faBell,
  faBold,
  faBoxOpen,
  faBoxes,
  faBuilding,
  faBriefcase,
  faCalculator,
  faCalendarAlt,
  faCarCrash,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faCartPlus,
  faCreditCard,
  faChartBar,
  faCheck,
  faChessKing,
  faChessQueen,
  faChevronDown,
  faChevronRight,
  faClock,
  faCommentAlt,
  faCommentDollar,
  faCode,
  faCog,
  faCogs,
  faColumns,
  faCopy,
  faEllipsisH,
  faEnvelope,
  faFileExcel,
  faExclamationCircle,
  faExclamationTriangle,
  faExpand,
  faEye,
  faFax,
  faGlobeAmericas,
  faHammer,
  faHandshake,
  faHeading,
  faIndent,
  faItalic,
  faInfoCircle,
  faKey,
  faList,
  faListOl,
  faMap,
  faMinusCircle,
  faObjectUngroup,
  faOutdent,
  faPallet,
  faPauseCircle,
  faPencilAlt,
  faPhone,
  faPlusCircle,
  faQuestion,
  faRedo,
  faRetweet,
  faSearch,
  faShuttleVan,
  faSignOutAlt,
  faStickyNote,
  faStrikethrough,
  faSolarPanel,
  faSortAlphaUp,
  faSortAlphaDown,
  faSpinner,
  faTachometerAlt,
  faTag,
  faTimes,
  faTrash,
  faTrashAlt,
  faTruckLoading,
  faTruckMoving,
  faUnderline,
  faUndo,
  faUser,
  faUsers,
  faUsersCog,
  faUserShield,
  faUserTie,
  faWarehouse,
  // far
  faCaretSquareDown,
  faComment,
  faComments,
  faCreditCard,
  faPlusSquare,
)

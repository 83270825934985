import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './Accordion.scss'

export default class Accordion extends Component {
  render = () => (
    <div className="accordion">
      <div className="header">
        <h4>{this.props.title}</h4>
        <span className="new-item" onClick={this.props.newItemOnClick} >
          <span className="icon">
            <FontAwesomeIcon icon={['fas', 'plus-circle']} />
          </span>
          {this.props.newItemLabel}
        </span>
      </div>
      {this.props.children}
    </div>
  )
}

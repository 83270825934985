import React from 'react'
import ModalWindow from '../layout/ModalWindow'


function RestoreDetailsModal(props) {
  const { restoreDetails, closeModal, modalIsOpen } = props

  const restoreAndClose = () => {
    restoreDetails()
    closeModal()
  }

  const Modal = ({ children }) => (
    <ModalWindow
      className={'restore-details-confirmation-modal'}
      isOpen={modalIsOpen}
      closeModal={closeModal}
    >
      {children}
    </ModalWindow>
  )

  const ClearButton = () => (
    <button onClick={closeModal} className="success">
      New Quote
    </button>
  )

  const RestoreButton = () => (
    <button
      id={"restore-details-modal-confirm-button"}
      onClick={restoreAndClose}
      className="primary"
    >
      Restore Details
    </button>
  )

  return (
    <Modal> 
      <h3>Restore Saved Details</h3>
      <span>
        A partially completed Quote can be restored.
      </span>
      <div className="options">
        <ClearButton />
        <RestoreButton />
      </div>
    </Modal>
  )
}

export default RestoreDetailsModal

import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import Api from '../../../api/call'
import { GROUPAGE_BULK_EMAIL } from '../../../root/action-types'

import EmailRequestStatus from './EmailRequestStatus'
import JobRow from './JobRow'
import SelectAllJobs from './SelectAllJobs'
import SendEmails from './SendEmails'

import { sendEmailsSelector, markedForEmailingSelector } from './redux-selectors'

import './emails.scss'


const HeaderRows = () => (
  <div className={'job-row header'}>
    <div></div>
    <span className="job-title">JOB</span>
    <span className="customer-name">CUSTOMER</span>
    <span className="invoice-emailed">INVOICE EMAILED</span>
    <span className="email-details"></span>
  </div>
)

const sendJobsRequest = ({ groupageData: { id } }) => {
  Api({
    request: GROUPAGE_BULK_EMAIL,
    id
  })
}

const renderJobs = (jobIds) => (
  jobIds.map(id => <JobRow id={id} key={id} />)
)

export const Emails = (props) => {
  const loadingRequest = useSelector(sendEmailsSelector)
  useEffect(() => sendJobsRequest(props), [loadingRequest])
  const jobIds = Object.keys(useSelector(markedForEmailingSelector))

  return (
    <div className="emails">
      <h4>Sales Emails</h4>
      <EmailRequestStatus />
      <div className="jobs">
        <HeaderRows />
        {renderJobs(jobIds)}
      </div>
      <div className="button-row">
        <SelectAllJobs />
        <SendEmails />
      </div>
    </div>
  )
}

export default Emails

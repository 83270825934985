import React, { Component } from 'react'
import { pick, startCase } from 'lodash'

import IndexTable from './IndexTable'

const CONTROLS = [
  'pageSizeOptions',
  'handlePageChange',
  'handleSizeChange',
  'handleSearchChange',
  'handleSortChange',
  'handleSortDirectionChange',
]

const sorter = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' })

export default class BasicTable extends Component {

  pageSizeOptions = [10, 20, 50].map(o => ({ label: o, value: o }))

  state = {
    page: 1,
    pageSize: this.pageSizeOptions[0],
    search: '',
    sort: '',
    sortDirection: 1,
  }

  handlePageChange = (p) => {
    this.setState({ page: p })
  }

  handleSizeChange = (option) => {
    this.setState({ pageSize: option })
  }

  handleSearchChange = ({ target: { value } }) => {
    this.setState({ search: value })
  }

  handleSortChange = (sort) => {
    this.setState({ sort })
  }

  handleSortDirectionChange = () => {
    this.setState({ sortDirection: -1 * this.state.sortDirection })
  }

  // Row matches if any column begins with search term, case insensitive
  matched = () => {
    if (!this.state.search) { return this.props.data }
    const matcher = new RegExp(`^${this.state.search}`, 'i')
    return this.props.data.filter(row =>
      Object.values(row).some(v => String(v).match(matcher))
    )
  }

  sorted = () => {
    const data = this.matched()
    const column = this.state.sort && this.state.sort.value
    if (column) {
      data.sort((a, b) =>
        this.state.sortDirection * sorter.compare(a[column], b[column])
      )
    }

    return data
  }

  getSortOptions = () => (
    this.props.columns.map(({ accessor, Header, sort })  => ({
      value: sort || accessor,
      label: Header || startCase(accessor)
    }))
  )

  render = () => {
    const { data, count } = this.getCurrent()
    return <IndexTable
      columns={this.props.columns}
      sortOptions={this.getSortOptions()}
      data={data}
      total={this.total()}
      count={count}
      model={this.props.model}
      linkString={this.props.linkString}
      {...this.state}
      {...pick(this, CONTROLS)}
    />
  }

  getCurrent = () => {
    const page = this.state.page
    const size = this.state.pageSize.value
    const results = this.sorted()
    return {
      count: results.length,
      data: results.slice((page - 1) * size, page * size)
    }
  }

  total = () => (
    this.props.data.length
  )

}


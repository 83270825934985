import ActionTypes from '../../root/action-types'

const {
  AUTOLINE_CUSTOMER_RATE,
  JOBS_COSTING,
  TARIFFS_DISTRIBUTION_OPTIONS,
  JOBS_HISTORY,
} = ActionTypes

const INITIAL_AUTOLINE = { details: {} }

export const autoline = (state = INITIAL_AUTOLINE, action) => {
  switch (action.type) {
    case `${AUTOLINE_CUSTOMER_RATE}_START`:
    case `${JOBS_COSTING}_START`:
    case `${TARIFFS_DISTRIBUTION_OPTIONS}_START`:
      return {
        ...state,
        loading: true,
        hasError: false,
        options: null,
      }
    case `${JOBS_COSTING}_SUCCESS`:
    case `${AUTOLINE_CUSTOMER_RATE}_SUCCESS`:
      return {
        ...state,
        loading: false,
        details: action.response.data,
      }
    case `${TARIFFS_DISTRIBUTION_OPTIONS}_SUCCESS`:
      return {
        ...state,
        loading: false,
        options: action.response.data,
      }
    case `${JOBS_COSTING}_FAILURE`:
    case `${AUTOLINE_CUSTOMER_RATE}_FAILURE`:
    case `${TARIFFS_DISTRIBUTION_OPTIONS}_FAILURE`:
      return {
        ...state,
        loading: false,
        hasError: true,
        details: action.response.data,
      }
    default:
      return state
  }
}

const INITIAL_HISTORY = {
  details: []
}

export const history = (state = INITIAL_HISTORY, action) => {
  switch (action.type) {
    case `${JOBS_HISTORY}_START`:
      return {
        ...state,
        loading: true,
        hasError: false,
      }
    case `${JOBS_HISTORY}_FAILURE`:
      return {
        ...state,
        loading: false,
        hasError: true,
        details: action.response.data,
      }
    case `${JOBS_HISTORY}_SUCCESS`:
      return {
        ...state,
        loading: false,
        details: action.response.data.jobs,
      }
    default:
      return state
  }
}


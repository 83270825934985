import React from 'react'

import {
  CountrySelect, InlineLabelledToggle, Conditional, useForm, Field,
} from '../../forms/'
import { TEMPLATES_GROUPAGES } from '../../root/action-types'


const Form = ({ edit, initialFields }) => {
  const {
    fields, formSetter, submit, toggler, fieldChange
  } = useForm(initialFields, TEMPLATES_GROUPAGES)

  return (
    <div className="form">
      <div className="section details">
        <div className="fields">
          <Field label="Name">
            <input
              name="name"
              value={fields.name}
              onChange={fieldChange}
              disabled={!edit}
            />
          </Field>
        </div>
        <div className="fields">
          <CountrySelect
            labelPrefix="Origin "
            value={fields.originCountryCode}
            onChange={formSetter('originCountryCode')}
            isDisabled={!edit}
          />
          <CountrySelect
            labelPrefix="Destination "
            value={fields.destinationCountryCode}
            onChange={formSetter('destinationCountryCode')}
            isDisabled={!edit}
          />
        </div>
        <div className="header-rule" />
        <div className="fields">
          <InlineLabelledToggle
            name="hazardous"
            value={!!fields.adr}
            onChange={toggler('adr')}
            isDisabled={!edit}
          />
        </div>
        <Conditional if={edit}>
          <div className="controls">
            <button className="primary" onClick={submit}>
              {fields.id ? 'Update' : 'Create'} Groupage Template
            </button>
          </div>
        </Conditional>
      </div>
    </div>
  )
}

export default Form

import React, { Component } from 'react'
import { get } from 'lodash'

import { connect } from 'react-redux'
import { USERS_UPDATE } from '../../root/action-types'
import Api from '../../api/call'
import { Field } from '../../forms/Field'
import { Status } from '../../forms/Status'

import AssignUserRoles from './AssignUserRoles'

export class EditUser extends Component {

  state = {
    ...this.props.user,
    isConfirmed: false,
    password_confirmation: "",
  }

  setField = (e) => {
    this.setState({ [e.currentTarget.name]: e.currentTarget.value})
  }

  setRole = (e) => {
    const { name } = e.target
    this.setState( prev => ({ [name]: !prev[name] }))
  }

  toggleConfirmed = () => (
    this.setState( prev => ({ isConfirmed: !prev.isConfirmed}))
  )

  sendUpdate = () => {
    Api({
      request: USERS_UPDATE,
      id: this.state.id,
      data: this.state,
    })
    this.toggleConfirmed()
  }

  statusMessage = () => {
    const { loading, errors, userHasUpdated } = this.props.updateUser
    if (loading) {
      return 'Processing...'
    } else if (errors && errors.length) {
      return errors.join(", ")
    } else if (userHasUpdated) {
      return 'User Details Saved.'
    } else {
      return ''
    }
  }

  options = () => (
    <div className="button-container">
      <button className="save-details" onClick={this.toggleConfirmed}>
        Save Details
      </button>
      <button className="discard-changes" onClick={this.props.closeEditUserPane}>
        Discard Changes
      </button>
    </div>
  )

  confirmOptions = () => (
    <div className="button-container">
      <span>Are you sure?</span>
      <button className="discard-changes" onClick={this.toggleConfirmed}>
        Cancel
      </button>
      <button className="save-details" onClick={this.sendUpdate}>
        Yes
      </button>
    </div>
  )

  inputField = ({label, name, value, type, placeholder }) => (
    <Field label={label}>
      <input
        name={name}
        value={value || ''}
        onChange={this.setField}
        type={type}
        placeholder={placeholder}
        autoComplete="off"
      />
    </Field>
  )

  render = () => (
    <div className="user-management-add-new-users">
      <div className="header">
        <h3>Edit User</h3>
      </div>
      <div className="header-rule" />
      <div className="form-container">
        <Status isError={!!get(this.props.updateUser, 'errors')}>
          {this.statusMessage()}
        </Status>

        <this.inputField
          label="Email Address"
          name="email"
          type="email"
          value={this.state.email}
          placeholder="Enter the users' email adress"
        />

        <div className="double-input">
          <this.inputField
            label="First Name"
            name="firstName"
            value={this.state.firstName}
            type="text"
            placeholder="Users' First Name"
          />

          <this.inputField
            label="Last Name"
            name="lastName"
            value={this.state.lastName}
            type="text"
            placeholder="Users' Last Name"
          />
        </div>

        <div>
          <this.inputField
            label="Password"
            name="password"
            value={this.state.password}
            type="password"
            placeholder="Enter a new password"
          />
        </div>

        <div>
          <this.inputField
            label="Confirm Password"
            name="password_confirmation"
            value={this.state.password_confirmation}
            type="password"
            placeholder="Confirm your password"
          />
        </div>

        <AssignUserRoles
          setRole={this.setRole}
          state={this.state}
        />

        { this.state.isConfirmed ? <this.confirmOptions /> : <this.options /> }

      </div>
    </div>
  )
}

const mapStateToProps = ({ users }) => ({
  updateUser: users.user || {},
})

export default connect(mapStateToProps)(EditUser)

import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import HTML5Backend from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import 'array-flat-polyfill'

import { store } from './root/store'
import history from './root/browser-history'
import App from './root/App'

import './index.css'
import { unregister } from './registerServiceWorker'

render(
  <Provider store={store}>
    <DndProvider backend={HTML5Backend}>
      <Router history={history}>
        <App />
      </Router>
    </DndProvider>
  </Provider>,
  document.getElementById('root')
)

unregister()

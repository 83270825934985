import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { startCase } from 'lodash'

import './quick-find-form.scss'


const ICON_FOR_MODEL = {
  company: ['fas', 'building'],
  quote: ['far', 'comment'],
  job: ['fas', 'box-open'],
  groupage: ['fas', 'boxes']
}

function QuickFindQueryOption(props) {
  const { className, onClick, type } = props

  return (
    <div
      className={`query-type-option ${className}`}
      onClick={onClick}
    >
      <span className="model-icon">
        <FontAwesomeIcon icon={ICON_FOR_MODEL[type]} fixedWidth />
      </span>
      {startCase(type)}
    </div>
  )
}

export default QuickFindQueryOption

import React from 'react'
import { Link } from 'react-router-dom'
import { startCase } from 'lodash'

import { buildRedirectPath } from  './QuickFindRedirect'

function QuickFindMessage(props) {

  const noResult = () => {
    const { results, queryType } = props
    return results.length === 0 && queryType != ""
  }

  const messageType = () => (
    noResult() ? noResultMessage() : multipleResultsMessage()
  )

  const noResultMessage = () => {
    const { queryType, queryString } = props

    return `No ${startCase(queryType)} matching '${queryString}' found.`
  }

  const close = () => {
    const { close, timestamp } = props
    close(timestamp)
  }

  const resultsListItems = ({ results, queryType }) => (
    results.map(r => (
      <li key={r.id}>
        <Link
          className={"suggestion"}
          to={buildRedirectPath(queryType, r.id)}
          onClick={close}
        >
          {r.reference || r.name}
        </Link>
      </li>
    ))
  )

  const multipleResultsMessage = () => (
    <React.Fragment>
      Did you mean?
      <ul>
        {resultsListItems(props)}
      </ul>
    </React.Fragment>
  )

  return (
    <div>
      {messageType()}
    </div>
  )
}

export default QuickFindMessage

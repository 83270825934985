import axios from 'axios'

const { get, post, put } = axios

const FREIGHT_CLOUD = process.env.REACT_APP_FREIGHTSOFT_API_URL

const CORE = process.env.REACT_APP_CORE_API_URL

const TARIFFS = process.env.REACT_APP_TARIFFS_API_URL

const CRUD_ROUTES = ({ action, server, path }) => ({
  [action]: { verb: get, server, path },
  [`${action}_SHOW`]: { verb: get, server, path },
  [`${action}_CREATE`]: { verb: post, server, path },
  [`${action}_UPDATE`]: { verb: put, server, path },
  [`${action}_DESTROY`]: { verb: axios.delete, server, path },
})

const SEARCH = ({ action, server, path }) => ({
  [`${action}_SEARCH`]: { verb: get, server, path: `${path}/search` },
  [`${action}_LABEL`]: { verb: get, server, path: `${path}/label` },
})

const SEND_EMAIL = ({ action, server, path }) => ({
  [`${action}_SEND_EMAIL`]: { verb: get, server, path: `${path}/send_email` },
})

export const cable = `${String(CORE).replace('http', 'ws')}/cable`

export default {
  USERS_LOGIN: {
    verb: post,
    server: CORE,
    path: 'authenticate'
  },
  FILE_DOWNLOAD: {
    verb: post,
    server: CORE,
    path: 'downloads',
  },
  USERS_SEND_PASSWORD_RESET_EMAIL: {
    verb: post,
    server: FREIGHT_CLOUD,
    path: '/users/password'
  },
  USERS_PASSWORD_RESET: {
    verb: put,
    server: FREIGHT_CLOUD,
    path: '/users/password'
  },
  PLANNER_UNASSIGNED_UNITS: {
    verb: get,
    server: CORE,
    path: 'planner/unassigned_units'
  },
  PLANNER_UNIT_UPDATE: {
    verb: put,
    server: CORE,
    path: 'jobs'
  },
  PLANNER_WEEK: {
    verb: get,
    server: CORE,
    path: 'planner/week'
  },
  PLANNER_PREVIOUSLY_ON: {
    verb: get,
    server: CORE,
    path: 'planner/previously_on'
  },
  CALCULATOR_COUNTRIES: {
    verb: get,
    server: TARIFFS,
    path: 'distribution/countries'
  },
  CALCULATOR_LOCATIONS: {
    verb: get,
    server: TARIFFS,
    path: 'distribution/locations'
  },
  CALCULATOR_RATE: {
    verb: get,
    server: TARIFFS,
    path: 'quote/new'
  },
  TARIFFS_CONFIG: {
    verb: get,
    server: TARIFFS,
    path: 'config'
  },
  TARIFFS_CONFIG_UPDATE: {
    verb: put,
    server: TARIFFS,
    path: 'config'
  },
  TARIFFS_DISTRIBUTION_OPTIONS: {
    verb: get,
    server: TARIFFS,
    path: 'distribution/options'
  },
  QUICK_FIND: {
    verb: get,
    server: CORE,
    path: 'quick_find'
  },
  ...CRUD_ROUTES({
    action: 'COMPANIES',
    server: CORE,
    path: 'companies',
  }),
  ...SEARCH({
    action: 'COMPANIES',
    server: CORE,
    path: 'companies',
  }),
  COMPANIES_DETAILS: {
    verb: get,
    server: CORE,
    path: 'company_details'
  },
  XERO_CONFIG: {
    verb: get,
    server: CORE,
    path: 'xero_config'
  },
  XERO_TAX_RATES: {
    verb: get,
    server: CORE,
    path: 'xero_tax_rates',
  },
  XERO_ACCOUNTS: {
    verb: get,
    server: CORE,
    path: 'xero_accounts',
  },
  XERO_CONTACTS: {
    verb: get,
    server: CORE,
    path: 'xero_contacts',
  },
  XERO_CONTACTS_SHOW: {
    verb: get,
    server: CORE,
    path: 'xero_contacts',
  },
  XERO_CONTACTS_CREATE: {
    verb: post,
    server: CORE,
    path: 'xero_contacts',
  },
  XERO_CONTACTS_IMPORT: {
    verb: post,
    server: CORE,
    path: 'xero_contacts/import',
  },
  XERO_INVOICES_CREATE: {
    verb: post,
    server: CORE,
    path: 'xero_invoices',
  },
  SUPPLIER_INVOICES_CREATE: {
    verb: post,
    server: CORE,
    path: 'supplier_invoices',
  },
  ...CRUD_ROUTES({
    action: 'ADDRESSES',
    server: CORE,
    path: 'addresses',
  }),
  ...SEARCH({
    action: 'ADDRESSES',
    server: CORE,
    path: 'addresses',
  }),
  ...CRUD_ROUTES({
    action: 'CONTACTS',
    server: CORE,
    path: 'contacts',
  }),
  ...CRUD_ROUTES({
    action: 'OPENING_TIMES',
    server: CORE,
    path: 'opening_times',
  }),
  ...CRUD_ROUTES({
    action: 'LINE_ITEMS',
    server: CORE,
    path: 'line_items',
  }),
  LINE_ITEMS_MANAGE: {
    verb: post,
    server: CORE,
    path: 'line_items/manage'
  },
  ...SEND_EMAIL({
    action: 'QUOTES',
    server: CORE,
    path: 'quotes',
  }),
  ...CRUD_ROUTES({
    action: 'QUOTES',
    server: CORE,
    path: 'quotes',
  }),
  ...CRUD_ROUTES({
    action: 'EMAILS',
    server: CORE,
    path: 'emails',
  }),
  ...CRUD_ROUTES({
    action: 'NOTES',
    server: CORE,
    path: 'notes',
  }),
  ...CRUD_ROUTES({
    action: 'PACKAGE_TYPES',
    server: CORE,
    path: 'package_types',
  }),
  JOBS_SEND_EMAIL: {
    verb: get,
    server: CORE,
    path: 'jobs/send_email'
  },
  JOBS_EMAIL_TEMPLATES: {
    verb: get,
    server: CORE,
    path: 'jobs/email_templates'
  },
  JOBS_COSTING: {
    verb: get,
    server: CORE,
    path: 'jobs/costing'
  },
  JOBS_HISTORY: {
    verb: get,
    server: CORE,
    path: 'jobs/history'
  },
  QUOTES_EMAIL_TEMPLATE: {
    verb: get,
    server: CORE,
    path: 'quotes/email_template'
  },
  QUOTES_HISTORY: {
    verb: get,
    server: CORE,
    path: 'quote_histories'
  },
  CONVERT_QUOTE_TO_JOB: {
    verb: post,
    server: CORE,
    path: 'quote_conversions'
  },
  ...CRUD_ROUTES({
    action: 'JOBS',
    server: CORE,
    path: 'jobs',
  }),
  ...CRUD_ROUTES({
    action: 'GROUPAGES',
    server: CORE,
    path: 'groupages',
  }),
  GROUPAGE_JOBS: {
    verb: get,
    server: CORE,
    path: 'groupage_jobs'
  },
  GROUPAGE_BULK_INVOICE: {
    verb: get,
    server: CORE,
    path: 'groupage_bulk_invoices'
  },
  GROUPAGE_SEND_JOB_INVOICES: {
    verb: post,
    server: CORE,
    path: 'groupage_bulk_invoices'
  },
  GROUPAGE_BULK_EMAIL: {
    verb: get,
    server: CORE,
    path: 'groupage_bulk_emails'
  },
  GROUPAGE_SEND_JOB_EMAILS: {
    verb: post,
    server: CORE,
    path: 'groupage_bulk_emails'
  },
  GROUPAGE_FINANCIALS: {
    verb: get,
    server: CORE,
    path: 'groupage_financials',
  },
  ...CRUD_ROUTES({
    action: "TEMPLATES_GROUPAGES",
    server: CORE,
    path: 'groupage_templates',
  }),
  ...CRUD_ROUTES({
    action: "TARIFFS_DISTRIBUTION",
    server: TARIFFS,
    path: 'distribution'
  }),
  ...CRUD_ROUTES({
    action: "TARIFFS_CUSTOMERS",
    server: TARIFFS,
    path: 'customers'
  }),
  AUTOLINE_CUSTOMER_RATE: {
    verb: get,
    server: TARIFFS,
    path: 'customers/autoline'
  },
  ...CRUD_ROUTES({
    action: "TARIFFS_SURCHARGES",
    server: TARIFFS,
    path: 'surcharges'
  }),
  ...CRUD_ROUTES({
    action: "TARIFFS_FREIGHT",
    server: TARIFFS,
    path: 'freight'
  }),
  ...CRUD_ROUTES({
    action: "USERS",
    server: CORE,
    path: 'users',
  }),
  ...SEARCH({
    action: 'USERS',
    server: CORE,
    path: 'users',
  }),
  CURRENT_USER: {
    verb: get,
    server: CORE,
    path: 'users/current',
  },
  ADMIN_SETTINGS: {
    verb: get,
    server: CORE,
    path: 'admin/settings'
  },
  ADMIN_SETTINGS_UPDATE: {
    verb: put,
    server: CORE,
    path: 'admin/settings'
  },
  ...SEARCH({
    action: 'COMMODITIES',
    server: CORE,
    path: 'commodities',
  }),
}

import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { MARK_FOR_EMAILING } from '../../../root/action-types'

import { markedForEmailingSelector, jobsSelector } from './redux-selectors'
import { jobPreventedFromEmailingBy } from './email-validation'


const SelectAllJobs = () => {
  const marked = useSelector(markedForEmailingSelector)
  const jobs = useSelector(jobsSelector)
  const dispatch = useDispatch()

  const markEmails = () => {
    Object.keys(marked).filter(id => !marked[id]).map(markIfValid)
  }

  const markIfValid = (id) => {
    valid(id) && dispatch({ type: MARK_FOR_EMAILING, payload: { id } })
  }

  const valid = (id) => {
    const job = jobs.find(job => job.id == id)
    return !job.salesInvoiced && isReadyToEmail(job)
  }

  const isReadyToEmail = (job) => (
    jobPreventedFromEmailingBy(job).length === 0
  )

  const noJobsEmailable = () => (
    jobs.filter(job => {
      return !job.salesInvoiced && isReadyToEmail(job)
    }
    ).length === 0
  )

  return (
    <button
      disabled={noJobsEmailable()}
      onClick={markEmails}
      className="success"
    >
      Select All
    </button>
  )
}

export default SelectAllJobs

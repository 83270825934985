import {
  PLANNER_WEEK,
  PLANNER_BOOKINGS_EVENT,
  PLANNER_PREVIOUSLY_ON,
} from '../../root/action-types'

const INITIAL_BOOKINGS = {}

const update = (state, newById) => {
  const next = { ...state, ...newById }
  Object.entries(newById).forEach(([id, data]) =>
    data == 'destroyed' && delete next[id]
  )
  return next
}

export const bookings = (state = INITIAL_BOOKINGS, action) => {
  switch (action.type) {
    case `${PLANNER_WEEK}_START`:
      return {
        ...state,
      }
    case `${PLANNER_WEEK}_SUCCESS`: {
      return update(state, action.response.data.bookings)
    }
    case `${PLANNER_PREVIOUSLY_ON}_SUCCESS`: {
      return update(state, action.response.data.bookings)
    }
    case PLANNER_BOOKINGS_EVENT: {
      return update(state, action.details)
    }
    case `${PLANNER_WEEK}_FAILURE`:
      return {
        ...state,
      }
    default:
      return state
  }
}

export const TEST_ACCESS = {
  INITIAL_BOOKINGS,
  update,
}

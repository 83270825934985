const SALE_MARKUP = 40
const COST_MIN = 25

// Yes, it is a pretty esoteric algorithm ;)
export const insuranceCost = ({ freight, goodsValue }) => {
  if (!goodsValue) { return NaN }

  const value = 1.1 * (Number(freight) + Number(goodsValue))
  const insurance = Math.max(0.002 * value, COST_MIN)

  const rounded = parseFloat(insurance.toFixed(2))

  return rounded
}

export const insuranceSale = (props) => {
  const base = insuranceCost(props) + SALE_MARKUP
  const rounded = Math.ceil(base / 5) * 5

  return rounded
}

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { pick } from 'lodash'

import { SupplierHubSelect } from '../../forms/select'
import CostConfirmation from '../../line-items/CostConfirmation'

import './supplier.scss'


export class Supplier extends Component {

  state = {
    ...pick(this.props, ['defaultSupplierHub'])
  }

  changeDefaultSupplierHub = (value) => (
    this.setState({ defaultSupplierHub: value})
  )

  editControls = () => (
    this.props.edit ? this.controls() : null
  )

  controls = () => (
    <div className="section controls">
      <button className="submit" onClick={this.save}>Save</button>
    </div>
  )

  save = () => (
    this.props.updateApiRequest(this.state)
  )

  render = () => {
    const { defaultSupplierHub } = this.state
    const { edit } = this.props

    return (
      <div className="supplier-tab">
        <h4>Supplier Information</h4>
        <div className="section">
          <div className="double-input">
            <SupplierHubSelect
              label={'Default Supplier Hub'}
              value={defaultSupplierHub}
              onChange={this.changeDefaultSupplierHub}
              isDisabled={!edit}
            />
          </div>
        </div>
        {this.editControls()}
        <h4>Unconfirmed Cost Line Items</h4>
        <div className="section">
          <CostConfirmation companyId={this.props.id} />
        </div>
      </div>
    )
  }

}

const mapStateToProps = ({
  companies,
}) => ({
  ...companies.show.data || {},
})

export default connect(mapStateToProps)(Supplier)

const hide_bars_on_these_paths = [
  '/login',
  '/users/password/edit',
]

export const hideBars = (url_path  = window.location.pathname) => (
  hide_bars_on_these_paths.includes(url_path)
)

export const SideBarVisibility = (props) => (
  hideBars() ? null : props.children
)

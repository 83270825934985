import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
moment.locale('en-gb')


const addDays = (date, days) => {
  var mDate = moment(date, "DD/MM/yyyy")
  return mDate.add(days, 'days').format("DD/MM/YY")
}

const displayDateRange = (date) => {
  return `${moment(date, "DD/MM/yyyy").format("DD/MM/YY")} - ${addDays(date, 6)}`
}


class WeekDayInput extends Component {

  displayValue = () => {
    const { value, filterByDate } = this.props
    return !filterByDate ? displayDateRange(value) : `        ${value}`
  }

  render = () => {
    const { onClick, weekNumber } = this.props

    return (
      <div className="week-picker" onClick={onClick}>
        <label id="planner-week-picker" className="week-picker-icon">
          <FontAwesomeIcon icon={['far','calendar-alt']} fixedWidth />
        </label>
        <input
          className="planner-week-picker"
          value={this.displayValue()}
          readOnly
          id="planner-week-picker"
        />
        <label
          className="week-picker-label"
          id="planner-week-picker"
        >
          {`Week ${weekNumber}`}
        </label>
      </div>
    )
  }
}

export default WeekDayInput

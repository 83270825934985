import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './CreateNew.scss'


export class CreateNew extends Component {

  state = {
    dropdownOpen: false
  }

  componentDidMount = () => {
    document.addEventListener('click', this.clickOutside)
  }

  componentWillUnmount = () => {
    document.removeEventListener('click', this.clickOutside)
  }

  toggleDropdown = () => {
    this.setState(prevState => ({ dropdownOpen: !prevState.dropdownOpen }))
    this.closable = false
    setTimeout(() => this.closable = true, 10)
  }

  setRef = (node) => {
    this.ref = node
  }

  clickOutside = ({ target }) => {
    if (this.ref && this.closable && !this.ref.contains(target)) {
      this.setState({ dropdownOpen: false })
    }
  }

  openClosedClass = () => (
    this.state.dropdownOpen ? 'open' : 'closed'
  )

  newModelLink = ({ icon, label, path }) => (
    <Link className={"item-link"} to={path}>
      <div className={"new-option"} onClick={this.toggleDropdown}>
        <span className="create-new-icon">
          <FontAwesomeIcon icon={icon} fixedWidth />
        </span>
        {label}
      </div>
    </Link>
  )

  renderDropdown = () => (
    <div
      ref={this.setRef}
      className={`topbar-create-new-dropdown ${this.openClosedClass()}`}
    >
      <this.newModelLink icon={['fas', 'building']} label={'Company'} path={'/companies/new'} />
      <this.newModelLink icon={['far', 'comment']} label={'Quote'} path={'/quotes/new'} />
      <this.newModelLink icon={['fas', 'box-open']} label={'Job'} path={'/jobs/new'} />
      <this.newModelLink icon={['fas', 'boxes']} label={'Groupages'} path={'/groupages/new'} />
    </div>
  )

  render = () => (
    <div className={`topbar-item topbar-create-new ${this.openClosedClass()}`}>
      <div className="create-new-clickable" onClick={this.toggleDropdown}>
        <span className="topbar-create-new-icon">
          <FontAwesomeIcon icon={['far', 'plus-square']} fixedWidth />
        </span>
        <span>Create New</span>
      </div>
      {this.renderDropdown()}
    </div>
  )
}

export default CreateNew


import React from 'react'
import Toggle from 'react-toggle'
import { startCase } from 'lodash'

import './toggle.scss'

export const InlineLabelledToggle = ({ name, value, isDisabled, className = '', ...props }) => (
  <div className={`toggle inline-labelled ${className}`}>
    <label>
      {startCase(name)}
    </label>
    <Toggle
      name={name}
      checked={value}
      disabled={isDisabled}
      icons={false}
      {...props}
    />
  </div>
)

export default InlineLabelledToggle

import endpoints from '../api/endpoints'

const resetActions = {
  ADDRESSES_MANAGEMENT_RESET_STORE: 'ADDRESSES_MANAGEMENT_RESET_STORE',
  CONTACTS_MANAGEMENT_RESET_STORE: 'CONTACTS_MANAGEMENT_RESET_STORE',
  EMAILS_MANAGEMENT_RESET_STORE: 'EMAILS_MANAGEMENT_RESET_STORE',
  PACKAGE_TYPES_NEW: 'PACKAGE_TYPES_NEW',
  TARIFFS_DISTRIBUTION_NEW: 'TARIFFS_DISTRIBUTION_NEW',
  TARIFFS_CUSTOMERS_NEW: 'TARIFFS_CUSTOMERS_NEW',
  TARIFFS_SURCHARGES_NEW: 'TARIFFS_SURCHARGES_NEW',
  TARIFFS_FREIGHT_NEW: 'TARIFFS_SURCHARGES_NEW',
  TEMPLATES_GROUPAGES_NEW: 'TEMPLATES_GROUPAGE_NEW',
  CALCULATOR_RESET: "CALCULATOR_RESET",
}

const ActionTypes = {
  API_REQUEST: "API_REQUEST",
  CALCULATOR_STEP_CHANGE: "CALCULATOR_STEP_CHANGE",
  CALCULATOR_SAVE_DETAILS: "CALCULATOR_SAVE_DETAILS",
  LOGOUT: "LOGOUT",
  PLANNER_UNITS_EVENT: 'PLANNER_UNITS_EVENT',
  PLANNER_COLUMNS_SELECT: 'PLANNER_COLUMNS_SELECT',
  PLANNER_JOB_STATUS_SELECT: 'PLANNER_JOB_STATUS_SELECT',
  PLANNER_BOOKINGS_EVENT: 'PLANNER_BOOKINGS_EVENT',
  PLANNER_FILTER_PARAMS: 'PLANNER_FILTER_PARAMS',
  MARK_FOR_INVOICING: 'MARK_FOR_INVOICING',
  MARK_FOR_EMAILING: 'MARK_FOR_EMAILING',
  ...resetActions,
}

const apiActions = Object.keys(endpoints).map(e =>
  [e, `${e}_START`, `${e}_SUCCESS`, `${e}_FAILURE`, `${e}_RESET_STORE`]
).flat()

apiActions.forEach(a => ActionTypes[a] = a)

export default ActionTypes

export const {
  API_REQUEST,
  CALCULATOR_STEP_CHANGE,
  CALCULATOR_SAVE_DETAILS,
  LOGOUT,
  PLANNER_UNITS_EVENT,
  PLANNER_COLUMNS_SELECT,
  PLANNER_JOB_STATUS_SELECT,
  PLANNER_BOOKINGS_EVENT,
  PLANNER_FILTER_PARAMS,
  MARK_FOR_INVOICING,
  MARK_FOR_EMAILING,
  ADDRESSES_MANAGEMENT_RESET_STORE,
  CONTACTS_MANAGEMENT_RESET_STORE,
  EMAILS_MANAGEMENT_RESET_STORE,
  PACKAGE_TYPES_NEW,
  TARIFFS_DISTRIBUTION_NEW,
  TARIFFS_CUSTOMERS_NEW,
  TARIFFS_SURCHARGES_NEW,
  TARIFFS_FREIGHT_NEW,
  CALCULATOR_RESET,
  USERS_LOGIN,
  USERS_LOGIN_START,
  USERS_LOGIN_SUCCESS,
  USERS_LOGIN_FAILURE,
  USERS_LOGIN_RESET_STORE,
  FILE_DOWNLOAD,
  FILE_DOWNLOAD_START,
  FILE_DOWNLOAD_SUCCESS,
  FILE_DOWNLOAD_FAILURE,
  FILE_DOWNLOAD_RESET_STORE,
  USERS_SEND_PASSWORD_RESET_EMAIL,
  USERS_SEND_PASSWORD_RESET_EMAIL_START,
  USERS_SEND_PASSWORD_RESET_EMAIL_SUCCESS,
  USERS_SEND_PASSWORD_RESET_EMAIL_FAILURE,
  USERS_SEND_PASSWORD_RESET_EMAIL_RESET_STORE,
  USERS_PASSWORD_RESET,
  USERS_PASSWORD_RESET_START,
  USERS_PASSWORD_RESET_SUCCESS,
  USERS_PASSWORD_RESET_FAILURE,
  USERS_PASSWORD_RESET_RESET_STORE,
  PLANNER_UNASSIGNED_UNITS,
  PLANNER_UNASSIGNED_UNITS_START,
  PLANNER_UNASSIGNED_UNITS_SUCCESS,
  PLANNER_UNASSIGNED_UNITS_FAILURE,
  PLANNER_UNASSIGNED_UNITS_RESET_STORE,
  PLANNER_UNIT_UPDATE,
  PLANNER_UNIT_UPDATE_START,
  PLANNER_UNIT_UPDATE_SUCCESS,
  PLANNER_UNIT_UPDATE_FAILURE,
  PLANNER_UNIT_UPDATE_RESET_STORE,
  PLANNER_WEEK,
  PLANNER_WEEK_START,
  PLANNER_WEEK_SUCCESS,
  PLANNER_WEEK_FAILURE,
  PLANNER_WEEK_RESET_STORE,
  PLANNER_PREVIOUSLY_ON,
  PLANNER_PREVIOUSLY_ON_START,
  PLANNER_PREVIOUSLY_ON_SUCCESS,
  PLANNER_PREVIOUSLY_ON_FAILURE,
  PLANNER_PREVIOUSLY_ON_RESET_STORE,
  CALCULATOR_COUNTRIES,
  CALCULATOR_COUNTRIES_START,
  CALCULATOR_COUNTRIES_SUCCESS,
  CALCULATOR_COUNTRIES_FAILURE,
  CALCULATOR_COUNTRIES_RESET_STORE,
  CALCULATOR_LOCATIONS,
  CALCULATOR_LOCATIONS_START,
  CALCULATOR_LOCATIONS_SUCCESS,
  CALCULATOR_LOCATIONS_FAILURE,
  CALCULATOR_LOCATIONS_RESET_STORE,
  CALCULATOR_RATE,
  CALCULATOR_RATE_START,
  CALCULATOR_RATE_SUCCESS,
  CALCULATOR_RATE_FAILURE,
  CALCULATOR_RATE_RESET_STORE,
  TARIFFS_SUPPLIERS,
  TARIFFS_SUPPLIERS_START,
  TARIFFS_SUPPLIERS_SUCCESS,
  TARIFFS_SUPPLIERS_FAILURE,
  TARIFFS_SUPPLIERS_RESET_STORE,
  TARIFFS_CONFIG,
  TARIFFS_CONFIG_START,
  TARIFFS_CONFIG_SUCCESS,
  TARIFFS_CONFIG_FAILURE,
  TARIFFS_CONFIG_RESET_STORE,
  TARIFFS_CONFIG_UPDATE,
  TARIFFS_CONFIG_UPDATE_START,
  TARIFFS_CONFIG_UPDATE_SUCCESS,
  TARIFFS_CONFIG_UPDATE_FAILURE,
  TARIFFS_CONFIG_UPDATE_RESET_STORE,
  QUICK_FIND,
  QUICK_FIND_START,
  QUICK_FIND_SUCCESS,
  QUICK_FIND_FAILURE,
  QUICK_FIND_RESET_STORE,
  COMPANIES,
  COMPANIES_START,
  COMPANIES_SUCCESS,
  COMPANIES_FAILURE,
  COMPANIES_RESET_STORE,
  COMPANIES_SHOW,
  COMPANIES_SHOW_START,
  COMPANIES_SHOW_SUCCESS,
  COMPANIES_SHOW_FAILURE,
  COMPANIES_SHOW_RESET_STORE,
  COMPANIES_CREATE,
  COMPANIES_CREATE_START,
  COMPANIES_CREATE_SUCCESS,
  COMPANIES_CREATE_FAILURE,
  COMPANIES_CREATE_RESET_STORE,
  COMPANIES_UPDATE,
  COMPANIES_UPDATE_START,
  COMPANIES_UPDATE_SUCCESS,
  COMPANIES_UPDATE_FAILURE,
  COMPANIES_UPDATE_RESET_STORE,
  COMPANIES_DESTROY,
  COMPANIES_DESTROY_START,
  COMPANIES_DESTROY_SUCCESS,
  COMPANIES_DESTROY_FAILURE,
  COMPANIES_DESTROY_RESET_STORE,
  COMPANIES_SEARCH,
  COMPANIES_SEARCH_START,
  COMPANIES_SEARCH_SUCCESS,
  COMPANIES_SEARCH_FAILURE,
  COMPANIES_SEARCH_RESET_STORE,
  COMPANIES_LABEL,
  COMPANIES_LABEL_START,
  COMPANIES_LABEL_SUCCESS,
  COMPANIES_LABEL_FAILURE,
  COMPANIES_LABEL_RESET_STORE,
  COMPANIES_DETAILS,
  COMPANIES_DETAILS_START,
  COMPANIES_DETAILS_SUCCESS,
  COMPANIES_DETAILS_FAILURE,
  COMPANIES_DETAILS_RESET_STORE,
  ADDRESSES,
  ADDRESSES_START,
  ADDRESSES_SUCCESS,
  ADDRESSES_FAILURE,
  ADDRESSES_RESET_STORE,
  ADDRESSES_SHOW,
  ADDRESSES_SHOW_START,
  ADDRESSES_SHOW_SUCCESS,
  ADDRESSES_SHOW_FAILURE,
  ADDRESSES_SHOW_RESET_STORE,
  ADDRESSES_CREATE,
  ADDRESSES_CREATE_START,
  ADDRESSES_CREATE_SUCCESS,
  ADDRESSES_CREATE_FAILURE,
  ADDRESSES_CREATE_RESET_STORE,
  ADDRESSES_UPDATE,
  ADDRESSES_UPDATE_START,
  ADDRESSES_UPDATE_SUCCESS,
  ADDRESSES_UPDATE_FAILURE,
  ADDRESSES_UPDATE_RESET_STORE,
  ADDRESSES_DESTROY,
  ADDRESSES_DESTROY_START,
  ADDRESSES_DESTROY_SUCCESS,
  ADDRESSES_DESTROY_FAILURE,
  ADDRESSES_DESTROY_RESET_STORE,
  ADDRESSES_SEARCH,
  ADDRESSES_SEARCH_START,
  ADDRESSES_SEARCH_SUCCESS,
  ADDRESSES_SEARCH_FAILURE,
  ADDRESSES_SEARCH_RESET_STORE,
  ADDRESSES_LABEL,
  ADDRESSES_LABEL_START,
  ADDRESSES_LABEL_SUCCESS,
  ADDRESSES_LABEL_FAILURE,
  ADDRESSES_LABEL_RESET_STORE,
  CONTACTS,
  CONTACTS_START,
  CONTACTS_SUCCESS,
  CONTACTS_FAILURE,
  CONTACTS_RESET_STORE,
  CONTACTS_SHOW,
  CONTACTS_SHOW_START,
  CONTACTS_SHOW_SUCCESS,
  CONTACTS_SHOW_FAILURE,
  CONTACTS_SHOW_RESET_STORE,
  CONTACTS_CREATE,
  CONTACTS_CREATE_START,
  CONTACTS_CREATE_SUCCESS,
  CONTACTS_CREATE_FAILURE,
  CONTACTS_CREATE_RESET_STORE,
  CONTACTS_UPDATE,
  CONTACTS_UPDATE_START,
  CONTACTS_UPDATE_SUCCESS,
  CONTACTS_UPDATE_FAILURE,
  CONTACTS_UPDATE_RESET_STORE,
  CONTACTS_DESTROY,
  CONTACTS_DESTROY_START,
  CONTACTS_DESTROY_SUCCESS,
  CONTACTS_DESTROY_FAILURE,
  CONTACTS_DESTROY_RESET_STORE,
  OPENING_TIMES,
  OPENING_TIMES_START,
  OPENING_TIMES_SUCCESS,
  OPENING_TIMES_FAILURE,
  OPENING_TIMES_RESET_STORE,
  OPENING_TIMES_SHOW,
  OPENING_TIMES_SHOW_START,
  OPENING_TIMES_SHOW_SUCCESS,
  OPENING_TIMES_SHOW_FAILURE,
  OPENING_TIMES_SHOW_RESET_STORE,
  OPENING_TIMES_CREATE,
  OPENING_TIMES_CREATE_START,
  OPENING_TIMES_CREATE_SUCCESS,
  OPENING_TIMES_CREATE_FAILURE,
  OPENING_TIMES_CREATE_RESET_STORE,
  OPENING_TIMES_UPDATE,
  OPENING_TIMES_UPDATE_START,
  OPENING_TIMES_UPDATE_SUCCESS,
  OPENING_TIMES_UPDATE_FAILURE,
  OPENING_TIMES_UPDATE_RESET_STORE,
  OPENING_TIMES_DESTROY,
  OPENING_TIMES_DESTROY_START,
  OPENING_TIMES_DESTROY_SUCCESS,
  OPENING_TIMES_DESTROY_FAILURE,
  OPENING_TIMES_DESTROY_RESET_STORE,
  LINE_ITEMS,
  LINE_ITEMS_START,
  LINE_ITEMS_SUCCESS,
  LINE_ITEMS_FAILURE,
  LINE_ITEMS_RESET_STORE,
  LINE_ITEMS_SHOW,
  LINE_ITEMS_SHOW_START,
  LINE_ITEMS_SHOW_SUCCESS,
  LINE_ITEMS_SHOW_FAILURE,
  LINE_ITEMS_SHOW_RESET_STORE,
  LINE_ITEMS_CREATE,
  LINE_ITEMS_CREATE_START,
  LINE_ITEMS_CREATE_SUCCESS,
  LINE_ITEMS_CREATE_FAILURE,
  LINE_ITEMS_CREATE_RESET_STORE,
  LINE_ITEMS_UPDATE,
  LINE_ITEMS_UPDATE_START,
  LINE_ITEMS_UPDATE_SUCCESS,
  LINE_ITEMS_UPDATE_FAILURE,
  LINE_ITEMS_UPDATE_RESET_STORE,
  LINE_ITEMS_DESTROY,
  LINE_ITEMS_DESTROY_START,
  LINE_ITEMS_DESTROY_SUCCESS,
  LINE_ITEMS_DESTROY_FAILURE,
  LINE_ITEMS_DESTROY_RESET_STORE,
  LINE_ITEMS_MANAGE,
  LINE_ITEMS_MANAGE_START,
  LINE_ITEMS_MANAGE_SUCCESS,
  LINE_ITEMS_MANAGE_FAILURE,
  LINE_ITEMS_MANAGE_RESET_STORE,
  JOBS_SEND_EMAIL,
  JOBS_SEND_EMAIL_START,
  JOBS_SEND_EMAIL_SUCCESS,
  JOBS_SEND_EMAIL_FAILURE,
  JOBS_SEND_EMAIL_RESET_STORE,
  QUOTES,
  QUOTES_START,
  QUOTES_SUCCESS,
  QUOTES_FAILURE,
  QUOTES_RESET_STORE,
  QUOTES_SHOW,
  QUOTES_SHOW_START,
  QUOTES_SHOW_SUCCESS,
  QUOTES_SHOW_FAILURE,
  QUOTES_SHOW_RESET_STORE,
  QUOTES_CREATE,
  QUOTES_CREATE_START,
  QUOTES_CREATE_SUCCESS,
  QUOTES_CREATE_FAILURE,
  QUOTES_CREATE_RESET_STORE,
  QUOTES_UPDATE,
  QUOTES_UPDATE_START,
  QUOTES_UPDATE_SUCCESS,
  QUOTES_UPDATE_FAILURE,
  QUOTES_UPDATE_RESET_STORE,
  QUOTES_DESTROY,
  QUOTES_DESTROY_START,
  QUOTES_DESTROY_SUCCESS,
  QUOTES_DESTROY_FAILURE,
  QUOTES_DESTROY_RESET_STORE,
  QUOTES_SEND_EMAIL,
  QUOTES_SEND_EMAIL_START,
  QUOTES_SEND_EMAIL_SUCCESS,
  QUOTES_SEND_EMAIL_FAILURE,
  QUOTES_SEND_EMAIL_RESET_STORE,
  EMAILS,
  EMAILS_START,
  EMAILS_SUCCESS,
  EMAILS_FAILURE,
  EMAILS_RESET_STORE,
  EMAILS_SHOW,
  EMAILS_SHOW_START,
  EMAILS_SHOW_SUCCESS,
  EMAILS_SHOW_FAILURE,
  EMAILS_SHOW_RESET_STORE,
  EMAILS_CREATE,
  EMAILS_CREATE_START,
  EMAILS_CREATE_SUCCESS,
  EMAILS_CREATE_FAILURE,
  EMAILS_CREATE_RESET_STORE,
  EMAILS_UPDATE,
  EMAILS_UPDATE_START,
  EMAILS_UPDATE_SUCCESS,
  EMAILS_UPDATE_FAILURE,
  EMAILS_UPDATE_RESET_STORE,
  EMAILS_DESTROY,
  EMAILS_DESTROY_START,
  EMAILS_DESTROY_SUCCESS,
  EMAILS_DESTROY_FAILURE,
  EMAILS_DESTROY_RESET_STORE,
  JOBS_EMAIL_TEMPLATES,
  JOBS_EMAIL_TEMPLATES_START,
  JOBS_EMAIL_TEMPLATES_SUCCESS,
  JOBS_EMAIL_TEMPLATES_FAILURE,
  JOBS_EMAIL_TEMPLATES_RESET_STORE,
  QUOTES_EMAIL_TEMPLATE,
  QUOTES_EMAIL_TEMPLATE_START,
  QUOTES_EMAIL_TEMPLATE_SUCCESS,
  QUOTES_EMAIL_TEMPLATE_FAILURE,
  QUOTES_EMAIL_TEMPALTE_RESET_STORE,
  QUOTES_HISTORY,
  QUOTES_HISTORY_START,
  QUOTES_HISTORY_SUCCESS,
  QUOTES_HISTORY_FAILURE,
  QUOTES_HISTORY_RESET_STORE,
  CONVERT_QUOTE_TO_JOB,
  CONVERT_QUOTE_TO_JOB_START,
  CONVERT_QUOTE_TO_JOB_SUCCESS,
  CONVERT_QUOTE_TO_JOB_FAILURE,
  CONVERT_QUOTE_TO_JOB_RESET_STORE,
  JOBS,
  JOBS_START,
  JOBS_SUCCESS,
  JOBS_FAILURE,
  JOBS_RESET_STORE,
  JOBS_SHOW,
  JOBS_SHOW_START,
  JOBS_SHOW_SUCCESS,
  JOBS_SHOW_FAILURE,
  JOBS_SHOW_RESET_STORE,
  JOBS_CREATE,
  JOBS_CREATE_START,
  JOBS_CREATE_SUCCESS,
  JOBS_CREATE_FAILURE,
  JOBS_CREATE_RESET_STORE,
  JOBS_UPDATE,
  JOBS_UPDATE_START,
  JOBS_UPDATE_SUCCESS,
  JOBS_UPDATE_FAILURE,
  JOBS_UPDATE_RESET_STORE,
  JOBS_DESTROY,
  JOBS_DESTROY_START,
  JOBS_DESTROY_SUCCESS,
  JOBS_DESTROY_FAILURE,
  JOBS_DESTROY_RESET_STORE,
  GROUPAGES,
  GROUPAGES_START,
  GROUPAGES_SUCCESS,
  GROUPAGES_FAILURE,
  GROUPAGES_RESET_STORE,
  GROUPAGES_SHOW,
  GROUPAGES_SHOW_START,
  GROUPAGES_SHOW_SUCCESS,
  GROUPAGES_SHOW_FAILURE,
  GROUPAGES_SHOW_RESET_STORE,
  GROUPAGES_CREATE,
  GROUPAGES_CREATE_START,
  GROUPAGES_CREATE_SUCCESS,
  GROUPAGES_CREATE_FAILURE,
  GROUPAGES_CREATE_RESET_STORE,
  GROUPAGES_UPDATE,
  GROUPAGES_UPDATE_START,
  GROUPAGES_UPDATE_SUCCESS,
  GROUPAGES_UPDATE_FAILURE,
  GROUPAGES_UPDATE_RESET_STORE,
  GROUPAGES_DESTROY,
  GROUPAGES_DESTROY_START,
  GROUPAGES_DESTROY_SUCCESS,
  GROUPAGES_DESTROY_FAILURE,
  GROUPAGES_DESTROY_RESET_STORE,
  GROUPAGE_JOBS,
  GROUPAGE_JOBS_START,
  GROUPAGE_JOBS_SUCCESS,
  GROUPAGE_JOBS_FAILURE,
  GROUPAGE_JOBS_RESET_STORE,
  GROUPAGE_FINANCIALS,
  GROUPAGE_FINANCIALS_START,
  GROUPAGE_FINANCIALS_SUCCESS,
  GROUPAGE_FINANCIALS_FAILURE,
  GROUPAGE_FINANCIALS_RESET_STORE,
  GROUPAGE_BULK_INVOICE,
  GROUPAGE_BULK_INVOICE_START,
  GROUPAGE_BULK_INVOICE_SUCCESS,
  GROUPAGE_BULK_INVOICE_FAILURE,
  GROUPAGE_BULK_INVOICE_RESET_STORE,
  GROUPAGE_SEND_JOB_INVOICES,
  GROUPAGE_SEND_JOB_INVOICES_START,
  GROUPAGE_SEND_JOB_INVOICES_SUCCESS,
  GROUPAGE_SEND_JOB_INVOICES_FAILURE,
  PACKAGE_TYPES,
  PACKAGE_TYPES_START,
  PACKAGE_TYPES_SUCCESS,
  PACKAGE_TYPES_FAILURE,
  PACKAGE_TYPES_SHOW,
  PACKAGE_TYPES_SHOW_START,
  PACKAGE_TYPES_SHOW_SUCCESS,
  PACKAGE_TYPES_SHOW_FAILURE,
  PACKAGE_TYPES_CREATE,
  PACKAGE_TYPES_CREATE_START,
  PACKAGE_TYPES_CREATE_SUCCESS,
  PACKAGE_TYPES_CREATE_FAILURE,
  PACKAGE_TYPES_UPDATE,
  PACKAGE_TYPES_UPDATE_START,
  PACKAGE_TYPES_UPDATE_SUCCESS,
  PACKAGE_TYPES_UPDATE_FAILURE,
  PACKAGE_TYPES_DESTROY,
  PACKAGE_TYPES_DESTROY_START,
  PACKAGE_TYPES_DESTROY_SUCCESS,
  PACKAGE_TYPES_DESTROY_FAILURE,
  GROUPAGE_BULK_EMAIL,
  GROUPAGE_BULK_EMAIL_START,
  GROUPAGE_BULK_EMAIL_SUCCESS,
  GROUPAGE_BULK_EMAIL_FAILURE,
  GROUPAGE_BULK_EMAIL_RESET_STORE,
  GROUPAGE_SEND_JOB_EMAILS,
  GROUPAGE_SEND_JOB_EMAILS_START,
  GROUPAGE_SEND_JOB_EMAILS_SUCCESS,
  GROUPAGE_SEND_JOB_EMAILS_FAILURE,
  TARIFFS_DISTRIBUTION,
  TARIFFS_DISTRIBUTION_START,
  TARIFFS_DISTRIBUTION_SUCCESS,
  TARIFFS_DISTRIBUTION_FAILURE,
  TARIFFS_DISTRIBUTION_RESET_STORE,
  TARIFFS_DISTRIBUTION_SHOW,
  TARIFFS_DISTRIBUTION_SHOW_START,
  TARIFFS_DISTRIBUTION_SHOW_SUCCESS,
  TARIFFS_DISTRIBUTION_SHOW_FAILURE,
  TARIFFS_DISTRIBUTION_SHOW_RESET_STORE,
  TARIFFS_DISTRIBUTION_CREATE,
  TARIFFS_DISTRIBUTION_CREATE_START,
  TARIFFS_DISTRIBUTION_CREATE_SUCCESS,
  TARIFFS_DISTRIBUTION_CREATE_FAILURE,
  TARIFFS_DISTRIBUTION_CREATE_RESET_STORE,
  TARIFFS_DISTRIBUTION_UPDATE,
  TARIFFS_DISTRIBUTION_UPDATE_START,
  TARIFFS_DISTRIBUTION_UPDATE_SUCCESS,
  TARIFFS_DISTRIBUTION_UPDATE_FAILURE,
  TARIFFS_DISTRIBUTION_UPDATE_RESET_STORE,
  TARIFFS_DISTRIBUTION_DESTROY,
  TARIFFS_DISTRIBUTION_DESTROY_START,
  TARIFFS_DISTRIBUTION_DESTROY_SUCCESS,
  TARIFFS_DISTRIBUTION_DESTROY_FAILURE,
  TARIFFS_DISTRIBUTION_DESTROY_RESET_STORE,
  TARIFFS_CUSTOMERS,
  TARIFFS_CUSTOMERS_START,
  TARIFFS_CUSTOMERS_SUCCESS,
  TARIFFS_CUSTOMERS_FAILURE,
  TARIFFS_CUSTOMERS_RESET_STORE,
  TARIFFS_CUSTOMERS_SHOW,
  TARIFFS_CUSTOMERS_SHOW_START,
  TARIFFS_CUSTOMERS_SHOW_SUCCESS,
  TARIFFS_CUSTOMERS_SHOW_FAILURE,
  TARIFFS_CUSTOMERS_SHOW_RESET_STORE,
  TARIFFS_CUSTOMERS_CREATE,
  TARIFFS_CUSTOMERS_CREATE_START,
  TARIFFS_CUSTOMERS_CREATE_SUCCESS,
  TARIFFS_CUSTOMERS_CREATE_FAILURE,
  TARIFFS_CUSTOMERS_CREATE_RESET_STORE,
  TARIFFS_CUSTOMERS_UPDATE,
  TARIFFS_CUSTOMERS_UPDATE_START,
  TARIFFS_CUSTOMERS_UPDATE_SUCCESS,
  TARIFFS_CUSTOMERS_UPDATE_FAILURE,
  TARIFFS_CUSTOMERS_UPDATE_RESET_STORE,
  TARIFFS_CUSTOMERS_DESTROY,
  TARIFFS_CUSTOMERS_DESTROY_START,
  TARIFFS_CUSTOMERS_DESTROY_SUCCESS,
  TARIFFS_CUSTOMERS_DESTROY_FAILURE,
  TARIFFS_CUSTOMERS_DESTROY_RESET_STORE,
  TARIFFS_SURCHARGES,
  TARIFFS_SURCHARGES_START,
  TARIFFS_SURCHARGES_SUCCESS,
  TARIFFS_SURCHARGES_FAILURE,
  TARIFFS_SURCHARGES_RESET_STORE,
  TARIFFS_SURCHARGES_SHOW,
  TARIFFS_SURCHARGES_SHOW_START,
  TARIFFS_SURCHARGES_SHOW_SUCCESS,
  TARIFFS_SURCHARGES_SHOW_FAILURE,
  TARIFFS_SURCHARGES_SHOW_RESET_STORE,
  TARIFFS_SURCHARGES_CREATE,
  TARIFFS_SURCHARGES_CREATE_START,
  TARIFFS_SURCHARGES_CREATE_SUCCESS,
  TARIFFS_SURCHARGES_CREATE_FAILURE,
  TARIFFS_SURCHARGES_CREATE_RESET_STORE,
  TARIFFS_SURCHARGES_UPDATE,
  TARIFFS_SURCHARGES_UPDATE_START,
  TARIFFS_SURCHARGES_UPDATE_SUCCESS,
  TARIFFS_SURCHARGES_UPDATE_FAILURE,
  TARIFFS_SURCHARGES_UPDATE_RESET_STORE,
  TARIFFS_SURCHARGES_DESTROY,
  TARIFFS_SURCHARGES_DESTROY_START,
  TARIFFS_SURCHARGES_DESTROY_SUCCESS,
  TARIFFS_SURCHARGES_DESTROY_FAILURE,
  TARIFFS_SURCHARGES_DESTROY_RESET_STORE,
  TARIFFS_FREIGHT,
  TARIFFS_FREIGHT_START,
  TARIFFS_FREIGHT_SUCCESS,
  TARIFFS_FREIGHT_FAILURE,
  TARIFFS_FREIGHT_RESET_STORE,
  TARIFFS_FREIGHT_SHOW,
  TARIFFS_FREIGHT_SHOW_START,
  TARIFFS_FREIGHT_SHOW_SUCCESS,
  TARIFFS_FREIGHT_SHOW_FAILURE,
  TARIFFS_FREIGHT_SHOW_RESET_STORE,
  TARIFFS_FREIGHT_CREATE,
  TARIFFS_FREIGHT_CREATE_START,
  TARIFFS_FREIGHT_CREATE_SUCCESS,
  TARIFFS_FREIGHT_CREATE_FAILURE,
  TARIFFS_FREIGHT_CREATE_RESET_STORE,
  TARIFFS_FREIGHT_UPDATE,
  TARIFFS_FREIGHT_UPDATE_START,
  TARIFFS_FREIGHT_UPDATE_SUCCESS,
  TARIFFS_FREIGHT_UPDATE_FAILURE,
  TARIFFS_FREIGHT_UPDATE_RESET_STORE,
  TARIFFS_FREIGHT_DESTROY,
  TARIFFS_FREIGHT_DESTROY_START,
  TARIFFS_FREIGHT_DESTROY_SUCCESS,
  TARIFFS_FREIGHT_DESTROY_FAILURE,
  TARIFFS_FREIGHT_DESTROY_RESET_STORE,
  USERS,
  USERS_START,
  USERS_SUCCESS,
  USERS_FAILURE,
  USERS_RESET_STORE,
  USERS_SHOW,
  USERS_SHOW_START,
  USERS_SHOW_SUCCESS,
  USERS_SHOW_FAILURE,
  USERS_SHOW_RESET_STORE,
  USERS_CREATE,
  USERS_CREATE_START,
  USERS_CREATE_SUCCESS,
  USERS_CREATE_FAILURE,
  USERS_CREATE_RESET_STORE,
  USERS_UPDATE,
  USERS_UPDATE_START,
  USERS_UPDATE_SUCCESS,
  USERS_UPDATE_FAILURE,
  USERS_UPDATE_RESET_STORE,
  USERS_DESTROY,
  USERS_DESTROY_START,
  USERS_DESTROY_SUCCESS,
  USERS_DESTROY_FAILURE,
  USERS_DESTROY_RESET_STORE,
  USERS_SEARCH,
  USERS_SEARCH_START,
  USERS_SEARCH_SUCCESS,
  USERS_SEARCH_FAILURE,
  USERS_SEARCH_RESET_STORE,
  USERS_LABEL,
  USERS_LABEL_START,
  USERS_LABEL_SUCCESS,
  USERS_LABEL_FAILURE,
  USERS_LABEL_RESET_STORE,
  CURRENT_USER,
  CURRENT_USER_START,
  CURRENT_USER_SUCCESS,
  CURRENT_USER_FAILURE,
  CURRENT_USER_RESET_STORE,
  ADMIN_SETTINGS,
  ADMIN_SETTINGS_START,
  ADMIN_SETTINGS_SUCCESS,
  ADMIN_SETTINGS_FAILURE,
  ADMIN_SETTINGS_RESET_STORE,
  ADMIN_SETTINGS_UPDATE,
  ADMIN_SETTINGS_UPDATE_START,
  ADMIN_SETTINGS_UPDATE_SUCCESS,
  ADMIN_SETTINGS_UPDATE_FAILURE,
  ADMIN_SETTINGS_UPDATE_RESET_STORE,
  COMMODITIES_SEARCH,
  COMMODITIES_SEARCH_START,
  COMMODITIES_SEARCH_SUCCESS,
  COMMODITIES_SEARCH_FAILURE,
  COMMODITIES_SEARCH_RESET_STORE,
  COMMODITIES_LABEL,
  COMMODITIES_LABEL_START,
  COMMODITIES_LABEL_SUCCESS,
  COMMODITIES_LABEL_FAILURE,
  COMMODITIES_LABEL_RESET_STORE,
  XERO_CONFIG,
  XERO_CONFIG_START,
  XERO_CONFIG_SUCCESS,
  XERO_CONFIG_FAILURE,
  XERO_CONTACTS,
  XERO_CONTACTS_START,
  XERO_CONTACTS_SUCCESS,
  XERO_CONTACTS_FAILURE,
  XERO_CONTACTS_SHOW,
  XERO_CONTACTS_SHOW_START,
  XERO_CONTACTS_SHOW_SUCCESS,
  XERO_CONTACTS_SHOW_FAILURE,
  XERO_INVOICES_CREATE,
  XERO_INVOICES_CREATE_START,
  XERO_INVOICES_CREATE_SUCCESS,
  XERO_INVOICES_CREATE_FAILURE,
  XERO_TAX_RATES,
  XERO_TAX_RATES_START,
  XERO_TAX_RATES_SUCCESS,
  XERO_TAX_RATES_FAILURE,
  XERO_ACCOUNTS,
  XERO_ACCOUNTS_START,
  XERO_ACCOUNTS_SUCCESS,
  XERO_ACCOUNTS_FAILURE,
  TEMPLATES_GROUPAGES,
  TEMPLATES_GROUPAGES_START,
  TEMPLATES_GROUPAGES_SUCCESS,
  TEMPLATES_GROUPAGES_FAILURE,
  TEMPLATES_GROUPAGES_NEW,
  TEMPLATES_GROUPAGES_SHOW,
  TEMPLATES_GROUPAGES_SHOW_START,
  TEMPLATES_GROUPAGES_SHOW_SUCCESS,
  TEMPLATES_GROUPAGES_SHOW_FAILURE,
  TEMPLATES_GROUPAGES_CREATE,
  TEMPLATES_GROUPAGES_CREATE_START,
  TEMPLATES_GROUPAGES_CREATE_SUCCESS,
  TEMPLATES_GROUPAGES_CREATE_FAILURE,
  TEMPLATES_GROUPAGES_UPDATE,
  TEMPLATES_GROUPAGES_UPDATE_START,
  TEMPLATES_GROUPAGES_UPDATE_SUCCESS,
  TEMPLATES_GROUPAGES_UPDATE_FAILURE,
  TEMPLATES_GROUPAGES_DESTROY,
  TEMPLATES_GROUPAGES_DESTROY_START,
  TEMPLATES_GROUPAGES_DESTROY_SUCCESS,
  TEMPLATES_GROUPAGES_DESTROY_FAILURE,
} = ActionTypes

import React, { Component } from 'react'
import { get, isEqual } from 'lodash'
import { connect } from 'react-redux'

import Api from '../../api/call'
import { COMPANIES_DETAILS } from '../../root/action-types'
import { Info } from '../../generic/show/sidebar.js'
import SidebarCompanyNotes from '../../generic/SidebarCompanyNotes'

import '../../generic/sidebar.scss'

export class Sidebar extends Component {

  componentDidMount() {
    this.requestDetails(this.props)
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps, this.props)) {
      this.requestDetails(this.props)
    }
  }

  requestDetails = ({ customerId, shipperId }) => {
    customerId && Api({ request: COMPANIES_DETAILS, id: customerId })
    shipperId && Api({ request: COMPANIES_DETAILS, id: shipperId })
  }

  renderCompanies = () => {
    const { customerId, shipperId, details } = this.props

    return (
      <React.Fragment>
        {customerId && <this.renderCompanyDetails role={"Customer"} {...details[customerId]} />}
        {shipperId && <this.renderCompanyDetails
          role="Shipper"
          priority={customerId ? 'quaternary' : 'tertiary'}
          {...details[shipperId]}
        />}
      </React.Fragment>
    )
  }

  contactFullName = ({ firstName, lastName }) => (
    [firstName, lastName].filter(Boolean).join(' ') || '-'
  )

  findPhone = ({ telephone }) => {
    return telephone || '-'
  }

  findEmail = ({ email }) => {
    return email || '-'
  }

  renderInvoiceStatus = () => {
    const { salesInvoiced } = this.props

    return (
      <div className="details secondary">
        <h4>Invoice Status</h4>
        <Info name="Sales Invoiced" value={!!salesInvoiced} />
      </div>
    )
  }

  renderCompanyDetails = ({ name, primaryContact = {}, role, priority }) => {
    const contactName = this.contactFullName(primaryContact || '-')
    const phone = this.findPhone(primaryContact || '-')
    const email = this.findEmail(primaryContact || '-')

    return (
      <div className={`details customer-details ${priority || 'tertiary'}`}>
        <h4>{role}</h4>
        <Info name="Company" value={name} />
        <Info name="Contact" value={contactName} />
        <Info name="Phone" value={phone} />
        <Info name="Email" value={email} />
      </div>
    )
  }

  render = () => (
    <div className="sidebar">
      <div className="details details primary">
        <h1>{this.props.title}</h1>
      </div>
      {this.renderInvoiceStatus()}
      {this.renderCompanies()}
      {this.props.children}
      <SidebarCompanyNotes
        id={this.props.customerId}
        companyRole={'Customer'}
        category={'bookings'}
      />
    </div>
  )
}

const mapStateToProps = ({ companies: { details } }) => ({
  details: get(details, 'details', {})
})

export default connect(mapStateToProps)(Sidebar)

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get, pick, startCase } from 'lodash'

import { Field } from '../../forms/'
import {
  SimpleSelect,
  AddressSelect,
  CompanySelect,
  CountrySelect,
  UserSelect,
  SupplierHubSelect,
  CommoditySelect,
} from '../../forms/select'
import { DateSelector } from '../../forms/date-time'

import Incoterm from '../../forms/Incoterm'

import './General.scss'

import { FREIGHT_MODE_TYPES as QUOTE_FREIGHT_MODE_TYPES } from '../../quotes/quote/General'
import { STATUS } from '../constants'

const FREIGHT_MODE_TYPES = [
  ...QUOTE_FREIGHT_MODE_TYPES,
  "Pro Rata"
]

const DATES = [
  'goodsReadyDate',
  'contactOnDate',
  'etaToClientDate',
  'updateReceivedDate',
]

export class General extends Component {

  state = {
    ...pick(
      this.props,
      [
        'customerId',
        'originCountryCode',
        'destinationCountryCode',
        'status',
        'shipperId',
        'consigneeId',
        'originAddressId',
        'destinationAddressId',
        ...DATES,
        'bookingDate',
        'ownerId',
        'incotermType',
        'incotermNamedPlace',
        'freightMode',
        'weekNumber',
        'supplierId',
        'hub',
        'commodity',
        'toCall',
        'toBook',
        'salesToRequote',
        'requestedRate',
      ]
    )
  }

  componentDidMount = () => {
    this.eurobridgeLogic()
  }

  eurobridgeLogic = () => {
    // If the Job is an Import default the Consignee to be the Customer
    if (this.props.consigneeId == null) {
      if (this.props.destinationCountryCode == 'MT') {
        this.setState({ consigneeId: this.props.customerId })
      }
    }
    // If the Job is an Export default the Shipper to be the Customer
    if (this.props.shipperId == null) {
      if (this.props.originCountryCode == 'MT') {
        this.setState({ shipperId: this.props.customerId })
      }
    }
  }

  selectStateSetter = (name, callback = null) => (
    ({ value }) => this.setState(
      { [name]: value },
      callback ? callback() : null
    )
  )

  setSupplier = ({ value }) => {
    const { results } = this.props.search
    const supplierResult = results.find(r => r.id === value)
    const defaultHub = get(supplierResult, 'defaultSupplierHub')
    this.setState({ supplierId: value, hub: defaultHub })
  }

  setCustomer = this.selectStateSetter('customerId')
  setShipper = this.selectStateSetter('shipperId', () => this.setState({ originAddressId: null }))
  setConsignee = this.selectStateSetter('consigneeId', () => this.setState({ destinationAddressId: null }))
  setOriginAddress = this.selectStateSetter('originAddressId')
  setDestinationAddress = this.selectStateSetter('destinationAddressId')
  setCommodity = ({ value }) => {this.setState({ commodity: value })}

  setIncotermType = ({ value }) => this.setState({ incotermType: value })
  setValue = ({ target: { name, value } }) => this.setState({ [name]: value })

  setOwnerId = ({ value }) => { this.setState({ ownerId: value }) }

  stateSetters = {}

  stateSetter = (name) => {
    if (!this.stateSetter[name]) {
      this.stateSetters[name] = (val) => this.setState({ [name]: val })
    }
    return this.stateSetters[name]
  }

  dates = () => (
    DATES.map(d =>
      <DateSelector
        label={startCase(d)}
        key={d}
        value={this.state[d]}
        onChange={this.stateSetter(d)}
        isDisabled={!this.props.edit}
      />
    )
  )

  setFormValue = ({ target: { name, value } }) => {
    this.setState({ [name]: value })
  }

  field = ({ label, name, ...props }) => (
    <Field label={label || startCase(name)}>
      <input
        name={name}
        value={this.state[name] || ''}
        onChange={this.setFormValue}
        disabled={!this.props.edit}
        {...props}
      />
    </Field>
  )

  editControls = () => (
    this.props.edit ? <this.controls /> : null
  )

  controls = () => (
    <div className="section controls">
      <button className="submit" onClick={this.save}>Save</button>
    </div>
  )

  save = () => (
    this.props.updateApiRequest(this.state)
  )

  render = () => (
    <div className="job-general">
      <div className="section">
        <h4>Job Details</h4>
        <CompanySelect
          label={'Customer'}
          value={this.state.customerId}
          onChange={this.setCustomer}
          isDisabled={!this.props.edit}
          customer
          allowCreation
        />
        <DateSelector
          label={'Booking Date'}
          value={this.state.bookingDate}
          onChange={this.stateSetter('bookingDate')}
          isDisabled={!this.props.edit}
        />
        <SimpleSelect
          label="Job Status"
          options={STATUS}
          onChange={this.stateSetter('status')}
          value={this.state.status}
          isDisabled={!this.props.edit}
        />
        <UserSelect
          label={'Job Owner'}
          value={this.state.ownerId}
          onChange={this.setOwnerId}
          isDisabled={!this.props.edit}
        />
        <CompanySelect
          label={'Shipper'}
          value={this.state.shipperId}
          onChange={this.setShipper}
          isDisabled={!this.props.edit}
          customer
          allowCreation
        />
        <AddressSelect
          label={'Collection Address'}
          companyId={this.state.shipperId}
          value={this.state.originAddressId}
          onChange={this.setOriginAddress}
          isDisabled={!this.props.edit || !this.state.shipperId}
          allowCreation
        />
        <CompanySelect
          label={'Consignee'}
          value={this.state.consigneeId}
          onChange={this.setConsignee}
          isDisabled={!this.props.edit}
          customer
          allowCreation
        />
        <AddressSelect
          label={'Delivery Address'}
          companyId={this.state.consigneeId}
          value={this.state.destinationAddressId}
          onChange={this.setDestinationAddress}
          isDisabled={!this.props.edit || !this.state.consigneeId}
          allowCreation
        />
        <CountrySelect
          labelPrefix={'Origin '}
          value={this.state.originCountryCode}
          onChange={this.stateSetter('originCountryCode')}
          isDisabled={!this.props.edit}
        />
        <CountrySelect
          labelPrefix={'Destination '}
          value={this.state.destinationCountryCode}
          onChange={this.stateSetter('destinationCountryCode')}
          isDisabled={!this.props.edit}
        />
        <Incoterm
          isDisabled={!this.props.edit}
          incotermType={this.state.incotermType}
          incotermNamedPlace={this.state.incotermNamedPlace}
          setIncotermType={this.setIncotermType}
          setIncotermNamedPlace={this.setValue}
        />
        <SimpleSelect
          label="Freight Mode"
          options={FREIGHT_MODE_TYPES}
          onChange={this.stateSetter('freightMode')}
          value={this.state.freightMode}
          isDisabled={!this.props.edit}
        />
        <CompanySelect
          label="Supplier"
          value={this.state.supplierId}
          onChange={this.setSupplier}
          isDisabled={!this.props.edit}
          customer
          allowCreation
        />
        <SupplierHubSelect
          key={this.state.supplierId}
          onChange={this.stateSetter('hub')}
          value={this.state.hub}
          isDisabled={!this.props.edit}
        />
        <CommoditySelect
          value={this.state.commodity}
          onChange={this.setCommodity}
          isDisabled={!this.props.edit}
        />
      </div>
      <div className="section">
        <h4>Dates</h4>
        <this.field name="weekNumber" />
        {this.dates()}
      </div>
      <div className="section">
        <h4>Notes</h4>
        <this.field name="toCall" />
        <this.field name="toBook" />
        <this.field name="salesToRequote" />
        <this.field name="requestedRate" />
      </div>

      <this.editControls />
    </div>
  )
}

const mapStateToProps = ({ jobs: { show }, companies: { search } }) => ({
  ...show.data || {},
  search
})

export default connect(mapStateToProps)(General)

import React from 'react'

import { boolOrValue } from '../display'

export const Info = ({ name, value, className, children }) => (
  <div className={`info ${className || ''}`}>
    <label>{name}</label>
    <span className="value">{children || boolOrValue(value)}</span>
  </div>
)

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { get } from 'lodash'

import Api from '../../api/call'
import { GROUPAGES_CREATE, GROUPAGES_CREATE_RESET_STORE } from '../../root/action-types'
import { Status, formatErrors } from '../../forms/Status'
import { Loading } from '../../generic/Loading'

import General from './General'

import './new.scss'

export const save = (attributes) => {
  Api({
    request: GROUPAGES_CREATE,
    data: attributes,
  })
}

class GroupageNew extends Component {

  inError = () => (
    !!get(this.props.groupage, 'errors')
  )

  groupageCreated = () => {
    if (get(this.props.groupage, 'groupageCreated', false)) {
      this.props.dispatch({
        type: GROUPAGES_CREATE_RESET_STORE,
      })
      return true
    }
    return false
  }

  newGroupageRedirect = () => (
    <Redirect to={`/groupages/${this.props.groupage.data.id}`} />
  )

  defaults = () => ({
    destinationCountryCode: 'MT',
    status: 'Active',
    ownerId: this.props.ownerId,
  })

  formOrLoading = () => (
    this.props.loading ? <Loading /> : this.renderForm()
  )

  renderForm = () => (
    <div className="new-groupage-container">
      <h1>New Groupage</h1>
      <div className="status-container">
        <Status isError={this.inError()}>
          {formatErrors(get(this.props.groupage, 'errors', []))}
        </Status>
      </div>
      <div className="pane-container">
        <div className="pane">
          <General
            edit
            save={save}
            {...this.defaults()}
            formError={this.inError()}
          />
        </div>
      </div>
    </div>
  )

  render = () => (
    this.groupageCreated() ? this.newGroupageRedirect() : this.formOrLoading()
  )

}

const mapStateToProps = ({ groupages: { groupage }, users: { current } }) => (
  { groupage, ownerId: current.id, loading: current.loading }
)

export default connect(mapStateToProps)(GroupageNew)

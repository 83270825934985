import React, { Component } from 'react'
import { connect } from 'react-redux'
import { pick, startCase } from 'lodash'

import { InlineLabelledToggle } from '../../forms/toggle'
import { SimpleSelect } from '../../forms/'

import './supplier.scss'

import { FREIGHT_MODE_TYPES } from '../../quotes/quote/General'


export class Customer extends Component {

  state = {
    ...pick(this.props, ['defaultUpToDoor', 'defaultFreightMode'])
  }

  toggleDefaultUpToDoor = () => (
    this.setState(prevState => ({ defaultUpToDoor: !prevState.defaultUpToDoor }))
  )

  editControls = () => (
    this.props.edit ? this.controls() : null
  )

  controls = () => (
    <div className="section controls">
      <button className="submit" onClick={this.save}>Save</button>
    </div>
  )

  changeSelect = (name) => (
    (value) => { this.setState({ [name]: value }) }
  )

  multiSelect = ({ name, options, ...props }) => (
    <SimpleSelect
      label={startCase(name)}
      value={this.state[name]}
      onChange={this.changeSelect(name)}
      options={options}
      isDisabled={!this.props.edit}
      {...props}
    />
  )

  save = () => (
    this.props.updateApiRequest(this.state)
  )

  render = () => {
    const { defaultUpToDoor } = this.state
    const { edit } = this.props

    return (
      <div className="supplier-tab">
        <h4>Quoting Preferences</h4>
        <div className="section">
          <div className="double-input">
            <InlineLabelledToggle
              name={"Up To Door"}
              value={defaultUpToDoor}
              onChange={this.toggleDefaultUpToDoor}
              isDisabled={!edit}
            />
            <this.multiSelect
              name="defaultFreightMode"
              options={FREIGHT_MODE_TYPES}
            />
          </div>
        </div>
        {this.editControls()}
      </div>
    )
  }

}

const mapStateToProps = ({
  companies,
}) => ({
  ...companies.show.data || {},
})

export default connect(mapStateToProps)(Customer)

import React from 'react'
import { Redirect } from 'react-router-dom'


const PLURALISED_QUERY_TYPES = {
  company: 'companies',
  groupage: 'groupages',
  job: 'jobs',
  quote: 'quotes'
}

const pluraliseQueryType = (queryType) => (
  PLURALISED_QUERY_TYPES[queryType]
)

export const buildRedirectPath = (queryType, id) => (
  `/${pluraliseQueryType(queryType)}/${id}`
)

const redirect = (data) => {
  const { queryType, results } = data
  return <Redirect to={buildRedirectPath(queryType, results[0].id)} />
}

const shouldRedirect = (data) => (
  data && data.results.length === 1
)

function QuickFindRedirect(props) {
  const { data } = props

  return (
    shouldRedirect(data) ? redirect(data) : null
  )
}

export default QuickFindRedirect

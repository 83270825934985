export const markedForInvoicingSelector = (
  { groupages: { invoices: { markedForInvoicing } } }
) => {
  return markedForInvoicing
}

export const jobsSelector = (
  { groupages: { invoices: { jobs: { data } } } }
) => {
  return data
}

export const jobSelector = (
  { groupages: { invoices: { jobs: { data } } } }, id
) => {
  return data.find(job => job.id == id) || {}
}

export const sendInvoicesSelector = (
  { groupages: { invoices: { sendInvoices } } }
) => {
  return sendInvoices
}

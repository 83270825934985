import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Select from 'react-select'

import ColumnSelect from './ColumnSelect'

class ControlRow extends Component {

  state = {
    columnSelecting: false,
  }

  renderSearch = () => {
    const {handleSearchChange, search, model} = this.props
    if (!handleSearchChange) return null
    return (
      <div className="search">
        <FontAwesomeIcon icon={ ['fas', 'search'] } fixedWidth />
        <input
          type="text"
          placeholder={model ? `Search ${model}...` : "Search..."}
          value={search}
          onChange={handleSearchChange}
        />
      </div>
    )
  }

  renderSort = () => {
    const { handleSortChange, handleSortDirectionChange, sort, sortDirection, sortOptions } = this.props
    if (!(handleSortChange && handleSortDirectionChange && sortOptions)) return null
    return (
      <div className="sort">
        <label>Sort by:</label>
        <Select
          classNamePrefix="select"
          className="sortSelect"
          value={ sort } onChange={ handleSortChange } options={ sortOptions }
        />
        <span className="sortDirection" onClick={ handleSortDirectionChange } >
          <FontAwesomeIcon icon={ sortDirection ? ['fas', 'sort-alpha-up'] : ['fas', 'sort-alpha-down'] } fixedWidth />
        </span>
      </div>
    )
  }

  renderPageSize = () => {
    const {pageSize, handleSizeChange, pageSizeOptions} = this.props
    return (
      <div className="pageSize">
        <label>Per page:</label>
        <Select
          classNamePrefix="select"
          className="pageSelect"
          value={ pageSize } onChange={ handleSizeChange } options={ pageSizeOptions }
        />
      </div>
    )
  }

  renderCategory = () => {
    const {category, handleCategoryChange, categoryOptions} = this.props
    if (!(handleCategoryChange && categoryOptions)) return null
    return (
      <div className="category">
        <label>Category:</label>
        <Select
          classNamePrefix="select"
          className="categorySelect"
          value={ category } onChange={ handleCategoryChange } options={ categoryOptions } isMulti={ true }
        />
      </div>
    )
  }

  columnSelect = () => (
    this.props.setColumns ? this.renderColumnSelect() : null
  )

  showColumnSelector = () => (
    this.setState({ columnSelecting: !this.state.columnSelecting })
  )

  renderColumnSelect = () => (
    <div>
      <button
        className="columns"
        onClick={this.showColumnSelector}
        title="Change columns"
      >
        <FontAwesomeIcon icon="columns" />
      </button>
      <ColumnSelect
        open={this.state.columnSelecting}
        close={this.showColumnSelector}
        options={this.props.columnOptions}
        value={this.props.currentColumns}
        setColumns={this.props.setColumns}
        defaultColumns={this.props.defaultColumns}
      />
    </div>
  )

  render = () => (
    <React.Fragment>
      <div className='controlRow'>
        <div className="leftSide">
          { this.renderSearch() }
        </div>
        <div className="centre">
          {this.renderSort()}
          {this.columnSelect()}
        </div>
        <div className="rightSide">
          {this.renderPageSize()}
          {this.renderCategory()}
        </div>
      </div>
      <hr />
    </React.Fragment>
  )
}

export default ControlRow

import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import {hideBars, SideBarVisibility} from '../layout/SideBarVisibility'
import Navbar from '../navbar/Navbar'
import Topbar from '../topbar/Topbar'

import './Layout.scss'

const BIG = 1380

class Layout extends Component {

  defaultExpandedState = () => {
    const saved = localStorage.getItem("eb-navbar-expand")
    if (saved) { return saved == "true" }

    return window.innerWidth > BIG
  }

  state = {
    expanded: this.defaultExpandedState()
  }

  width = window && window.innerWidth

  componentDidMount = () => {
    window.addEventListener('resize', this.queueResize)
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.queueResize)
  }

  queueResize = () => {
    clearTimeout(this.queuedResize)
    this.queuedResize = setTimeout(this.resize, 400)
  }

  resize = () => {
    const isBigger = window.innerWidth > this.width
    if (this.state.expanded && !isBigger && window.innerWidth < BIG) {
      this.setState({ expanded: false })
    } else if (!this.expanded && isBigger && window.innerWidth > BIG) {
      this.setState({ expanded: true })
    }
    this.width = window.innerWidth
  }

  toggleNavbarExpanded = () => {
    this.setState(prevState => {
      localStorage.setItem("eb-navbar-expand", !prevState.expanded)
      return { expanded: !prevState.expanded }
    })
  }

  render = () => (
    <React.Fragment>
      <SideBarVisibility>
        <Topbar
          toggleNavbarExpanded={this.toggleNavbarExpanded}
          navbarExpanded={this.state.expanded}
        />
      </SideBarVisibility>

      <div className="layout-under-topbar">
        <SideBarVisibility>
          <Navbar
            expanded={this.state.expanded}
          />
        </SideBarVisibility>

        <div className={
          hideBars() ? "layout-barless-page" : "layout-content-pane"
        }>
          {this.props.children}
        </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Layout)
import React, { Component } from 'react'
import { connect } from 'react-redux'

import Api from '../../api/call'
import { ADMIN_SETTINGS } from '../../root/action-types'

import { SimpleSelect } from './SimpleSelect'

export class SupplierHubSelect extends Component {

  componentDidMount = () => {
    Api({ request: ADMIN_SETTINGS })
  }

  render = () => {
    const { label, value, onChange, isDisabled } = this.props
    const { supplierHubOptions } = this.props

    return (
      <SimpleSelect
        label={label || 'Supplier Hub'}
        placeholder="N/A"
        value={value}
        onChange={onChange}
        options={supplierHubOptions}
        isDisabled={isDisabled}
        isClearable
      />
    )
  }
}

const mapStateToProps = ({
  adminSettings: { show: { details: { defaultSupplierHubOptions} } },
}) => ({
  supplierHubOptions: defaultSupplierHubOptions || [],
})

export default connect(mapStateToProps)(SupplierHubSelect)

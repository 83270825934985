import React, { Component } from 'react'
import { connect } from 'react-redux'

import Details from './Details'
import Rates from './Rates'

import './new.scss'

const STEPS = {
  details: Details,
  rates: Rates,
}

class QuoteNew extends Component {

  currentStep = () => {
    const Step = STEPS[this.props.step]
    return <Step location={this.props.location} />
  }

  render = () => (
    <div className="quote-new generic-show">
      <h1>New Quote</h1>
      <this.currentStep />
    </div>
  )

}

const mapStateToProps = ({ calculator: { quoteStep } }) => ({
  step: quoteStep || 'details'
})

export default connect(mapStateToProps)(QuoteNew)

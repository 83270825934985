import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, Redirect } from 'react-router-dom'
import qs from 'qs'

import Api from '../../api/call'
import ActionTypes from '../../root/action-types'

import { Field } from '../../forms/Field'
import { Status } from '../../forms/Status'

import './PasswordReset.scss'

import resetGraphic from '../../root/global-assets/password_reset.svg'
import { brandLogo } from '../../theme/assets'

const DEFAULT_STATE = {
  password: '',
  passwordCheck: '',
  passwordLength: 'empty',
  passwordsMatch: 'empty',
  passwordValid: false,
  reset_password_token: '',
  showLengthWarning: false,
  showMatchWarning: false,
  redirectToLogin: false,
}

class PasswordReset extends Component {

  state = {
    ...DEFAULT_STATE
  }

  static getDerivedStateFromProps = (nextProps) => {
    if (nextProps.passwordReset) {
      return {
        redirectToLogin: true,
      }
    }
    return null
  }

  componentDidMount = () => {
    const parsed = qs.parse(window.location.search.slice(1))
    const reset_password_token = parsed['reset_password_token']
    this.setState({ reset_password_token: reset_password_token })
  }

  redirectToLogin = () => (
    <Redirect to="/login" />
  )

  setFieldAndValidate = (e) => {
    this.setState({ [e.currentTarget.name]: e.currentTarget.value}, () => {
      this.validate()
    })
  }

  validate = () => {
    const { password, passwordCheck } = this.state
    const passwordLength = this.passwordLength(password, 8)
    const passwordsMatch = this.passwordsMatch(password, passwordCheck)
    this.setState({ passwordLength: passwordLength })
    this.setState({ passwordsMatch: passwordsMatch })
    if (passwordLength && passwordsMatch) {
      this.setState({ passwordValid: true })
    } else {
      this.setState({ passwordValid: false })
    }
  }

  passwordLength = (password, minLength) => (
    password.length >= minLength
  )

  passwordsMatch = (password, passwordCheck) => (
    password === passwordCheck
  )

  enableLengthWarning = () => (
    this.setState({ showLengthWarning: true })
  )

  enableMatchWarning = () => (
    this.setState({ showMatchWarning: true })
  )

  errorMessage = () => {
    if (this.props.error['reset_password_token']) {
      return (
        `This reset password token ${this.props.error['reset_password_token']}`
      )
    }
    if (this.props.error['password']) {
      return `The password ${this.props.error['password']}`
    }
    return JSON.stringify(this.props.error)
  }

  passwordMessage = () => {
    if (!this.state.passwordLength) {
      return (
        this.state.showLengthWarning ? 'Passwords must be at least 8 characters long' : ''
      )
    }
    if (!this.state.passwordsMatch) {
      return (
        this.state.showMatchWarning ? 'Passwords do not match' : ''
      )
    }
    return ''
  }

  statusMessage = () => {
    if (this.props.error) {
      return this.errorMessage()
    }

    return this.passwordMessage()
  }

  data = () => (
    { user:
      { reset_password_token: this.state.reset_password_token,
        password: this.state.password
      }
    }
  )

  onKeyPress = ({ key }) => {
    key == 'Enter' && this.state.passwordValid && this.submit()
  }

  submit = () => {
    if (this.state.passwordValid) {
      Api({ request: ActionTypes.USERS_PASSWORD_RESET, data: this.data() })
    }
  }

  renderPasswordReset = () => (
    <div className="password-reset-container">
      <div className="password-reset-left">
        <div className="password-reset-logo-container">
          <img className="password-reset-logo" src={brandLogo} alt="Logo" />
        </div>
        <div className="password-reset-form-container">
          <div className="password-reset-title">
            <h1>Password Reset</h1>
            <span>Enter a new password</span>
          </div>

          <Status isError={!this.props.passwordReset}>
            {this.statusMessage()}
          </Status>

          <Field
            label="New Password"
            className="password-reset-field"
            error={!this.state.passwordLength && this.state.showLengthWarning}
          >
            <span className="password-input-icon-container">
              <FontAwesomeIcon
                icon={['fas', 'key']}
                className={'password-input-icon-svg'}
              />
            </span>
            <input
              name="password"
              value={this.state.password}
              onChange={this.setFieldAndValidate}
              onBlur={this.enableLengthWarning}
              type="password"
              placeholder="New Password"
              autoComplete="off"
            />
          </Field>

          <Field
            label="Confirm New Password"
            className="password-reset-field"
            error={!this.state.passwordsMatch && this.state.showMatchWarning}
          >
            <span className="password-input-icon-container">
              <FontAwesomeIcon
                icon={['fas', 'key']}
                className={'password-input-icon-svg'}
              />
            </span>
            <input
              name="passwordCheck"
              value={this.state.passwordCheck}
              onChange={this.setFieldAndValidate}
              onBlur={this.enableMatchWarning}
              type="password"
              placeholder="Confirm New Password"
              autoComplete="off"
              onKeyPress={this.onKeyPress}
            />
          </Field>

          <button
            type="button"
            className="send-password-reset"
            onClick={() => this.submit()}
          >
            Submit
          </button>
        </div>
      </div>
      <div className="password-reset-right">
        <img
          className="password-reset-image"
          src={resetGraphic}
          alt="Set Password"
        />
        <div className="password-reset-service-terms">
          <Link to={'/login'}>
            <span>Login</span>
          </Link>
        </div>
      </div>
    </div>
  )

  render = () => {
    if (this.state.redirectToLogin) {
      return this.redirectToLogin()
    }
    return this.renderPasswordReset()
  }
}

const mapStateToProps = ({ resetPassword }) => (
  resetPassword
)

export default connect(mapStateToProps)(PasswordReset)

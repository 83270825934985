import React from 'react'

import Html from 'slate-html-serializer'

export const BLOCK_TAGS = {
  blockquote: 'quote',
  p: 'paragraph',
  pre: 'code',
  h2: 'heading',
  li: 'list-item',
  ol: 'numbered-list',
  ul: 'bulleted-list',
  div: 'div',
}

export const MARK_TAGS = {
  em: 'italic',
  strong: 'bold',
  code: 'code',
  u: 'underline',
  del: 'strikethrough',
  small: 'small',
}

const deserializeBlock = function deserialize(element, next) {
  const type = BLOCK_TAGS[element.tagName.toLowerCase()]
  if (type) {
    return {
      object: 'block',
      type: type,
      data: { className: element.getAttribute('class') },
      nodes: next(element.childNodes),
    }
  }
}

const serializeBlock = function serialize(object, children) {
  const smallStyle = {
    lineHeight: '1.3',
    fontSize: '0.9em',
    display: 'block',
  }

  if (object.object == 'block') {
    switch (object.type) {
      case 'code':
        return (
          <pre><code>{children}</code></pre>
        )
      case 'paragraph':
        return <p className={object.data.get('className')}>{children}</p>
      case 'quote':
        return <blockquote>{children}</blockquote>
      case 'heading':
        return <h2>{children}</h2>
      case 'list-item':
        return <li>{children}</li>
      case 'bulleted-list':
        return <ul>{children}</ul>
      case 'numbered-list':
        return <ol>{children}</ol>
      case 'div':
        return  <div style={smallStyle}>{children}</div>
    }
  }
}

const deserializeMark = function deserialize(element, next) {
  const type = MARK_TAGS[element.tagName.toLowerCase()]
  if (type) {
    return {
      object: 'mark',
      type: type,
      nodes: next(element.childNodes),
    }
  }
}

const serializeMark = function serialize(object, children) {
  if (object.object == 'mark') {
    switch (object.type) {
      case 'bold':
        return <strong>{children}</strong>
      case 'italic':
        return <em>{children}</em>
      case 'underline':
        return <u>{children}</u>
      case 'strikethrough':
        return <del>{children}</del>
      case 'code':
        return <code>{children}</code>
      case 'small':
        return <small>{children}</small>
    }
  }
}

export const rules = [
  {
    deserialize: deserializeBlock,
    serialize: serializeBlock,
  },
  {
    deserialize: deserializeMark,
    serialize: serializeMark,
  },
]

export const html = new Html({ rules })


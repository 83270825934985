import React from 'react'
import { Link } from 'react-router-dom'
import { IconButton } from '../../generic/Buttons'

import ModelActions from '../../generic/ModelActions'
import Notifications from './Notifications'

import './controls.scss'

const newQuoteURLString = ({id, defaultUpToDoor }) => (
  `/quotes/new?customer=${id}&upToDoor=${defaultUpToDoor}`
)

const newJobURLString = ({ id }) => (
  `/jobs/new?customer=${id}`
)

const ButtonLink = ({ path, icon, text }) => (
  <Link to={path}>
    <IconButton className="primary" icon={icon}>
      {text}
    </IconButton>
  </Link>
)

const Controls = (props) => {
  const { edit, editAction, destroyAction } = props
  const newQuotePath = newQuoteURLString(props)
  const newJobPath = newJobURLString(props)

  return (
    <div className="company-controls">
      <div className="left">
      </div>
      <div className="right">
        <Notifications />
        <ButtonLink
          path={newQuotePath}
          icon={'plus-circle'}
          text={'New Quote'}
        />
        <ButtonLink
          path={newJobPath}
          icon={'plus-circle'}
          text={'New Job'}
        />
        <ModelActions
          modelName={'Company'}
          edit={edit}
          editAction={editAction}
          deleteAction={destroyAction}
        />
      </div>
    </div>
  )
}

export default Controls

import React, { Component } from 'react'
import { connect } from 'react-redux'

import QuickFindMessage from './QuickFindMessage'


export class QuickFindAlertListener extends Component {

  componentDidUpdate = () => {
    if (this.props.results.length !== 1 && this.props.queryType) {
      this.props.newAlert(this.buildAlert())
    }
  }

  buildAlert = () => {
    const timestamp = +new Date
    return {
      timestamp: timestamp,
      message: <QuickFindMessage timestamp={timestamp} {...this.props} />
    }
  }

  render = () => (
    null
  )
}

const mapStateToProps = ({ quickFind }) => (
  quickFind.data
)

export default connect(mapStateToProps)(QuickFindAlertListener)

import React from "react"
import { Field } from './Field'
import Select from 'react-select'


const INCOTERM_TYPES = [
  { value: 'CIP', label: `CIP | Carriage and Insurance Paid To` },
  { value: 'CPT', label: `CPT | Carriage Paid To` },
  { value: 'DAP', label: `DAP | Delivered at Place` },
  { value: 'DAT', label: `DAT | Delivered at Terminal` },
  { value: 'DDP', label: `DDP | Delivered Duty Paid` },
  { value: 'EXW', label: `EXW | Ex Works` },
  { value: 'FCA', label: `FCA | Free Carrier` },
  { value: 'FOB', label: `FOB | Free On Board` },
]

const getOption = (value) => (
  INCOTERM_TYPES.find(type => type.value == value)
)

export const IncotermType = ({ value, onChange, isDisabled }) => (
  <Field label="Incoterm Type">
    <Select
      classNamePrefix="select"
      className="select"
      options={INCOTERM_TYPES}
      name="incotermType"
      value={getOption(value)}
      onChange={onChange}
      isDisabled={isDisabled}
    />
  </Field>
)

function Incoterm(props) {
  const {
    incotermType,
    setIncotermType,
    incotermNamedPlace,
    setIncotermNamedPlace,
    isDisabled
  } = props

  return (
    <React.Fragment>
      <IncotermType
        value={incotermType}
        onChange={setIncotermType}
        isDisabled={isDisabled}
      />
      <Field label={"Incoterm Named Place"}>
        <input
          type="text"
          maxLength="255"
          name={"incotermNamedPlace"}
          value={incotermNamedPlace || ''}
          onChange={setIncotermNamedPlace}
          disabled={isDisabled}
        />
      </Field>
    </React.Fragment>
  )
}

export default Incoterm

import React, { Component } from 'react'
import { connect } from 'react-redux'
import CreatableSelect from 'react-select/creatable'

import { Field } from '../Field'

import Api from '../../api/call'
import {
  COMMODITIES_SEARCH
} from '../../root/action-types'

const options = (data) => (
  data.map(o => createOption(o.value))
)

const createOption = (value) => (
  {value: value, label: value}
)

class CommoditySelect extends Component {

  state = {
    inputValue: ''
  }

  componentDidMount = () => {
    this.requestCommodities()
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.value != prevProps.value) {
      this.requestCommodities()
    }
  }

  onInputChange = (inputValue) => {
    this.setState({ inputValue })
    this.queueUpdate()
  }

  queueUpdate = (milliseconds = 400) => {
    clearTimeout(this.timeoutUpdate)
    this.timeoutUpdate = setTimeout(this.requestCommodities, milliseconds)
  }

  requestCommodities = () => {
    Api({
      request: COMMODITIES_SEARCH,
      params: { search: this.state.inputValue }
    })
  }

  render = () => {
    const {
      commodities, isLoading
    } = this.props
    const {
      label, error, forceError, value, onChange, isDisabled
    } = this.props

    return (
      <Field
        label={label || 'Commodity'}
        error={error}
        forceError={forceError}
      >
        <CreatableSelect
          classNamePrefix="select"
          className="select"
          value={createOption(value)}
          options={options(commodities)}
          inputValue={this.state.inputValue}
          onInputChange={this.onInputChange}
          onChange={onChange}
          isLoading={isLoading}
          isDisabled={isDisabled}
        />
      </Field>
    )
  }
}

const mapStateToProps = ({ commodities }) => ({
  commodities: commodities.results || [],
  isLoading: commodities.loading
})

export default connect(mapStateToProps)(CommoditySelect)

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get, startCase } from 'lodash'

import Api from '../api/call'
import { GROUPAGES } from '../root/action-types'
import { countryName } from '../addresses/countries'

import PagedIndexTable from '../generic/indexTable/PagedIndexTable'
import './GroupagesIndex.scss'

const SORT_OPTIONS = [
  { value: 'reference', label: 'Our Ref' },
  { value: 'originCountryCode', label: 'Origin Country' },
  { value: 'destinationCountryCode', label: 'Destination Country' },
]

const DEFAULT_SORT = SORT_OPTIONS[0]
const DEFAULT_SORT_ASCENDING = false
const LINK_STRING = '/groupages/'
const COLUMNS = [
  {
    Header: "Our Ref",
    accessor: "reference",
  },
  {
    Header: "Origin Country",
    accessor: "origin_country",
  },
  {
    Header: "Destination Country",
    accessor: "destination_country",
  },
]

class GroupagesIndex extends Component {

  componentDidMount = () => {
    this.updateSortOptions()
  }

  updateSortOptions = () => {
    Api({ request: GROUPAGES })
  }

  getData = () => {
    const rawData = Object.values(get(this.props.index, 'data', {}))
    const data = rawData.map(groupages => {
      return ({
        id: groupages.id,
        reference: groupages.reference,
        origin_country: countryName(groupages.originCountryCode),
        destination_country: countryName(groupages.destinationCountryCode),
      })
    })
    return data
  }

  getSortOptions = () => (
    SORT_OPTIONS.map(o => ({ value: o, label: startCase(o) }))
  )

  getTotal = () => (
    get(this.props.index, 'total', 0)
  )

  getCount = () => (
    get(this.props.index, 'count', 0)
  )

  render = () => (
    <PagedIndexTable
      defaultSort={ DEFAULT_SORT }
      defaultSortDirection ={ DEFAULT_SORT_ASCENDING }
      requestDataAction = { GROUPAGES }
      model="groupages"
      data={ this.getData() }
      columns={ COLUMNS }
      count={ this.getCount() }
      total={ this.getTotal() }
      linkString={ LINK_STRING }
      sortOptions={SORT_OPTIONS}
    />
  )

}

const mapStateToProps = ({ groupages: { index } }) => ({
  index
})

export default connect(mapStateToProps)(GroupagesIndex)

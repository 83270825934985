import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import NoteForm from './NoteForm'


class EditNote extends Component {

  state = {
    ...this.props.note,
    updateAttempted: false,
  }

  exitEditMode = () => {
    this.props.resetNoteUnderEdit()
  }

  setValue = ({ target: { name, value } }) => (
    this.setState({ [name]: value })
  )

  noteTitlePresent = () => (
    this.state.title != ''
  )

  sendUpdate = () => {
    this.setState({ updateAttempted: true })
    this.noteTitlePresent() && this.props.apiRequest({...this.state})
  }

  render = () => (
    <div className="edit-note-container">
      <NoteForm
        note={this.state}
        onChange={this.setValue}
        formError={this.state.updateAttempted}
      />
      <div className="details-options">
        <div>
          <span onClick={this.sendUpdate}>
            <label>
              <FontAwesomeIcon icon={['fas', 'pencil-alt']} />
            </label>
            Save Changes
          </span>
        </div>
        <div>
          <span onClick={this.exitEditMode}>
            <label>
              <FontAwesomeIcon icon={['fas', 'trash-alt']} />
            </label>
            Discard Changes
          </span>
        </div>
      </div>
    </div>
  )
}

export default EditNote
import { combineReducers } from 'redux'

import {
  standardForCollections,
} from '../api/standard-reducers'

import {
  EMAILS_CREATE_START,
  EMAILS_CREATE_SUCCESS,
  EMAILS_CREATE_FAILURE,
  EMAILS_UPDATE_START,
  EMAILS_UPDATE_SUCCESS,
  EMAILS_UPDATE_FAILURE,
  EMAILS_DESTROY_START,
  EMAILS_DESTROY_SUCCESS,
  EMAILS_DESTROY_FAILURE,
  EMAILS_MANAGEMENT_RESET_STORE,
  JOBS_SEND_EMAIL_START,
  JOBS_SEND_EMAIL_SUCCESS,
  JOBS_SEND_EMAIL_FAILURE,
  QUOTES_SEND_EMAIL_START,
  QUOTES_SEND_EMAIL_SUCCESS,
  QUOTES_SEND_EMAIL_FAILURE,
} from '../root/action-types'

export const DEFAULT_STATE = {
  loading: false,
  emailHasUpdated: false,
}

const successState = (state, action) => (
  {
    ...state,
    loading: false,
    emailHasUpdated: true,
    errors: action.response.data.errors,
    data: action.response.data,
  }
)

const failureState = (state, action) => (
  {
    ...state,
    loading: false,
    emailHasUpdated: false,
    errors: action.response.data.errors,
  }
)

export function emailManagement(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case EMAILS_CREATE_START:
    case EMAILS_UPDATE_START:
    case EMAILS_DESTROY_START:
      return { ...state, loading: true, emailHasUpdated: false }
    case EMAILS_CREATE_SUCCESS:
    case EMAILS_UPDATE_SUCCESS:
    case EMAILS_DESTROY_SUCCESS:
      return successState(state, action)
    case EMAILS_CREATE_FAILURE:
    case EMAILS_UPDATE_FAILURE:
    case EMAILS_DESTROY_FAILURE:
      return failureState(state, action)
    case EMAILS_MANAGEMENT_RESET_STORE:
      return DEFAULT_STATE
    default:
      return state
  }
}

export function sendEmail(state={loading: false, error: null}, action) {
  switch (action.type) {
    case JOBS_SEND_EMAIL_START:
    case QUOTES_SEND_EMAIL_START:
      return {...state, loading:true, }
    case JOBS_SEND_EMAIL_SUCCESS:
    case QUOTES_SEND_EMAIL_SUCCESS:
      return {...state, loading: false }
    case JOBS_SEND_EMAIL_FAILURE:
    case QUOTES_SEND_EMAIL_FAILURE:
      return { ...state, loading: false, error: action.response.data }
    default:
      return state
  }
}


export default combineReducers({
  index: standardForCollections('EMAILS'),
  email: emailManagement,
  sendEmail,
})

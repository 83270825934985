import React, { Component } from 'react'

import AccordionItem from '../../../generic/accordion/AccordionItem'

import DisplayNote from './DisplayNote'
import EditNote from './EditNote'

import './NotesTab.scss'

export const blankNoteWithCategory = (category) => ({
  id: '',
  title: '',
  content: '',
  category: category,
})

export default class Note extends Component {

  resetNoteUnderEdit = () => (
    this.props.resetNoteUnderEdit()
  )

  renderEditMode = (props) => (
    <EditNote {...props} />
  )

  renderNoteDetails = () => {
    const { editMode, note, setNoteUnderEdit } = this.props
    if (editMode) {
      return (
        <this.renderEditMode
          note={note}
          apiRequest={this.props.updateNote}
          resetNoteUnderEdit={this.resetNoteUnderEdit}
        />
      )
    }
    return (
      <DisplayNote
        note={note}
        setNoteUnderEdit={setNoteUnderEdit}
        resetNoteUnderEdit={this.resetNoteUnderEdit}
        destroy={this.props.destroy}
      />
    )
  }

  render = () => (
    <AccordionItem
      name={this.props.note.title}
      open={this.props.open}
    >
      {this.renderNoteDetails()}
    </AccordionItem>
  )
}


import React, { Component } from 'react'
import { IconButton } from '../generic/Buttons'
import { Link } from 'react-router-dom'
import QuotesIndex from './QuotesIndex'

import "./ViewAllQuotes.scss"

class ViewAllQuotes extends Component {

  render = () => (
    <div className="view-all-quotes-container ">
      <div className="index-controls">
        <h1>Quotes</h1>
        <Link to="/quotes/new">
          <IconButton className="primary" icon="plus-circle">
            New Quote
          </IconButton>
        </Link>
      </div>
      <QuotesIndex/>
    </div>
  )

}

export default ViewAllQuotes

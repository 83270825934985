import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './IndexTable.scss'
import ControlRow from './ControlRow'
import Columns from './Columns'
import Contents from './Contents'
import PagingRow from './PagingRow'

class IndexTable extends Component {
  render = () => (
    <div className={'table index-table ' + this.props.model}>
      <ControlRow {...this.props} />
      <Columns columns={this.props.columns} />
      <Contents {...this.props} />
      <PagingRow {...this.props } />
    </div>
  )
}

IndexTable.propTypes = {
  model: PropTypes.string,
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  count: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  linkString: PropTypes.string,
  pageSizeOptions: PropTypes.array.isRequired,
  categoryOptions: PropTypes.array,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.object.isRequired,
  search: PropTypes.string,
  handlePageChange: PropTypes.func.isRequired,
  handleSizeChange: PropTypes.func.isRequired,
  handleSearchChange: PropTypes.func,
  handleCategoryChange: PropTypes.func
}

export default IndexTable

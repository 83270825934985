import { combineReducers } from 'redux'

import {
  standardForCollections,
} from '../../../api/standard-reducers'

import {
  MARK_FOR_INVOICING,
  GROUPAGE_BULK_INVOICE_SUCCESS,
  GROUPAGE_SEND_JOB_INVOICES_START,
  GROUPAGE_SEND_JOB_INVOICES_SUCCESS,
  GROUPAGE_SEND_JOB_INVOICES_FAILURE,
} from '../../../root/action-types'

const INITIAL = {}

const resetMarkedForInvoicing = (jobs) => (
  jobs.reduce((obj, job) => {
    obj[job.id] = false
    return obj
  }, {})
)

export const markedForInvoicing = (state = INITIAL, action) => {
  switch (action.type) {
    case MARK_FOR_INVOICING: {
      const id = action.payload.id
      return {
        ...state,
        [id]: !state[id]
      }
    }
    case GROUPAGE_BULK_INVOICE_SUCCESS:
      return {
        ...resetMarkedForInvoicing(action.response.data.data)
      }
    default:
      return state
  }
}

const INITIAL_SEND_INVOICES = {
  loading: false,
  xeroResponses: {},
}

export const sendInvoices = (state = INITIAL_SEND_INVOICES, action) => {
  switch (action.type) {
    case GROUPAGE_SEND_JOB_INVOICES_START:
      return {
        ...state,
        loading: true,
      }
    case GROUPAGE_SEND_JOB_INVOICES_SUCCESS:
      return {
        ...state,
        loading: false,
        xeroResponses: action.response.data,
      }
    case GROUPAGE_SEND_JOB_INVOICES_FAILURE:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}

export const TEST_ACCESS = {
  INITIAL,
  resetMarkedForInvoicing,
  sendInvoices,
}

export default combineReducers({
  jobs: standardForCollections('GROUPAGE_BULK_INVOICE'),
  markedForInvoicing: markedForInvoicing,
  sendInvoices: sendInvoices,
})

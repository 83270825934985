import React, { Component } from 'react'
import { startCase } from 'lodash'

import {
  Field, FieldSection, MoneyInput, InputGroup, SimpleSelect, CheckBox
} from '../../forms/'
import CountrySelect from '../../calculator/CountrySelect'
import { CompanySelect } from '../../forms/select'

import Api from '../../api/call'
import {
  TARIFFS_SURCHARGES_UPDATE,
  TARIFFS_SURCHARGES_CREATE
} from '../../root/action-types'

import './form.scss'

const CATEGORIES = [
  'Express',
  'Fork Lift',
  'Hazardous',
  'Intrastat',
  'Miscellaneous',
  'Non EU',
  'Overlength',
  'Stuffing',
  'Transfer',
  'Tail Lift',
  'Customs Clearance',
]

const CONTEXTS = ['Sales', 'Cost', 'Both']

export default class Form extends Component {

  state = { name: '', ...this.props.fields }

  action = () => (
    this.state.id ? TARIFFS_SURCHARGES_UPDATE : TARIFFS_SURCHARGES_CREATE
  )

  actionName = () => (
    `${this.state.id ? 'Update' : 'Create'} Surcharge`
  )

  rate = ({ label, name, ...props }) => (
    <Field label={label || startCase(name)}>
      <MoneyInput
        name={name}
        value={this.state[name] || ''}
        onChange={this.setFormValue}
        disabled={!this.props.edit}
        {...props}
      />
    </Field>
  )

  field = ({ label, name, suffix, className, ...props }) => (
    <Field label={label || startCase(name)} className={className}>
      <InputGroup suffix={suffix}>
        <input
          name={name}
          value={this.state[name] || ''}
          onChange={this.setFormValue}
          disabled={!this.props.edit}
          {...props}
        />
      </InputGroup>
    </Field>
  )

  setFormValue = ({ target: { name, value } }) => {
    this.setState({ [name]: value })
  }

  submit = () => {
    Api({
      request: this.action(),
      id: this.state.id,
      data: {
        ...this.state,
      }
    })
  }

  ifEditting = ({ children }) => (
    this.props.edit ? children : null
  )

  stateSetter = (name) => (
    (val) => this.setState({ [name]: val })
  )

  setCategory  = this.stateSetter('category')
  setContext = this.stateSetter('context')
  setCountries = this.stateSetter('countries')

  setSupplier = ({ value }) => this.setState({ supplier_id: value })

  setToggle = ({ target: { name } }) => (
    this.setState({ [name]: !this.state[name] })
  )

  formToggle = ({ name }) => (
    <CheckBox
      name={name}
      label={startCase(name)}
      value={this.state[name] || false}
      onChange={this.setToggle}
      disabled={!this.props.edit}
    />
  )

  countriesSelect = () => {
    if (this.state.global) {
      return <input disabled value="All" />
    } else {
      return <CountrySelect
        isMulti
        value={this.state.countries}
        onChange={this.setCountries}
        isDisabled={!this.props.edit}
      />
    }
  }

  render = () => (
    <div className="form tariff-surcharges-form">
      <FieldSection title="Description" className="details">
        <this.field name="name" className="double" />
      </FieldSection>
      <FieldSection title="Application" className="details">
        <SimpleSelect
          label="Category"
          options={CATEGORIES}
          onChange={this.setCategory}
          value={this.state.category}
          isDisabled={!this.props.edit}
        />
        <SimpleSelect
          label="Context"
          options={CONTEXTS}
          onChange={this.setContext}
          value={this.state.context}
          isDisabled={!this.props.edit}
        />
        <this.field
          name="min_weight"
          type="number"
          suffix="kg"
          placeholder="N/A"
        />
        <this.field
          name="max_weight"
          type="number"
          suffix="kg"
          placeholder="N/A"
        />
        <this.field
          name="min_length"
          type="number"
          suffix="m"
          placeholder="N/A"
        />
        <this.field
          name="max_length"
          type="number"
          suffix="m"
          placeholder="N/A"
        />
        <Field label="Countries">
          <this.countriesSelect />
        </Field>
        <CompanySelect
          label="Supplier"
          value={this.state.supplier_id}
          onChange={this.setSupplier}
          isDisabled={!this.props.edit}
          supplier
        />
        <div className="toggles">
          <this.formToggle name="global" />
          <this.formToggle name="amalgamate" />
        </div>
      </FieldSection>
      <FieldSection title="Rate">
        <this.rate name="flat_rate" label="Flat" />
        <this.field name="percentage" label="Percentage" type="number" suffix="%" />
        <this.rate name="per_100kg" />
        <this.rate name="per_metre" type="number"/>
        <div className="divider" />
        <this.rate name="min_rate" label="Min" placeholder="N/A" />
        <this.rate name="max_rate" label="Max" placeholder="N/A" />
      </FieldSection>
      <this.ifEditting>
        <div className="controls">
          <button className="primary" onClick={this.submit}>{this.actionName()}</button>
        </div>
      </this.ifEditting>
    </div>
  )
}

import Sales from './Sales'
import Cost from './Cost'

const COMPONENTS = {
  cost: Cost,
  sales: Sales,
}

const Autoline = ({ category, ...props }) => (
  COMPONENTS[category](props)
)

export default Autoline

import { combineReducers } from 'redux'

import distribution from './distribution/reducer'
import customers from './customers/reducer'
import surcharges from './surcharges/reducer'
import freight from './freight/reducer'
import config from './config/reducer'

export default combineReducers({
  distribution,
  customers,
  surcharges,
  freight,
  config
})


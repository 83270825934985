import React, { Component } from 'react'

import AllContacts from '../../contacts/AllContacts'
import AllAddresses from '../../addresses/AllAddresses'
import ImportContacts from '../../xero/ImportContacts'
import { Conditional } from '../../forms/'

import './contacts-tab.scss'

export default class ContactsTab extends Component {

  render = () => (
    <div className="contacts-tab">
      <div className="company-contacts-container">
        <AllContacts {...this.props} />
        <AllAddresses {...this.props} />
      </div>
      <Conditional if={this.props.companyData.xeroContactId}>
        <div className="controls">
          <ImportContacts companyId={this.props.companyData.id} />
        </div>
      </Conditional>
    </div>
  )
}

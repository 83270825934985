import React from 'react'

import ModelIndexTable from '../generic/indexTable/ModelIndexTable'

import { QUOTES } from '../root/action-types'
import { QUOTES_INDEX_COLUMNS } from '../root/local-storage-keys'
import { countryName } from '../addresses/countries'

import './QuotesIndex.scss'


const SORT_OPTIONS = [
  { value: 'reference', label: 'Our Ref' },
  { value: 'originCountryCode', label: 'Origin Country' },
  { value: 'destinationCountryCode', label: 'Destination Country' },
  { value: 'originLocation', label: 'Origin Location' },
  { value: 'destinationLocation', label: 'Destination Location' },
  { value: 'expiryDate', label: 'Expiry Date' },
]
const DEFAULT_SORT = SORT_OPTIONS[0]
const DEFAULT_SORT_ASCENDING = false
const COLUMNS = [
  'reference',
  'customerName',
  'originCountry',
  'originLocation',
  'destinationCountry',
  'destinationLocation',
  'expiryDate',
  'goodsReadyDate',
  'packageQuantity',
  'packageType',
  'cubicMetres',
  'grossWeightKg',
  'hazardous',
  'overlength',
]

const DEFAULT_COLUMNS = COLUMNS.slice(0, 7)


function QuotesIndex() {
  const dataMap = (row) => (
    {
      ...row,
      originCountry: countryName(row.originCountryCode),
      destinationCountry: countryName(row.destinationCountryCode),
      expiryDate: String(row.expiryDate),
    }
  )

  return (
    <ModelIndexTable
      model={'quotes'}
      dataMap={dataMap}
      requestAction={QUOTES}
      LOCAL_STORAGE_KEY={QUOTES_INDEX_COLUMNS}
      COLUMNS={COLUMNS}
      DEFAULT_COLUMNS={DEFAULT_COLUMNS}
      SORT_OPTIONS={SORT_OPTIONS}
      DEFAULT_SORT={DEFAULT_SORT}
      DEFAULT_SORT_ASCENDING={DEFAULT_SORT_ASCENDING}
    />
  )
}

export default QuotesIndex

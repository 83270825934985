import React, { Component } from 'react'

import { DropdownButton, DropdownOption } from '../../generic/Buttons'

import './notifications.scss'

const None = (
  <DropdownOption
    icon="comment-alt"
    label="No notifications exist."
    className="disabled"
  />
)

export default class Notifications extends Component {

  label = () => (
    `${(this.props.items || []).length || 'No'} Notifications`
  )

  items = () => {
    if ((this.props.items || []).length) {
      return this.props.items.map(
        n => <DropdownOption key={n.id} icon={n.icon} label={n.title} />
      )
    } else {
      return None
    }
  }

  render = () => (
    <DropdownButton
      icon="bell"
      className="company-notifications"
      label={<this.label />}
      caret={1}
    >
      <this.items />
    </DropdownButton>
  )
}

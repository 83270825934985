import { useState } from 'react'

import Api from '../api/call'

export const useForm = (initialState, modelAction) => {
  const [fields, setFields] = useState(initialState)

  const submit = () => saveAction(initialState.id, modelAction)(fields)

  const fieldChange = ({ target: { name, value } }) => {
    setFields({ ...fields, [name]: value })
  }
  const inputChange = ({ target }) => fieldChange(target)
  const formSetter = (name) => (value) => fieldChange({ name, value })
  const toggler = (name) => () => fieldChange({ name, value: !fields[name] })

  return { fields, submit, fieldChange, inputChange, formSetter, toggler }
}

const saveAction = (id, modelAction) => {
  if (id) {
    return (data) => Api({ request: `${modelAction}_UPDATE`, id, data })
  }
  return (data) => Api({ request: `${modelAction}_CREATE`, data })
}


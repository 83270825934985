import React, { Component } from 'react'
import { pick, startCase } from 'lodash'
import Toggle from 'react-toggle'

import { Field } from '../../forms/Field'
import { InputGroup } from '../../forms/InputGroup'
import { InlineLabelledToggle } from '../../forms/toggle'


import Measurements from '../../measurements/Measurements'

import '../../jobs/job/Consignment.scss'


const CONSIGNMENT_FLAGS = [
  'doNotStack',
  'doNotTransship',
  'doNotPartLoad',
  'doNotBreakDown',
  'tailLift',
  'overheadLoad',
  'overheadUnload',
  'podRequired',
  'intrastat',
  'customs',
  'forkLift',
  'express',
  'insured',
  'overlength',
]

const MEASUREMENT_FIELDS = [
  'packageQuantity',
  'packageType',
  'grossWeightKg',
  'cubicMetres',
  'loadingMetres',
  'maxPackageLength',
  'maxPackageHeight',
  'maxPackageWeight',
]

const STATE_FIELDS = [
  ...MEASUREMENT_FIELDS,
  'packageDimensionLines',
  ...CONSIGNMENT_FLAGS,
  'additionalInformation',
  'insuranceValue',
  'hazardous',
  'hazardSummaryText',
]

export class Consignment extends Component {

  state = {
    ...pick(this.props.data, STATE_FIELDS)
  }

  setValue = ({ target: { name, value }}) => {
    this.setState({ [name]: value })
  }

  setToggle = ({ target: { name } }) => {
    this.setState((prevState) => ({ [name]: !prevState[name] }))
  }

  stateSetter = (name) => (
    (val) => this.setState({ [name]: val })
  )

  measurements = () => {
    return pick(this.state, MEASUREMENT_FIELDS)
  }

  setMeasurements = (measurementSet) => {
    this.setState({ ...measurementSet })
  }

  setPackageDimensionLines = (lines) => (
    this.setState({ packageDimensionLines: [...lines] })
  )

  editControls = () => (
    this.props.edit ? <this.controls /> : null
  )

  controls = () => (
    <div className="section controls">
      <button className="submit" onClick={this.save}>Save</button>
    </div>
  )

  removeUnspecifiedValues = () => {
    const stateCopy = {...this.state}
    if (!this.state.insured) {
      stateCopy.insuranceValue = ''
    }
    if (!this.state.hazardous) {
      stateCopy.hazardSummaryText = ''
    }
    return stateCopy
  }

  save = () => {
    this.props.updateApiRequest(this.removeUnspecifiedValues(this.state))
  }

  inlineToggleWithLabel = ({ name }) => (
    <div className="inline-toggle-with-label">
      <label>
        {startCase(name)}
      </label>
      <Toggle
        name={name}
        checked={this.state[name]}
        onChange={this.setToggle}
        disabled={!this.props.edit}
        icons={false}
      />
    </div>
  )

  consignmentOptions = () => (
    <div className="consignment-options">
      {
        CONSIGNMENT_FLAGS.map(option => (
          <this.inlineToggleWithLabel name={option} key={option} />
        ))
      }
    </div>
  )

  additionalInformationField = () => (
    <Field label={"Additional Information"}>
      <input
        type="text"
        maxLength="255"
        name={'additionalInformation'}
        value={this.state.additionalInformation || ''}
        onChange={this.setValue}
        disabled={!this.props.edit}
      />
    </Field>
  )

  insuranceValueField = () => {
    if (this.state.insured) {
      return (
        <Field label={"Insurance Value"}>
          <InputGroup prefix={'€'}>
            <input
              type="number"
              min="0.00"
              name={'insuranceValue'}
              value={this.state.insuranceValue}
              onChange={this.setValue}
              disabled={!this.props.edit || !this.state.insured}
            />
          </InputGroup>
        </Field>
      )
    }
    return null
  }

  hazardSummaryTextField = () => {
    if (this.state.hazardous) {
      return (
        <Field>
          <input
            type="text"
            placeholder="Enter Hazard Description"
            name={'hazardSummaryText'}
            value={this.state.hazardSummaryText}
            onChange={this.setValue}
            disabled={!this.props.edit}
          />
        </Field>
      )
    }
    return null
  }


  renderConsignmentOptions = () => (
    <div className="section">
      <h4>Consignment Options</h4>
      <this.consignmentOptions />
      <div className="form-fields">
        <this.additionalInformationField />
        <this.insuranceValueField />
      </div>
    </div>
  )

  renderGoodsDetails = () => (
    <div className="section">
      <h4>Goods Details</h4>
      <div className="form-fields">
        <InlineLabelledToggle
          name={"hazardous"}
          value={this.state.hazardous}
          onChange={this.setToggle}
          isDisabled={!this.props.edit}
        />
        <this.hazardSummaryTextField />
      </div>
    </div>
  )

  render = () => (
    <div className="consignment-tab">
      <Measurements
        edit={this.props.edit}
        measurer="customer"
        fields={this.measurements()}
        setFields={this.setMeasurements}
        packageDimensionLines={this.state.packageDimensionLines}
        setPackageDimensionLines={this.setPackageDimensionLines}
      />
      <this.renderConsignmentOptions />
      <this.renderGoodsDetails />
      <this.editControls />
    </div>
  )
}

export default Consignment

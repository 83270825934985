import React from 'react'
import { startCase } from 'lodash'

import ModelIndexTable from '../generic/indexTable/ModelIndexTable'

import { JOBS } from '../root/action-types'
import { JOBS_INDEX_COLUMNS } from '../root/local-storage-keys'
import { countryName } from '../addresses/countries'

import './JobsIndex.scss'


const SORT_OPTIONS = [
  { value: 'created_at', label: 'Created At'},
  { value: 'reference', label: 'Reference'},
  { value: 'countryCode', label: 'Country' },
  { value: 'bookingDate', label: 'Booking Date' },
  { value: 'goodsReadyDate', label: 'Goods Ready Date' },
  { value: 'status', label: 'Status' },
]
const DEFAULT_SORT = SORT_OPTIONS[0]
const DEFAULT_SORT_ASCENDING = false
export const COLUMNS = [
  'reference',
  'customer',
  'country',
  'bookingDate',
  'goodsReadyDate',
  'status',
  'weekNumber',
  'salesInvoiced',
  'telephone',
  'additionalInformation',
  'contactOnDate',
  'cubicMetres',
  'etaToClientDate',
  'grossWeightKg',
  'loadingMetres',
  'hazardous',
  'incotermType',
  'packageType',
  'packageQuantity',
  'updateReceivedDate',
  'shipper',
  'consignee',
  'supplier',
  'province',
  'toCall',
  'toBook',
  'salesToRequote',
  'requestedRate',
  'originLocation',
  'destinationLocation',
  'nonEu',
  'overlength',
  'doorDeliveryOption',
  'commodity',
  'hub',
  'priority',
]

const DEFAULT_COLUMNS = COLUMNS.slice(0, 7)

function JobsIndex() {
  const dataMap = (row) => (
    {
      ...row,
      country: countryName(row.countryCode),
      priority: startCase(row.priority)
    }
  )

  return (
    <ModelIndexTable
      model={'jobs'}
      dataMap={dataMap}
      requestAction={JOBS}
      LOCAL_STORAGE_KEY={JOBS_INDEX_COLUMNS}
      COLUMNS={COLUMNS}
      DEFAULT_COLUMNS={DEFAULT_COLUMNS}
      SORT_OPTIONS={SORT_OPTIONS}
      DEFAULT_SORT={DEFAULT_SORT}
      DEFAULT_SORT_ASCENDING={DEFAULT_SORT_ASCENDING}
    />
  )
}

export default JobsIndex

import { defaults } from 'axios'
import ActionCable from 'actioncable'

import { store } from '../root/store'
import history from '../root/browser-history'
import { LOGIN_AUTH_TOKEN, LOGIN_USER, LOGIN_USER_ID } from '../root/local-storage-keys'

import endpoints, { cable } from './endpoints'

const config = {}

const getStore = () => (
  config.store || store
)

export default function Api(args) {
  getStore().dispatch({
    type: 'API_REQUEST',
    payload: {
      request: args.request,
      action: buildAction(args)
    }
  })
}

function buildAction({ request, id, params, data }) {
  const { verb, server, path, query, types } = endpoints[request]
  const url = [server, path, id].filter(x => x).join('/')

  if (query) { // GraphQL
    params = { query, variables: enforceTypes(types, data || params) }
    data = data && params
  }

  return () => verb(url, data || { params })
}

const enforceTypes = (types, params) => {
  if (!types) { return params }

  const typed = {}
  Object.keys(params).forEach(k =>
    typed[k] = forceType(params[k], types[k])
  )

  return typed
}

const forceType = (value, method) => {
  switch (typeof method) {
    case 'function':
      return method(value)
    case 'object':
      return enforceTypes(method, value)
    default:
      return value
  }
}

export const setAuthToken = (token) => {
  localStorage.setItem(LOGIN_AUTH_TOKEN, token)
  defaults.headers.common['Authorization'] = token
}

export const getAuthToken = () => (
  defaults.headers.common['Authorization']
)

let Cable = undefined

export const getCable = () => {
  if (!Cable) {
    Cable = ActionCable.createConsumer(`${cable}?Authorization=${getAuthToken()}`)
  }
  return Cable
}

export const setUser = ({ email, id }) => {
  localStorage.setItem(LOGIN_USER, email)
  localStorage.setItem(LOGIN_USER_ID, id)
  defaults.headers.common['User'] = email
  defaults.headers.common['UserId'] = id
}

export const isAuthenticated = () => (
  defaults.headers.common['Authorization'] ? true : false
)

// Useful for mocking api in tests
export const setApiStore = (mock) => (
  config.store = mock
)

// You can't redirect within an action for some reason, so we need to do it
// async. We only need this for the case when your session has invalidated
const redirectToLogin = () => {
  setTimeout(() => history.push('/login'), 1)
}

const rememberLastPage = () => {
  // Async requests sometimes fire after we've logged out
  if (window.location.pathname == '/login') { return }
  window.logoutLocation = window.location.pathname
}

export const logout = () => {
  rememberLastPage()
  localStorage.removeItem(LOGIN_AUTH_TOKEN)
  defaults.headers.common['Authorization'] = undefined
  Cable = undefined
  redirectToLogin()
}

// set defaults if page reloaded
const init = () => {
  if (!defaults.headers.common['Authorization']) {
    defaults.headers.common['Authorization'] = localStorage.getItem(LOGIN_AUTH_TOKEN)
    defaults.headers.common['User'] = localStorage.getItem(LOGIN_USER)
    defaults.headers.common['UserId'] = localStorage.getItem(LOGIN_USER_ID)
  }
}

init()

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Tab, TabList, TabPanel } from 'react-tabs'
import TabsWrapper from '../../generic/TabsWrapper'
import { get, pick } from 'lodash'

import Api from '../../api/call'
import {
  JOBS_SHOW,
  JOBS_UPDATE,
  JOBS_DESTROY,
  JOBS_UPDATE_RESET_STORE,
  JOBS_DESTROY_RESET_STORE,
  JOBS_EMAIL_TEMPLATES,
} from '../../root/action-types'

import { Status, formatErrors } from '../../forms/Status'
import { Loading } from '../../generic/Loading'

import Controls from './Controls'
import Sidebar from './Sidebar'
import General from './General'
import Consignment from './Consignment'
import MeasurementsTab from './MeasurementsTab'
import Billing from '../../line-items/Billing'
import EmailsTab from '../../emails/EmailsTab'

import './Job.scss'


class Job extends Component {

  state = {
    paramsId: '',
    edit: get(this.props.location, 'search', '').includes('editMode') || false
  }


  toggleEdit = () => (
    this.setState(
      prev => ({ edit: !prev.edit }),
      () => this.fetchJobData()
    )
  )

  componentDidMount() {
    this.resetStores()
    this.setJobFromUrl()
  }

  componentDidUpdate = (prevProps) => {
    this.urlParamsIdHasChanged(prevProps) && this.setJobFromUrl()
    document.title = `Job ${get(this.props.show, 'data.reference', '')}`

    if (
      this.props.job.jobHasUpdated
      && !prevProps.job.jobHasUpdated
      && this.state.edit
    ) {
      this.toggleEdit()
    }
  }

  urlParamsIdHasChanged = (prevProps) => (
    get(this.props.match, 'params.id') != get(prevProps.match, 'params.id')
  )

  setJobFromUrl = () => {
    this.setState(
      { paramsId: get(this.props.match, 'params.id') },
      () => this.fetchJobData()
    )
  }

  fetchJobData = () => {
    Api({ request: JOBS_SHOW, id: this.state.paramsId })
    Api({ request: JOBS_EMAIL_TEMPLATES, params: { id: this.state.paramsId } })
  }

  resetStores = () => {
    this.props.dispatch({
      type: JOBS_UPDATE_RESET_STORE,
    })
    this.props.dispatch({
      type: JOBS_DESTROY_RESET_STORE,
    })
  }

  removeQueryString = () => (
    this.props.history.push(`/jobs/${this.state.paramsId}`)
  )

  updateApiRequest = (attributes) => {
    this.resetStores()
    Api({
      request: JOBS_UPDATE,
      id: get(this.props.show.data, 'id'),
      data: attributes
    })
    this.removeQueryString()
    this.fetchJobData()
  }

  destroyApiRequest = () => {
    this.resetStores()
    Api({
      request: JOBS_DESTROY,
      id: get(this.props.show.data, 'id')
    })
  }

  redirectToCompanies = () => {
    this.resetStores()
    return (
      <Redirect to="/jobs" />
    )
  }

  key = () => {
    const { id, updated_at } = this.props.show.data || {}
    const { edit } = this.state

    return `${id}-${updated_at}-${edit}`
  }

  inError = () => (
    !!get(this.props.job, 'errors')
  )

  collateErrorMessages = () => (
    formatErrors(get(this.props.job, 'errors', []))
  )

  billingProps = () => {
    const id = get(this.props.show, 'data.id')
    return { parentType: "Job", parentId: id, key: `Job-billing-${id}` }
  }

  sidebarProps = () => {
    const data = get(this.props.show, 'data', {})
    return {
      title: data.reference || '',
      ...pick(data, ['customerId', 'shipperId', 'salesInvoiced']),
    }
  }

  loading = () => (
    this.props.show.loading ? <Loading /> : null
  )

  renderJob = () => (
    <div className="job-container">

      <Controls
        {...this.props.show.data}
        key={`${this.key()}-${this.inError()}`} // rerender the controls if error state changes..
        updateApiRequest={this.updateApiRequest}
        edit={this.state.edit}
        editAction={this.toggleEdit}
        destroyAction={this.destroyApiRequest}
        emails={this.props.emails}
      />

      <div className="status-container">
        <Status isError={this.inError()}>
          {this.collateErrorMessages()}
        </Status>
      </div>

      <div className="body">
        <Sidebar {...this.sidebarProps()} />
        <div className="main">
          <this.loading />
          <TabsWrapper
            edit={this.state.edit}
            toggleEdit={this.toggleEdit}
          >
            <TabList>
              <Tab>General</Tab>
              <Tab>Consignment</Tab>
              <Tab>Measurements</Tab>
              <Tab>Billing</Tab>
              <Tab>Emails</Tab>
            </TabList>
            <TabPanel>
              <General
                edit={this.state.edit}
                key={this.key()}
                updateApiRequest={this.updateApiRequest}
              />
            </TabPanel>
            <TabPanel>
              <Consignment
                data={get(this.props.show, 'data')}
                edit={this.state.edit}
                key={this.key()}
                updateApiRequest={this.updateApiRequest}
              />
            </TabPanel>
            <TabPanel>
              <MeasurementsTab
                data={get(this.props.show, 'data')}
                edit={this.state.edit}
                key={this.key()}
                updateApiRequest={this.updateApiRequest}
              />
            </TabPanel>
            <TabPanel>
              <Billing
                {...this.billingProps()}
                edit={this.state.edit}
                toggleEdit={this.toggleEdit}
              />
            </TabPanel>
            <TabPanel>
              <EmailsTab
                parentId={get(this.props.show.data, 'id')}
                key={this.key()} />
            </TabPanel>
          </TabsWrapper>
        </div>
      </div>
    </div>
  )

  render = () => (
    get(this.props.job, 'jobDestroyed', false) ? (
      this.redirectToCompanies()
    ) : (
      this.renderJob()
    )
  )
}

const mapStateToProps = ({ jobs }) => ({
  show: jobs.show || {},
  job: jobs.job || {},
  emails: jobs.emails || {},
})

export default connect(mapStateToProps)(Job)

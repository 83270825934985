import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { CompanySelect } from '../forms/select'
import { MoneyInput, Conditional } from '../forms/'
import AccountSelect from '../xero/AccountSelect'
import TaxRateSelect from '../xero/TaxRateSelect'


export default class Item extends Component {

  valueSetter = (name) => (
    (obj) => {
      const value = obj.value || obj
      this.props.setter({
        index: this.props.index,
        newValues: { [name]: value }
      })
    }
  )

  setCompanyId = this.valueSetter('companyId')
  setTaxRate = this.valueSetter('taxRate')

  setChargeCode = (obj) => {
    const chargeCode = obj.value
    const taxRate = obj.defaultTaxRate
    const newValues = { chargeCode, taxRate }
    this.props.setter({ index: this.props.index, newValues })
  }

  setFormValue = ({ target: { name, value } }) => {
    this.props.setter({ index: this.props.index, newValues: { [name]: value } })
  }

  belongsToAnInvoice = () => !!this.props.xeroInvoiceId

  locked = () => {
    const { unlock } = this.props
    return this.belongsToAnInvoice() && !unlock
  }

  ifEditing = ({ children }) => (
    this.props.edit ? children : null
  )

  ifDeletable = ({ children }) => (
    !this.belongsToAnInvoice() ? children : null
  )

  disabled = () => (
    !this.props.edit || this.locked() || this.props._destroy
  )

  Input = ({ name, ...props }) => (
    <div className={`col ${name}`}>
      <input
        name={name}
        value={this.props[name] || ''}
        onChange={this.setFormValue}
        type="text"
        disabled={this.disabled()}
        {...props}
      />
    </div>
  )

  destroyProps = () => {
    if (this.props._destroy) {
      return { className: "primary", children: "✓" }
    } else {
      return { className: "danger", children: "✕" }
    }
  }

  render = () => (
    <div className={`line-item ${this.props._destroy ? 'condemned' : ''}`}>
      <div className="col chargeCode">
        <AccountSelect
          value={this.props.chargeCode}
          category={this.props.category}
          onChange={this.setChargeCode}
          disabled={this.disabled()}
        />
      </div>
      <this.Input name="description" />
      <div className="col companyId">
        <CompanySelect
          value={this.props.companyId || ''}
          customer={this.props.category == 'sales'}
          supplier={this.props.category == 'cost'}
          onChange={this.setCompanyId}
          isDisabled={this.disabled()}
          omitWrapper
        />
      </div>
      <div className="col value">
        <MoneyInput
          value={this.props.value || ''}
          name="value"
          onChange={this.setFormValue}
          disabled={this.disabled()}
        />
      </div>
      <div className="col taxRate">
        <TaxRateSelect
          value={this.props.taxRate}
          category={this.props.category}
          onChange={this.setTaxRate}
          disabled={this.disabled()}
        />
      </div>
      <div className="col confirmed">
        <Conditional if={this.belongsToAnInvoice()}>
          <FontAwesomeIcon icon="check" />
        </Conditional>
      </div>
      <div className="col destroy">
        <this.ifEditing>
          <this.ifDeletable>
            <button
              {...this.destroyProps()}
              id={this.props.index}
              onClick={this.props.destroy}
            />
          </this.ifDeletable>
        </this.ifEditing>
      </div>
    </div>
  )

}


import { range } from 'lodash'

import Api from '../../api/call'

import {
  PLANNER_BOOKINGS_EVENT,
  PLANNER_UNITS_EVENT,
  PLANNER_PREVIOUSLY_ON,
} from '../../root/action-types'

const INITIAL = {}

const checkForMissed = (...params) => {
  const [last, next] = params.map(p => parseFloat(p))
  const expected = last + 1

  if (!last || next == expected) { return }

  requestMissed(range(expected, next))
}

const requestMissed = (numbers) => {
  setTimeout(
    () => Api({ request: PLANNER_PREVIOUSLY_ON, params: { numbers } }),
    1
  )
}

export const events = (state = INITIAL, action) => {
  switch (action.type) {
    case PLANNER_UNITS_EVENT:
    case PLANNER_BOOKINGS_EVENT: {
      checkForMissed(state.last, action.number)

      return { ...state, last: action.number }
    }
    default:
      return state
  }
}

export const TEST_ACCESS = {
  INITIAL,
}


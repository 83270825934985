import React from 'react'
import { connect } from 'react-redux'

import { columnWidthClass } from '../unit/Unit'

import './block-wrapper.scss'

const COUNT = Object.values({
  reduce: count => ++count,
  initialValue: 0
})

const SUM = Object.values({
  reduce: (total, value) => (total + (parseFloat(value) || 0)),
  initialValue: 0
})

const TOTAL_REDUCERS = {
  reference: COUNT,
  packageQuantity: SUM,
  grossWeightKg: SUM,
  cubicMetres: SUM,
  loadingMetres: SUM,
}

const TOTAL_UNITS = {
  reference: 'jobs',
  packageQuantity: 'pkg',
  grossWeightKg: 'kg',
  cubicMetres: 'm³',
  loadingMetres: 'm',
}

const PRECISION = {
  cubicMetres: 2,
  loadingMetres: 2,
}

const COLUMNS_TO_TOTAL = Object.keys(TOTAL_REDUCERS)

const BlockFooter = ({ columns, ...props }) => (
  <div className="bw-footer">
    <div className="footings">
      <span className={`header color ${columnWidthClass('icon')}`} />
      {columns.map(c => <FooterColumn key={c} column={c} {...props} />)}
    </div>
  </div>
)

const FooterColumn = ({ column, blockUnits }) => (
  <span className={columnWidthClass(column)}>
    {COLUMNS_TO_TOTAL.includes(column) ? columnTotal(column, blockUnits) : null}
  </span>
)

const columnTotal = (column, blockUnits) => {
  const total = (blockUnits).map(u => u[column])
    .reduce(...TOTAL_REDUCERS[column])
    .toFixed(PRECISION[column] || 0)
  const unit = TOTAL_UNITS[column] || ''

  return `${total} ${unit}`
}

const mapStateToProps = ({ planner: { config, units } }, { id }) => ({
  columns: config.columns,
  blockUnits: units.byBlock[id] || [],
})

export default connect(mapStateToProps)(BlockFooter)

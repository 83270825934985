import React, { Component } from 'react'
import { get } from 'lodash'
import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const value = (row, accessor) => {
  const v = get(row, accessor)

  if (!v) { return null }
  if (v == true) { return <FontAwesomeIcon icon="check" /> }
  return v
}

class Contents extends Component {

  baseRow = row => (
    <div className='row' key={ row.id }>
      { this.props.columns.map((column) => (
        <span
          className={'cell flex-' + column.flex || 1}
          key={ column.accessor + row.id }
        >
          {value(row, column.accessor)}
        </span>
      )) }
    </div>
  )

  renderLinkedRow = (row) => (
    <Link
      className="navbar-item-link"
      to={row.linkString || this.props.linkString + row.id}
      key={row.id}
    >
      {this.baseRow(row)}
    </Link>
  )

  renderRow = (row) => {
    if (row.linkString || this.props.linkString) {
      return this.renderLinkedRow(row)
    } else {
      return this.baseRow(row)
    }
  }

  render = () => (
    <div className='contents'>
      {this.props.data.map(row => this.renderRow(row))}
    </div>
  )
}

export default Contents

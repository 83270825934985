export const STATUS = [
  "Issues",
  "Delayed-Monday",
  "Delayed",
  "Confirmed",
  "In Transit",
  "Pending",
  "Finalised",
  "Cancelled",
]

export const MEASURERS = {
  customer: "customer",
  shipper: "shipper",
  supplier: "supplier",
  warehouse: "warehouse",
}

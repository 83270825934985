import React, { Component } from 'react'

import AllEmails from './AllEmails'

export default class EmailsTab extends Component {

  render = () => (
    <div className="quote-emails-container">
      <AllEmails {...this.props} />
    </div>
  )
}

import React from 'react'
import { useSelector } from 'react-redux'

import history from '../../root/browser-history'
import { TEMPLATES_GROUPAGES } from '../../root/action-types'
import SimpleIndexTable from '../../generic/indexTable/Simple'

const COLUMNS = [
  { Header: "Name", accessor: "name" },
  { Header: "Reference", accessor: "ref" },
  { Header: "Origin Country", accessor: "originCountry" },
  { Header: "Destination Country", accessor: "destinationCountry" },
  { Header: "ADR", accessor: "adr" },
]

const GroupageIndex = () => {
  const { data } = useSelector(redux => redux.templates.groupages.index)

  return (
    <SimpleIndexTable
      title="Groupage Templates"
      columns={COLUMNS}
      model="template"
      linkString="/templates/groupages/"
      actionType={TEMPLATES_GROUPAGES}
      data={data}
      history={history}
    />
  )
}

export default GroupageIndex

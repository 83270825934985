import React, { Component } from 'react'
import { get } from 'lodash'
import { Redirect } from 'react-router-dom'

import Api from '../api/call'
import { Loading } from './Loading'
import ModelActions from './ModelActions'
import { FocusStatus } from '../forms'

import './show.scss'

export default class Show extends Component {

  isMountingNew = () => (
    get(this.props.match, 'params.id', 'new') == 'new'
  )

  state = {
    edit: this.isMountingNew(),
  }

  // Can change when you save record
  isNewRecord = () => (
    get(this.props.details, 'id', 'new') == 'new'
  )

  dispatch = (action) => (
    this.props.dispatch && this.props.dispatch(action)
  )

  componentDidMount() {
    const id = get(this.props.match, 'params.id', 'new')
    if (id == 'new') {
      return this.dispatch({ type: this.props.actions.new })
    }

    Api({ request: this.props.actions.show, id })
  }

  toggleEdit = () => {
    this.setState(prev => ({ edit: !prev.edit }))
  }

  formOrLoading = () => (
    this.props.loading ? <Loading /> : <this.props.form edit={this.state.edit} />
  )

  destroy = () => {
    Api({
      request: this.props.actions.destroy,
      id: this.props.details.id,
    })
  }

  destroyed = () => (
    <Redirect to={{
      pathname: this.props.indexPath,
      state: { status: [`${this.props.modelName} has been deleted.`] }
    }} />
  )

  unlessNewRecord = ({ children }) => (
    this.isNewRecord() ? null : children
  )

  action = () => {
    if (this.isNewRecord() && this.isMountingNew()) { return 'New' }
    if (this.state.edit) { return 'Edit' }
    return null
  }

  formKey = () => (
    `${this.props.modelName}-editMode-${this.state.edit}`
  )

  page = () => (
    <div className={`generic-show ${this.props.className || ''}`}>
      <div className="controls">
        <h1>{this.action()} {this.props.modelName}</h1>
        <this.unlessNewRecord>
          <ModelActions
            modelName={this.props.modelName}
            edit={this.state.edit}
            editAction={this.toggleEdit}
            deleteAction={this.destroy}
          />
        </this.unlessNewRecord>
      </div>
      <FocusStatus
        isError={this.props.hasErrors}
        messages={this.props.status}
      />
      <this.formOrLoading key={this.formKey()}/>
    </div>
  )

  render = () => (
    this.props.destroyed ? this.destroyed() : this.page()
  )
}


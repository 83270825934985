import React from 'react'
import Select from 'react-select'
import { orderBy } from 'lodash'

import { STATUS } from '../../groupages/groupage/General'

import { Control } from './ControlBar'

import '../../forms/select/select.scss'


const options = (STATUS || []).map(s => ({ value: s, label: s }))

const selectOptions = orderBy(Object.values(options), ['label'])

const findMultiValue = (value) => (
  value && value.map(v => findValue(v))
)

const findValue = (value) => (
  value && Object.values(options).find(o => o.value == value)
)

export const StatusPicker = (props) => {
  const {
    value,
    onChange,
  } = props

  const onSelectChange = (value) => onChange((value || []).map(v => v.value))

  return (
    <Control>
      <Select
        isMulti
        classNamePrefix="select"
        className="select multi status-picker"
        placeholder="All Groupages"
        value={findMultiValue(value)}
        options={selectOptions}
        onChange={onSelectChange}
      />
    </Control>
  )
}

export default StatusPicker

import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './block-options.scss'

import Download from '../../api/Download'

class BlockOptions extends Component {

  state = {
    open: false,
  }

  componentDidMount = () => {
    document.addEventListener('click', this.clickOutside)
  }

  componentWillUnmount = () => {
    document.removeEventListener('click', this.clickOutside)
  }

  setRef = (node) => {
    this.ref = node
  }

  clickOutside = ({ target }) => {
    if (this.ref && this.state.open && this.closable && !this.ref.contains(target)) {
      this.setState({ open: false })
    }
  }

  openPicker = (e) => {
    e.stopPropagation()
    if (this.state.open) { return }
    this.setState({ open: !this.state.open })
    this.closable = false
    setTimeout(() => this.closable = true, 10)
  }

  option = ({text}) => (
    <div className="block-option">
      <Download type="picking_list" id={this.props.id}>{text}</Download>
    </div>
  )

  renderDropMenu = () => (
    <div className="block-options-drop-menu" ref={this.setRef}>
      <this.option text={"Picking List PDF"} />
    </div>
  )

  render = () => (
    <div className="block-options" onClick={this.openPicker}>
      <div className="icon-container">
        <FontAwesomeIcon icon={'ellipsis-h'} />
      </div>
      {this.state.open ? this.renderDropMenu() : null}
    </div>
  )
}

export default BlockOptions

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { startCase } from 'lodash'

import Api, { isAuthenticated } from '../api/call'
import { USERS_LOGIN }from '../root/action-types'

import { Field } from '../forms/Field'
import { Status } from '../forms/Status'

import "./Login.scss"

import { brandLogo } from '../theme/assets'
import signinGraphic from '../root/global-assets/Home_header_graphic.svg'

const startingPage = () => (
  window.logoutLocation || '/companies'
)

class Login extends Component {

  state = {
    email: '',
    password: '',
  }

  renderLogin = () => (
    <div className="login">
      <div className="login-left">
        <div className="login-logo-container">
          <img className="login-logo" src={brandLogo} alt="Logo" />
        </div>
        <div className="login-form-container">
          <div className="login-title">
            <h1> Sign In </h1>
            <span> Sign In to continue to your account. </span>
          </div>

          <Status isError={this.props.error}>
            {this.statusMessage()}
          </Status>

          <Field
            label="Email Address"
            className={'login-field'}
            error={this.props.error}
          >
            <span className="login-input-icon-container">
              <FontAwesomeIcon
                icon={['fas', 'user']}
                className={'login-input-icon-svg'}
              />
            </span>
            <input
              type="email"
              name="email"
              onChange={this.setEmail}
              value={this.state.email}
              placeholder="Enter your email address"
              spellCheck="false"
              autoComplete="off"
            />
          </Field>

          <Field
            label="Password"
            className={'login-field'}
            error={this.props.error}
          >
            <span className="login-input-icon-container">
              <FontAwesomeIcon
                icon={['fas', 'key']}
                className={'login-input-icon-svg'}
              />
            </span>
            <input
              type="password"
              name="password"
              onChange={this.setPassword}
              value={this.state.password}
              placeholder="Enter your password"
              onKeyPress={this.onKeyPress}
            />
          </Field>

          <button
            type="button"
            className="login-sign-in-button"
            onClick={this.submit}
          >
            Sign In
          </button>

          {/* NOT IMPLEMENTED YET */}
          {/* <button type="button" className="login-help-button"> Can&#39;t Sign In?</button> */}
        </div>
      </div>

      <div className="login-right">
        <div className="login-image-container">
          <img
            className="login-image"
            src={signinGraphic}
            alt="Sign in to your account"
          />
        </div>
      </div>
    </div>
  )

  render = () => (
    isAuthenticated() ? <Redirect to={startingPage()} /> : this.renderLogin()
  )

  statusMessage = () => {
    const { error } = this.props
    return error && this.userAuthErrors(error)
  }

  userAuthErrors = (error) => (
    error['user_authentication'] && error['user_authentication'].map(startCase).join(',')
  )

  onKeyPress = ({ key }) => {
    key == 'Enter' && this.state.password && this.submit()
  }

  submit = () => (
    Api({ request: USERS_LOGIN, data: this.state })
  )

  setEmail = (e) => (
    this.setState({ email: e.currentTarget.value })
  )

  setPassword = (e) => (
    this.setState({ password: e.currentTarget.value })
  )
}

const mapStateToProps = ({ users: { userLogin } }) => ({
  ...userLogin,
})

export default connect(mapStateToProps)(Login)

import React from 'react'
import { useSelector } from 'react-redux'
import { uniq } from 'lodash'

import Api from '../../api/call'
import { TARIFFS_CUSTOMERS, COMPANIES_LABEL } from '../../root/action-types'
import SimpleIndexTable from '../../generic/indexTable/Simple'

const COLUMNS = [
  { Header: "Country", accessor: "countryName" },
  { Header: "Customer", accessor: "customerName" },
  { Header: "Direction", accessor: "direction" },
  { Header: "Disabled", accessor: "disabled" },
]

const connectCustomer = (data, companies, loading) => {
  const missing = []
  const result = [...data]
  result.forEach(t => {
    t.customerName = companies[t.customer_id]
    if (t.customer_id && !t.customerName) { missing.push(t.customer_id) }
  })
  if (missing.length && !loading) {
    Api({ request: COMPANIES_LABEL, params: { id: uniq(missing) } })
  }

  return result
}

const Index = ({ history }) => {
  const tariffs = useSelector(r => r.tariffs.customers.index)
  const companies = useSelector(r => r.companies.search)
  const loading = tariffs.loading || companies.loading
  const data = connectCustomer(tariffs.data, companies.labels, loading)

  return (
    <SimpleIndexTable
      title="Customer Tariffs"
      columns={COLUMNS}
      model="tariff"
      linkString="/tariffs/customers/"
      actionType={TARIFFS_CUSTOMERS}
      data={data}
      history={history}
      loading={loading}
    />
  )
}

export default Index

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

import { CURRENT_USER } from '../root/action-types'
import Api from '../api/call'

import './Topbar.scss'
import { brandLogo, stagingLogo } from '../theme/assets'

import Logout from './Logout'
import CreateNew from './CreateNew'
import QuickFind from './quick-find/QuickFind'

class Topbar extends Component {
  componentDidMount = () => (
    Api({ request: CURRENT_USER })
  )

  logo = () => (
    this.stagingServer() ? stagingLogo : brandLogo
  )

  stagingServer = () => (
    process.env.REACT_APP_FREIGHTSOFT_ENV == 'staging'
  )

  renderToggleIcon = (navbarExpanded) => {
    if (navbarExpanded) {
      return <FontAwesomeIcon icon={['fas', 'outdent']} />
    }
    return <FontAwesomeIcon icon={['fas', 'indent']} />
  }

  render = () => (
    <div className="topbar">
      <div className="topbar-item topbar-navbar-toggle" onClick={this.props.toggleNavbarExpanded}>
        {this.renderToggleIcon(this.props.navbarExpanded)}
      </div>

      <div className="topbar-item topbar-logo-container">
        <Link to={'/'}>
          <img className="topbar-logo" src={this.logo()} alt="Logo" />
        </Link>
      </div>

      <QuickFind />

      <CreateNew />

      <div className="topbar-item topbar-notifications">
        <span className="topbar-notifications-icon">
          <FontAwesomeIcon icon={['fas', 'bell']} fixedWidth />
        </span>
        <span className="notifications-counter">0</span>
      </div>

      <div className="topbar-item topbar-user">
        <span className="topbar-user-icon">
          <span className="user-icon">
            <FontAwesomeIcon icon={['fas', 'user']} fixedWidth />
          </span>
        </span>
        <span className="topbar-user-name">
          {(get(this.props.currentUser, 'firstName') || '')
           +' '+(get(this.props.currentUser, 'lastName') || '')}
        </span>
      </div>

      <Logout />
    </div>
  )
}

const mapStateToProps = ({ users: { current } }) => ({
  currentUser: current
})

export default connect(mapStateToProps)(Topbar)

import React, { Component } from 'react'
import CreatableSelect from 'react-select/creatable'

import { Field } from '../Field'

import './select.scss'

const components = {
  DropdownIndicator: null,
}

const createOption = (label) => ({
  label,
  value: label,
})

const valueArrayToOptions = (valueArray) => (
  valueArray.map(label => createOption(label))
)

const extractValueArray = (options) => (
  options.map(v => v.label)
)

export class MultiSelectTextInput extends Component {

  state = {
    inputValue: '',
    options: valueArrayToOptions(this.props.valueArray) || []
  }

  handleInputChange = (inputValue) => {
    this.setState({ inputValue })
  }

  handleChange = (options) => {
    this.setState({ options })
    this.bubbleValues(options)
  }

  bubbleValues = (options) => {
    this.props.onChange(extractValueArray(options))
  }

  handleKeyDown = (event) => {
    const { inputValue, options } = this.state
    if (!inputValue) return
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        this.handleInputChange('')
        this.handleChange([...options, createOption(inputValue)])
        event.preventDefault()
    }
  }

  render = () => {
    const { inputValue, options } = this.state
    const { label, isDisabled, placeholder, loading } = this.props

    return (
      <Field label={label}>
        <CreatableSelect
          components={components}
          inputValue={inputValue}
          isClearable={false}
          isMulti
          menuIsOpen={false}
          onChange={this.handleChange}
          onInputChange={this.handleInputChange}
          onKeyDown={this.handleKeyDown}
          classNamePrefix="select"
          className="select multi"
          placeholder={placeholder || "Type something and press enter..."}
          value={options}
          isDisabled={isDisabled}
          loading={loading}
        />
      </Field>
    )
  }
}

export default MultiSelectTextInput

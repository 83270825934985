import React, { Component } from 'react'
import { get, invoke } from 'lodash'

import './Status.scss'

export const formatErrors = (errors) => (
  errors.map((e, index) => {
    return <div key={index}>{e}</div>
  })
)

export const Status = ({ isError, children, messages }) => (
  <div className={
    isError ? "form-status error" : "form-status"
  }>
    {children}
    {messages ? formatErrors(messages) : null}
  </div>
)

export class FocusStatus extends Component {

  css = () => (
    this.props.isError ? "form-status error" : "form-status"
  )

  scrollToMe = () => {
    if (get(this.props.messages, 'length')) {
      invoke(this.ref, 'scrollIntoView')
    }
  }

  componentDidMount = this.scrollToMe

  componentDidUpdate(prevProps) {
    if (this.props.messages != prevProps.messages) {
      this.scrollToMe()
    }
  }

  setRef = (node) => {
    this.ref = node
  }

  render = () => (
    <div className={this.css()} ref={this.setRef}>
      {formatErrors(this.props.messages || [])}
    </div>
  )
}

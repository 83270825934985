import React, { Component } from 'react'

import FloatingAlert from './FloatingAlert'

import './floating-alert.scss'


const MAX_LENGTH = 1

const limitStreamLength = (stream, maxLength = MAX_LENGTH) => (
  stream.filter((_, index) => index < maxLength)
)

class FloatingAlertStream extends Component {

  state = {
    stream: []
  }

  joinStream = (alert) => {
    const newStream = limitStreamLength([alert].concat(this.state.stream))
    this.setState({ stream: newStream })
  }

  removeFromStream = (timestamp) => {
    const newStream = this.state.stream.filter(alert => alert.timestamp != timestamp)
    this.setState({ stream: newStream })
  }

  renderStream = () => (
    this.state.stream.map(alert =>
      <FloatingAlert
        {...alert}
        close={this.removeFromStream}
        key={alert.timestamp}
      />
    )
  )

  renderListener = () => {
    const { alertListener: AlertListener } = this.props
    return <AlertListener newAlert={this.joinStream} close={this.removeFromStream} />
  }

  render = () => (
    <div className="floating-alert-stream">
      <this.renderListener />
      <this.renderStream />
    </div>
  )
}

export default FloatingAlertStream

import React, { Component } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './react-toggle.scss'
import './field.scss'


export class Field extends Component {

  state = {
    hasBeenTouched: false,
  }

  touched = () => (
    this.setState({ hasBeenTouched: true })
  )

  displayError = () => {
    const { error, forceError } = this.props
    return ((forceError && error) || (error && this.state.hasBeenTouched))
  }

  buildClassName = () => {
    const { disableFocusStyle, className } = this.props
    return (
      `field `
      + `${this.displayError() ? 'field-error': ''} `
      + `${(disableFocusStyle ? '' : 'field-focus-style')} `
      + `${className || ''}`
    )
  }

  renderRequiredField = () => (
    <span className="required-field">
      &nbsp;*
    </span>
  )

  render = () =>{
    const { label, required, children } = this.props
    return (
      <div className={this.buildClassName()} onBlur={this.touched}>
        <label>{label}{required ? this.renderRequiredField() : null}</label>
        {children}
      </div>
    )
  }
}

export const FieldSection = ({ title, children, className, ...props }) => (
  <div className={`section ${className || ''}`} {...props}>
    <h4>{title}</h4>
    <div className="header-rule" />
    <div className="fields">
      {children}
    </div>
  </div>
)

export const ExpandSection = (
  { title, children, className, toggleExpanded, expanded, ...props }
) => (
  <div
    className={`section expand-section ${expanded ? 'expanded' : 'collapsed'} ${className || ''}`}
    {...props}
  >
    <div
      className={`header ${expanded ? 'expanded' : 'collapsed'}`}
      onClick={toggleExpanded}
    >
      <h4>{title}</h4>
      <FontAwesomeIcon
        icon={`${expanded ? 'caret-up' : 'caret-down'}`}
        className="expand"
      />
    </div>
    <div className="header-rule" />
    <div className="fields">
      {expanded ? children : null}
    </div>
  </div>
)

import React, { Component } from 'react'

export default class PrivacyPolicy extends Component {
  
  render = () => (
    <div>
      <h1>Privacy Policy</h1>
    </div>
  )
}

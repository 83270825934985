import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'

import { CONTACTS } from '../root/action-types'
import Api from '../api/call'

import Accordion from '../generic/accordion/Accordion'
import Contact, { BLANK_CONTACT } from './Contact'

class AllContacts extends Component {

  state = {
    contactUnderEdit: '',
  }

  componentDidMount() {
    this.getCompanyContacts()
  }

  setContactUnderEdit = (contact) => (
    this.setState({ contactUnderEdit: contact }, this.getCompanyContacts)
  )

  getCompanyId = () => (
    get(this.props.companyData, 'id')
  )

  getCompanyContacts = () => (
    Api({
      request: CONTACTS,
      params: { companyId: get(this.props.companyData, 'id') }
    })
  )

  renderContacts = () => (
    get(this.props.index, 'data', []).map((contact) => this.renderContact(contact))
  )

  newContact = () => (
    this.setContactUnderEdit('new')
  )

  newPrimaryCheck = () => (
    get(this.props.index, 'count') == 0
  )

  existingPrimaryCheck = (contact) => (
    get(this.props.companyData, 'primaryContactId') == contact.id
  )

  renderNewContact = () => (
    <Contact
      contact={BLANK_CONTACT}
      companyId={this.getCompanyId()}
      isPrimary={this.newPrimaryCheck()}
      setContactUnderEdit={this.setContactUnderEdit}
      newContact editMode open
    />
  )

  renderContact = (contact) => (
    <Contact
      key={contact.id}
      contact={contact}
      companyId={this.getCompanyId()}
      isPrimary={this.existingPrimaryCheck(contact)}
      editMode={contact.id === this.state.contactUnderEdit}
      setContactUnderEdit={this.setContactUnderEdit}
    />
  )

  render = () => (
    <Accordion
      title={'All Contacts'}
      newItemLabel={'New Contact'}
      newItemOnClick={this.newContact}
    >
      { this.state.contactUnderEdit === 'new' ? this.renderNewContact() : this.renderContacts() }
    </Accordion>
  )

}

const mapStateToProps = ({ companies: { contacts: { index } } }) => (
  { index }
)

export default connect(mapStateToProps)(AllContacts)

import React from 'react'

import { OrderableSelect } from '../../forms/select/OrderableSelect'
import ModalWindow from '../../layout/ModalWindow'

import './column-select.scss'

export default function ColumnSelect(props) {
  const { setColumns, defaultColumns, open, close, title } = props

  const resetToDefault = () => setColumns(defaultColumns)

  const modal = () => (
    <ModalWindow
      isOpen={open}
      closeModal={close}
      className="column-select"
    >
      <h3>{title ? title : 'Column Selection'}</h3>
      <OrderableSelect {...props} onChange={setColumns} />
      <div className="options">
        <button className="danger" onClick={resetToDefault}>Default</button>
        <button className="primary" onClick={close}>OK</button>
      </div>
    </ModalWindow>
  )

  return open ? modal() : null
}

import React, { Component } from 'react'
import { get } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ModalWindow from '../layout/ModalWindow'
import AllOpeningTimes from './opening-times/AllOpeningTimes'
import { countryName } from './countries'

import WarehouseModal from './warehouse/WarehouseModal'

export default class DisplayAddress extends Component {

  state = {
    warehouseModalOpen: false,
  }

  openWarehouseModal = () => (
    this.setState({ warehouseModalOpen: true })
  )

  closeWarehouseModal = () => (
    this.setState({ warehouseModalOpen: false })
  )

  addressTypeString = () => {
    const addressTypeString = this.props.address.office ? 'Office' : null
    if (this.props.address.warehouse) {
      return (
        <span>
          {addressTypeString ? addressTypeString + ' / ' : null}
          <this.showWarehouseText />
        </span>
      )
    }
    return addressTypeString || 'Unknown'
  }

  showWarehouseText = () => (
    <span className="warehouse-modal-link">
      <span onClick={this.openWarehouseModal}>
        Warehouse
      </span>
    </span>
  )

  primaryText = () => (
    this.props.isPrimary ? this.showPrimary() : null
  )

  showPrimary = () => (
    <div className="address-type">
      Primary Address
    </div>
  )

  renderAddressType = () => (
    <div className="address-type">
      Address Type: {this.addressTypeString()}
    </div>
  )

  constructGoogleMapsLink = () => {
    const GOOGLE_QUERY_URL = 'https://www.google.com/maps/search/?api=1&query='
    const { addressString } = this.props
    const mapsURL = GOOGLE_QUERY_URL + encodeURIComponent(addressString)
    return mapsURL
  }

  state = {
    openingTimesModalOpen: false,
  }

  openOpeningTimesModal = () => (
    this.setState({ openingTimesModalOpen: true })
  )

  closeOpeningTimesModal = () => (
    this.setState(
      { openingTimesModalOpen: false },
      this.props.resetAddressUnderEdit
    )
  )

  editThisAddress = () => (
    this.props.setAddressUnderEdit(this.props.address.id)
  )

  primaryControl = () => (
    this.props.isPrimary ? null : this.setPrimaryIcon()
  )

  setPrimaryIcon = () => (
    <div>
      <span onClick={this.props.setPrimary}>
        <label>
          <FontAwesomeIcon icon="chess-king" />
        </label>
        Set As Primary
      </span>
    </div>
  )

  renderOpeningTimesModal = () => (
    <ModalWindow
      className={"opening-times-modal"}
      isOpen={this.state.openingTimesModalOpen}
      closeModal={this.closeOpeningTimesModal}
    >
      <h3>Opening Hours</h3>
      <AllOpeningTimes
        addressString={this.props.addressString}
        addressId={get(this.props.address, 'id')}
        checkForPropChanges={this.props.resetAddressUnderEdit}
      />
    </ModalWindow>
  )

  renderDisplayMode = () => {
    const {
      lines,
      postcode
    } = this.props.address

    const addressLines = [
      ...lines,
      postcode
    ].filter(Boolean)

    return (
      <React.Fragment>
        {
          addressLines.map((line, index) => (
            <div className="address-line" key={index}>{line}</div>
          ))
        }
        <div className="address-line">{countryName(this.props.address.countryCode)}</div>

        <this.renderAddressType />
        <this.primaryText />
      </React.Fragment>
    )
  }

  render = () => (
    <div className="display-address">
      <this.renderOpeningTimesModal />
      <WarehouseModal
        addressString={this.props.addressString}
        isOpen={this.state.warehouseModalOpen}
        closeModal={this.closeWarehouseModal}
        warehouse={this.props.address.warehouseDetails}
      />

      <div className="details-address">
        {this.renderDisplayMode()}
      </div>
      <div className="details-options">
        <div>
          <a
            href={this.constructGoogleMapsLink()}
            target="_blank"
            rel="noopener noreferrer"
          >
            <label>
              <FontAwesomeIcon icon={['fas', 'map']} />
            </label>
            Map
          </a>
        </div>
        <div>
          <span onClick={this.openOpeningTimesModal}>
            <label>
              <FontAwesomeIcon icon={['fas', 'clock']} />
            </label>
            Opening Hours
          </span>
        </div>
        <div>
          <span onClick={this.editThisAddress}>
            <label>
              <FontAwesomeIcon icon={['fas', 'pencil-alt']} />
            </label>
            Edit
          </span>
        </div>
        <this.primaryControl />
      </div>
    </div>
  )
}

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { omit } from 'lodash'

import { Field } from '../../forms/Field'
import {
  SimpleSelect,
  CountrySelect,
  UserSelect,
} from '../../forms/select'
import CompanySelect from '../../forms/select/CompanySelect'

import Incoterm from '../../forms/Incoterm'

import './General.scss'
import { DateSelector, TimeSelector } from '../../forms/date-time'

const LOCATION_TYPES = [
  "Partial Postcode",
  "Region",
  "Country",
  "Depot",
  "Door Delivery",
  "Door Delivery Unloading",
]

export const FREIGHT_MODE_TYPES = [
  "No Quote",
  "Normal",
  "Special",
  "Super low",
]

const STATUS = ["Active", "Converted", "Expired"]

export class General extends Component {

  state = omit(this.props, ['edit'])

  stateSetter = (name) => (
    (val) => this.setState({ [name]: val })
  )

  setCustomer = ({ value }) => { this.setState({ customerId: value }) }
  setOwner = ({ value }) => { this.setState({ ownerId: value }) }
  setStatus = this.stateSetter('status')
  setFreightMode = this.stateSetter('freightMode')
  setOriginCountryCode = this.stateSetter('originCountryCode')
  setDestinationCountryCode = this.stateSetter('destinationCountryCode')
  setGoodsReadyDate = this.stateSetter('goodsReadyDate')
  setGoodsReadyTime = this.stateSetter('goodsReadyTime')
  setExpiryDate = this.stateSetter('expiryDate')
  setDeliveryDeadlineDate = this.stateSetter('deliveryDeadlineDate')
  setDeliveryDeadlineTime = this.stateSetter('deliveryDeadlineTime')
  setOriginLocationType = this.stateSetter('originLocationType')
  setDestinationLocationType = this.stateSetter('destinationLocationType')
  setIncotermType = ({ value }) => this.setState({ incotermType: value })
  setValue = ({ target: { name, value } }) => this.setState({ [name]: value })

  setFormValue = ({ target: { name, value } }) => (
    this.setState({ [name]: value })
  )

  field = ({ label, name, ...props }) => (
    <Field label={label}>
      <input
        name={name}
        value={this.state[name] || ''}
        onChange={this.setFormValue}
        disabled={!this.props.edit}
        {...props}
      />
    </Field>
  )

  editControls = () => (
    this.props.edit ? <this.controls /> : null
  )

  controls = () => (
    <div className="section controls">
      <button className="submit" onClick={this.save}>Save</button>
    </div>
  )

  save = () => (
    this.props.updateApiRequest(this.state)
  )

  render = () => (
    <div className="quote-general">
      <div className="section">
        <h4>Quote Details</h4>
        <div className="double-input">
          <CompanySelect
            label={'Customer'}
            value={this.state.customerId}
            onChange={this.setCustomer}
            isDisabled={!this.props.edit}
            customer
          />
          <DateSelector
            label={'Expiry Date'}
            value={this.state.expiryDate}
            onChange={this.setExpiryDate}
            isDisabled={!this.props.edit}
          />
        </div>
        <div className="double-input">
          <SimpleSelect
            label="Quote Status"
            options={STATUS}
            onChange={this.setStatus}
            value={this.state.status}
            isDisabled={true}
          />
          <UserSelect
            label={'Quote Owner'}
            value={this.state.ownerId}
            onChange={this.setOwner}
            isDisabled={!this.props.edit}
            customer
          />
        </div>
        <div className="double-input">
          <CountrySelect
            labelPrefix="Origin "
            value={this.state.originCountryCode}
            onChange={this.setOriginCountryCode}
            isDisabled={!this.props.edit}
          />
          <CountrySelect
            labelPrefix="Destination "
            value={this.state.destinationCountryCode}
            onChange={this.setDestinationCountryCode}
            isDisabled={!this.props.edit}
          />
        </div>
        <div className="double-input">
          <SimpleSelect
            label="Origin Location Type"
            options={LOCATION_TYPES}
            onChange={this.setOriginLocationType}
            value={this.state.originLocationType}
            isDisabled={!this.props.edit}
          />
          <this.field
            label="Origin Location"
            name="originLocation"
            disabled={!this.props.edit}
          />
        </div>
        <div className="double-input">
          <SimpleSelect
            label="Destination Location Type"
            options={LOCATION_TYPES}
            onChange={this.setDestinationLocationType}
            value={this.state.destinationLocationType}
            isDisabled={!this.props.edit}
          />
          <this.field
            label="Destination Location"
            name="destinationLocation"
            disabled={!this.props.edit}
          />
        </div>
        <div className="double-input">
          <Incoterm
            isDisabled={!this.props.edit}
            incotermType={this.state.incotermType}
            incotermNamedPlace={this.state.incotermNamedPlace}
            setIncotermType={this.setIncotermType}
            setIncotermNamedPlace={this.setValue}
          />
        </div>
        <div className="double-input">
          <DateSelector
            label={'Goods Ready Date'}
            value={this.state.goodsReadyDate}
            onChange={this.setGoodsReadyDate}
            isDisabled={!this.props.edit}
          />
          <TimeSelector
            label={'Goods Ready Time'}
            value={this.state.goodsReadyTime}
            onChange={this.setGoodsReadyTime}
            isDisabled={!this.props.edit}
          />
        </div>
        <div className="double-input">
          <DateSelector
            label={'Delivery Req. By Date'}
            value={this.state.deliveryDeadlineDate}
            onChange={this.setDeliveryDeadlineDate}
            isDisabled={!this.props.edit}
          />
          <TimeSelector
            label={'Delivery Req. By Time'}
            value={this.state.deliveryDeadlineTime}
            onChange={this.setDeliveryDeadlineTime}
            isDisabled={!this.props.edit}
          />
        </div>
        <div className="double-input">
          <SimpleSelect
            label="Freight Mode"
            value={this.state.freightMode}
            onChange={this.setFreightMode}
            options={FREIGHT_MODE_TYPES}
            isDisabled={!this.props.edit}
          />
          <div />
        </div>
      </div>
      <this.editControls />
    </div>
  )
}

const mapStateToProps = ({ quotes: { show } }) => ({
  ...((show && show.data) || {}),
})

export default connect(mapStateToProps)(General)

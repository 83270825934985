import {
  QUICK_FIND_START,
  QUICK_FIND_SUCCESS,
  QUICK_FIND_FAILURE,
  QUICK_FIND_RESET_STORE
} from '../../root/action-types'

export const DEFAULT_STATE = {
  loading: false,
  errors: null,
  data: { results: [], queryType: '', queryString: '' }
}

const quickFind = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case QUICK_FIND_START:
      return {
        ...state,
        loading: true
      }
    case QUICK_FIND_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: action.response.data.errors,
        data: action.response.data.details
      }
    case QUICK_FIND_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.response.data.errors,
        data: action.response.data.details
      }
    case QUICK_FIND_RESET_STORE:
      return {
        ...DEFAULT_STATE
      }
    default:
      return state
  }
}

export default quickFind

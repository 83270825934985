import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { get, pick } from 'lodash'
import { Tab, TabList, TabPanel } from 'react-tabs'
import TabsWrapper from '../../generic/TabsWrapper'

import Api from '../../api/call'
import {
  COMPANIES_SHOW,
  COMPANIES_UPDATE_RESET_STORE,
  COMPANIES_UPDATE,
  COMPANIES_DESTROY_RESET_STORE,
  COMPANIES_DESTROY
} from '../../root/action-types'

import { Status, formatErrors } from '../../forms/Status'
import { Loading } from '../../generic/Loading'

import Sidebar from './Sidebar'
import Controls from './Controls'
import General from './General'
import ContactsTab from './ContactsTab'
import RegNumbers from './RegNumbers'
import Supplier from './Supplier'
import Customer from './Customer'
import Financial from '../Financial'
import NotesTab from './notes/NotesTab'

import './show-company.scss'

class Company extends Component {

  state = {
    paramsId: '',
    edit: false,
  }

  componentDidMount() {
    this.resetStores()
    this.setCompanyFromUrl()
  }

  componentDidUpdate = (prevProps) => {
    this.urlParamsIdHasChanged(prevProps) && this.setCompanyFromUrl()

    if (
      this.props.company.companyHasUpdated
      && !prevProps.company.companyHasUpdated
    ) {
      this.toggleEdit()
    }
  }

  urlParamsIdHasChanged = (prevProps) => (
    get(this.props.match, 'params.id') != get(prevProps.match, 'params.id')
  )

  setCompanyFromUrl = () => {
    this.setState(
      { paramsId: get(this.props.match, 'params.id') },
      () => this.fetchCompanyData()
    )
  }

  fetchCompanyData = () => {
    Api({
      request: COMPANIES_SHOW,
      id: this.state.paramsId
    })
  }

  resetStores = () => {
    this.props.dispatch({ type: COMPANIES_UPDATE_RESET_STORE })
    this.props.dispatch({ type: COMPANIES_DESTROY_RESET_STORE })
  }

  toggleEdit = () => (
    this.setState(
      prev => ({ edit: !prev.edit }),
      () => this.fetchCompanyData()
    )
  )

  updateApiRequest = (attributes) => (
    Api({
      request: COMPANIES_UPDATE,
      id: get(this.props.show.data, 'id'),
      data: attributes,
    })
  )

  destroyApiRequest = () => (
    Api({
      request: COMPANIES_DESTROY,
      id: get(this.props.show.data, 'id')
    })
  )

  inError = () => (
    !!get(this.props.company, 'errors')
  )

  collateErrorMessages = () => (
    formatErrors(get(this.props.company, 'errors', []))
  )

  key = () => {
    const { id, updated_at } = this.props.show.data || {}
    const { edit } = this.state

    return `${id}-${updated_at}-${edit}`
  }

  redirectToCompanies = () => {
    this.props.dispatch({
      type: COMPANIES_DESTROY_RESET_STORE,
    })
    return (
      <Redirect to="/companies" />
    )
  }

  renderSupplierTab = () => {
    const disabled = !get(this.props.show.data, 'supplier', false)
    return <Tab disabled={disabled}>Supplier</Tab>
  }

  renderCustomerTab = () => {
    const disabled = !get(this.props.show.data, 'customer', false)
    return <Tab disabled={disabled}>Customer</Tab>
  }

  loading = () => (
    this.props.show.loading ? <Loading /> : null
  )

  renderCompany = () => (
    <div className="show-company-container">

      <Controls
        {...this.props.show.data}
        edit={this.state.edit}
        editAction={this.toggleEdit}
        destroyAction={this.destroyApiRequest}
      />

      <div className="status-container">
        <Status isError={this.inError()}>
          {this.collateErrorMessages()}
        </Status>
      </div>

      <div className="body">
        <Sidebar
          id={get(this.props.show, 'data.id')}
        />

        <div className="main">
          <this.loading />
          <TabsWrapper
            edit={this.state.edit}
            toggleEdit={this.toggleEdit}
          >
            <TabList>
              <Tab>General</Tab>
              <Tab>Contacts</Tab>
              <Tab>Reg. Numbers</Tab>
              <Tab>Financial</Tab>
              {this.renderCustomerTab()}
              {this.renderSupplierTab()}
              <Tab>Notes</Tab>
            </TabList>
            <TabPanel>
              <General
                updateApiRequest={this.updateApiRequest}
                edit={this.state.edit}
                key={this.key()}
              />
            </TabPanel>
            <TabPanel>
              <ContactsTab
                updateCompany={this.fetchCompanyData}
                key={this.key()}
                companyData={this.props.show.data}
              />
            </TabPanel>
            <TabPanel>
              <RegNumbers
                updateApiRequest={this.updateApiRequest}
                edit={this.state.edit}
                key={this.key()}
                companyId={get(this.props.show.data, 'id')}
                regNumbers={
                  pick(
                    this.props.show.data,
                    [
                      'companyRegistration',
                      'eoriNumber',
                      'vatNumber'
                    ]
                  )
                }
              />
            </TabPanel>
            <TabPanel>
              <Financial
                updateApiRequest={this.updateApiRequest}
                edit={this.state.edit}
                key={this.key()}
              />
            </TabPanel>
            <TabPanel>
              <Customer
                updateApiRequest={this.updateApiRequest}
                edit={this.state.edit}
                key={this.key()}
              />
            </TabPanel>
            <TabPanel>
              <Supplier
                updateApiRequest={this.updateApiRequest}
                edit={this.state.edit}
                key={this.key()}
              />
            </TabPanel>
            <TabPanel>
              <NotesTab
                updateApiRequest={this.updateApiRequest}
                accordionKey={this.key()}
              />
            </TabPanel>
          </TabsWrapper>
        </div>
      </div>
    </div>
  )

  render = () => (
    get(this.props.company, 'companyDestroyed', false) ? (
      this.redirectToCompanies()
    ) : (
      this.renderCompany()
    )
  )
}

const mapStateToProps = ({ companies }) => ({
  show: companies.show || {},
  company: companies.company || {}
})

export default connect(mapStateToProps)(Company)

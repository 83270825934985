import React, { Component } from 'react'
import Toggle from 'react-toggle'
import { capitalize, get } from 'lodash'

import { Field } from '../../forms/Field'

export default class OpeningTimeForm extends Component {

  renderDayToggle = (day) => (
    <div className="day-toggle">
      <span>{capitalize(day.substring(0,3))}</span>
      <Toggle
        name={day}
        checked={this.props.openingTime[day]}
        onChange={this.props.toggleDay}
        icons={false}
      />
    </div>
  )

  parseTimeToInt = (timeString) => (
    parseInt(timeString.replace(':', ''))
  )

  endTimeError = () => {
    if (get(this.props.openingTime, 'endTime') == '') {
      return true
    }

    const start = this.parseTimeToInt(this.props.openingTime.startTime)
    const end = this.parseTimeToInt(this.props.openingTime.endTime)

    if ( start >= end ) {
      return true
    }

    return false
  }

  render = () => (
    <div className={"opening-time-form-container"}>
      <div className={"form"}>
        <div className={"time-form"}>
          <Field
            label="Opening Time"
            error={get(this.props.openingTime, 'startTime') == ''}
          >
            <input
              name="startTime"
              value={this.props.openingTime.startTime}
              onChange={this.props.setTime}
              type='time'
              required
            />
          </Field>
          <Field
            label="Closing Time"
            error={this.endTimeError()}
          >
            <input
              name="endTime"
              value={this.props.openingTime.endTime}
              onChange={this.props.setTime}
              type='time'
              required
            />
          </Field>
        </div>
        <div className={"day-form"}>
          {this.renderDayToggle('monday')}
          {this.renderDayToggle('tuesday')}
          {this.renderDayToggle('wednesday')}
          {this.renderDayToggle('thursday')}
          {this.renderDayToggle('friday')}
          {this.renderDayToggle('saturday')}
          {this.renderDayToggle('sunday')}
        </div>
      </div>
      <div className={"form-options"}>
        <div
          className={"discard"}
          onClick={this.props.discardChanges}
        >
          Discard
        </div>
        <div onClick={this.props.apiRequest}>
          Save
        </div>
      </div>
    </div>
  )

}

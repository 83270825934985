import { combineReducers } from 'redux'

import { standardModelCreateUpdateDestroy } from '../api/standard-reducers'
import { LINE_ITEMS, LINE_ITEMS_MANAGE } from '../root/action-types'

const INITIAL_COLLECTION = { data: [], total: 0, count: 0 }

export const collection = (state = INITIAL_COLLECTION, action) => {
  switch (action.type) {
    case `${LINE_ITEMS}_START`:
    case `${LINE_ITEMS_MANAGE}_START`:
      return {
        ...state,
        loading: true,
        status: null,
        hasErrors: null,
      }
    case `${LINE_ITEMS}_FAILURE`:
      return {
        ...state,
        loading: false,
        errors: action.response.data.errors,
      }
    case `${LINE_ITEMS_MANAGE}_FAILURE`:
      return {
        ...state,
        loading: false,
        hasErrors: true,
        ...action.response.data.details,
      }
    case `${LINE_ITEMS}_SUCCESS`:
      return {
        ...state,
        loading: false,
        errors: null,
        hasErrors: false,
        ...action.response.data,
      }
    case `${LINE_ITEMS_MANAGE}_SUCCESS`:
      return {
        ...state,
        loading: false,
        errors: null,
        ...action.response.data.details,
      }
    default:
      return state
  }
}


export default combineReducers({
  collection,
  lineItem: standardModelCreateUpdateDestroy('lineItem', 'LINE_ITEMS'),
})


import { GROUPAGE_FINANCIALS } from '../../../root/action-types'

const INITIAL_STATE = {
  loading: false,
  byId: {
    groupage: {},
    jobs: {}
  }
}

const financials = (state = {...INITIAL_STATE}, action) => {
  switch (action.type) {
    case `${GROUPAGE_FINANCIALS}_START`:
      return {
        ...state,
        loading: true,
      }
    case `${GROUPAGE_FINANCIALS}_SUCCESS`: {
      const { data } = action.response
      const { groupage } = data
      const byId = {...state.byId}
      if (groupage && groupage.id) {
        byId[groupage.id] = data
      }
      return {
        ...state,
        loading: false,
        byId
      }
    }
    case `${GROUPAGE_FINANCIALS}_FAILURE`:
      return {
        ...state,
        loading: false,
        errors: action.response.data.errors,
      }
    default:
      return state
  }
}

export const TEST_ACCESS = {
  INITIAL_STATE
}

export default financials

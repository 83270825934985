import React, { Component } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Editor } from 'slate-react'

import './slate-editor.scss'


export default class SlateEditor extends Component {

  editorRef = editor => (
    this.editor = editor
  )

  onClickMark = (event, type) => {
    event.preventDefault()
    this.editor.toggleMark(type)
  }

  onClickHeading = () => {
    const isActive = this.hasBlock('heading')
    this.editor.setBlocks(isActive ? 'paragraph' : 'heading')
  }

  onClickBlock = (event, type) => {
    event.preventDefault()

    switch (type) {
      case 'heading':
        this.onClickHeading()
        break
      case 'bulleted-list':
      case 'numbered-list':
        this.onClickList(type)
        break
      default:
    }
  }

  onClickList = (type) => {
    const isList = this.hasBlock('list-item')

    this.editor
      .setBlocks('paragraph')
      .unwrapBlock('bulleted-list')
      .unwrapBlock('numbered-list')
    if (!isList) {
      this.editor
        .setBlocks('list-item')
        .wrapBlock(type)
    }
  }

  renderMarkButton = (type, icon) => {
    const isActive = this.hasMark(type)

    return (
      <button
        className={`editor-button ${icon} ${isActive ? 'active' : null}`}
        onPointerDown={event => this.onClickMark(event, type)}
      >
        <FontAwesomeIcon icon={icon} />
      </button>
    )
  }

  blockIsActive = type => {
    let isActive = this.hasBlock(type)

    if (['numbered-list', 'bulleted-list'].includes(type)) {
      const { emailBody: { document, blocks } } = this.props

      if (blocks.size > 0) {
        const parent = document.getParent(blocks.first().key)
        isActive = this.hasBlock('list-item') && parent && parent.type === type
      }
    }
    return isActive
  }

  renderBlockButton = (type, icon) => {
    const isActive = this.blockIsActive(type)

    return (
      <button
        className={`editor-button ${icon} ${isActive ? 'active' : null}`}
        onPointerDown={event => this.onClickBlock(event, type)} >
        <FontAwesomeIcon icon={icon} />
      </button>
    )
  }

  copyToClipboard = () => {
    this.editor.moveToRangeOfDocument().focus()
    setTimeout(() => document.execCommand('copy'), 1)
  }

  renderCopyButton = (icon) => (
    <button
      className={`editor-button ${icon}`}
      onPointerDown={this.copyToClipboard}>
      <FontAwesomeIcon icon={icon} />
    </button>
  )

  hasMark = type => {
    const { emailBody } = this.props
    return emailBody.activeMarks.some(mark => mark.type === type)
  }

  hasBlock = type => {
    const { emailBody } = this.props
    return emailBody.blocks.some(block => block.type === type)
  }

  renderEditorToolbar = () => (
    <div className='editor-toolbar'>
      {this.renderMarkButton('bold', 'bold')}
      {this.renderMarkButton('italic', 'italic')}
      {this.renderMarkButton('underline', 'underline')}
      {this.renderMarkButton('strikethrough', 'strikethrough')}
      {this.renderMarkButton('code', 'code')}

      {this.renderBlockButton('heading', 'heading')}
      {this.renderBlockButton('bulleted-list', 'list')}
      {this.renderBlockButton('numbered-list', 'list-ol')}

      {this.renderCopyButton('copy')}
    </div>
  )

  render = () => (
    <React.Fragment>
      <Editor
        readOnly={this.props.readOnly}
        className='editor'
        value={this.props.emailBody}
        onChange={this.props.onChange}
        renderMark={this.renderMark}
        renderBlock={this.renderBlock}
        ref={this.editorRef}
      />
      {this.props.readOnly ? null : <this.renderEditorToolbar />}
    </React.Fragment>
  )

  renderMark = (props, editor, next) => {
    const { children } = props
    switch (props.mark.type) {
      case 'bold':
        return <strong>{children}</strong>
      case 'code':
        return <code>{children}</code>
      case 'italic':
        return <em>{children}</em>
      case 'strikethrough':
        return <del>{children}</del>
      case 'underline':
        return <u>{children}</u>
      case 'small':
        return <small>{children}</small>
      default:
        return next()
    }
  }

  renderBlock = (props, editor, next) => {
    const { attributes, children, node } = props
    switch (node.type) {
      case 'block-quote':
        return <blockquote {...attributes}>{children}</blockquote>
      case 'bulleted-list':
        return <ul {...attributes}>{children}</ul>
      case 'heading':
        return <h2 {...attributes}>{children}</h2>
      case 'list-item':
        return <li {...attributes}>{children}</li>
      case 'numbered-list':
        return <ol {...attributes}>{children}</ol>
      default:
        return next()
    }
  }


}

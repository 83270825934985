import { combineReducers } from 'redux'

import {
  standardForCollections,
  standardModelCreateUpdateDestroy,
  standardShow
} from '../api/standard-reducers'
import { QUOTES_HISTORY, QUOTES_EMAIL_TEMPLATE } from '../root/action-types'

export const INITIAL_HISTORY = { quotes: [] }

export const INITIAL_EMAIL = {}

export const history = (state = INITIAL_HISTORY, action) => {
  switch (action.type) {
    case `${QUOTES_HISTORY}_START`:
      return {
        ...INITIAL_HISTORY,
        loading: true,
      }
    case `${QUOTES_HISTORY}_SUCCESS`:
      return {
        ...state,
        ...action.response.data,
        loading: false,
      }
    case `${QUOTES_HISTORY}_FAILURE`:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}

export const email = (state = INITIAL_EMAIL, action) => {
  switch (action.type) {
    case `${QUOTES_EMAIL_TEMPLATE}_START`:
      return {
        ...INITIAL_EMAIL,
        loading: true,
      }
    case `${QUOTES_EMAIL_TEMPLATE}_SUCCESS`:
      return {
        ...state,
        ...action.response.data,
        loading: false,
      }
    case `${QUOTES_EMAIL_TEMPLATE}_FAILURE`:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}

export const quote = standardModelCreateUpdateDestroy('quote', 'QUOTES')

export default combineReducers({
  quote,
  history,
  email,
  show: standardShow('QUOTES_SHOW'),
  index: standardForCollections('QUOTES'),
})

import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import QuickFindQueryOption from './QuickFindQueryOption'
import './quick-find-form.scss'


const MODELS = ['company', 'quote', 'job', 'groupage']

export class QuickFindQuerySelector extends Component {

  state = {
    dropdownOpen: false
  }

  componentDidMount = () => {
    document.addEventListener('click', this.clickOutside)
  }

  componentWillUnmount = () => {
    document.removeEventListener('click', this.clickOutside)
  }

  toggleDropdown = () => {
    this.setState(prevState => ({ dropdownOpen: !prevState.dropdownOpen }))
    this.closable = false
    setTimeout(() => this.closable = true, 10)
  }

  setRef = (node) => {
    this.ref = node
  }

  clickOutside = ({ target }) => {
    if (this.ref && this.closable && !this.ref.contains(target)) {
      this.setState({ dropdownOpen: false })
    }
  }

  expandedClass = () => (
    this.state.dropdownOpen ? 'expanded' : ''
  )

  selectQueryType = (type) => {
    this.props.setQueryType(type)
    this.toggleDropdown()
  }

  downChevron = () => (
    <span className="dropdown-icon" onClick={this.toggleDropdown}>
      <FontAwesomeIcon icon={['fas', 'chevron-down']} size='xs' />
    </span>
  )

  renderQueryTypeSelector = () => (
    <div
      className={`query-type-selector ${this.expandedClass()}`}
      ref={this.setRef}
    >
      <QuickFindQueryOption
        type={this.props.queryType}
        onClick={this.toggleDropdown}
        className="selected-query"
      />
      {this.downChevron()}
    </div>
  )

  renderQueryTypes = (queryTypes = []) => (
    queryTypes.map(type => (
      <QuickFindQueryOption
        type={type}
        onClick={() => this.selectQueryType(type)}
        key={type}
      />
    ))
  )

  renderDropdown = () => (
    <div className={`quick-find-query-dropdown ${this.expandedClass()}`}>
      {this.renderQueryTypes(MODELS)}
    </div>
  )

  render = () => (
    <React.Fragment>
      {this.renderQueryTypeSelector()}
      {this.renderDropdown()}
    </React.Fragment>
  )
}

export default QuickFindQuerySelector

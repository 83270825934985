import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { get } from 'lodash'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

import {
  ADDRESSES_DESTROY, ADDRESSES_MANAGEMENT_RESET_STORE
} from '../root/action-types'
import Api from '../api/call'

import { Status } from '../forms/Status'

import AddressForm from './AddressForm'
import ModalWindow from '../layout/ModalWindow'

import Warehouse from './warehouse/Warehouse'

class EditAddress extends Component {

  warehouseDetails = get(this.props.address, 'warehouseDetails')

  state = {
    confirmDeleteModalOpen: false,
    ...this.props.address,
  }

  componentDidMount = () => (
    this.dispatchAddressStoreReset()
  )

  componentDidUpdate = (prevProps) => {
    const previous = get(prevProps.addressManagement, 'addressHasUpdated')
    const current = get(this.props.addressManagement, 'addressHasUpdated')
    if (current !== previous && current) {
      this.exitEditMode()
    }
  }

  exitEditMode = () => {
    this.dispatchAddressStoreReset()
    this.props.resetAddressUnderEdit()
  }

  openConfirmDeleteModal = () => (
    this.setState({ confirmDeleteModalOpen: true })
  )

  closeConfirmDeleteModal = () => (
    this.setState({ confirmDeleteModalOpen: false })
  )

  setValue = ({ target: { name, value } }) => (
    this.setState({ [name]: value })
  )

  toggleValue = ({ target: { name } }) => {
    this.setState((prevState) => ({ [name]: !prevState[name] }))
  }

  setCountryValue = (country) => {
    this.setState({ countryCode: country.value })
  }

  destroyAddress = () => (
    Api({
      request: ADDRESSES_DESTROY,
      id: get(this.props.address, 'id'),
    })
  )

  sendUpdate = () => (
    this.props.apiRequest({ ...this.state, warehouseDetails: this.warehouseDetails })
  )

  renderDestroyOption = () => (
    <div className="destroy">
      <span onClick={this.openConfirmDeleteModal}>
        <label>
          <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} />
        </label>
        Delete
      </span>
    </div>
  )

  dispatchAddressStoreReset = () => (
    this.props.dispatch({
      type: ADDRESSES_MANAGEMENT_RESET_STORE,
    })
  )

  inError = () => (
    get(this.props.addressManagement, 'errors', []).length != 0
  )

  modalContent = ({error}) => {
    if (error) {
      return (
        <Status isError={this.inError}>
          {get(this.props.addressManagement, 'errors')}
        </Status>
      )
    }

    return (
      <button
        className={"delete-button"}
        onClick={this.destroyAddress}
      >
        Delete
      </button>
    )
  }

  fetchWarehouseAttributes = (attributes) => {
    this.warehouseDetails = attributes
  }

  renderDeleteModal = () => (
    <ModalWindow
      className="contact-delete-modal"
      isOpen={this.state.confirmDeleteModalOpen}
      closeModal={this.closeConfirmDeleteModal}
    >
      <h3>Confirm Address Deletion</h3>
      <span className={'name'}>
        {get(this.props.address, 'lines[0]')}
      </span>
      <this.modalContent error={this.inError()} />

    </ModalWindow>
  )

  renderWarehouseTab = () => (
    this.state.warehouse ? <Tab>Warehouse</Tab> : <Tab disabled={true} />
  )

  render = () => (
    <div className="edit-address-container">
      <this.renderDeleteModal />

      <Tabs>
        <TabList>
          <Tab>Address</Tab>
          {this.renderWarehouseTab()}
        </TabList>
        <TabPanel>
          <AddressForm
            address={this.state}
            onChange={this.setValue}
            onChangeToggle={this.toggleValue}
            onChangeCountry={this.setCountryValue}
            formError={this.inError()}
          />
        </TabPanel>
        <TabPanel>
          <Warehouse
            passUpData={this.fetchWarehouseAttributes}
            details={this.warehouseDetails}
          />
        </TabPanel>
      </Tabs>

      <div className="details-options">
        <div>
          <span onClick={this.sendUpdate}>
            <label>
              <FontAwesomeIcon icon={['fas', 'pencil-alt']} />
            </label>
            Save Changes
          </span>
        </div>
        <div>
          <span onClick={this.exitEditMode}>
            <label>
              <FontAwesomeIcon icon={['fas', 'trash-alt']} />
            </label>
            Discard Changes
          </span>
        </div>
        { this.props.newAddress ? null : this.renderDestroyOption() }
      </div>

    </div>
  )
}

const mapStateToProps = ({ companies: { addresses: { addressManagement } } }) => (
  { addressManagement }
)

export default connect(mapStateToProps)(EditAddress)

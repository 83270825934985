import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'
import { Tab, TabList, TabPanel } from 'react-tabs'

import TabsWrapper from '../../generic/TabsWrapper'
import GenericShow from '../../generic/Show'
import {
  TEMPLATES_GROUPAGES_SHOW, TEMPLATES_GROUPAGES_DESTROY, TEMPLATES_GROUPAGES_NEW
} from '../../root/action-types'
import Form from './Form'

import Billing from '../../line-items/Billing'

const actions = {
  show: TEMPLATES_GROUPAGES_SHOW,
  destroy: TEMPLATES_GROUPAGES_DESTROY,
  new: TEMPLATES_GROUPAGES_NEW,
}

export class Show extends Component {

  // Can change when you save record
  id = () => (
    get(this.props.details, 'id') || get(this.props.match, 'params.id', 'new')
  )

  form = (props) => (
    <TabsWrapper>
      <TabList>
        <Tab>Application</Tab>
        <Tab disabled={this.id() == 'new'}>Billing</Tab>
      </TabList>
      <TabPanel>
        <Form
          initialFields={this.props.details}
          key={this.id()}
          {...props}
        />
      </TabPanel>
      <TabPanel>
        <Billing {...props} parentType="GroupageTemplate" parentId={this.id()} />
      </TabPanel>
    </TabsWrapper>
  )

  render = () => (
    <div>
      <GenericShow
        actions={actions}
        indexPath="/templates/groupages"
        modelName="Groupage Template"
        form={this.form}
        {...this.props}
      />
    </div>
  )

}

const mapStateToProps = ({ templates: { groupages: { show } } }) => (
  show
)

export default connect(mapStateToProps)(Show)


import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { get } from 'lodash'
import qs from 'qs'

import Api from '../../api/call'
import { JOBS_CREATE, JOBS_CREATE_RESET_STORE } from '../../root/action-types'

import { CompanySelect, CountrySelect } from '../../forms/select'
import { DateSelector } from '../../forms/date-time'

import { Status, formatErrors } from '../../forms/Status'

import './NewJob.scss'

const getCustomerIdParam = (props) => (
  qs.parse(get(props, 'location.search', '').slice(1))['customer']
)

class NewJob extends Component {

  state = {
    customerId: getCustomerIdParam(this.props) || '',
    originCountryCode: '',
    destinationCountryCode: 'MT',
    bookingDate: new Date()
  }

  stateSetter = (name) => (
    (val) => this.setState({ [name]: val })
  )

  setOriginCountry = this.stateSetter('originCountryCode')
  setDestinationCountry = this.stateSetter('destinationCountryCode')
  setBookingDate = this.stateSetter('bookingDate')
  setCustomerId = (e) => {
    this.setState({
      customerId: e.value
    })
  }

  createJobApiRequest = () => (
    Api({
      request: JOBS_CREATE,
      data: this.state,
    })
  )

  inError = () => (
    !!get(this.props.job, 'errors')
  )

  jobCreated = () => {
    if (get(this.props.job, 'jobCreated', false)) {
      this.props.dispatch({
        type: JOBS_CREATE_RESET_STORE,
      })
      return true
    }
    return false
  }

  newJobRedirect = () => (
    <Redirect to={`/jobs/${this.props.job.data.id}?editMode`} />
  )

  renderForm = () => (
    <div className="new-job-container">
      <h1>New Job</h1>
      <div className="status-container">
        <Status isError={this.inError()}>
          {formatErrors(get(this.props.job, 'errors', []))}
        </Status>
      </div>
      <div className="pane-container">
        <div className="pane">
          <div className="header">
            <h3>Job Details</h3>
          </div>
          <div className="header-rule" />
          <div className="double-input">
            <CompanySelect
              label={'Customer'}
              value={this.state.customerId}
              onChange={this.setCustomerId}
              customer
              required
              error={this.state.customerId == ''}
              forceError={this.inError()}
            />
            <DateSelector
              label={'Booking Date'}
              value={this.state.bookingDate}
              onChange={this.setBookingDate}
              required
            />
          </div>
          <div className="double-input">
            <CountrySelect
              labelPrefix={'Origin '}
              value={this.state.originCountryCode}
              onChange={this.setOriginCountry}
              required={true}
              error={this.state.originCountryCode == ''}
              forceError={this.inError()}
            />
            <CountrySelect
              labelPrefix={'Destination '}
              value={this.state.destinationCountryCode}
              onChange={this.setDestinationCountry}
              required={true}
              error={this.state.destinationCountryCode == ''}
              forceError={this.inError()}
            />
          </div>
        </div>
        <button className="submit primary" onClick={this.createJobApiRequest}>
          Create Job
        </button>
      </div>
    </div>
  )

  render = () => (
    this.jobCreated() ? this.newJobRedirect() : this.renderForm()
  )

}

const mapStateToProps = ({ jobs: { job } }) => (
  { job }
)

export default connect(mapStateToProps)(NewJob)

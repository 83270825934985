import React, { Component } from 'react'
import { DragSource } from 'react-dnd'
import { Link } from 'react-router-dom'
import { toLower } from 'lodash'

import { actualMeasurements } from '../../../jobs/job/measurements'
import AccordionItem from '../../../generic/accordion/AccordionItem'

import './drag-and-drop-job.scss'


const type = 'drag-and-drop-job'

export const spec = {
  canDrag: function(props) {
    return props.canDrag
  },
  beginDrag(props) {
    const jobIdObject = { id: props.id }
    return jobIdObject
  },
  endDrag(props, monitor) {
    if (monitor.didDrop()) {
      const dropResult = monitor.getDropResult()
      const groupageId = dropResult['targetGroupage']
      const jobId = props.id

      props.updateRequest(jobId, groupageId)
    }
  }
}

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }
}

const jobDetailText = (text, suffix) => (
  text ? `${text} ${suffix ? suffix : ''}` : '-'
)

export class DragAndDropJob extends Component {

  jobTitleString = ({ reference, customerName, ...props }) => {
    const { grossWeightKg, cubicMetres } = actualMeasurements(props)
    return [
      reference,
      customerName,
      jobDetailText(grossWeightKg, 'KG'),
      jobDetailText(cubicMetres, 'm³')
    
    ].filter(Boolean).join(' | ')
  }

  statusIndicator = (status) => (
    <span className={`status-indicator ${toLower(status)}`}>{status}</span>
  )

  jobTitle = (props) => (
    <span className="job-title">
      <span className="title-string">{this.jobTitleString(props)}</span>
      {this.statusIndicator(props.status)}
    </span>
  )

  linkOrSpan = ({path, text}) => (
    path ? <Link to={path}>{text}</Link> : <span>{text}</span>
  )

  jobDetail = ({ label, text, suffix, path = null }) => (
    <div className={"job-detail"}>
      <label>{label}</label>
      <this.linkOrSpan path={path} text={jobDetailText(text, suffix)} />
    </div>
  )

  renderJobDetails = ({
    reference,
    id,
    customerName,
    customerId,
    ...props,
  }) => {
    const { grossWeightKg, cubicMetres } = actualMeasurements(props)
    return (
      <div className="drag-and-drop-job-details">
        <this.jobDetail label={'Job Reference'} text={reference} path={`/jobs/${id}`} />
        <this.jobDetail label={'Customer'} text={customerName} path={`/companies/${customerId}`} />
        <this.jobDetail label={'Gross Weight'} text={grossWeightKg} suffix={'kg'} />
        <this.jobDetail label={'Cubic Metres'} text={cubicMetres} suffix={'m³'} />
        {/* <this.jobDetail label={'Job Controller'} text={} /> */}
      </div>
    )
  }

  render() {
    const { connectDragSource } = this.props

    return connectDragSource(
      <div className="drag-and-drop-job">
        <AccordionItem name={this.jobTitle(this.props)}>
          <this.renderJobDetails {...this.props} />
        </AccordionItem>
      </div>
    )
  }

}

export default DragSource(type, spec, collect)(DragAndDropJob)


import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { get } from 'lodash'

import ModalWindow from '../../../layout/ModalWindow'

import './NotesTab.scss'


class DisplayNote extends Component {

  state = {
    confirmDeleteModalOpen: false,
  }

  editThisNote = () => (
    this.props.setNoteUnderEdit(this.props.note.id)
  )

  exitEditMode = () => {
    this.props.resetNoteUnderEdit()
  }

  openConfirmDeleteModal = () => (
    this.setState({ confirmDeleteModalOpen: true })
  )

  closeConfirmDeleteModal = () => (
    this.setState({ confirmDeleteModalOpen: false })
  )

  destroyNote = () => {
    this.props.destroy(this.props.note.id)
  }

  modalContent = () => (
    <button className={"delete-button"} onClick={this.destroyNote}>
      Delete
    </button>
  )

  renderDeleteModal = () => (
    <ModalWindow
      className="note-delete-modal"
      isOpen={this.state.confirmDeleteModalOpen}
      closeModal={this.closeConfirmDeleteModal}
    >
      <h3>Delete this note?</h3>
      <pre className={'name'}>
        {get(this.props.note, 'title')}
      </pre>
      <this.modalContent />
    </ ModalWindow>
  )

  renderDestroyOption = () => (
    <div className="destroy">
      <span onClick={this.openConfirmDeleteModal}>
        <label>
          <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} />
        </label>
        Delete
      </span>
    </div>
  )

  render = () => (
    <React.Fragment>
      <pre>
        {this.props.note.content}
      </pre>
      <this.renderDeleteModal />
      <div className="details-options">
        <div>
          <span onClick={this.editThisNote} >
            <label>
              <FontAwesomeIcon icon={['fas', 'pencil-alt']} />
            </label>
            Edit
          </span>
        </div>
        {this.renderDestroyOption()}
      </div>
    </React.Fragment>
  )
}

export default DisplayNote

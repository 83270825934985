import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Select from 'react-select'

import Api from '../../api/call'
import { Loading } from '../../generic/Loading'
import ModalWindow from '../../layout/ModalWindow'
import { FocusStatus, Field } from '../../forms'
import CompanyName from '../../companies/Name'
import ActionTypes from '../../root/action-types'

import Summary from './Summary'
import './autoline.scss'

const { JOBS_COSTING, TARIFFS_DISTRIBUTION_OPTIONS } = ActionTypes

export const Content = ({ id, replaceLineItems, cancel }) => {
  const { loading, details, hasError, options } = useSelector(s => s.jobs.autoline)
  const [step, setStep] = useState(0)
  const [distribution, setDistribution] = useState('')
  const complete = () => {
    replaceLineItems({ cost: distributionToLineItems(distribution) })
    cancel()
  }

  if (loading) { return <Loading /> }
  if (step == 0) {
    Api({ request: JOBS_COSTING, params: { id } }), [id]
    setStep(1)
  }
  else if (step == 1) {
    Api({ request: TARIFFS_DISTRIBUTION_OPTIONS, params: details })
    setStep(2)
  }

  return (
    <div className="autoline-content">
      <h1>Autoline Costs For Job {details.reference}</h1>
      <FocusStatus isError={hasError} messages={details.errors} />
      <Summary {...details} />
      <DistributionSelect
        options={options}
        value={distribution}
        onChange={setDistribution}
      />
      <div className="controls">
        <button
          className="primary"
          onClick={complete}
          disabled={!distribution}
        >
          Update Line Items
        </button>
        <button className="danger" onClick={cancel}>Cancel</button>
      </div>
    </div>
  )
}

const Label = ({ supplier_id, total }) => (
  <div className="distro-option">
    <span><CompanyName id={supplier_id} /></span>
    <span>€{total.toFixed(2)}</span>
  </div>
)

const option = (props) => ({
  value: props,
  label: <Label {...props} />,
})

const DistributionSelect = ({ options, value, onChange }) => {
  const selection = (options || []).map(option)

  return (
    <Field label="Supplier">
      <Select
        classNamePrefix="select"
        className="select"
        onChange={(o) => onChange(o && o.value)}
        options={selection}
        value={selection.find(s => s.value == value)}
      />
    </Field>
  )
}

const distributionToLineItems = ({ distribution, supplier_id, surcharges }) => (
  [
    { description: 'Distribution', value: distribution.rate },
    ...surcharges.map(({ name, rate }) => ({ description: name, value: rate })),
  ].map(li =>
    ({ ...li, companyId: supplier_id, category: 'cost' })
  )
)

const Button = ({ id, replaceLineItems }) => {
  const [modal, showModal] = useState(false)
  const cancel = () => showModal(false)

  return <>
    <button className="success" onClick={() => showModal(true)}>
      Autoline
    </button>
    <ModalWindow className="job-autoline-modal" isOpen={modal} closeModal={cancel}>
      {modal ? <Content id={id} replaceLineItems={replaceLineItems} cancel={cancel} /> : null}
    </ModalWindow>
  </>
}

export default Button

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'

import { EMAILS } from '../root/action-types'
import Api from '../api/call'

import Accordion from '../generic/accordion/Accordion'
import Email, { BLANK_EMAIL } from './Email'

class AllEmails extends Component {

  state = {
    emailUnderEdit: '',
  }

  setEmailUnderEdit = (email) => (
    this.setState({ emailUnderEdit: email }, this.getEmails )
  )

  getEmails = () => (
    Api({
      request: EMAILS,
      params: { parentId: this.props.parentId }
    })
  )

  componentDidMount() {
    this.getEmails()
  }

  unpackEmails = () => (
    get(this.props.index, 'data', [])
  )

  newEmail= () => (
    this.setEmailUnderEdit('new')
  )

  renderNewEmail= () => (
    <Email
      email={BLANK_EMAIL}
      parentId={this.props.parentId}
      editMode={true}
      setEmailUnderEdit={this.setEmailUnderEdit}
      newEmail={true}
      open={true}
    />
  )

  render = () => (
    <Accordion
      title={'Emails'}
      newItemLabel={'New Email'}
      newItemOnClick={this.newEmail}
    >
      { this.state.emailUnderEdit === 'new' ? this.renderNewEmail() : null }
      {
        this.unpackEmails().reverse().map((email)=> (
          <Email
            key={email.id}
            type={'EMAILS'}
            email={email}
            editMode={email.id === this.state.emailUnderEdit}
            setEmailUnderEdit={this.setEmailUnderEdit}
          />
        ))
      }
    </Accordion>
  )
}

const mapStateToProps = ({ emails: { index } }) => (
  { index }
)

export default connect(mapStateToProps)(AllEmails)

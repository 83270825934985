import React, { Component } from 'react'
import { IconButton } from '../generic/Buttons'
import { Link } from 'react-router-dom'
import JobsIndex from './JobsIndex'

import "./ViewAllJobs.scss"

class ViewAllJobs extends Component {

  render = () => (
    <div className="view-all-jobs-container ">
      <div className="index-controls">
        <h1>Jobs</h1>
        <Link to="/jobs/new">
          <IconButton className="primary" icon="plus-circle">
            New Job
          </IconButton>
        </Link>
      </div>
      <JobsIndex/>
    </div>
  )

}

export default ViewAllJobs

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'

import { USERS } from '../../root/action-types'
import Api from '../../api/call'
import UserRow from './UserRow'

class ManageUsers extends Component {

  componentDidMount() {
    Api({ request: USERS })
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.selected !== this.props.selected) {
      Api({ request: USERS })
    }
  }

  renderUsers = () => (
    get(this.props.userList, 'data', []).map(user => {
      return (
        <UserRow
          key={user.id}
          newId={get(this.props.selected, 'id')}
          user={user}
          editUser={this.props.editUser}
        />
      )
    }).reverse() // Show the most recently added users at the top.
  )

  render = () => (
    <div className="user-management-manage-users">
      <div className="header">
        <h3>Manage Users</h3>
      </div>
      <div className="header-rule" />
      {this.renderUsers()}
    </div>
  )
}

const mapStateToProps = ({ users: { index, user } }) => ({
  userList: index,
  selected: user,
})

export default connect(mapStateToProps)(ManageUsers)

import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import ModalWindow from '../layout/ModalWindow'
import { Loading } from '../generic/Loading'
import { Status } from '../forms'
import Api from '../api/call'
import { XERO_INVOICES_CREATE } from '../root/action-types'

import './post-invoice-button.scss'

export const Content = ({ id, cancel }) => {
  const { loading, byId, error }  = useSelector(redux => redux.xero.invoices)
  const [posted, setPosted] = useState(false)
  const post = () => {
    Api({ request: XERO_INVOICES_CREATE, data: { jobIds: [id] } })
    setPosted(true)
  }

  if (!posted) { return <Confirm post={post} cancel={cancel} /> }
  if (loading) { return <Loading /> }
  if (byId && byId[id]) { return <Result {...byId[id]} /> }
  return <Result error={error || 'Something went wrong...'} />
}

const Confirm = ({ post, cancel }) => (
  <>
    <div className="options">
      <button className="danger" onClick={cancel}>Cancel</button>
      <button className="primary" onClick={post}>Confirm</button>
    </div>
  </>
)

const Result = ({ error }) => (
  <Status isError={!!error}>
    {error || "Xero invoice created successfully!"}
  </Status>
)

const PostInvoiceButton = (props) => {
  const [modal, showModal] = useState(false)
  const close = () => showModal(false)

  return (
    <div className="xero-post-invoice-button">
      <ModalWindow
        className="post-invoice-modal"
        isOpen={modal}
        closeModal={close}
      >
        <h3>Post To Xero</h3>
        <span>Are you sure you want to post this job to Xero?</span>
        <Content {...props} cancel={close} />
      </ModalWindow>
      <button className="submit primary" onClick={() => showModal(true)}>
        Post To Xero
      </button>
    </div>
  )
}

export default PostInvoiceButton

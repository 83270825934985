import { combineReducers } from 'redux'

import {
  standardForCollections,
  standardShow,
  standardModelCreateUpdateDestroy
} from '../api/standard-reducers'

import invoices from './groupage/invoices/reducer'
import emails from './groupage/emails/reducer'
import financials from './groupage/financials/reducer'

export default combineReducers({
  index: standardForCollections('GROUPAGES'),
  show: standardShow('GROUPAGES_SHOW'),
  groupage: standardModelCreateUpdateDestroy('groupage', 'GROUPAGES'),
  groupageJobs: standardForCollections('GROUPAGE_JOBS'),
  invoices: invoices,
  emails,
  financials: financials,
})

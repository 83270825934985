import React, { useRef } from 'react'
import { startCase, isEqual } from 'lodash'
import { useDrag, useDrop } from 'react-dnd'
import DragNDropSVG from '../../root/global-assets/dragndrop.svg'

import './orderable-select.scss'

const TYPE = 'ORDERABLE_SELECT_VALUE'

const Bucket = ({ name, values, moveItem }) => (
  <div className={`bucket ${name}`}>
    <div className="drop-zone-header">{startCase(name)}</div>
    <div className="drop-zone">
      {values.map(v => <Item key={v} name={v} bucket={name} moveItem={moveItem} />)}
      <Item bucket={name} moveItem={moveItem} ghost />
    </div>
  </div>
)

const Item = ({ moveItem, ghost, ...props }) => {
  const ref = useRef(null)
  const [{ isDragging }, drag] = useDrag({
    item: { type: TYPE, ...props },
    collect: monitor => ({ isDragging: monitor.isDragging() })
  })
  const move = (dragged) => {
    ref.current && dragged.name != props.name && moveItem(dragged, props)
  }
  const [, drop] = useDrop({ accept: TYPE, hover: move, drop: move })
  ghost ? drop(ref) : drag(drop(ref))

  return (
    <span
      ref={ref}
      className={`${ghost ? 'ghost' : 'item'} ${isDragging ? 'dragging' : ''}`}
    >
      {startCase(props.name)}
    </span>
  )
}

const available = (options, value) => (
  options.filter(o => value.indexOf(o) == -1).sort()
)

let throttled = false

// Redrawing every millisecond causes performance issues, and isn't necessary
const throttle = () => {
  if (throttled) { return true }
  throttled = true
  setTimeout(() => throttled = false, 50)
  return false
}

const DragNDropCenter = () => (
  <div className="drag-n-drop">
    <img src={DragNDropSVG} alt="Drag and Drop Icon" className="dnd-icon" />
    <span className="dnd-text">Drag and Drop</span>
  </div>
)

export const OrderableSelect = ({ options, value, onChange }) => {
  const moveItem = (dragged, replace) => {
    if (throttle()) { return }
    const next = value.filter(v => v != dragged.name)
    if (replace.bucket == 'selected') {
      const i = value.indexOf(replace.name)
      next.splice(i == -1 ? value.length : i, 0, dragged.name)
    }
    if (isEqual(value, next)) { return }
    onChange(next)
  }

  return (
    <div className="orderable-select">
      <Bucket name="selected" values={value} moveItem={moveItem} />
      <DragNDropCenter />
      <Bucket name="available" values={available(options, value)} moveItem={moveItem} />
    </div>
  )
}


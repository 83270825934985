import React, { Component } from 'react'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import enGB from 'date-fns/locale/en-GB'

registerLocale('en-GB', enGB)
setDefaultLocale('en-GB')

import 'react-datepicker/dist/react-datepicker.css'

import { Control } from '../ControlBar'
import WeekDayInput from './WeekDayInput'


class WeekDayPicker extends Component {
  render = () => {
    const { value, onChange, highlightDates } = this.props

    return (
      <Control>
        <DatePicker
          locale='en-GB'
          dateFormat='dd/MM/yyyy'
          customInput={<WeekDayInput {...this.props} />}
          selected={value}
          onChange={onChange}
          showWeekNumbers
          highlightDates={highlightDates}
          popperPlacement="top-end"
        />
      </Control>
    )
  }
}

export default WeekDayPicker

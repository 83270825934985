import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Tab, TabList, TabPanel } from 'react-tabs'
import TabsWrapper from '../../generic/TabsWrapper'
import { get } from 'lodash'

import Api from '../../api/call'
import {
  GROUPAGES_SHOW,
  GROUPAGES_UPDATE,
  GROUPAGES_DESTROY,
  GROUPAGES_UPDATE_RESET_STORE,
  GROUPAGES_DESTROY_RESET_STORE,
} from '../../root/action-types'

import { Status, formatErrors } from '../../forms/Status'
import { Loading } from '../../generic/Loading'

import Controls from './Controls'
import Sidebar from './Sidebar'
import General from './General'
import Billing from '../../line-items/Billing'
import JobsOnAGroupage from './jobs-on-a-groupage/JobsOnAGroupage'
import Invoices from './invoices/Invoices'
import Emails from './emails/Emails'
import Financials from './financials/Financials'

import './Groupage.scss'


class Groupage extends Component {

  state = {
    paramsId: '',
    edit: false,
  }

  toggleEdit = () => (
    this.setState(
      prev => ({ edit: !prev.edit }),
      () => this.fetchGroupageData()
    )
  )

  componentDidMount() {
    this.resetStores()
    this.setGroupageFromUrl()
  }

  componentDidUpdate = (prevProps) => {
    this.urlParamsIdHasChanged(prevProps) && this.setGroupageFromUrl()
    document.title = `Groupage ${get(this.props.show, 'data.reference', '')}`

    if (
      this.props.groupage.groupageHasUpdated
      && !prevProps.groupage.groupageHasUpdated
    ) {
      this.toggleEdit()
    }
  }

  urlParamsIdHasChanged = (prevProps) => (
    get(this.props.match, 'params.id') != get(prevProps.match, 'params.id')
  )

  setGroupageFromUrl = () => {
    this.setState(
      { paramsId: get(this.props.match, 'params.id') },
      () => this.fetchGroupageData()
    )
  }

  fetchGroupageData = () => (
    Api({
      request: GROUPAGES_SHOW,
      id: this.state.paramsId
    })
  )

  resetStores = () => {
    this.props.dispatch({
      type: GROUPAGES_UPDATE_RESET_STORE,
    })
    this.props.dispatch({
      type: GROUPAGES_DESTROY_RESET_STORE,
    })
  }

  groupageId = () => (
    get(this.props.show, 'data.id')
  )

  save = (attributes) => {
    this.resetStores()
    Api({
      request: GROUPAGES_UPDATE,
      id: this.groupageId(),
      data: attributes
    })
  }

  destroyApiRequest = () => {
    this.resetStores()
    Api({
      request: GROUPAGES_DESTROY,
      id: this.groupageId(),
    })
  }

  redirectToCompanies = () => {
    this.resetStores()
    return (
      <Redirect to="/groupages" />
    )
  }

  key = () => {
    const { id, updated_at } = this.props.show.data || {}
    const { edit } = this.state

    return `${id}-${updated_at}-${edit}`
  }

  inError = () => (
    !!get(this.props.groupage, 'errors')
  )

  collateErrorMessages = () => (
    formatErrors(get(this.props.groupage, 'errors', []))
  )

  billingProps = () => {
    const id = this.groupageId()
    return { parentType: "Groupage", parentId: id, key: `Groupage-billing-${id}` }
  }

  loading = () => (
    this.props.show.loading ? <Loading /> : null
  )

  renderGroupage = () => (
    <div className="groupage-container">

      <Controls
        {...this.props.show.data}
        edit={this.state.edit}
        editAction={this.toggleEdit}
        destroyAction={this.destroyApiRequest}
      />

      <div className="status-container">
        <Status isError={this.inError()}>
          {this.collateErrorMessages()}
        </Status>
      </div>

      <div className="body">
        <Sidebar key={this.key()} {...this.props.show.data} />
        <div className="main">
          <this.loading />
          <TabsWrapper
            edit={this.state.edit}
            toggleEdit={this.toggleEdit}
          >
            <TabList>
              <Tab>General</Tab>
              <Tab>Billing</Tab>
              <Tab>Jobs</Tab>
              <Tab>Invoices</Tab>
              <Tab>Emails</Tab>
              <Tab>Financials</Tab>
            </TabList>
            <TabPanel>
              <General
                edit={this.state.edit}
                key={this.key()}
                save={this.save}
                {...get(this.props.show, 'data')}
              />
            </TabPanel>
            <TabPanel>
              <Billing
                {...this.billingProps()}
                edit={this.state.edit}
                toggleEdit={this.toggleEdit}
              />
            </TabPanel>
            <TabPanel>
              <JobsOnAGroupage
                groupageData={get(this.props.show, 'data')}
                edit={true}
              />
            </TabPanel>
            <TabPanel>
              <Invoices
                groupageData={get(this.props.show, 'data')}
                edit={true}
              />
            </TabPanel>
            <TabPanel>
              <Emails
                groupageData={get(this.props.show, 'data')}
                edit={true}
              />
            </TabPanel>
            <TabPanel>
              <Financials
                groupageId={this.groupageId()}
              />
            </TabPanel>
          </TabsWrapper>
        </div>
      </div>
    </div>
  )

  render = () => (
    get(this.props.groupage, 'groupageDestroyed', false) ? (
      this.redirectToCompanies()
    ) : (
      this.renderGroupage()
    )
  )
}

const mapStateToProps = ({ groupages }) => ({
  show: groupages.show || {},
  groupage: groupages.groupage || {}
})

export default connect(mapStateToProps)(Groupage)

export const markedForEmailingSelector = (
  { groupages: { emails: { markedForEmailing } } }
) => {
  return markedForEmailing
}

export const jobsSelector = (
  { groupages: { emails: { jobs: { data } } } }
) => {
  return data
}

export const jobSelector = (
  { groupages: { emails: { jobs: { data } } } }, id
) => {
  return data.find(job => job.id == id) || {}
}

export const sendEmailsSelector = (
  { groupages: { emails: { sendEmails } } }
) => {
  return sendEmails
}

import React, { Component } from 'react'
import { SketchPicker } from 'react-color'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { PLANNER_UNIT_UPDATE } from '../../root/action-types'
import Api from '../../api/call'
import { columnWidthClass } from './Unit'

import './unit-icon.scss'

// https://sashat.me/2017/01/11/list-of-20-simple-distinct-colors/
const PRESETS = [
  'GoldenRod',
  '#e6194B',
  '#3cb44b',
  '#ffe119',
  '#4363d8',
  '#f58231',
  '#911eb4',
  '#42d4f4',
  '#f032e6',
  '#bfef45',
  '#fabebe',
  '#469990',
  '#e6beff',
  '#9A6324',
  '#800000',
  '#aaffc3',
  '#808000',
  '#ffd8b1',
  '#000075',
  '#a9a9a9',
  '#000000',
  'DodgerBlue',
  'IndianRed',
  'LightSlateGray',
]

const preventDrag = (e) => {
  e.preventDefault()
  e.stopPropagation()
}

export default class UnitIcon extends Component {

  state = {}

  componentDidMount = () => {
    document.addEventListener('click', this.clickOutside)
  }

  clickOutside = ({ target }) => {
    if (this.ref && this.state.open && this.closable && !this.ref.contains(target)) {
      this.setState({ open: false })
    }
  }

  setRef = (node) => {
    this.ref = node
  }

  update = ({ hex }) => {
    Api({
      request: PLANNER_UNIT_UPDATE,
      id: this.props.id,
      data: { color: hex }
    })
  }

  colorPicker = () => (
    <React.Fragment>
      <div className="overlay" />
      <div ref={this.setRef}>
        <SketchPicker
          presetColors={PRESETS}
          color={this.props.color || PRESETS[0]}
          onChangeComplete={this.update}
          disableAlpha
        />
      </div>
    </React.Fragment>
  )

  openPicker = () => {
    if (this.state.open) { return }
    this.setState({ open: !this.state.open })
    this.closable = false
    setTimeout(() => this.closable = true, 10)
  }

  style = () => ({
    backgroundColor: this.props.color || PRESETS[0],
    color: this.props.color || PRESETS[0],
  })

  render = () => (
    <span
      className={`planner-unit-icon ${columnWidthClass('icon')}`}
      onClick={this.openPicker}
      onDragStart={preventDrag}
      onMouseDown={preventDrag}
      style={this.style()}
    >
      <FontAwesomeIcon icon="box-open" />
      {this.state.open ? this.colorPicker() : null}
    </span>
  )

}

import React, { Component } from 'react'
import { startCase } from 'lodash'

import { Field, InputGroup, Conditional } from '../forms/'
import Api from '../api/call'
import {
  PACKAGE_TYPES_UPDATE,
  PACKAGE_TYPES_CREATE
} from '../root/action-types'


export default class Form extends Component {

  state = { ...this.props.fields }

  action = () => (
    this.state.id ? PACKAGE_TYPES_UPDATE : PACKAGE_TYPES_CREATE
  )

  actionName = () => (
    `${this.state.id ? 'Update' : 'Create'} Package Type`
  )

  field = ({ label, name, suffix, required, ...props }) => (
    <Field label={label || startCase(name)} required={required}>
      <InputGroup suffix={suffix}>
        <input
          name={name}
          value={this.state[name] || ''}
          onChange={this.setFormValue}
          disabled={!this.props.edit}
          type="number"
          {...props}
        />
      </InputGroup>
    </Field>
  )

  setFormValue = ({ target: { name, value } }) => {
    this.setState({ [name]: value })
  }

  submit = () => {
    Api({
      request: this.action(),
      id: this.state.id,
      data: this.state,
    })
  }

  render = () => (
    <div className="form">
      <div className="section details">
        <div className="header">
          <h3>Details</h3>
        </div>
        <div className="header-rule" />
        <div className="fields">
          <this.field name="type" type="text" required />
          <this.field name="weight" suffix="kg" />
          <this.field name="length" suffix="cm" />
          <this.field name="width" suffix="cm" />
          <this.field name="height" suffix="cm" />
        </div>
      </div>
      <Conditional if={this.props.edit}>
        <div className="controls">
          <button className="primary" onClick={this.submit}>{this.actionName()}</button>
        </div>
      </Conditional>
    </div>
  )
}

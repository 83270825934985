import React, { Component } from 'react'
import { DropTarget } from 'react-dnd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import DragAndDropJob from './DragAndDropJob'

import './jobs-drop-column.scss'


const types = 'drag-and-drop-job'

export const spec = {
  drop(props) {
    return { targetGroupage: props.targetGroupage }
  }
}

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  }
}

export class JobsDropColumn extends Component {

  renderDragAndDropItems = () => {
    const { jobsList } = this.props

    return (jobsList || []).map((job, index) =>
      <this.conditionallyRenderJob
        job={job}
        key={`dnd-job-${job.id}-${index}`}
      />
    )
  }

  conditionallyRenderJob = ({ job }) => {
    const { renderCondition } = this.props

    return renderCondition(job) ? this.renderJob(job) : null
  }

  renderJob = (job) => {
    const { canDrag, updateRequest } = this.props

    return (
      <DragAndDropJob
        {...job}
        canDrag={canDrag}
        updateRequest={updateRequest}
      />
    )
  }

  renderDropzoneInstructions = () => (
    this.props.dropzoneInstructions ? <this.dropzoneInstructions /> : null
  )

  dropzoneInstructions = () => (
    <div className="dropzone-instructions">
      <FontAwesomeIcon icon={['fas', 'exclamation-circle']} className="icon" />
      <span>{this.props.dropzoneInstructions}</span>
    </div>
  )

  render() {
    const { connectDropTarget, title } = this.props

    return (
      connectDropTarget(
        <div className="jobs-drop-column">
          <h4 className="drop-column-title">{title}</h4>
          <div className={"horizontal-rule"} />
          <hr className={"rule"} />
          <div className="dropzone">
            {this.renderDragAndDropItems()}
            {this.renderDropzoneInstructions()}
          </div>
        </div>
      )
    )
  }

}

export default DropTarget(types, spec, collect)(JobsDropColumn)


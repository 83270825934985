import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import ModalWindow from '../../layout/ModalWindow'
import RecipientSelect from '../../emails/RecipientSelect'

import { get } from 'lodash'

import { Loading } from '../../generic/Loading'
import { Status } from '../../forms'

import Api from '../../api/call'
import { JOBS_SEND_EMAIL } from '../../root/action-types'
import SlateEditor from '../../emails/SlateEditor'
import { html } from '../../emails/SlateEditorRules'
import { Content } from '../../xero/PostInvoiceButton'

import './email-modal.scss'

const ModalContent = (props) => {
  const { loading, error } = useSelector(redux => redux.emails.sendEmail)
  const [posted, setPosted] = useState(false)

  if (!get(props, 'email.recipient')) {return <NoContact /> }
  if (!posted) { return <EmailContent {...props} setPosted={setPosted} />}
  if (loading) { return <Loading /> }
  return ( <XeroContent {...props} error={error} /> )
}

const XeroContent = ({ parentId, cancel, error }) => (
  <div className="after-email-modal">
    <EmailResult error={error} />
    <h3>Post To Xero</h3>
    <div className="after-message">Would you like to post this job to Xero?</div>
    <Content id={parentId} cancel={cancel} />
  </div>
)

const EmailContent = ({ parentId, companyId, email, cancel, setPosted }) => {
  const [emailBody, setEmailBody] = useState(html.deserialize(email.body))
  const [recipient, setRecipient] = useState([{value: email.recipient}])
  const onChange = editor => setEmailBody(editor.value)
  const onRecipientChange = r => setRecipient(r)
  const body = html.serialize(emailBody)
  const post = () => {
    Api({
      request: JOBS_SEND_EMAIL,
      params: { id: parentId, body, recipientArray, pdfType: "invoice", template: "invoice" } }
    )
    setPosted(true)
  }


  let recipientArray = []
  if (recipient) { recipientArray = recipient.map(x => x.value) }

  return (
    <React.Fragment>
      <h4>{email.subject}</h4>
      <RecipientSelect
        recipient={email.recipient}
        companyId={companyId}
        onChange={onRecipientChange} />
      <SlateEditor emailBody={emailBody} onChange={onChange} />
      <EmailOptions post={post} close={cancel} />
    </React.Fragment>
  )
}

const EmailOptions = ({ post, close }) => {
  const [confirmed, setConfirmed] = useState(false)
  const confirm = () => setConfirmed(true)
  const cancel = () => setConfirmed(false)

  if (!confirmed) {
    return (
      <div className="options">
        <button className="danger" onClick={close}>Cancel</button>
        <button className="primary" onClick={confirm}>Send Email</button>
      </div>
    )
  }
  return <Confirm cancel={cancel} post={post} />
}

const Confirm = ({ cancel, post }) => (
  <div className="after-options">
    <span>Are you sure?</span>
    <button className="primary" onClick={post}>
      Yes, Send it
    </button>
    <button className="danger" onClick={cancel}>
      Cancel
    </button>
  </div>
)

const EmailResult = ({ error }) => (
  <Status isError={!!error}>
    {error || "Email sent!"}
  </Status>
)

const NoContact = () => (
  <Status isError>
    No Primary Email Address!
  </Status>
)

const InvoiceTemplate = (props) => {
  const [modal, showModal] = useState(props.isOpen)
  const close = () => showModal(props.resetState)

  useEffect(() => { showModal(props.isOpen) }, [props.isOpen])

  return (
    <ModalWindow className="email-modal"
      isOpen={modal} closeModal={close} >
      <ModalContent {...props} cancel={close} />
    </ModalWindow>
  )
}

export default InvoiceTemplate

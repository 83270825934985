import React, { Component } from 'react'
import { Tabs } from 'react-tabs'
import TabChangeConfirmationModal from './TabChangeConfirmationModal'

class TabsWrapper extends Component {

  state = {
    tabIndex: 0,
    requestedTabChange: 0,
    tabChangeModalOpen: false
  }

  openTabChangeModal = () => (
    this.setState({ tabChangeModalOpen: true })
  )

  closeTabChangeModal = () => (
    this.setState({ tabChangeModalOpen: false })
  )

  changeTab = (tabIndex) => {
    this.setState({ tabIndex: tabIndex })
  }

  proceed = () => {
    this.props.toggleEdit()
    this.changeTab(this.state.requestedTabChange)
  }

  onTabSelect = (tabIndex) => {
    this.setState({ requestedTabChange: tabIndex })
    if (this.props.edit) {
      this.openTabChangeModal(tabIndex)
    } else {
      this.changeTab(tabIndex)
    }
  }

  renderChangeConfirmationModal = () => (
    <TabChangeConfirmationModal
      modalIsOpen={this.state.tabChangeModalOpen}
      closeModal={this.closeTabChangeModal}
      proceed={this.proceed}
    />
  )

  render = () => (
    <React.Fragment>
      <this.renderChangeConfirmationModal />
      <Tabs selectedIndex={this.state.tabIndex} onSelect={this.onTabSelect}>
        {this.props.children}
      </Tabs>
    </React.Fragment>
  )

}

export default TabsWrapper

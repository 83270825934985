import { combineReducers } from 'redux'

import {
  TARIFFS_DISTRIBUTION,
  TARIFFS_DISTRIBUTION_NEW,
  TARIFFS_DISTRIBUTION_SHOW,
  TARIFFS_DISTRIBUTION_CREATE,
  TARIFFS_DISTRIBUTION_UPDATE,
  TARIFFS_DISTRIBUTION_DESTROY,
} from  '../../root/action-types'
import { countryName } from '../../addresses/countries'

const addCountryName = (tariff) => {
  tariff.countryName = countryName(tariff.country)
  return tariff
}

const INITIAL_INDEX = { data: [], total: 0, count: 0, loading: true }

export const index = (state = INITIAL_INDEX, action) => {
  switch (action.type) {
    case `${TARIFFS_DISTRIBUTION}_START`:
      return {
        ...INITIAL_INDEX,
      }
    case `${TARIFFS_DISTRIBUTION}_SUCCESS`:
      return {
        ...state,
        data: action.response.data.map(addCountryName),
        loading: false,
      }
    case `${TARIFFS_DISTRIBUTION}_FAILURE`:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}

const INITIAL_SHOW = { loading: false, details: {}, status: [] }

export const show = (state = INITIAL_SHOW, action) => {
  switch (action.type) {
    case TARIFFS_DISTRIBUTION_NEW:
      return { ...INITIAL_SHOW }
    case `${TARIFFS_DISTRIBUTION_SHOW}_START`:
      return {
        ...INITIAL_SHOW,
        loading: true,
      }
    case `${TARIFFS_DISTRIBUTION_SHOW}_SUCCESS`:
      return {
        ...state,
        details: action.response.data,
        loading: false,
      }
    case `${TARIFFS_DISTRIBUTION_CREATE}_START`:
    case `${TARIFFS_DISTRIBUTION_UPDATE}_START`:
    case `${TARIFFS_DISTRIBUTION_DESTROY}_START`:
      return {
        ...state,
        loading: true,
        status: [],
        hasErrors: false,
      }
    case `${TARIFFS_DISTRIBUTION_UPDATE}_SUCCESS`:
      return {
        ...state,
        loading: false,
        details: action.response.data,
        status: ['Tariff updated successfully!'],
      }
    case `${TARIFFS_DISTRIBUTION_CREATE}_SUCCESS`:
      return {
        ...state,
        loading: false,
        details: action.response.data,
        status: ['Tariff created successfully!'],
      }
    case `${TARIFFS_DISTRIBUTION_SHOW}_FAILURE`:
    case `${TARIFFS_DISTRIBUTION_CREATE}_FAILURE`:
    case `${TARIFFS_DISTRIBUTION_UPDATE}_FAILURE`:
    case `${TARIFFS_DISTRIBUTION_DESTROY}_FAILURE`:
      return {
        ...state,
        loading: false,
        hasErrors: true,
        details: action.response.data.details,
        status: action.response.data.errors,
      }
    case `${TARIFFS_DISTRIBUTION_DESTROY}_SUCCESS`:
      return {
        ...state,
        loading: false,
        destroyed: true,
      }
    default:
      return state
  }
}

export default combineReducers({
  index,
  show,
})

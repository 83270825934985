import React, { Component } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { get, omit } from 'lodash'

import {
  COMPANIES_SEARCH,
  COMPANIES_CREATE,
  COMPANIES_CREATE_RESET_STORE
} from '../../root/action-types'
import Api from '../../api/call'

import ModalWindow from '../../layout/ModalWindow'
import { Field } from '../Field'
import { Status, formatErrors } from '../Status'
import CompanyName from '../../companies/Name'

import './NewOptionSelect.scss'


const BLANK_COMPANY = {
  name: '',
}

const options = (data) => (
  data.map(c => (
    { value: c.id, label: c.label }
  ))
)

class CompanySelect extends Component {

  state = {
    limit: 10,
    offset: 0,
    customer: !!this.props.customer || null,
    supplier: !!this.props.supplier || null,
    pickupAndDistribution: !!this.props.pickupAndDistribution || null,
    freightForwarder: !!this.props.freightForwarder || null,
    search: '',
    ...BLANK_COMPANY
  }

  closeAfterTimeout = () => {
    setTimeout(this.closeCreateModal, 750)
  }

  openCreateModal = () => (
    this.setState({ createModalOpen: true })
  )

  closeCreateModal = () => {
    this.resetForm()
    if (this.props.company.companyCreated) {
      this.props.onChange({ value: this.props.company.data.id })
    }
    this.dispatchCompanyStoreReset()
    this.setState({ createModalOpen: false })
  }

  resetForm = () => (
    this.setState(prevState => ({ ...prevState, ...BLANK_COMPANY }))
  )

  setValue = ({ target: { name, value } }) => (
    this.setState({ [name]: value })
  )

  inError = () => (
    get(this.props.company, 'errors', []).length != 0
  )

  requestCompanies = () => {
    Api({
      request: COMPANIES_SEARCH,
      params: { ...omit(this.state, Object.keys('BLANK_COMPANY')) }
    })
  }

  createCompanyApiRequest = () => (
    Api({
      request: COMPANIES_CREATE,
      data: {
        ...omit(
          this.state,
          ['limit','offset','search']
        )
      },
    })
  )

  dispatchCompanyStoreReset = () => (
    this.props.dispatch({
      type: COMPANIES_CREATE_RESET_STORE,
    })
  )

  getOption = () => {
    const { search: { results }, value } = this.props
    return options(results || []).find(o => o.value == value)
  }

  getName = () => {
    const { value } = this.props
    return value ? { label: <CompanyName id={value} />, value } : null
  }

  currentValue = () => (
    this.getOption() || this.getName()
  )

  wrapper = ({ children }) => {
    if (this.props.omitWrapper) { return children }
    const { required, error, forceError } = this.props

    return (
      <Field
        label={this.props.label}
        required={required}
        error={error}
        forceError={forceError}
      >
        <div className="children-container">{children}</div>
      </Field>
    )
  }

  onInputChange = (value) => {
    this.setState({ search: value })
    this.queueUpdate()
  }

  queueUpdate = () => {
    clearTimeout(this.timeoutUpdate)
    this.timeoutUpdate = setTimeout(this.requestCompanies, 200)
  }

  createOption = () => {
    if (!this.props.isDisabled && this.props.allowCreation) {
      return (
        <button
          className="open-new-modal"
          onClick={this.openCreateModal}>
          <FontAwesomeIcon icon={['fas', 'plus-circle']} className="icon" />
        </button>
      )
    }
    return null
  }

  saveCompany = () => {
    this.createCompanyApiRequest()
  }

  saveButtonOrSuccessStatus = () => {
    if (this.inError()) {
      return (
        <Status isError={this.inError()}>
          {formatErrors(this.props.company.errors)}
        </Status>
      )
    }
    if (this.props.company.companyCreated) {
      {this.closeAfterTimeout()}
      return (
        <Status>
          Company Created!
        </Status>
      )
    }
    return (
      <button className="create-option" onClick={this.saveCompany}>
        Save
      </button>
    )
  }

  renderCreateModal = () => (
    <ModalWindow
      isOpen={this.state.createModalOpen}
      closeModal={this.closeCreateModal}
    >
      <h3>New {this.props.label}</h3>
      <div className="select-modal-form-container">
        <Field label={"Company Name"}>
          <input
            name={'name'}
            value={this.state.name}
            onChange={this.setValue}
            placeholder={'Enter Name...'}
          />
        </Field>
        <div className="options">
          <this.saveButtonOrSuccessStatus />
        </div>
      </div>
    </ModalWindow>
  )

  render = () => {
    const { isDisabled, onChange } = this.props
    const { results, loading } = this.props.search

    return (
      <this.wrapper>
        <this.renderCreateModal />
        <Select
          classNamePrefix="select"
          className={`select ${this.props.allowCreation && !this.props.isDisabled ? 'make-room' : null}`}
          value={this.currentValue()}
          options={options(results || [])}
          isDisabled={isDisabled}
          isLoading={loading}
          onChange={onChange}
          onMenuOpen={() => this.queueUpdate(0)}
          onMenuClose={() => clearTimeout(this.timeoutUpdate)}
          onInputChange={this.onInputChange}
        />
        {this.createOption()}
      </this.wrapper>
    )
  }
}

const mapStateToProps = ({ companies: { search, company } }) => (
  { search, company }
)

export default connect(mapStateToProps)(CompanySelect)

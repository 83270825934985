import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { LOGOUT } from '../root/action-types'

import './Topbar.scss'

export class Logout extends Component {
  logout = () => {
    this.props.dispatch({ type: LOGOUT, })
  }

  render = () => (
    <div className="topbar-item topbar-logout" onClick={this.logout}>
      <FontAwesomeIcon icon={['fas', 'sign-out-alt']} fixedWidth />
    </div>
  )
}

export default connect()(Logout)

import React from 'react'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

import { Status } from '../../../forms/Status'

import { jobsSelector, sendInvoicesSelector } from './redux-selectors'


const xeroErrorsObject = (xeroResponses) => {
  const jobs = useSelector(jobsSelector)

  // { jobReference: "error for this job", }
  return (
    Object.keys(xeroResponses || [])
      .filter(id => xeroResponses[id].error != null)
      .reduce((obj, id) => {
        const job = jobs.find(job => job.id == id)
        obj[job.reference] = xeroResponses[id].error
        return obj
      }, {})
  )
}

const renderErrors = (errors) => (
  Object.keys(errors).map(ref =>
    <span key={`e-${ref}`}>
      {`${ref} : ${errors[ref]}`}
    </span>
  )
)

const statusMessage = (errors) => {
  if (isEmpty(errors)) return null
  return <div className="status-messages">{renderErrors(errors)}</div>
}

const InvoiceRequestStatus = () => {
  const sendInvoices = useSelector(sendInvoicesSelector)
  const { loading, xeroResponses } = sendInvoices
  const errors = xeroErrorsObject(xeroResponses)

  return (
    <Status
      key={`status-loading-${loading}`}
      isError={!isEmpty(errors)}
      className="invoice-request-status"
    >
      {loading ? 'Loading...' : statusMessage(errors)}
    </Status>
  )
}

export default InvoiceRequestStatus

import React, { Component } from 'react'
import { get } from 'lodash'
import Select from 'react-select'
import Toggle from 'react-toggle'

import { Field } from '../forms/Field'
import { countrySelectOptions } from './countries'
import { GrowingFieldList } from '../forms/GrowingFieldList'

import './address-form.scss'


export default class AddressForm extends Component {

  formInError = () => (
    this.props.formError
  )

  toggle = ({name, label}) => (
    <Field label={label}>
      <Toggle
        name={name}
        checked={this.props.address[name]}
        onChange={this.props.onChangeToggle}
        icons={false}
      />
    </Field>
  )

  formInput = ({ name, label, error=false, required=false, ...props }) => (
    <Field
      label={label}
      error={error}
      forceError={this.formInError() && required}
      required={required}
    >
      <input
        name={name}
        value={this.props.address[name] || ''}
        onChange={this.props.onChange}
        type="text"
        placeholder={`Enter ${label}`}
        {...props}
      />
    </Field>
  )

  lineInput = (props) => (
    <this.formInput
      onChange={this.setLine}
      value={get(this.props.address.lines, `[${props.name}]`, '')}
      {...props}
    />
  )

  setLine = ({ target: { name, value } }) => {
    const { address, onChange } = this.props

    const lines = [...(address.lines || [])]
    lines[name] = value
    onChange({ target: { name: 'lines', value: lines } })
  }

  getCurrentSelectValue = () => (
    countrySelectOptions.find(c => c.value == this.props.address.countryCode)
  )

  numberOfAddressLinesPresent = () => {
    const { address } = this.props

    return address.lines ? address.lines.length : 1
  }

  render = () => (
    <div className="address-form">

      <GrowingFieldList childrenToShow={this.numberOfAddressLinesPresent()}>
        <this.lineInput
          name="0"
          label={'Line One'}
          error={get(this.props.address.lines, '[0]') == ''}
          required={true}
        />

        <this.lineInput name="1" label={'Line Two'}/>
        <this.lineInput name="2" label={'Line Three'} />
        <this.lineInput name="3" label={'Line Four'} />
      </GrowingFieldList>

      <div className="double-input">
        <this.formInput name={'postcode'} label={'Postcode'} />
        <Field
          label="Country"
          error={get(this.props.address, 'countryCode') == ''}
          forceError={this.formInError()}
          required={true}
        >
          <Select
            classNamePrefix="select"
            className="select"
            value={this.getCurrentSelectValue()}
            onChange={this.props.onChangeCountry}
            options={countrySelectOptions}
          />
        </Field>
      </div>

      <div className="double-input">
        <this.toggle name={'office'} label={'Office?'} />
        <this.toggle name={'warehouse'} label={'Warehouse?'} />
      </div>
    </div>
  )
}

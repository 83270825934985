import adminSettings from '../admin/settings/reducer'
import standardAPI from '../api/standard-reducers'
import calculator from '../calculator/reducer'
import users from '../users/reducers'
import resetPassword from '../users/password/reducers'
import tariffs from '../tariffs/reducer'
import companies from '../companies/reducer'
import lineItems from '../line-items/reducer'
import convertQuote from '../quotes/quote/reducer'
import quotes from '../quotes/reducer'
import jobs from '../jobs/reducer'
import groupages from '../groupages/reducer'
import quickFind from '../topbar/quick-find/reducer'
import emails from '../emails/reducers'
import planner from '../planner/reducer'
import { download } from '../api/download'
import xero from '../xero/reducer'
import templates from '../templates/reducer'
import packageTypes from '../package-types/reducer'

export const reducers = {
  adminSettings,
  download,
  users,
  tariffs,
  companies,
  lineItems,
  quotes,
  jobs,
  emails,
  groupages,
  convertQuote,
  quickFind,
  planner,
  xero,
  templates,
  packageTypes,
  ...standardAPI,
  ...calculator,
  ...resetPassword,
}

import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Api from '../../../api/call'
import { Loading, BasicTable } from '../../../generic/'
import CompanyName from '../../../companies/Name'
import ActionTypes from '../../../root/action-types'

import './groupage-financials.scss'

const { GROUPAGE_FINANCIALS } = ActionTypes

export const fetchData = (groupageId) => {
  groupageId && Api({ request: GROUPAGE_FINANCIALS, id: groupageId })
}

export const financialsSelector = (
  { groupages: { financials: { loading, byId } } }, id
) => { return { loading, ...byId[id] } || {} }

const COLUMNS = [
  { accessor: "reference" },
  { accessor: "customer", flex: 2 },
  { accessor: "cubicMetres", Header: "cube" },
  { accessor: "salesTotal", Header: "job sales" },
  { accessor: "costTotal", Header: "job cost" },
  { accessor: "groupageCost", Header: "FREIGHT" },
  { accessor: "profit", Header: "profit" },
  { accessor: "normalisedProfit", Header: "profit per cube" },
]

const tableData = (data) => (
  (data || []).map(d => {
    const overallProfit = formatToCurrency(d.profit - d.groupageCost)
    return {
      ...d,
      reference: <Link to={`/jobs/${d.id}`}>{d.reference}</Link>,
      customer: <CompanyName id={d.customerId} />,
      salesTotal: formatToCurrency(d.salesTotal),
      costTotal: formatToCurrency(d.costTotal),
      profit: overallProfit,
      normalisedProfit: formatToCurrency(overallProfit / d.cubicMetres),
    }
  })
)

const formatToCurrency = (f) => f && f.toFixed(2)

const Financials = (props) => {
  const { groupageId } = props
  useEffect(() => fetchData(groupageId), [])
  const { jobs, loading } = useSelector(state => financialsSelector(state, groupageId))

  if (loading) { return <Loading /> }

  return (
    <div className="groupage-financials">
      <JobsSummary jobs={jobs} />
    </div>
  )
}

const JobsSummary = ({ jobs }) => (
  <div>
    <h4>Jobs Summary</h4>
    <BasicTable columns={COLUMNS} data={tableData(jobs)} />
  </div>
)

export default Financials
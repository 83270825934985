import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Tab, TabList, TabPanel } from 'react-tabs'
import TabsWrapper from '../../generic/TabsWrapper'
import { get } from 'lodash'

import Api from '../../api/call'
import {
  QUOTES_SHOW,
  QUOTES_UPDATE,
  QUOTES_DESTROY,
  QUOTES_UPDATE_RESET_STORE,
  QUOTES_DESTROY_RESET_STORE,
  CONVERT_QUOTE_TO_JOB,
  QUOTES_EMAIL_TEMPLATE,
} from '../../root/action-types'

import { Status, formatErrors } from '../../forms/Status'
import { Loading } from '../../generic/Loading'

import Controls from './Controls'
import Sidebar from '../../generic/Sidebar'
import SidebarCompanyNotes from '../../generic/SidebarCompanyNotes'
import General from './General'
import Billing from '../../line-items/Billing'
import Consignment from './Consignment'
import EmailsTab from '../../emails/EmailsTab'

import './Quote.scss'


class Quote extends Component {

  state = {
    paramsId: '',
    edit: false,
  }

  toggleEdit = () => (
    this.setState(
      prev => ({ edit: !prev.edit }),
      () => this.fetchQuoteData()
    )
  )

  componentDidMount() {
    this.resetStores()
    this.setQuoteFromUrl()
  }

  componentDidUpdate = (prevProps) => {
    this.urlParamsIdHasChanged(prevProps) && this.setQuoteFromUrl()
    document.title = `Quote ${get(this.props.show, 'data.reference', '')}`

    if (
      this.props.quote.quoteHasUpdated
      && !prevProps.quote.quoteHasUpdated
    ) {
      this.toggleEdit()
    }
  }

  urlParamsIdHasChanged = (prevProps) => (
    get(this.props.match, 'params.id') != get(prevProps.match, 'params.id')
  )

  setQuoteFromUrl = () => {
    this.setState(
      { paramsId: get(this.props.match, 'params.id') },
      () => this.fetchQuoteData()
    )
  }

  fetchQuoteData = () => {
    Api({ request: QUOTES_SHOW, id: this.state.paramsId })
    Api({ request: QUOTES_EMAIL_TEMPLATE, params: { id: this.state.paramsId } })
  }

  resetStores = () => {
    this.props.dispatch({
      type: 'CONVERT_QUOTE_TO_JOB_RESET_STORE',
    })
    this.props.dispatch({
      type: QUOTES_UPDATE_RESET_STORE,
    })
    this.props.dispatch({
      type: QUOTES_DESTROY_RESET_STORE,
    })
  }

  convertToJob = () => (
    this.convertApiRequest()
  )

  convertApiRequest = () => {
    this.resetStores()
    Api({
      request: CONVERT_QUOTE_TO_JOB,
      data: { id: get(this.props.show.data, 'id') }
    })
  }

  updateApiRequest = (attributes) => {
    this.resetStores()
    Api({
      request: QUOTES_UPDATE,
      id: get(this.props.show.data, 'id'),
      data: attributes,
    })
  }

  destroyApiRequest = () => {
    this.resetStores()
    Api({
      request: QUOTES_DESTROY,
      id: get(this.props.show.data, 'id'),
    })
  }

  redirectToCompanies = () => {
    this.resetStores()
    return (
      <Redirect to="/quotes" />
    )
  }

  redirectToJob = () => {
    this.resetStores()
    return (
      <Redirect to={`/jobs/${this.props.convertQuote.details.jobId}?editMode`} />
    )
  }

  key = () => {
    const { id, updated_at } = this.props.show.data || {}
    const { edit } = this.state

    return `${id}-${updated_at}-${edit}`
  }

  inError = () => (
    !!get(this.props.quote, 'errors')
    || !!get(this.props.convertQuote, 'errors')
  )

  collateErrorMessages = () => (
    formatErrors(
      [
        ...get(this.props.quote, 'errors', []),
        ...get(this.props.convertQuote, 'errors', [])
      ]
    )
  )

  billingProps = () => {
    const id = get(this.props.show, 'data.id')
    return { parentType: "Quote", parentId: id, key: `Quote-billing-${id}` }
  }

  statusIsConverted = () => (
    get(this.props.show.data, 'status', '') == 'Converted'
  )

  loading = () => (
    this.props.show.loading ? <Loading /> : null
  )

  renderQuote = () => (
    <div className="quote-container">

      <Controls
        {...this.props.show.data}
        convertAction={this.convertToJob}
        edit={this.state.edit}
        editAction={this.toggleEdit}
        disableEdit={this.statusIsConverted()}
        destroyAction={this.destroyApiRequest}
        email={this.props.email}
      />

      <div className="status-container">
        <Status isError={this.inError()}>
          {this.collateErrorMessages()}
        </Status>
      </div>

      <div className="body">
        <Sidebar
          title={`Quote ${get(this.props.show, 'data.reference')}`}
          id={get(this.props.show, 'data.customerId')}
        >
          <SidebarCompanyNotes
            id={get(this.props.show, 'data.customerId')}
            companyRole={'Customer'}
            category={'sales'}
          />
        </Sidebar>
        <div className="main">
          <this.loading />
          <TabsWrapper
            edit={this.state.edit}
            toggleEdit={this.toggleEdit}
          >
            <TabList>
              <Tab>General</Tab>
              <Tab>Consignment</Tab>
              <Tab>Billing</Tab>
              <Tab>Emails</Tab>
            </TabList>
            <TabPanel>
              <General
                edit={this.state.edit}
                key={this.key()}
                updateApiRequest={this.updateApiRequest}
              />
            </TabPanel>
            <TabPanel>
              <Consignment
                data={get(this.props.show, 'data')}
                edit={this.state.edit}
                key={this.key()}
                updateApiRequest={this.updateApiRequest}
              />
            </TabPanel>
            <TabPanel>
              <Billing
                {...this.billingProps()}
                edit={this.state.edit}
                toggleEdit={this.toggleEdit}
              />
            </TabPanel>
            <TabPanel>
              <EmailsTab
                parentId={get(this.props, 'show.data.id')}
                key={this.key()} />
            </TabPanel>
          </TabsWrapper>
        </div>
      </div>
    </div>
  )

  render = () => {
    if (get(this.props.quote, 'quoteDestroyed', false)) {
      return (
        this.redirectToCompanies()
      )
    } else if (get(this.props.convertQuote.details, 'jobId', false)) {
      return (
        this.redirectToJob()
      )
    }
    return (
      this.renderQuote()
    )
  }
}

const mapStateToProps = ({ quotes, convertQuote, }) => ({
  show: quotes.show || {},
  quote: quotes.quote || {},
  email: quotes.email || {},
  convertQuote: convertQuote || {},
})

export default connect(mapStateToProps)(Quote)

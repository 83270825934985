import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './Accordion.scss'

export default class AccordionItem extends Component {
  state = {
    open: this.props.open || false,
  }

  toggleDetails = () => (
    this.setState(prevState => ({ open: !prevState.open }))
  )

  renderDetails = () => (
    <div className="item-details">
      {this.props.children}
    </div>
  )

  renderStatusIcon = () => {
    const icon = (this.state.open ? 'minus-circle' : 'plus-circle')
    return (
      <span className={'icon'}>
        <FontAwesomeIcon icon={['fas', `${icon}`]} />
      </span>
    )
  }

  render = () => (
    <div className="item">
      <div className="item-name" onClick={this.toggleDetails}>
        {this.props.name}
        {this.renderStatusIcon()}
      </div>
      {this.state.open ? this.renderDetails() : null}
    </div>
  )
}

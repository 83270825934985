import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get, pick } from 'lodash'

import Api from '../../../api/call'
import {
  GROUPAGE_JOBS,
  JOBS_UPDATE,
  JOBS_UPDATE_RESET_STORE,
} from '../../../root/action-types'

import JobsDropColumn from './JobsDropColumn'
import { Status } from '../../../forms/Status'

import './jobs-on-a-groupage.scss'


export class JobsOnAGroupage extends Component {

  state = {
    groupageId: get(this.props.groupageData, 'id'),
    ...pick(this.props.groupageData,
      [
        'originCountryCode',
        'destinationCountryCode'
      ]
    )
  }

  componentDidMount = () => {
    this.resetJobUpdateStore()
    this.fetchJobs()
  }

  componentDidUpdate = (prevProps) => {
    if (
      !prevProps.job.jobHasUpdated
      && this.props.job.jobHasUpdated
    ) {
      this.fetchJobs()
    }
  }

  resetJobUpdateStore = () => (
    this.props.dispatch({
      type: JOBS_UPDATE_RESET_STORE,
    })
  )

  fetchJobs = () => (
    Api({ request: GROUPAGE_JOBS, params: { ...this.state } })
  )

  updateJobGroupageId = (jobId, groupageId) => {
    this.resetJobUpdateStore()
    Api({
      request: JOBS_UPDATE,
      id: jobId,
      data: { groupageId: groupageId }
    })
  }

  jobBelongsToThisGroupage = ({ groupageId }) => (
    groupageId == this.state.groupageId
  )

  status = () => (
    get(this.props, 'groupageData.status')
  )

  statusIsActive = () => (
    this.status() == 'Active'
  )

  unassignedColumnRenderCondition = ({ groupageId }) => (
    (groupageId == null) && this.statusIsActive() 
  )

  groupageColumnInstructions = () => (
    this.statusIsActive() ? 'You can drag additional Jobs here' : ''
  )

  renderGroupageJobsColumn = () => (
    <JobsDropColumn
      jobsList={this.props.groupageJobs}
      updateRequest={this.updateJobGroupageId}
      canDrag={this.statusIsActive()}
      targetGroupage={this.state.groupageId}
      renderCondition={this.jobBelongsToThisGroupage}
      title={'Jobs assigned to this groupage'}
      dropzoneInstructions={this.groupageColumnInstructions()}
    />
  )

  unassignedColumnInstructions = () => (
    this.statusIsActive() ? '' : `
     Jobs cannot be added to (or removed from) this groupage,
     because it has been marked as ${this.status()}.
    `
  )

  renderUnassignedJobsColumn = () => (
    <JobsDropColumn
      jobsList={this.props.groupageJobs}
      updateRequest={this.updateJobGroupageId}
      canDrag={this.statusIsActive()}
      targetGroupage={null}
      renderCondition={this.unassignedColumnRenderCondition}
      title={'Unassigned Jobs with matching job type'}
      dropzoneInstructions={this.unassignedColumnInstructions()}
    />
  )

  updateErrors = () => (
    get(this.props, 'job.errors', [])
  )

  renderJobUpdateStatus = () => (
    <Status isError={this.updateErrors().length > 0}>
      {this.updateErrors()}
    </Status>
  )

  render = () => (
    <div className="jobs-on-a-groupage-tab">
      <this.renderJobUpdateStatus />
      <div className="job-columns-container">
        <this.renderGroupageJobsColumn />
        <this.renderUnassignedJobsColumn />
      </div>
    </div>
  )
}

const mapStateToProps = ({ jobs: { job }, groupages: { groupageJobs } }) => ({
  groupageJobs: groupageJobs.data || [],
  job: job || {}
})

export default connect(mapStateToProps)(JobsOnAGroupage)

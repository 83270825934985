import React, { Component } from 'react'
import { connect } from 'react-redux'
import { pick, startCase } from 'lodash'

import { Field, SimpleSelect } from '../../forms/'
import { CheckBox } from '../../forms/CheckBox'

import './general.scss'

const STATUS = ['Active', 'Downtrader']
const LANGUAGES = ['English', 'Italian']

export class General extends Component {

  state = {
    ...pick(this.props, [
      'name',
      'status',
      'pickupAndDistribution',
      'freightForwarder',
      'customer',
      'supplier',
      'language',
    ]),
  }

  setFormValue = ({ target: { name, value } }) => (
    this.setState({ [name]: value })
  )

  setToggle = ({ target: { name } }) => {
    this.setState({ [name]: !this.state[name] })
  }

  field = ({ label, name, ...props }) => (
    <Field label={label}>
      <input
        name={name}
        value={this.state[name] || ''}
        onChange={this.setFormValue}
        disabled={!this.props.edit}
        {...props}
      />
    </Field>
  )

  toggle = ({ label, name }) => (
    <Field>
      <CheckBox
        name={name}
        label={label}
        value={this.state[name]}
        onChange={this.setToggle}
        disabled={!this.props.edit}
      />
    </Field>
  )

  changeSelect = (name) => (
    (value) => { this.setState({ [name]: value }) }
  )

  multiSelect = ({ name, options, ...props }) => (
    <SimpleSelect
      label={startCase(name)}
      value={this.state[name]}
      onChange={this.changeSelect(name)}
      options={options}
      isDisabled={!this.props.edit}
      {...props}
    />
  )

  editControls = () => (
    this.props.edit ? <this.controls /> : null
  )

  controls = () => (
    <div className="section controls">
      <button className="submit" onClick={this.save}>Save</button>
    </div>
  )

  save = () => (
    this.props.updateApiRequest(this.state)
  )

  render = () => (
    <div className="company-general">
      <div className="section details">
        <h4>Company Details</h4>
        <div className="fields">
          <this.field label="Display Name" name="name" />
          <this.multiSelect name="status" options={STATUS}/>
          <this.multiSelect name="language" options={LANGUAGES} placeholder="English" />
        </div>
      </div>
      <div className="toggles-section">
        <h4>Company Types</h4>
        <div className="toggles">
          <this.toggle label="Customer" name="customer" />
          <this.toggle label="Supplier" name="supplier" />
          <this.toggle label="Forwarder" name="freightForwarder" />
          <this.toggle label="Pickup / Distribution" name="pickupAndDistribution" />
        </div>
      </div>
      <this.editControls />
    </div>
  )
}

const mapStateToProps = ({ companies }) => ({
  ...companies.show.data || {}
})

export default connect(mapStateToProps)(General)

import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { startCase } from 'lodash'

import Api from '../../api/call'

import PagedIndexTable from './PagedIndexTable'


const ModelIndexTable = ({
  model,
  dataMap,
  requestAction,
  COLUMN_FLEX,
  LOCAL_STORAGE_KEY,
  COLUMNS,
  DEFAULT_COLUMNS,
  DEFAULT_SORT,
  DEFAULT_SORT_ASCENDING,
  CATEGORIES,
  SORT_OPTIONS,
}) => {

  const loadColumns = () => {
    try {
      return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)).filter(
        c => COLUMNS.indexOf(c) > -1
      )
    } catch (e) { return DEFAULT_COLUMNS }
  }

  const initialColumns = loadColumns()
  const [columns, setColumnsState] = useState(initialColumns)
  const selector = ({ [model]: { index } }) => { return index }
  const { data, count, total } = useSelector(selector)

  const setColumns = (columns) => {
    setColumnsState(columns)
    saveColumns(columns)
  }

  const saveColumns = (columns) => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(columns))
  }

  useEffect(() => updateSortOptions(), [])

  const updateSortOptions = () => {
    Api({ request: requestAction })
  }

  const indexColumns = columns.map(c => ({
    Header: startCase(c),
    accessor: c,
    flex: COLUMN_FLEX && COLUMN_FLEX[c] || 1,
  }))

  const mappedData = Object.values(data).map((o) => dataMap(o))
  const linkString = `/${model}/`

  return (
    <PagedIndexTable
      defaultSort={DEFAULT_SORT}
      defaultSortDirection={DEFAULT_SORT_ASCENDING}
      categories={CATEGORIES || []}
      requestDataAction={requestAction}
      model={model}
      data={mappedData}
      columns={indexColumns}
      currentColumns={columns}
      columnOptions={COLUMNS}
      setColumns={setColumns}
      defaultColumns={DEFAULT_COLUMNS}
      count={count}
      total={total}
      linkString={linkString}
      sortOptions={SORT_OPTIONS}
    />
  )
}

export default ModelIndexTable

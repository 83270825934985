import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'

import GenericShow from '../generic/Show'
import {
  PACKAGE_TYPES_SHOW, PACKAGE_TYPES_DESTROY, PACKAGE_TYPES_NEW
} from '../root/action-types'
import Form from './Form'

const actions = {
  show: PACKAGE_TYPES_SHOW,
  destroy: PACKAGE_TYPES_DESTROY,
  new: PACKAGE_TYPES_NEW,
}

export class Show extends Component {

  // Can change when you save record
  id = () => (
    get(this.props.details, 'id') || get(this.props.match, 'params.id', 'new')
  )

  form = (props) => (
    <Form
      fields={this.props.details}
      key={this.id()}
      {...props}
    />
  )

  render = () => (
    <GenericShow
      actions={actions}
      indexPath="/package-types"
      modelName="Package Type"
      form={this.form}
      {...this.props}
    />
  )

}

const mapStateToProps = ({ packageTypes: { show } }) => (
  show
)

export default connect(mapStateToProps)(Show)

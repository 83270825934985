import React, { Component } from 'react'
import { get } from 'lodash'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { constructName } from './Contact'

const ICON_FOR_KIND = {
  name: 'user',
  job: 'tag',
  email: 'envelope',
  telephone: 'phone',
  fax: 'fax',
  role: 'eye',
  primary: 'chess-queen',
}

export default class DisplayContact extends Component {

  getIconForKind = (kind) => (
    ICON_FOR_KIND[kind] || 'info-circle'
  )

  detail = ({ kind, text }, index) => {
    if (!text) return null
    return (
      <div className={'information-line'} key={index}>
        <label>
          <FontAwesomeIcon icon={['fas', this.getIconForKind(kind)]} />
        </label>
        <span>{text}</span>
      </div>
    )
  }

  renderJobDescription = () => {
    const jobTitle = get(this.props.contact, 'jobTitle') || ''
    const jobRole = get(this.props.contact, 'jobRole') || ''
    const jobDescription = [
      jobTitle,
      (jobRole != '') ? '('+jobRole+')' : ''
    ]
    return jobDescription.filter(Boolean).join(" ")
  }

  userRoles = () => {
    const { accounts, sales } = this.props.contact
    const rolesString = [
      accounts ? 'Accounts' : null,
      sales ? 'Sales' : null
    ].filter(Boolean).join('/')

    return rolesString
  }

  setPrimary = () => (
    this.props.isPrimary ? null : this.setPrimaryControl()
  )

  setPrimaryControl = () => (
    <div>
      <span onClick={this.props.setPrimary}>
        <label>
          <FontAwesomeIcon icon="chess-queen" />
        </label>
        Set As Primary
      </span>
    </div>
  )

  render = () => (
    <div className="display-contact">
      <div className="details-contacts">
        <this.detail
          kind={'name'}
          text={constructName(this.props.contact)}
        />
        <this.detail
          kind={'job'}
          text={this.renderJobDescription()}
        />
        <this.detail
          kind={'role'}
          text={this.userRoles()}
        />
        <this.detail kind="primary" text={this.props.isPrimary && 'Primary Contact'} />
        <this.detail kind="telephone" text={get(this.props.contact, 'telephone')} />
        <this.detail kind="email" text={get(this.props.contact, 'email')} />
      </div>

      <div className="details-options">
        <div>
          <span onClick={() => this.props.setContactUnderEdit(this.props.contact.id)}>
            <label>
              <FontAwesomeIcon icon={['fas', 'pencil-alt']} />
            </label>
            Edit
          </span>
        </div>
        <this.setPrimary />
      </div>
    </div>
  )
}

import { useState } from 'react'
import { uniq, pick, sumBy, startCase, get } from 'lodash'

import { MEASURERS } from '../jobs/constants'

const CHOICES = [
  'actualMeasurementsChoice',
  'chargeableMeasurementsChoice',
]

const useChoices = (data) => {
  const [choices, setAllChoices] = useState(pick(data, CHOICES))
  const setChoice = {}
  CHOICES.forEach(c => {
    setChoice[c] = (v) => setAllChoices({ ...choices, [c]: v })
  })

  return [choices, setChoice]
}

const useMeasurements = (saved) => {
  const byMeasurer = {}
  get(saved, 'measurements', []).forEach(m => byMeasurer[m.measurer] = m)

  const [measurements, setAllMeasurements] = useState(byMeasurer)
  const getMeasurement = (measurer) => measurements[measurer] || {}

  const measurementSetter = (measurer) => (v) => {
    setAllMeasurements({
      ...measurements,
      [measurer]: {
        ...measurements[measurer],
        ...v,
        measurer,
      },
    })
  }
  const deleteMeasurement = (measurer) => {
    const next = { ...measurements }
    delete next[measurer]
    setAllMeasurements(next)
  }

  return { measurements, getMeasurement, measurementSetter, deleteMeasurement }
}

const usePackageLines = (saved) => {
  const byMeasurer = {}
  saved.packageDimensionLines.forEach(p => {
    if (!byMeasurer[p.measurer]) { byMeasurer[p.measurer] = [] }
    byMeasurer[p.measurer].push(p)
  })
  const [packageLines, setAllPackageLines] = useState(byMeasurer)

  const getPackageLines = (measurer) => packageLines[measurer] || []
  const packageLinesSetter = (measurer) => (lines) => {
    setAllPackageLines({ ...packageLines, [measurer]: lines })
  }
  const deletePackageLines = (measurer) => {
    const next = { ...packageLines }
    delete next[measurer]
    setAllPackageLines(next)
  }

  return { packageLines, getPackageLines, packageLinesSetter, deletePackageLines }
}

const nextPartName = (tabs) => {
  let name
  let next = 65 // 'A'
  do {
    name = 'part' + String.fromCharCode(next)
    next += 1
  } while (tabs.includes(name))

  return name
}

const NUMERICS = [
  'packageQuantity',
  'grossWeightKg',
  'cubicMetres',
  'loadingMetres',
]

const num = (val) => Number(val) || 0

const toTwoDP = (val) => Math.round(val * 100) / 100

const calculateRemainder = ({ choices, measurements, target }) => {
  const total = measurements[choices.actualMeasurementsChoice] || {}
  const current = Object.values(measurements).filter(
    m => m.measurer != target && m.measurer.startsWith('part')
  )
  const remainder = {}
  NUMERICS.forEach(f => {
    remainder[f] = toTwoDP(
      num(total[f]) - sumBy(current, part => num(part[f]))
    )
  })
  remainder.packageType = total.packageType

  return remainder
}

const calculatePackageLineRemainder = ({ choices, packageLines, target }) => {
  const total = packageLines[choices.actualMeasurementsChoice] || []
  const current = Object.values(packageLines).flat().filter(
    pl => pl.measurer != target && pl.measurer.startsWith('part')
  )

  return total.map(line => ({
    ...line,
    measurer: target,
    quantity: packageQuantityRemainder(line, current),
  })).filter(line => line.quantity > 0)
}

const PACKAGE_MATCH = ['type', 'length', 'width', 'height', 'weight']

const packageQuantityRemainder = (match, partLines) => (
  num(match.quantity) - sumBy(
    partLines.filter(p => PACKAGE_MATCH.every(f => p[f] == match[f])),
    line => num(line.quantity)
  )
)

const toSentence = (array) => {
  const items = array.map(startCase)
  if (Intl.ListFormat) { // Chrome...
    return new Intl.ListFormat().format(items)
  } else {
    return items.join(', ')
  }
}

const validateSplit = ({ choices, measurements, packageLines }) => {
  if (0 == Object.keys(measurements).filter(m => m.startsWith('part'))) {
    return [] // not split
  }

  const measures = calculateRemainder({ choices, measurements })
  const packages = calculatePackageLineRemainder({ choices, packageLines })
  const wrong = NUMERICS.filter(n => measures[n] != 0)
  const warnings = []

  if (wrong.length) {
    warnings.push(`The combined ${toSentence(wrong)} of parts do not equal actual total.`)
  }
  if (packages.length) {
    warnings.push('The combined package dimensions lines of parts do not equal actual total.')
  }

  return warnings
}

export const useMeasurementsManager = (saved={}, updateApiRequest) => {
  const [choices, setChoice] = useChoices(saved)
  const {
    measurements,
    getMeasurement,
    measurementSetter,
    deleteMeasurement
  } = useMeasurements(saved)
  const {
    packageLines,
    getPackageLines,
    packageLinesSetter,
    deletePackageLines
  } = usePackageLines(saved)
  const save = () => {
    updateApiRequest({
      ...choices,
      measurements: Object.values(measurements),
      packageDimensionLines: Object.values(packageLines).flat(),
    })
  }
  const tabs = uniq([
    ...Object.keys(MEASURERS),
    ...Object.values(measurements).map(v => v && v.measurer).filter(v => v),
  ])

  const autoRemainder = (target) => {
    measurementSetter(target)({
      ...measurements[target],
      ...calculateRemainder({ choices, measurements, target, })
    })
    packageLinesSetter(target)(
      calculatePackageLineRemainder({ choices, packageLines, target })
    )
  }
  const split = () => {
    autoRemainder(nextPartName(tabs))
  }
  const deleteMeasurer = (target) => {
    deleteMeasurement(target)
    deletePackageLines(target)
  }

  const warnings = validateSplit({ choices, measurements, packageLines })

  return {
    choices, setChoice,
    tabs, getMeasurement, measurementSetter,
    getPackageLines, packageLinesSetter,
    warnings,
    actions: { split, deleteMeasurer, autoRemainder, save, },
  }
}

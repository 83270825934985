import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { pick } from 'lodash'

import Api from '../api/call'
import { Loading } from '../generic/Loading'
import ActionTypes from '../root/action-types'

import RatesForm from '../calculator/RatesForm'
import DetailsSummary from '../calculator/DetailsSummary'

import './revise.scss'

const { CALCULATOR_RATE } = ActionTypes

const DESTORIGIN = { Import: 'origin', Export: 'destination' }

const upToDoor = ({ originLocationType, destinationLocationType }) => (
  [
    originLocationType,
    destinationLocationType
  ].find(t => String(t).match(/Door Delivery/i))
)

const rateOptions = ({ direction, ...props }) => ({
  ...props,
  direction,
  upToDoor: upToDoor(props),
  country: props[`${DESTORIGIN[direction]}CountryCode`],
  location: props[`${DESTORIGIN[direction]}Location`],
})

const formProps = (data) => (
  pick(data, ['direction', 'upToDoor', 'customerId'])
)

const lineItemFormat = (lines, category, companyId) => (
  Object.entries(lines).map(([k, v]) => (
    { description: k, value: v, category, companyId }
  )).filter(li => li.value)
)

const billingFormat = ({ costLines, salesLines, supplierId, customerId }) => ({
  cost: lineItemFormat(costLines, 'cost', supplierId),
  sales: lineItemFormat(salesLines, 'sales', customerId),
})

const Revise = ({ replaceLineItems, cancel }) => {
  const { data } = useSelector(s => s.quotes.show)
  const { rates, loadingRates } = useSelector(r => r.calculator)
  const [form, setForm] = useState({})
  const registerForm = (getter) => setForm({ getter })
  const complete = () => {
    replaceLineItems(billingFormat(form.getter()))
    cancel()
  }
  const params = rateOptions(data)

  useEffect(() => {
    Api({ request: CALCULATOR_RATE, params })
  }, [data.id])

  if (loadingRates) { return <Loading /> }

  return (
    <div className="quote-revise generic-show">
      <div className="section">
        <h4>Revise</h4>
        <DetailsSummary {...params} />
      </div>
      <RatesForm {...formProps(params)} {...rates} registerForm={registerForm} />
      <div className="controls">
        <button className="primary" onClick={complete}>
          Update Line Items
        </button>
        <button className="danger" onClick={cancel}>Cancel</button>
      </div>
    </div>
  )
}

export default Revise

// A single source for the keys used for storing items in localStorage.

const LOCAL_STORAGE_KEYS = {
  LOGIN_AUTH_TOKEN: 'ebfs-atkn',
  LOGIN_USER: 'ebfs-otto',
  LOGIN_USER_ID: 'ebfs-bethany',
  PLANNER_COLUMNS: 'ebfs-sam-the-smilodon',
  PLANNER_JOB_STATUSES: 'ebfs-pjsp',
  COMPANIES_INDEX_COLUMNS: 'ebfs-cic',
  QUOTES_INDEX_COLUMNS: 'ebfs-qic',
  JOBS_INDEX_COLUMNS: 'ebfs-laurence-the-lion',
}

module.exports = LOCAL_STORAGE_KEYS

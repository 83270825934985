import React, { Component } from 'react'
import { startCase } from 'lodash'

import {
  Field, FieldSection, InputGroup, FocusStatus, MoneyInput,
} from '../../forms/'

import Api from '../../api/call'
import { TARIFFS_CONFIG_UPDATE } from '../../root/action-types'


export default class Form extends Component {

  state = { ...this.props.details }

  setFormValue = ({ target: { name, value } }) => {
    this.setState({ [name]: value })
  }

  submit = () => {
    Api({
      request: TARIFFS_CONFIG_UPDATE,
      data: this.state,
    })
  }

  ifEditting = ({ children }) => (
    this.props.edit ? children : null
  )

  field = ({ label, name, suffix, className, ...props }) => (
    <Field label={label || startCase(name)} className={className}>
      <InputGroup suffix={suffix}>
        <input
          name={name}
          value={this.state[name] || ''}
          onChange={this.setFormValue}
          disabled={!this.props.edit}
          type="number"
          {...props}
        />
      </InputGroup>
    </Field>
  )

  moneyField = ({ label, name, ...props }) => (
    <Field label={label || startCase(name)}>
      <MoneyInput
        name={name}
        value={this.state[name] || ''}
        onChange={this.setFormValue}
        disabled={!this.props.edit}
        {...props}
      />
    </Field>
  )

  render = () => (
    <div className="form">
      <FocusStatus
        isError={this.props.hasErrors}
        messages={this.props.status}
      />
      <FieldSection title="Freight" className="details">
        <this.field
          label="CBM:KG Ratio"
          name="freight_cbm_kg"
          suffix="kg"
        />
        <this.field
          label="LDM:KG Ratio"
          name="freight_ldm_kg"
          suffix="kg"
        />
        <this.moneyField name="freight_cbm_min_rate" label="Per CBM Min Rate" />
      </FieldSection>
      <FieldSection title="Markup" className="details">
        <this.field
          label="Overseas"
          name="overseas_markup"
          suffix="%"
        />
        <this.moneyField name="sales_markup" label="Final Sales" />
      </FieldSection>
      <this.ifEditting>
        <div className="controls">
          <button className="primary" onClick={this.submit}>Update</button>
        </div>
      </this.ifEditting>
    </div>
  )
}

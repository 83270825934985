import { combineReducers } from 'redux'

import {
  TARIFFS_CUSTOMERS,
  TARIFFS_CUSTOMERS_NEW,
  TARIFFS_CUSTOMERS_SHOW,
  TARIFFS_CUSTOMERS_CREATE,
  TARIFFS_CUSTOMERS_UPDATE,
  TARIFFS_CUSTOMERS_DESTROY,
} from  '../../root/action-types'
import { countryName } from '../../addresses/countries'

const addCountryName = (tariff) => {
  tariff.countryName = countryName(tariff.country)
  return tariff
}

const INITIAL_INDEX = { data: [], total: 0, count: 0, loading: true }

export const index = (state = INITIAL_INDEX, action) => {
  switch (action.type) {
    case `${TARIFFS_CUSTOMERS}_START`:
      return {
        ...INITIAL_INDEX,
      }
    case `${TARIFFS_CUSTOMERS}_SUCCESS`:
      return {
        ...state,
        data: action.response.data.map(addCountryName),
        loading: false,
      }
    case `${TARIFFS_CUSTOMERS}_FAILURE`:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}

const INITIAL_SHOW = { loading: false, details: {}, status: [] }

export const show = (state = INITIAL_SHOW, action) => {
  switch (action.type) {
    case TARIFFS_CUSTOMERS_NEW:
      return { ...INITIAL_SHOW }
    case `${TARIFFS_CUSTOMERS_SHOW}_START`:
      return {
        ...INITIAL_SHOW,
        loading: true,
      }
    case `${TARIFFS_CUSTOMERS_SHOW}_SUCCESS`:
      return {
        ...state,
        details: action.response.data,
        loading: false,
      }
    case `${TARIFFS_CUSTOMERS_CREATE}_START`:
    case `${TARIFFS_CUSTOMERS_UPDATE}_START`:
    case `${TARIFFS_CUSTOMERS_DESTROY}_START`:
      return {
        ...state,
        loading: true,
        status: [],
        hasErrors: false,
      }
    case `${TARIFFS_CUSTOMERS_UPDATE}_SUCCESS`:
      return {
        ...state,
        loading: false,
        details: action.response.data,
        status: ['Tariff updated successfully!'],
      }
    case `${TARIFFS_CUSTOMERS_CREATE}_SUCCESS`:
      return {
        ...state,
        loading: false,
        details: action.response.data,
        status: ['Tariff created successfully!'],
      }
    case `${TARIFFS_CUSTOMERS_SHOW}_FAILURE`:
    case `${TARIFFS_CUSTOMERS_CREATE}_FAILURE`:
    case `${TARIFFS_CUSTOMERS_UPDATE}_FAILURE`:
    case `${TARIFFS_CUSTOMERS_DESTROY}_FAILURE`:
      return {
        ...state,
        loading: false,
        hasErrors: true,
        details: action.response.data.details,
        status: action.response.data.errors,
      }
    case `${TARIFFS_CUSTOMERS_DESTROY}_SUCCESS`:
      return {
        ...state,
        loading: false,
        destroyed: true,
      }
    default:
      return state
  }
}

export default combineReducers({
  index,
  show,
})

import React, { Component } from 'react'
import { startCase } from 'lodash'

import {
  Field, FieldSection, MoneyInput, InputGroup
} from '../../forms/'

import Api from '../../api/call'
import {
  TARIFFS_FREIGHT_UPDATE,
  TARIFFS_FREIGHT_CREATE
} from '../../root/action-types'


export default class Form extends Component {

  state = { name: '', ...this.props.fields }

  action = () => (
    this.state.id ? TARIFFS_FREIGHT_UPDATE : TARIFFS_FREIGHT_CREATE
  )

  actionName = () => (
    `${this.state.id ? 'Update' : 'Create'} Freight Rate`
  )

  rate = ({ label, name, ...props }) => (
    <Field label={label || startCase(name)}>
      <MoneyInput
        name={name}
        value={this.state[name] || ''}
        onChange={this.setFormValue}
        disabled={!this.props.edit}
        {...props}
      />
    </Field>
  )

  field = ({ label, name, suffix, className, ...props }) => (
    <Field label={label || startCase(name)} className={className}>
      <InputGroup suffix={suffix}>
        <input
          name={name}
          value={this.state[name] || ''}
          onChange={this.setFormValue}
          disabled={!this.props.edit}
          {...props}
        />
      </InputGroup>
    </Field>
  )

  setFormValue = ({ target: { name, value } }) => {
    this.setState({ [name]: value })
  }

  submit = () => {
    Api({
      request: this.action(),
      id: this.state.id,
      data: {
        ...this.state,
      }
    })
  }

  ifEditting = ({ children }) => (
    this.props.edit ? children : null
  )

  cubicMetreField = (props) => (
    <this.field
      {...props} label={`${startCase(props.name)} (CBM >=)`}
      suffix="m³" type="number" step="0.1" min="0"
    />
  )

  render = () => (
    <div className="form tariff-freight-form">
      <FieldSection title="Description" className="details">
        <this.field name="name" className="double" />
      </FieldSection>
      <FieldSection title="Rates" className="details">
        <this.rate name="low" />
        <this.rate name="mid" />
        <this.rate name="high" />
      </FieldSection>
      <FieldSection title="Rate Boundaries" className="details">
        <this.cubicMetreField name="midThreshold" />
        <this.cubicMetreField name="highThreshold" />
      </FieldSection>
      <this.ifEditting>
        <div className="controls">
          <button className="primary" onClick={this.submit}>{this.actionName()}</button>
        </div>
      </this.ifEditting>
    </div>
  )
}

import React from 'react'
import TimeField from 'react-simple-timefield'

import { Field } from '../Field'

import './time-selector.scss'


export const TimeSelector = ({ label, error, value, onChange, isDisabled }) => (
  <Field
    className={'time-selector-field'}
    label={label}
    error={error}
  >
    <TimeField
      value={value || '--:--'}
      onChange={onChange}
      input={<input className={"time-selector-input"} />}
      disabled={isDisabled}
    />
  </Field>
)

export default TimeSelector

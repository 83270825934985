import React, { Component } from 'react'
import { capitalize, startCase, pick } from 'lodash'

import Api from '../../api/call'

import IndexTable from './IndexTable'


class PagedIndexTable extends Component {

  searchTimeout = null

  state = {
    page: 1,
    pageSize: { value: 20, label: 20 },
    search: '',
    category: null,
    sort: this.props.defaultSort,
    sortDirection: this.props.defaultSortDirection || false,
  }

  getCategories = (categories = this.props.categories || []) => (
    categories
  )

  initaliseCategories = () => {
    this.getCategories().map(c => (
      this.setState({ [c]: null })
    ))
  }

  componentDidMount = () => {
    this.initaliseCategories()
    this.updateTable()
  }

  updateTable() {
    const { page, search, sortDirection } = this.state
    const limit = this.state.pageSize.value
    const offset = (page * limit) - limit
    const sort = this.state.sort.value

    const categories = {}
    this.getCategories().filter(c => this.state[c]).forEach(c =>
      categories[c] = this.state[c]
    )

    const params = { limit, offset, search, sort, sortDirection, categories }
    Api({ request: this.props.requestDataAction, params })
  }

  getPageSizeOptions = () => (
    [
      { value: 5, label: 5 },
      { value: 20, label: 20 },
      { value: 50, label: 50 },
      { value: 100, label: 100 }
    ]
  )

  getCategoryOptions = () => {
    const options = this.getCategories().map(c => (
      { value: c, label: startCase(c) }
    ))
    if (options.length > 0) {
      return options
    }
    return null
  }

  handlePageChange = page => {
    this.setState({ page }, this.updateTable)
  }

  handleSizeChange = pageSize => {
    this.setState({ pageSize }, this.updateTable)
  }

  queueSearchUpdate = () => {
    clearTimeout(this.searchTimeout)
    this.searchTimeout = setTimeout(() => this.updateTable(), 400)
  }

  handleSearchChange = e => {
    const search = e.target.value
    this.setState({ search }, this.queueSearchUpdate)
    e.preventDefault()
  }

  handleCategoryChange = category => {
    const newState = {}
    this.getCategoryOptions().forEach(option => {
      newState[option.value] = ((category || []).map(selected => selected.value).includes(option.value) ? true : null)
    })
    this.setState({ category, ...newState }, this.updateTable)
  }

  handleSortChange = sort => {
    this.setState({ sort }, this.updateTable)
  }

  handleSortDirectionChange = () => {
    const sortDirection = !this.state.sortDirection
    this.setState({ sortDirection }, this.updateTable)
  }

  render = () => (
    <IndexTable
      {...pick(this.props, [
        'data', 'columns', 'currentColumns', 'columnOptions', 'setColumns',
        'defaultColumns', 'count', 'total', 'linkString', 'sortOptions',
      ])}
      {...pick(this.state, [
        'page', 'pageSize', 'search', 'sort', 'sortDirection'
      ])}
      model={capitalize(this.props.model)}
      pageSizeOptions={this.getPageSizeOptions()}
      categoryOptions={this.getCategoryOptions()}
      handlePageChange={this.handlePageChange}
      handleSizeChange={this.handleSizeChange}
      handleSearchChange={this.handleSearchChange}
      handleCategoryChange={this.handleCategoryChange}
      handleSortChange={this.handleSortChange}
      handleSortDirectionChange={this.handleSortDirectionChange}
    />
  )
}

export default PagedIndexTable

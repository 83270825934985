import React, { Component } from 'react'

import { DropdownButton, DropdownOption } from './Buttons'
import ModalWindow from '../layout/ModalWindow'


export default class ModelActions extends Component {

  state = {
    modalOpen: false,
  }

  modalIfOpen = () => (
    this.state.modalOpen ? this.confirmDeletionModal() : null
  )

  toggleModal = () => {
    this.setState(prev => ({ modalOpen: !prev.modalOpen }))
  }

  confirmDeletionModal = () => (
    <ModalWindow
      className="model-actions"
      isOpen={this.state.modalOpen}
      closeModal={this.toggleModal}
    >
      <h3>Confirm {this.props.modelName} Deletion</h3>
      <div className="description">
        Are you sure you want to delete this {`${this.props.modelName}`}?<br/> This action cannot be reversed.
      </div>
      <div className="options">
        <button className="delete-button" onClick={this.props.deleteAction}>
          Delete
        </button>
        <button className="cancel-button" onClick={this.toggleModal}>
          Cancel
        </button>
      </div>
    </ModalWindow>
  )

  editButtonLabel = () => (
    this.props.edit ? 'Discard changes' : `Edit ${this.props.modelName}`
  )

  editOption = () => (
    <DropdownOption
      icon="pencil-alt"
      label={this.editButtonLabel()}
      onClick={this.props.editAction}
    />
  )

  deleteOption = () => (
    <DropdownOption
      className="danger"
      icon="trash-alt"
      label={`Delete ${this.props.modelName}`}
      onClick={this.toggleModal}
    />
  )

  renderEditOption = () => (
    this.props.disableEdit ? null : <this.editOption />
  )

  renderDeleteOption = () => (
    this.props.deleteAction ? this.deleteOption() : null
  )

  render = () => (
    <DropdownButton className="model-actions secondary" icon="ellipsis-h" label="Actions">
      {this.props.children}
      {this.renderEditOption()}
      {this.renderDeleteOption()}
      {this.modalIfOpen()}
    </DropdownButton>
  )
}

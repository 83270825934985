import React, { Component } from 'react'
import moment from 'moment'
moment.locale('en-gb')

import WeekDayPicker from './WeekDayPicker'


export const defaultWeekNumber = () => (
  moment(new Date()).format('W')
)

export const defaultDate = () => (
  moment(new Date()).isoWeekday("Monday").toDate()
)

class WeekDayControl extends Component {

  dateModeOn = () => this.props.updateFilterByDate(true)
  dateModeOff = () => {
    this.props.updateFilterByDate(false)
    this.setMonday(this.props.value)
  }

  futureDaysArray = (daysAhead = []) => {
    const { value } = this.props // javascript Date
    return daysAhead.map(x => moment(value).add(x, 'days').toDate())
  }
  sunday = () => this.futureDaysArray([6])
  tuesdayThroughSaturday = () => this.futureDaysArray([1,2,3,4,5])

  setDate = (value) => {
    const date = moment(value, "dd/MM/yyyy")

    this.props.updateWeekNumber(date.format('W'))
    this.props.updateDateOfUse(value)
  }

  setMonday = (value) => {
    const date = moment(value, "dd/MM/yyyy")
    const monday = date.isoWeekday("Monday").toDate()

    this.props.updateWeekNumber(date.format('W'))
    this.props.updateDateOfUse(monday)
  }

  renderDateWeekSwitcher = () => {
    const { filterByDate } = this.props

    return (
      <div className="date-week-switcher">
        <div
          className={filterByDate ? 'off' : 'on'}
          onClick={this.dateModeOff}
          title="Groupages by Week"
        >
          Week
        </div>
        <div
          className={filterByDate ? 'on' : 'off'}
          onClick={this.dateModeOn}
          title="Groupages by Date"
        >
          Day
        </div>
      </div>
    )
  }

  highlightDates = () => (
    this.props.filterByDate ? (
      []
    ) : (
      [
        { 'react-datepicker__day--selected' :this.sunday() },
        { 'mid-week-range': this.tuesdayThroughSaturday() },
      ]
    )
  )

  onChangeFunction = () => (
    this.props.filterByDate ? this.setDate : this.setMonday
  )

  render = () => (
    <div className="week-control">
      {this.renderDateWeekSwitcher()}
      <WeekDayPicker
        highlightDates={this.highlightDates()}
        onChange={this.onChangeFunction()}
        {...this.props}
      />
    </div>
  )
}

export default WeekDayControl

import React, { Component } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { get, omit, pick } from 'lodash'

import {
  ADDRESSES_SEARCH,
  ADDRESSES_LABEL,
  ADDRESSES_CREATE,
  ADDRESSES_MANAGEMENT_RESET_STORE
} from '../../root/action-types'
import Api from '../../api/call'

import ModalWindow from '../../layout/ModalWindow'
import AddressForm from '../../addresses/AddressForm'
import { BLANK_ADDRESS } from '../../addresses/Address'
import { Field } from '../Field'
import { Status, formatErrors } from '../Status'

import './NewOptionSelect.scss'

const options = (data) => (
  data.map(c => (
    { value: c.id, label: c.label }
  ))
)

class AddressSelect extends Component {

  state = {
    limit: 10,
    offset: 0,
    search: '',
    createModalOpen: false,
    ...BLANK_ADDRESS
  }

  closeAfterTimeout = () => {
    setTimeout(this.closeCreateModal, 750)
  }

  openCreateModal = () => (
    this.setState({ createModalOpen: true })
  )

  closeCreateModal = () => {
    this.resetForm()
    if (this.props.addressManagement.addressHasUpdated) {
      this.props.onChange({ value: this.props.addressManagement.data.details.id })
    }
    this.dispatchAddressStoreReset()
    this.setState({ createModalOpen: false })
  }

  resetForm = () => (
    this.setState(prevState => ({ ...prevState, ...BLANK_ADDRESS }))
  )

  setValue = ({ target: { name, value } }) => (
    this.setState({ [name]: value })
  )

  toggleValue = ({ target: { name } }) => {
    this.setState((prevState) => ({ [name]: !prevState[name] }))
  }

  setCountryValue = (country) => {
    this.setState({ countryCode: country.value })
  }

  inError = () => (
    get(this.props.addressManagement, 'errors', []).length != 0
  )

  requestAddresses = () => {
    Api({
      request: ADDRESSES_SEARCH,
      params: { ...pick(this.state, ['limit', 'offset', 'search']), companyId: this.props.companyId }
    })
  }

  createAddressApiRequest = () => (
    Api({
      request: ADDRESSES_CREATE,
      data: {
        ...omit(
          this.state,
          ['limit', 'offset', 'search', 'createModalOpen']
        ),
        companyId: this.props.companyId
      },
    })
  )

  dispatchAddressStoreReset = () => (
    this.props.dispatch({
      type: ADDRESSES_MANAGEMENT_RESET_STORE,
    })
  )

  getOption = () => {
    const { search: { results }, value } = this.props
    return options(results || []).find(o => o.value == value)
  }

  getName = () => {
    const { value, search: { labels } } = this.props
    if (labels[value]) {
      return { label: labels[value], value }
    }
  }

  // All instances use same store but may be requesting different labels,
  // So we need to check whether this update is ours.
  shouldComponentUpdate(nextProps) {
    if (!this.loadingName) { return true }
    if (nextProps.value && !nextProps.search.labels[nextProps.value]) {
      return false
    }
    this.loadingName = false
    return true
  }

  currentValue = () => {
    if (this.loadingName) { return { label: 'Loading... ' } }

    const id = this.props.value
    if (!id) { return }

    const option = this.getOption() || this.getName()
    if (option) { return option }

    this.loadingName = true
    Api({ request: ADDRESSES_LABEL, params: { id } })
  }

  wrapper = ({ children }) => {
    if (this.props.omitWrapper) { return children }
    const { error, forceError } = this.props

    return (
      <Field label={this.props.label} error={error} forceError={forceError}>
        <div className="children-container">{children}</div>
      </Field>
    )
  }

  onInputChange = (value) => {
    this.setState({ search: value })
    this.queueUpdate()
  }

  queueUpdate = (milliseconds = 400) => {
    clearTimeout(this.timeoutUpdate)
    this.timeoutUpdate = setTimeout(this.requestAddresses, milliseconds)
  }

  createOption = () => {
    if (!this.props.isDisabled && this.props.allowCreation) {
      return (
        <button
          className="open-new-modal"
          onClick={this.openCreateModal}>
          <FontAwesomeIcon icon={['fas', 'plus-circle']} className="icon" />
        </button>
      )
    }
    return null
  }

  saveAddress = () => {
    this.createAddressApiRequest()
  }

  saveButtonOrSuccessStatus = () => {
    if (this.inError()) {
      return (
        <Status isError={this.inError()}>
          {formatErrors(this.props.addressManagement.errors)}
        </Status>
      )
    }
    if (this.props.addressManagement.addressHasUpdated) {
      {this.closeAfterTimeout()}
      return (
        <Status>
          Address Created!
        </Status>
      )
    }
    return (
      <button className="create-option" onClick={this.saveAddress}>
        Save
      </button>
    )
  }

  renderCreateModal = () => (
    <ModalWindow
      isOpen={this.state.createModalOpen}
      closeModal={this.closeCreateModal}
    >
      <h3>New Address</h3>
      <div className="select-modal-form-container">
        <AddressForm
          address={this.state}
          onChange={this.setValue}
          onChangeToggle={this.toggleValue}
          onChangeCountry={this.setCountryValue}
          formError={this.inError()}
        />
        <this.saveButtonOrSuccessStatus />
      </div>
    </ ModalWindow>
  )

  render = () => {
    const { isDisabled, onChange } = this.props
    const { results, loading } = this.props.search

    return (
      <this.wrapper>
        <this.renderCreateModal />
        <Select
          classNamePrefix="select"
          className={`select ${this.props.allowCreation && !this.props.isDisabled ? 'make-room' : null}`}
          value={this.currentValue()}
          options={options(results || [])}
          isDisabled={isDisabled}
          isLoading={loading}
          onChange={onChange}
          onMenuOpen={() => this.queueUpdate(0)}
          onMenuClose={() => clearTimeout(this.timeoutUpdate)}
          onInputChange={this.onInputChange}
        />
        {this.createOption()}
      </this.wrapper>
    )
  }
}

const mapStateToProps = ({ companies: { addresses: { search, addressManagement } } }) => (
  { search, addressManagement }
)

export default connect(mapStateToProps)(AddressSelect)

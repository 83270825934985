import React from 'react'
import { useSelector } from 'react-redux'

import { PACKAGE_TYPES } from '../root/action-types'
import SimpleIndexTable from '../generic/indexTable/Simple'

const COLUMNS = [
  { Header: "Type", accessor: "type" },
  { Header: "Weight", accessor: "weight" },
  { Header: "Length", accessor: "length" },
  { Header: "Width", accessor: "width" },
  { Header: "Height", accessor: "height" },
]

const Index = ({ history }) => {
  const { details, loading } = useSelector(r => r.packageTypes.index)

  return (
    <SimpleIndexTable
      title="Package Types"
      columns={COLUMNS}
      model="Package Type"
      linkString="/package-types/"
      actionType={PACKAGE_TYPES}
      data={Object.values(details || {})}
      history={history}
      loading={loading}
    />
  )
}

export default Index

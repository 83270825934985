import { combineReducers } from 'redux'

import {
  standardShow,
  standardModelCreateUpdateDestroy
} from '../api/standard-reducers'
import { JOBS, JOBS_EMAIL_TEMPLATES } from '../root/action-types'
import { autoline, history } from './autoline/reducer'

export const INITIAL_EMAIL = {}

export const emails = (state = INITIAL_EMAIL, action) => {
  switch (action.type) {
    case `${JOBS_EMAIL_TEMPLATES}_START`:
      return {
        ...state,
        loading: true,
      }
    case `${JOBS_EMAIL_TEMPLATES}_SUCCESS`:
      return {
        ...action.response.data,
        loading: false,
      }
    case `${JOBS_EMAIL_TEMPLATES}_FAILURE`:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}

const INITIAL_INDEX_STATE = {
  data: [],
  total: 0,
  count: 0,
}

export const index = (state = INITIAL_INDEX_STATE, action) => {
  switch (action.type) {
    case `${JOBS}_START`:
      return {
        ...state,
        loading: true,
      }
    case `${JOBS}_SUCCESS`: {
      return {
        ...state,
        loading: false,
        ...action.response.data
      }
    }
    case `${JOBS}_FAILURE`:
      return {
        ...state,
        loading: false,
        errors: action.response.data.errors,
      }
    default:
      return state
  }
}

export default combineReducers({
  emails,
  index,
  show: standardShow('JOBS_SHOW'),
  job: standardModelCreateUpdateDestroy('job', 'JOBS'),
  autoline,
  history,
})

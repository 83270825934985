import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { get, omit } from 'lodash'

import { EMAILS_MANAGEMENT_RESET_STORE } from '../root/action-types'

import { html } from './SlateEditorRules'

import EmailForm from './EmailForm'


class EditEmail extends Component {

  state = {
    ...this.props.email,
    emailBody: html.deserialize(this.props.email.body),
  }

  componentDidMount = () => (
    this.dispatchEmailStoreReset()
  )

  componentDidUpdate = (prevProps) => {
    const previous = get(prevProps.emailManagement, 'emailHasUpdated')
    const current = get(this.props.emailManagement, 'emailHasUpdated')
    if (current !== previous && current) {
      this.exitEditMode()
    }
  }

  exitEditMode = () => {
    this.dispatchEmailStoreReset()
    this.props.resetEmailUnderEdit()
  }

  setValue = ({ target: { name, value } }) => (
    this.setState({ [name]: value })
  )

  setEditorValue = (newValue) => (
    this.setState({ emailBody: newValue })
  )

  inError = () => (
    get(this.props.emailManagement, 'errors', []).length != 0
  )

  sendUpdate = () => {
    this.props.apiRequest({
      ...omit(this.state, 'body'),
      body: html.serialize(this.state.emailBody),
    })
  }

  dispatchEmailStoreReset = () => (
    this.props.dispatch({
      type: EMAILS_MANAGEMENT_RESET_STORE,
    })
  )

  render = () => (
    <div className="edit-email-container">

      <EmailForm
        key={this.state.id}
        email={this.state}
        onChange={this.setValue}
        onEditorChange={this.setEditorValue}
        emailBody={this.state.emailBody}
        formError={this.inError()}
      />
      <div className="email-details-options">
        <div>
          <span onClick={this.sendUpdate}>
            <label>
              <FontAwesomeIcon icon={['fas', 'pencil-alt']} />
            </label>
            Save Changes
          </span>
        </div>
        <div>
          <span onClick={this.exitEditMode}>
            <label>
              <FontAwesomeIcon icon={['fas', 'trash-alt']} />
            </label>
            Discard Changes
          </span>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ emails: { email } }) => (
  { emailManagement: email }
)

export default connect(mapStateToProps)(EditEmail)

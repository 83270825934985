import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { capitalize, get } from 'lodash'

import { fetchData, financialsSelector } from './financials/Financials'
import { Loading } from '../../generic/'

import { Info } from '../../generic/show/sidebar.js'

import './Sidebar.scss'


const renderFinancialInfo = ({ costTotal, totalVolume, totalProfit, profitPerCube }) => {
  return (
    <React.Fragment>
      <Info name="Volume (m³)" value={totalVolume} />
      <Info name="Cost (€)" value={costTotal} />
      <Info name="Profit (€)" value={totalProfit} />
      <Info name="Profit / Cube (€)" value={profitPerCube} />
    </ React.Fragment>
  )
}

const SideBar = (props) => {
  const { id, reference } = props
  useEffect(() => fetchData(id), [])
  const { loading, groupage } = useSelector(state => financialsSelector(state, id))

  const groupageStatus = () => {
    const status = get(props, 'status', '')
    return (
      <span className="status">
        <i className={status} />{capitalize(status)}
      </span>
    )
  }

  return (
    <div className="groupage-sidebar">
      <div className="details groupage-details primary">
        <h1>Groupage<br />{`${reference}`}</h1>
        <Info name="Status" value={groupageStatus()} />
        {loading ? <Loading /> : renderFinancialInfo(groupage || {})}
      </div>
    </div>
  )

}

export default SideBar

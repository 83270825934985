import React from 'react'

import { BounceLoader } from 'react-spinners'
import "./loading.scss"

export const Loading = () => (
  <div className="loading-spinner">
    <BounceLoader
      size={45}
      color="#46A847"
    />
  </div>
)

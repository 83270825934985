import React, { Component } from 'react'
import { omit } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { IconButton } from './Buttons'

import './dropdown-button.scss'


export const DropdownOption = ({ icon, label, ...props }) => (
  <li {...props}>
    <FontAwesomeIcon icon={icon} /><label>{label}</label>
  </li>
)

export const DropdownOptionButton = ({ icon, label, ...props }) => (
  <button {...props}>
    <FontAwesomeIcon icon={icon} /><label>{label}</label>
  </button >
)

export class DropdownButton extends Component {

  state = { active: false }

  onClick = () => {
    this.setState({ active: !this.state.active })
    this.closable = false
    setTimeout(() => this.closable = true, 10)
  }

  setRef = (node) => {
    this.ref = node
  }

  content = () => (
    <div className="dropdown-content" ref={this.setRef}>
      {this.props.children}
    </div>
  )

  activeContent = () => (
    this.state.active ? <this.content /> : null
  )

  clickOutside = ({ target }) => {
    if (this.ref && this.closable && !this.ref.contains(target)) {
      this.setState({ active: false })
    }
  }

  clickInside = () => {
    if (this.props.closeOnClick && this.ref && this.closable) {
      this.setState({ active: false })
    }
  }

  componentDidMount = () => {
    document.addEventListener('click', this.clickOutside)
    document.addEventListener('click', this.clickInside)
  }

  componentWillUnmount = () => {
    document.removeEventListener('click', this.clickOutside)
    document.removeEventListener('click', this.clickInside)
  }

  caret = () => (
    <i>{this.state.active ? '▲' : '▼'}</i>
  )

  optionalCaret = () => (
    this.props.caret ? <this.caret /> : null
  )

  render = () => (
    <div className={`dropdown-button ${this.props.dropUp ? 'dropup' : null}`}>
      <IconButton
        {...omit(this.props, ['label', 'children', 'dropUp', 'closeOnClick'])}
        onClick={this.onClick}
      >
        {this.props.label}
        <this.optionalCaret />
      </IconButton>
      <this.activeContent />
    </div>
  )

}

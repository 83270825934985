import { omit } from 'lodash'

const OMIT = [
  'country',
  'location',
  'locationType',
  'distributionOption',
  'doorDeliveryOption',
  'goodsValue',
  'costLines',
  'salesLines',
  'freightOption',
  'freight',
  'distribution',
  'freightMin',
]

export const apiFormat = (params) => ({
  originCountryCode: originCountry(params),
  destinationCountryCode: destinationCountry(params),
  ...location(params),
  ...omit(params, OMIT),
  insuranceValue: params.goodsValue,
  nonEU: params.nonEU || params.nonEu,
  lineItems: lineItems(params),
  road: true,
  freightMode: params.freightOption.name
})

const lineItems = ({ salesLines, costLines }) => ([
  ...Object.entries(salesLines).map(([k, v]) => (
    { description: k, value: v, category: 'sales', }
  )),
  ...Object.entries(costLines).map(([k, v]) => (
    { description: k, value: v, category: 'cost', }
  )),
].filter(l => l.value))

const location = ({ direction, location, locationType, doorDeliveryType }) => {
  const maltaType = doorDeliveryType || 'Depot'

  if (String(direction).match(/import/i)) {
    return {
      originLocationType: locationType,
      originLocation: location,
      destinationLocationType: maltaType,
      destinationLocation: 'Malta',
    }
  } else {
    return {
      originLocationType: maltaType,
      originLocation: 'Malta',
      destinationLocationType: locationType,
      destinationLocation: location,
    }
  }
}

const originCountry = ({ direction, country }) => (
  direction == 'Import' ? country : 'MT'
)

const destinationCountry = ({ direction, country }) => (
  direction == 'Export' ? country : 'MT'
)


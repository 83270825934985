import React from 'react'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import { get } from 'lodash'

import Api from '../api/call'
import { XERO_ACCOUNTS } from '../root/action-types'

const CATEGORIES = {
  sales: 'REVENUE',
  cost: 'all',
}

const accountOptions = (accounts, category) => (
  (accounts[CATEGORIES[category]] || []).map(o => ({
    label: `${o.code} - ${o.name}`,
    value: o.code,
    defaultTaxRate: o.tax_type,
  }))
)

// We only need to fetch accounts from xero once; they are unlikely to change
// during runtime (and users can reload the page if it ever happens)
// We need a module level var because if 10 components mount at once when the
// billing tab loads, it makes 10 api requests and gives bad performance.
// Since redux actions are async, we can not rely on the loading prop here.
let loaded

const useXeroAccounts = () => {
  const { accounts } = useSelector(redux => redux.xero)

  if (!loaded) {
    Api({ request: XERO_ACCOUNTS })
    loaded = true
  }

  return accounts
}

export const AccountName = ({ code }) => {
  const accounts = useXeroAccounts()
  const account = get(accounts.byCode, code, {})

  return <>{code} - {account.name}</>
}

const AccountSelect = ({ value, onChange, category, ...props }) => {
  const { loading, ...accounts } = useXeroAccounts()

  const options = accountOptions(accounts, category)

  return (
    <Select
      className="select"
      classNamePrefix="select"
      options={options}
      value={options.find(o => o.value == value)}
      onChange={onChange}
      isDisabled={props.disabled}
      isLoading={loading}
      {...props}
    />
  )
}

export default AccountSelect


import { combineReducers } from 'redux'

import { setAuthToken, setUser } from '../api/call'
import { CURRENT_USER, USERS_LOGIN, USERS, USERS_SHOW } from '../root/action-types'
import {
  standardSearch,
  standardForCollections,
  standardModelCreateUpdateDestroy,
  standardShow
} from '../api/standard-reducers'

const DEFAULT_STATE = {
  loading: false,
  error: null,
  details: {},
  loggedIn: false,
}

const INITIAL_CURRENT = {}

export const current = (state = INITIAL_CURRENT, action) => {
  switch (action.type) {
    case `${CURRENT_USER}_START`: {
      return { ...INITIAL_CURRENT, loading: true }
    }
    case `${CURRENT_USER}_SUCCESS`: {
      return {
        ...state,
        loading: false,
        ...action.response.data,
      }
    }
    case `${CURRENT_USER}_FAILURE`: {
      return { ...state, loading: false, error: action.response.data.error }
    }
    default: {
      return state
    }
  }
}

export const userLogin = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case `${USERS_LOGIN}_START`:
      return { ...state, loading: true, error: null }
    case `${USERS_LOGIN}_SUCCESS`: {
      setAuthToken(action.response.data.auth_token)
      setUser(action.response.data.user)
      return {
        ...state,
        loading: false,
        error: null,
        loggedIn: true,
      }
    }
    case `${USERS_LOGIN}_FAILURE`:
      return { ...state, loading: false, error: action.response.data.error }
    default:
      return state
  }
}

export default combineReducers({
  index: standardForCollections(USERS),
  show: standardShow(USERS_SHOW),
  user: standardModelCreateUpdateDestroy('user', USERS),
  search: standardSearch(USERS),
  userLogin,
  current,
})


import React from 'react'

import './check-box.scss'

const triggerInput = ({ currentTarget }) => {
  currentTarget.lastChild.click()
}

const classes = (value, { disabled, className }) => (
  `checkbox ${value ? 'checked' : ''} ${disabled ? 'disabled' : ''} ${className || ''}`
)

export const CheckBox = ({ label, value, ...props }) => (
  <div
    className={classes(value, props)}
    onClick={triggerInput}
  >
    <span className="label">{label}</span>
    <input type="checkbox" checked={!!value} {...props} readOnly />
  </div>
)

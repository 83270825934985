import React, { Component } from 'react'
import Toggle from 'react-toggle'
import { startCase } from 'lodash'

import { Field } from '../../forms/Field'
import { InputGroup } from '../../forms/InputGroup'

import './warehouse-form.scss'


export default class WarehouseForm extends Component {

  numberInput = (name, label, suffix, disabled) => (
    <Field
      label={label}
    >
      <InputGroup suffix={suffix}>
        <input
          type="number"
          min="0.1"
          step="0.05"
          name={name}
          value={this.props.warehouse[name]}
          onChange={this.props.onChange}
          disabled={disabled}
        />
      </InputGroup>
    </Field>
  )

  heightInput = ({name, label}) => this.numberInput(
    name,
    label,
    'm',
    this.props.formDisabled
  )

  weightInput = ({name, label}) => this.numberInput(
    name,
    label,
    'kg',
    this.props.formDisabled
  )

  toggle = ({name, label }) => (
    <Field label={label}>
      <Toggle
        name={name}
        checked={this.props.warehouse[name]}
        onChange={this.props.onChangeToggle}
        disabled={this.props.formDisabled}
        icons={false}
      />
    </Field>
  )

  requirement = ({name, label}) => {
    const {
      onRequirementToggle,
      formDisabled,
      onRequirementDetailChange,
      warehouse: { requirements },
    } = this.props
    return (
      <Field label={label} className={'requirement'}>
        <Toggle
          name={name}
          checked={requirements[name].applies}
          onChange={onRequirementToggle}
          disabled={formDisabled}
        />
        {
          requirements[name].applies ? (
            <input
              name={name}
              type="text"
              value={requirements[name].details}
              onChange={onRequirementDetailChange}
              disabled={formDisabled || !requirements[name].applies}
              placeholder={'Enter Details'}
            />
          ) : ( null )
        }
      </Field>
    )
  }

  renderRequirements = () => (
    Object.keys(this.props.warehouse.requirements).map(r => (
      <this.requirement name={r} label={startCase(r)} key={r} />
    ))
  )

  render = () => (
    <div className="warehouse-form">
      <div className="double-input">
        <this.toggle name="rearLoading" label="Rear Loading" />
        <this.toggle name="sideLoading" label="Side Loading" />
        <this.toggle name="topLoading" label="Top Loading" />
      </div>

      <div className="double-input">
        <this.heightInput
          name={'loadingDoorHeight'}
          label={'Max Loading Height'}
          suffix={'m'}
        />

        <this.weightInput
          name={'maxForkLiftWeight'}
          label={'Max Fork Lift Weight'}
          suffix={'kg'}
        />
      </div>

      <div className="warehouse-requirements">
        {this.renderRequirements()}
      </div>
    </div>
  )
}

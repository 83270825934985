import React, { Component } from 'react'

import './floating-alert.scss'


const LIFESPAN = 4000

export class FloatingAlert extends Component {

  state = {
    visible: false,
    expired: false
  }

  componentDidMount = () => (
    this.visible()
  )

  appear = () => (
    this.setState({ visible: true })
  )

  visible = () => {
    !this.state.expired && setTimeout(this.appear, 100)
  }

  expire = () => {
    !this.state.expired && setTimeout(this.expireThisAlert, LIFESPAN)
  }

  expireThisAlert = () => {
    this.setState({ expired: true, visible: false })
    setTimeout(this.closeAlert, 300)
  }

  closeAlert = () => {
    this.props.close(this.props.timestamp)
  }

  visibleClass = () => (
    this.state.visible ? 'visible' : ''
  )

  render = () => (
    <div className={`floating-alert ${this.visibleClass()}`}>
      {this.expire()}
      {this.props.message}
      <div className={'close-alert'} onClick={this.closeAlert}>x</div>
    </div>
  )
}

export default FloatingAlert

import {
  USERS_PASSWORD_RESET_START,
  USERS_PASSWORD_RESET_SUCCESS,
  USERS_PASSWORD_RESET_FAILURE
} from '../../root/action-types'

const DEFAULT_STATE = {
  loading: false,
  error: null,
  passwordReset: false,
  details: {},
}

function resetPassword(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case USERS_PASSWORD_RESET_START:
      return {
        ...state,
        loading: true,
        error: null,
        passwordReset: false,
      }
    case USERS_PASSWORD_RESET_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
        passwordReset: true,
        details: action.response.data,
      }
    }
    case USERS_PASSWORD_RESET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.response.data.errors,
        passwordReset: false,
      }
    default:
      return state
  }
}

export default {
  resetPassword
}

import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './GrowingFieldList.scss'

export class GrowingFieldList extends Component {

  state = {
    childrenRendered: this.props.childrenToShow || 1,
  }

  addChild = () => (
    this.setState(prevState => ({
      childrenRendered: prevState.childrenRendered + 1
    }))
  )

  removeChild = () => (
    this.setState(prevState => ({
      childrenRendered: prevState.childrenRendered - 1
    }))
  )

  allChildrenAreRendered = () => (
    this.state.childrenRendered === this.props.children.length
  )

  renderChildrenUpToCurrent = () => (
    this.props.children.slice(0, this.state.childrenRendered - 1)
  )

  childWithOptionsClassName = () => {
    if (
      this.state.childrenRendered === 1
      || this.allChildrenAreRendered()
    ) {
      return 'child-with-an-option'
    }
    return 'child-with-two-options'
  }

  renderChildWithOptions = () => (
    <div className={this.childWithOptionsClassName()}>
      {
        this.props.children[this.state.childrenRendered - 1]
      }
      <this.renderButtons />
    </div>
  )

  renderButtons = () => (
    <div className="growing-field-list-options">
      {
        this.state.childrenRendered > 1 ? <this.renderMinusButton /> : null
      }
      {
        this.allChildrenAreRendered() ? null : <this.renderPlusButton />
      }
    </div>
  )

  renderPlusButton = () => (
    <button onClick={this.addChild} className="plus-button" title="Add Line">
      <FontAwesomeIcon
        icon={['fas', 'plus-circle']}
        className="icon"
        fixedWidth
      />
    </button>
  )

  renderMinusButton = () => (
    <button onClick={this.removeChild} className="minus-button" title="Remove Line">
      <FontAwesomeIcon
        icon={['fas', 'minus-circle']}
        className="icon"
        fixedWidth
      />
    </button>
  )

  render = () => (
    <div className="growing-field-list">
      <this.renderChildrenUpToCurrent />
      <this.renderChildWithOptions />
    </div>
  )

}

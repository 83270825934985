import React, { Component } from 'react'

import AddNewUser from './AddNewUser'
import EditUser from './EditUser'
import ManageUsers from './ManageUsers'

import "./UserManagement.scss"

class UserManagement extends Component {
  state = {
    user: '',
  }

  setUserUnderEdit = (user) => (
    this.setState({ user: user })
  )

  resetUserUnderEdit = () => (
    this.setState({ user: '' })
  )

  renderRightPane = () => {
    if (this.state.user) {
      return (
        <EditUser
          user={this.state.user}
          closeEditUserPane={this.resetUserUnderEdit}
          key={this.state.user.id}
        />
      )
    }
    return <AddNewUser />
  }

  render = () => (
    <div className="user-management-container">
      <div className="main-header">
        <h1>User Management</h1>
        <button className="add-new-user" onClick={ this.resetUserUnderEdit }>
          Add New User
        </button>
      </div>
      <div className="user-management-pane-container">
        <ManageUsers
          editUser={this.setUserUnderEdit}
        />
        {this.renderRightPane()}
      </div>
    </div>
  )
}

export default UserManagement

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isEqual } from 'lodash'

import {
  PLANNER_FILTER_PARAMS,
} from '../../root/action-types'

import WeekDayControl from './week-day-picker/WeekDayControl'
import ColumnSelect from '../../generic/indexTable/ColumnSelect'
import { COLUMNS as PLANNER_COLUMNS } from '../../jobs/JobsIndex'
import {
  DEFAULT_COLUMNS as DEFAULT_PLANNER_COLUMNS,
  DEFAULT_JOB_STATUS,
} from '../manage/config'
import CountriesPicker from './CountriesPicker'
import StatusPicker from './StatusPicker'
import { PLANNER_COLUMNS_SELECT, PLANNER_JOB_STATUS_SELECT } from '../../root/action-types'
import { STATUS as JOB_STATUS } from '../../jobs/constants'

import './planner-controls.scss'


export const Control = (props) => (
  <div className="planner-control">
    {props.children}
  </div>
)

class ControlBar extends Component {

  state = {
    columnPickerOpen: false,
    jobStatusPickerOpen: false,
  }

  stateSetter = (name) => (
    (value) => this.setState({ [name]: value }, this.updateFilterOptions)
  )
  showColumnSelector = () => this.setState({ columnPickerOpen: true })
  hideColumnSelector = () => this.setState({ columnPickerOpen: false })

  showJobStatusSelector = () => this.setState({ jobStatusPickerOpen: true }) 
  hideJobStatusSelector = () => this.setState({ jobStatusPickerOpen: false })

  setColumns = (value) => {
    this.props.dispatch({ type: PLANNER_COLUMNS_SELECT, payload: [...value] })
  }

  setJobStatuses = (value) => {
    this.props.dispatch({ type: PLANNER_JOB_STATUS_SELECT, payload: [...value] })
  }

  updateParam = (name) => (
    (value) => {
      if (isEqual(this.props.params[name], value)) { return }

      this.props.dispatch({
        type: PLANNER_FILTER_PARAMS,
        payload: { [name]: value },
      })
    }
  )
  updateOriginCountries = this.updateParam('originCountries')
  updateDestinationCountries = this.updateParam('destinationCountries')
  updateStatuses = this.updateParam('statuses')
  updateWeekNumber = this.updateParam('weekNumber')
  updateFilterByDate = this.updateParam('filterByDate')
  updateDateOfUse = this.updateParam('dateOfUse')

  renderColumnSelect = () => (
    <Control>
      <button
        className="columns"
        onClick={this.showColumnSelector}
        title="Change columns"
      >
        <FontAwesomeIcon icon="columns" />
      </button>
      <ColumnSelect
        open={this.state.columnPickerOpen}
        close={this.hideColumnSelector}
        options={PLANNER_COLUMNS}
        value={this.props.columns}
        setColumns={this.setColumns}
        defaultColumns={DEFAULT_PLANNER_COLUMNS}
      />
    </Control>
  )

  renderJobStatusSelect = () => (
    <Control>
      <button
        className="columns"
        onClick={this.showJobStatusSelector}
        title="Choose Job Statuses"
      >
        <FontAwesomeIcon icon="pallet" />
      </button>
      <ColumnSelect
        title={'Job Status Selection'}
        open={this.state.jobStatusPickerOpen}
        close={this.hideJobStatusSelector}
        options={JOB_STATUS}
        value={this.props.jobStatuses}
        setColumns={this.setJobStatuses}
        defaultColumns={DEFAULT_JOB_STATUS}
      />
    </Control>
  )

  renderCountrySelects = () => (
    <React.Fragment>
      <CountriesPicker
        label={'origin'}
        value={this.props.params.originCountries}
        onChange={this.updateOriginCountries}
      />
      <CountriesPicker
        label={'destination'}
        value={this.props.params.destinationCountries}
        onChange={this.updateDestinationCountries}
      />
    </React.Fragment>
  )

  renderStatusSelect = () => (
    <StatusPicker
      value={this.props.params.statuses}
      onChange={this.updateStatuses}
    />
  )

  controlRowColumnClass = ({ params }) => {
    const { originCountries, destinationCountries, statuses } = params
    const filterCount = (
      originCountries.length
      + destinationCountries.length
      + statuses.length
    )
    return filterCount > 4 ? 'column-mode' : null
  }

  render = () => (
    <div className={`planner-controls ${this.controlRowColumnClass(this.props)}`}>
      <WeekDayControl
        value={this.props.params.dateOfUse}
        weekNumber={this.props.params.weekNumber}
        filterByDate={this.props.params.filterByDate}
        updateWeekNumber={this.updateWeekNumber}
        updateFilterByDate={this.updateFilterByDate}
        updateDateOfUse={this.updateDateOfUse}
      />
      {this.renderCountrySelects()}
      {this.renderStatusSelect()}
      {this.renderColumnSelect()}
      {this.renderJobStatusSelect()}
    </div>
  )
}

const mapStateToProps = ({ planner: { config: { columns, jobStatuses }, filter: { params } } }) => ({
  jobStatuses,
  columns,
  params,
})

export default connect(mapStateToProps)(ControlBar)

import React from 'react'

import { Field, InputGroup } from '../../forms/'
import { countryName } from '../../addresses/countries'

const location = ({ postcode, region }) => (
  [postcode, region].filter(f => f).join(' - ')
)

const Summary = ({ customerName, country, direction, ...props }) => (
  <div className="summary">
    <Info label="Customer" value={customerName} />
    <Info label="Country" value={countryName(country)} />
    <Info label="Direction" value={direction} />
    <Info label="Location" value={location(props)} />
    <Consignment {...props} />
  </div>
)

const Info = ({ label, value, prefix, suffix }) => (
  <Field label={label}>
    <InputGroup prefix={prefix} suffix={suffix}>
      <input disabled value={value} />
    </InputGroup>
  </Field>
)

const Consignment = ({ cubicMetres, grossWeightKg }) => (
  <>
  <Info label="Cubic Metres" value={cubicMetres} suffix="m³" />
  <Info label="Gross Weight" value={grossWeightKg} suffix="kg" />
  </>
)

export default Summary

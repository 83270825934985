import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import { startCase, get } from 'lodash'

import Api from '../api/call'
import ActionTypes from '../root/action-types'

const { COMPANIES_SHOW } = ActionTypes

const useDefaultFreightMode = (customerId) => {
  useEffect(() => {
    Api({ request: COMPANIES_SHOW, id: customerId })
  }, [customerId])

  return useSelector(r => get(r.companies.show, 'data.defaultFreightMode'))
}

const selectOptions = (options) => (
  options.sort((a, b) => parseFloat(a.rate) - parseFloat(b.rate))
    .map(o => ({ ...o, label: <Option {...o} />, }))
)

export const Option = ({ name, rate }) => (
  <div className="option">
    <span className="choice">{startCase(name)}</span>
    <span className="rate">{'€' + rate.toFixed(2)}</span>
  </div>
)

const freightOption = ({ name, rate, min }) => ({
  label: <Option name={name} rate={rate} />,
  name,
  value: { rate, min },
})

const useFreightOptions = () => {
  const options = useSelector(r => r.calculator.rates.freight)
  return (options || []).map(freightOption).sort(
    (a, b) => parseFloat(b.value.rate) - parseFloat(a.value.rate)
  )
}

export const FreightSelect = ({ value, onChange, customerId, ...props }) => {
  const options = useFreightOptions()
  const defaultName = useDefaultFreightMode(customerId)
  const defaultOption = options.find(f => f.name == defaultName) || options[0]

  useEffect(() => {
    onChange && onChange(defaultOption)
  }, [defaultName])

  return <Select
    className="quote-rate-select select"
    classNamePrefix="qrs select"
    options={options}
    value={value}
    onChange={onChange}
    {...props}
  />
}

const RateSelect = ({ options, value, onChange, ...props }) => {
  const selection = selectOptions(options || [])
  if (!value && onChange && selection[0]) { onChange(selection[0]) }

  return <Select
    className="quote-rate-select select"
    classNamePrefix="qrs select"
    options={selection}
    value={value}
    onChange={onChange}
    {...props}
  />
}

export default RateSelect

import React, { Component } from 'react'
import { capitalize, get } from 'lodash'
import { connect } from 'react-redux'

import Api from '../../api/call'
import { COMPANIES_DETAILS } from '../../root/action-types'
import { countryName } from '../../addresses/countries'
import { Info } from '../../generic/show/sidebar.js'
import CompanyName from '../Name'

import './sidebar.scss'

export class Sidebar extends Component {

  componentDidMount() {
    const { id } = this.props
    id && Api({ request: COMPANIES_DETAILS, id })
  }

  componentDidUpdate(prevProps) {
    const last = prevProps.id
    const current = this.props.id
    if (current && current != last) {
      this.componentDidMount()
    }
  }

  address = (id) => {
    try {
      const { lines, postcode, countryCode } = this.props.details[id].primaryAddress
      return [...lines, postcode, countryName(countryCode)].filter(x => x).join(',\n')
    } catch (e) {
      return ''
    }
  }

  companyStatus = (id) => {
    const status = get(this.props, `details[${id}].status`, '')
    return (
      <span className="status">
        <i className={status} />{capitalize(status)}
      </span>
    )
  }

  companyName = (id) => (
    get(this.props, `details[${id}].name`)
  )

  render = () => {
    const { id } = this.props

    return (
      <div className="company-sidebar">
        <div className="details primary">
          <h1><CompanyName id={id} /></h1>
          <Info name="Company Status" value={this.companyStatus(id)} />
        </div>
        <div className="details customer-details tertiary">
          <h4>Main Address</h4>
          <Info className="address" name="" value={this.address(id)} />
        </div>
        <div className="details financial secondary">
          <h4>Financial Details</h4>
          <Info name="Combined Balance" value="$0.00" />
          <Info name="Customer (Pending)" value="$0.00" />
          <Info name="Customer (Posted)" value="$0.00" />
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ companies: { details } }) => ({
  details: get(details, 'details', {}),
})

export default connect(mapStateToProps)(Sidebar)


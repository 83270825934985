import React, { Component } from 'react'
import { IconButton } from '../generic/Buttons'
import { Link } from 'react-router-dom'
import GroupagesIndex from './GroupagesIndex'

import "./ViewAllGroupages.scss"

class ViewAllGroupages extends Component {

  render = () => (
    <div className="view-all-groupages-container ">
      <div className="index-controls">
        <h1>Groupages</h1>
        <Link to="/groupages/new">
          <IconButton className="primary" icon="plus-circle">
            New Groupage
          </IconButton>
        </Link>
      </div>
      <GroupagesIndex/>
    </div>
  )
}

export default ViewAllGroupages

import React, { Component } from 'react'
import { connect } from 'react-redux'

import SimpleIndexTable from '../../generic/indexTable/Simple'
import { TARIFFS_SURCHARGES } from '../../root/action-types'

const COLUMNS = [
  { Header: "Category", accessor: "category" },
  { Header: "Context", accessor: "context" },
  { Header: "Name", accessor: "name" },
  { Header: "Rate", accessor: "rate" },
]

export class Index extends Component {

  render = () => (
    <div className="tariffs-surcharges-index">
      <SimpleIndexTable
        title="Tariff Surcharges"
        actionType={TARIFFS_SURCHARGES}
        model="surcharge"
        linkString="/tariffs/surcharges/"
        columns={COLUMNS}
        data={this.props.data}
        history={this.props.history}
      />
    </div>
  )

}

const mapStateToProps = ({ tariffs: { surcharges: { index } } }) => (
  index
)

export default connect(mapStateToProps)(Index)

import React from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'

import Api from '../api/call'
import { XERO_CONTACTS } from '../root/action-types'

const XeroContactSelect = ({ options, value, onChange, ...props }) => {
  if (!options) { Api({ request: XERO_CONTACTS }) }

  return (
    <Select
      className="select"
      classNamePrefix="select"
      options={options || []}
      value={options && options.find(o => o.value == value)}
      onChange={o => onChange(o && o.value)}
      isDisabled={props.disabled}
      isClearable
      {...props}
    />
  )
}

const selectData = (byId) => (
  Object.values(byId).map(c => ({ value: c.contactId, label: c.name }))
)

const mapStateToProps = ({ xero: { contacts: { byId, loading } } }) => ({
  options: byId && selectData(byId),
  loading,
})

export default connect(mapStateToProps)(XeroContactSelect)


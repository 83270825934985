export const cm_cubed_to_m_cubed = (volume) => (
  volume / 1.0e+06
)

export const line_volume_in_cm_cubed = ({ quantity, length, width, height}) => {
  const volume_cm_cubed = [quantity, length, width, height].reduce(
    (total, value) => total *= parseFloat(value)
  )

  return volume_cm_cubed
}

export const calculate_line_volume = (line) => {
  const volume_cm_cubed = line_volume_in_cm_cubed(line)
  const volume_m_cubed = cm_cubed_to_m_cubed(volume_cm_cubed)

  return volume_m_cubed
}

export const total_dimension_lines_volume = (lines) => {
  const line_volumes = lines.map(line => calculate_line_volume(line))

  const total_volume = line_volumes.reduce(
    ((total, value) => total + value), 0.0
  )

  return total_volume
}

export const round_up_to_two_decimal_places = (value) => (
  Math.ceil(100.0 * value) / 100.0
)

export const autoTotalCubicMetres = (lines) => (
  round_up_to_two_decimal_places(total_dimension_lines_volume(lines))
)

const lineLoadingMetres = ({ quantity, width, length }) => (
  (parseFloat(quantity) * parseFloat(width) * parseFloat(length)) / 24000
)

export const autoTotalLoadingMetres = (lines) => (
  lines.map(lineLoadingMetres).reduce((total, val) => total + val).toFixed(2)
)

export const autoPackageLength = (lines) => (
  Math.max(...lines
    .map(l => [l.length, l.width])
    .flat()
    .map(x => parseFloat(x))
    .filter(x => x)
  )
)

export const autoPackageHeight = (lines) => (
  Math.max(...lines.map(l => parseFloat(l.height)))
)

export const autoPackageWeight = (lines) => (
  Math.max(...lines.map(l => parseFloat(l.weight)))
)

export const autoTotalQuantity = (lines) => (
  lines.map(line => parseFloat(line.quantity)).reduce(
    ((total, value) => total + value), 0.0
  )
)

export const autoTotalPackageType = (lines, defaultType = 'Package') => {
  const uniqueCategories = [...new Set(lines.map(line => line.type))]
  if (uniqueCategories.length == 1) {
    return uniqueCategories[0]
  }
  return defaultType
}

export const autoTotalWeight = (lines) => (
  lines
    .map(line => (line.quantity * line.weight))
    .reduce(((total, value) => total + value), 0.0)
)

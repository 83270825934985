import React from 'react'
import { useSelector } from 'react-redux'
import Select from 'react-select'

import Api from '../api/call'
import { XERO_TAX_RATES } from '../root/action-types'


const taxRateOptions = (taxRates, category) => (
  (taxRates[category] || []).map(o => ({
    label: o.name,
    value: o.type,
  }))
)


// We only need to fetch tax rates from xero once; they are unlikely to change
// during runtime (and users can reload the page if it ever happens)
// We need a module level var because if 10 components mount at once when the
// billing tab loads, it makes 10 api requests and gives bad performance.
// Since redux actions are async, we can not rely on the loading prop here.
let loaded

const TaxRateSelect = ({ value, onChange, category, ...props }) => {
  const { loading, ...taxRates } = useSelector(redux => redux.xero.taxRates)
  if (!loaded) {
    Api({ request: XERO_TAX_RATES })
    loaded = true
  }
  const options = taxRateOptions(taxRates, category)

  return (
    <Select
      className="select"
      classNamePrefix="select"
      options={options}
      value={options.find(o => o.value == value)}
      onChange={({ value }) => onChange(value)}
      isDisabled={props.disabled}
      isLoading={loading}
      {...props}
    />
  )
}

export default TaxRateSelect

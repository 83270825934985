import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Modal from 'react-modal'

import "./ModalWindow.scss"

if (process.env.NODE_ENV !== 'test') {
  // Prevents console warnings in production.
  // Not required for testing, and causes test failures if present.
  Modal.setAppElement('#root')
}

const ModalWindow = ({ isOpen, closeModal, className, children }) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={closeModal}
    className={"modal-window " + className}
    overlayClassName="modal-overlay"
    shouldCloseOnOverlayClick={true}
    ariaHideApp={ !process.env.NODE_ENV === 'test' } // Don't whinge in test
  >
    <div className="close-modal" onClick={closeModal}>
      <FontAwesomeIcon icon={['fas', 'times']} size="lg" fixedWidth />
    </div>
    <div className="modal-content">
      {children}
    </div>
  </Modal>
)

export default ModalWindow

import React, { Component } from 'react'
import { startCase } from 'lodash'

import { Tabs, Tab, TabList, TabPanel } from 'react-tabs'

import AllNotes from './AllNotes'

import './NotesTab.scss'

const COMPANY_NOTE_TYPES = [
  'sales',
  'bookings',
  'finances',
  'warehouse',
]


export default class NotesTab extends Component {

  renderTabHeaders = () => (
    COMPANY_NOTE_TYPES.map(c => 
      <Tab key={`notes-category-tab-header-${c}`}>
        {startCase(c)}
      </Tab>
    )
  )

  renderTabPanels = () => (
    COMPANY_NOTE_TYPES.map(c =>
      <TabPanel
        key={`notes-category-tab-panel-${c}`}
      >
        <AllNotes
          key={this.props.accordionKey}
          {...this.props}
          category={c}
        />
      </TabPanel>
    )
  )

  render = () => (
    <div className="notes-container">
      <Tabs>
        <TabList>
          {this.renderTabHeaders()}
        </TabList>
        {this.renderTabPanels()}
      </Tabs>
    </div>
  )
}

import { combineReducers } from 'redux'

import {
  standardForCollections,
} from '../../../api/standard-reducers'

import {
  MARK_FOR_EMAILING,
  GROUPAGE_BULK_EMAIL_SUCCESS,
  GROUPAGE_SEND_JOB_EMAILS_START,
  GROUPAGE_SEND_JOB_EMAILS_SUCCESS,
  GROUPAGE_SEND_JOB_EMAILS_FAILURE,
} from '../../../root/action-types'

const INITIAL = {}

const resetMarkedForEmailing = (jobs) => (
  jobs.reduce((obj, job) => {
    obj[job.id] = false
    return obj
  }, {})
)

export const markedForEmailing = (state = INITIAL, action) => {
  switch (action.type) {
    case MARK_FOR_EMAILING: {
      const id = action.payload.id
      return {
        ...state,
        [id]: !state[id]
      }
    }
    case GROUPAGE_BULK_EMAIL_SUCCESS:
      return {
        ...resetMarkedForEmailing(action.response.data.data)
      }
    default:
      return state
  }
}

const INITIAL_SEND_EMAILS = {
  loading: false,
  emailResponses: {},
}

export const sendEmails = (state = INITIAL_SEND_EMAILS, action) => {
  switch (action.type) {
    case GROUPAGE_SEND_JOB_EMAILS_START:
      return {
        ...state,
        loading: true,
      }
    case GROUPAGE_SEND_JOB_EMAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        emailResponses: action.response.data,
      }
    case GROUPAGE_SEND_JOB_EMAILS_FAILURE:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}

export const TEST_ACCESS = {
  INITIAL,
  resetMarkedForEmailing,
  sendEmails,
}

export default combineReducers({
  jobs: standardForCollections('GROUPAGE_BULK_EMAIL'),
  markedForEmailing,
  sendEmails,
})

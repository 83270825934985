import React, { Component } from 'react'
import { omit, get, startCase } from 'lodash'

import {
  Field, CheckBox, CountrySelect, SimpleSelect, FormTable, InputGroup,
} from '../../forms/'
import { CompanySelect } from '../../forms/select'
import Api from '../../api/call'
import {
  TARIFFS_DISTRIBUTION_UPDATE,
  TARIFFS_DISTRIBUTION_CREATE
} from '../../root/action-types'
import { apiFormat, tableFormat } from './rate-formatting'

const DIRECTIONS = ['Import', 'Export', 'Both']

const MODES = ['Country', 'Partial Postcode', 'Region']

export default class Form extends Component {

  state = omit(this.props.fields, ['rates'])

  action = () => (
    this.state.id ? TARIFFS_DISTRIBUTION_UPDATE : TARIFFS_DISTRIBUTION_CREATE
  )

  actionName = () => (
    `${this.state.id ? 'Update' : 'Create'} ` +
    `${this.state.direction == 'Import' ? 'Pickup' : 'Distribution'} Tariff`
  )

  setToggle = ({ target: { name } }) => (
    this.setState({ [name]: !this.state[name] })
  )

  formToggle = ({ name }) => (
    <CheckBox
      name={name}
      label={startCase(name)}
      value={this.state[name] || false}
      onChange={this.setToggle}
      disabled={!this.props.edit}
    />
  )

  field = ({ label, name, suffix, ...props }) => (
    <Field label={label || startCase(name)}>
      <InputGroup suffix={suffix}>
        <input
          name={name}
          value={this.state[name] || ''}
          onChange={this.setFormValue}
          disabled={!this.props.edit}
          type="number"
          {...props}
        />
      </InputGroup>
    </Field>
  )

  setFormValue = ({ target: { name, value } }) => {
    this.setState({ [name]: value })
  }

  registerFormTable = (getter) => {
    this.getRates = getter
  }

  submit = () => {
    Api({
      request: this.action(),
      id: this.state.id,
      data: {
        ...this.state,
        rates: apiFormat(this.getRates())
      }
    })
  }

  tableRates = () => (
    tableFormat(get(this.props.fields, 'rates'))
  )

  ifEditting = ({ children }) => (
    this.props.edit ? children : null
  )

  stateSetter = (name) => (
    (val) => this.setState({ [name]: val })
  )

  setCountry = this.stateSetter('country')
  setDirection = this.stateSetter('direction')
  setMode = this.stateSetter('mode')

  setSupplierId = ({ value }) => {
    this.setState({ supplier_id: value })
  }

  render = () => (
    <div className="form">
      <div className="section details">
        <div className="header">
          <h3>Details</h3>
        </div>
        <div className="header-rule" />
        <div className="fields">
          <CountrySelect
            value={this.state.country}
            onChange={this.setCountry}
            isDisabled={!this.props.edit}
          />
          <CompanySelect
            label="Supplier"
            value={this.state.supplier_id}
            onChange={this.setSupplierId}
            isDisabled={!this.props.edit}
            supplier
          />
          <this.field name="cbm_kg" label="CBM:KG Ratio" />
          <this.field name="ldm_kg" label="LDM:KG Ratio" />
          <this.field name="height_kg" label="Height M:KG Ratio" />
          <this.field name="max_package_height" placeholder="N/A" suffix="cm" />
          <this.field name="max_package_weight" placeholder="N/A" suffix="kg" />
          <SimpleSelect
            label="Direction"
            options={DIRECTIONS}
            onChange={this.setDirection}
            value={this.state.direction}
            isDisabled={!this.props.edit}
          />
          <SimpleSelect
            label="Mode"
            options={MODES}
            onChange={this.setMode}
            value={this.state.mode}
            isDisabled={!this.props.edit}
          />
          <div className="toggles">
            <this.formToggle name="all_in" />
            <this.formToggle name="disabled" />
          </div>
        </div>
      </div>
      <div className="section scrollable">
        <div className="header">
          <h3>Rates</h3>
        </div>
        <div className="header-rule" />
        <FormTable
          headRowPlaceholder="KGs..."
          originType="readOnly"
          headRowType="number"
          cellType="number"
          cells={this.tableRates()}
          register={this.registerFormTable}
          readOnly={!this.props.edit}
        />
      </div>
      <this.ifEditting>
        <div className="controls">
          <button className="primary" onClick={this.submit}>{this.actionName()}</button>
        </div>
      </this.ifEditting>
    </div>
  )
}

import React from 'react'
import { startCase } from 'lodash'

import './priority-switch.scss'

const JOB_PRIORITIES = [
  'low',
  'standard',
  'high',
]
const MAX_PRIORITY_INDEX = JOB_PRIORITIES.length - 1

const positionClassName = (index) => {
  switch(index) {
    case 0: return 'start'
    case MAX_PRIORITY_INDEX: return 'end'
    default: return 'middle'
  }
}

export const PrioritySwitch = ({ value, onChange }) => {

  const priorityOption = (priority, index) => (
    <div
      key={`priority-option-${priority}-${index}`}
      className={`${positionClassName(index)} ${priority == value ? 'selected' : ''}`}
      onClick={() => onChange(priority)}
    >
      {startCase(priority)}
    </div>
  )

  return (
    <div
      className="job-priority-switch"
    >
      <span className="label">Priority</span>
      {JOB_PRIORITIES.map((priority, index) => priorityOption(priority, index))}
    </div>
  )
}

export default PrioritySwitch


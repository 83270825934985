import React, { Component } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class NavGroup extends Component {

  isSelected = () => (
    this.props.selectedGroup == this.props.id
  )

  renderGroupTextToSide = () => (
    <span className='navbar-group-side'>
      {this.props.text}
    </span>
  )

  renderDropdown = () => (
    <div className={this.props.expanded ? 'navbar-drop-down' : 'navbar-drop-side'}>
      {this.props.expanded ? null : this.renderGroupTextToSide()}
      {this.props.children}
    </div>
  )

  render = () => (
    <div className="navbar-group-container">
      <span
        onClick={() => this.props.onClick(this.props.id)}
        className={'navbar-group ' + (this.isSelected() ? "navbar-group-selected" : null)}
      >
        <span className="navbar-icon-container">
          <FontAwesomeIcon icon={this.props.icon} fixedWidth />
        </span>
        <span className="navbar-label">
          {this.props.expanded ? this.props.text : null}
          <span className={'navbar-chevron ' + (this.isSelected() ? "chevron-selected" : null)}>
            {this.props.expanded ? <FontAwesomeIcon icon={['fas', 'chevron-right']} size='xs' /> : null}
          </span>
        </span>
      </span>
      {this.isSelected() ? this.renderDropdown() : null}
    </div>
  )
}

import React from 'react'
import ModalWindow from '../layout/ModalWindow'
import { IconButton } from './Buttons'

function TabChangeConfirmationModal(props) {
  const { proceed, closeModal, modalIsOpen } = props

  const proceedAndClose = () => {
    proceed()
    closeModal()
  }

  return (
    <ModalWindow
      className={'tab-change-confirmation-modal'}
      isOpen={modalIsOpen}
      closeModal={closeModal}
    >
      <h3>Unsaved Changes</h3>
      <span>This tab is still under edit, any unsaved changes will be lost if you continue.</span>
      <div className="options">
        <button onClick={closeModal} className="primary">
          Go Back
        </button>
        <IconButton
          id={"tab-change-confirmation-modal-confirm-button"}
          onClick={proceedAndClose}
          className="delete-button"
          icon="exclamation-triangle"
        >
          Continue
        </IconButton>
      </div>
    </ ModalWindow>
  )
}

export default TabChangeConfirmationModal

import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Toggle from 'react-toggle'

import { Field } from '../../forms/Field'
import { userRoleIcons } from '../../users/roles'

class AssignUserRoles extends Component {

  renderUserRoleOption = ({ name, displayText }) => (
    <div className="user-role-toggle-item">
      <Toggle
        name={name}
        checked={!!this.props.state[name]}
        onChange={this.props.setRole}
        icons={false}
      />
      <span>
        <FontAwesomeIcon icon={userRoleIcons[name]} fixedWidth />
      </span>
      {displayText}
    </div>
  )

  render = () => (
    <Field label="Assign User Roles" disableFocusStyle={true}>
      <div className="toggle-box">
        <this.renderUserRoleOption
          name="admin"
          displayText={'Administrator'}
        />
      </div>
      <div className="rule" />
      <div className="toggle-box">
        <this.renderUserRoleOption
          name={'warehouse'}
          displayText={'Warehouse'}
        />
        <this.renderUserRoleOption
          name={'manager'}
          displayText={'Manager'}
        />
        <this.renderUserRoleOption
          name={'accounts'}
          displayText={'Accounts'}
        />
        <this.renderUserRoleOption
          name={'sales'}
          displayText={'Sales'}
        />
        <this.renderUserRoleOption
          name={'operations'}
          displayText={'Operations'}
        />
        <this.renderUserRoleOption
          name={'claims'}
          displayText={'Claims'}
        />
      </div>
    </Field>
  )
}

export default AssignUserRoles

import { combineReducers } from 'redux'

import {
  TARIFFS_CONFIG,
  TARIFFS_CONFIG_UPDATE,
} from  '../../root/action-types'

const INITIAL_SHOW = { loading: false, details: {}, status: [] }

export const show = (state = INITIAL_SHOW, action) => {
  switch (action.type) {
    case `${TARIFFS_CONFIG}_START`:
      return {
        ...INITIAL_SHOW,
        loading: true,
      }
    case `${TARIFFS_CONFIG}_SUCCESS`:
      return {
        ...state,
        details: action.response.data,
        loading: false,
      }
    case `${TARIFFS_CONFIG_UPDATE}_START`:
      return {
        ...state,
        loading: true,
        status: [],
        hasErrors: false,
      }
    case `${TARIFFS_CONFIG_UPDATE}_SUCCESS`:
      return {
        ...state,
        loading: false,
        details: action.response.data,
        status: ['Config updated successfully!'],
      }
    case `${TARIFFS_CONFIG}_FAILURE`:
    case `${TARIFFS_CONFIG_UPDATE}_FAILURE`:
      return {
        ...state,
        loading: false,
        hasErrors: true,
        details: action.response.data.details,
        status: action.response.data.errors,
      }
    default:
      return state
  }
}

export default combineReducers({
  show,
})

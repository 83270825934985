import React, { Component } from 'react'
import { connect } from 'react-redux'
import Moment from 'moment'
import { Link } from 'react-router-dom'

import Api from '../api/call'
import { QUOTES_HISTORY } from '../root/action-types'
import { Loading } from '../generic/Loading'

import './history.scss'

const HOME_COUNTRY = 'MT'

const Empty = () => (
  <div className="empty">
    No quote history available for customer
  </div>
)

const formatDate = (date) => (
  Moment(date).format('ll')
)

const locations = ({
  originCountryCode, originLocation, destinationCountryCode, destinationLocation
}) => (
  `${originLocation} (${originCountryCode}) → ` +
  `${destinationLocation} (${destinationCountryCode})`
)

const requestParams = ({ direction, customerId, country, location }) => {
  const isAnImport = direction == 'Import'

  return ({
    customerId: customerId,
    [isAnImport ? 'destinationCountryCode' : 'originCountryCode']: HOME_COUNTRY,
    [isAnImport ? 'originCountryCode' : 'destinationCountryCode']: country,
    [isAnImport ? 'originLocation' : 'destinationLocation']: location
  })
}

const locationType = ({
  originCountryCode, destinationCountryCode, originLocationType, destinationLocationType
}) => {
  if (destinationCountryCode == 'MT') { return destinationLocationType }
  if (originCountryCode == 'MT') { return originLocationType }
  return null
}

const measurements = ({ grossWeightKg, cubicMetres, loadingMetres, packageLength }) => {
  const kg = grossWeightKg && `${grossWeightKg}kg`
  const cube = cubicMetres && `${cubicMetres}m³`
  const ldm = loadingMetres && `${loadingMetres} ldm`
  const pl = packageLength && `${packageLength} cm`
  return [kg, cube, ldm, pl].filter(x => x).join(' | ')
}

const perCubicRateText = (total, { cubicMetres }) => {
  if (cubicMetres <= 0) {
    return null
  }

  const perCubicMetre = (total/cubicMetres).toFixed(2)
  return ` (${perCubicMetre} €/m³)`
}

const Quote = ({ reference, status, total, created_at, id, ...props }) => (
  <div className={`quote ${status}`}>
    <div className="ref">
      <span><Link to={`/quotes/${id}`}>{reference}</Link></span>
      <span>{status}</span>
    </div>
    <div>
      <span>{locations(props)}</span>
      <span>{locationType(props)}</span>
    </div>
    <div>
      <span>{measurements(props)}</span>
      <span>
        <span className="rate">{`€${total.toFixed(2)}`}</span>
        {perCubicRateText(total, props)}
      </span>
    </div>
    <div>
      <span>{formatDate(created_at)}</span>
    </div>
  </div>
)

class History extends Component {

  fetchQuotes = () => {
    if (!this.props.details) { return }
    Api({ request: QUOTES_HISTORY, params: requestParams(this.props.details) })
  }

  componentDidMount = this.fetchQuotes

  componentDidUpdate(prevProps) {
    if (prevProps.details != this.props.details) {
      this.fetchQuotes()
    }
  }

  quotesThatWillFit = () => {
    const { quotes } = this.props
    return window.innerHeight < 900 ? quotes.slice(0,3) : quotes.slice(0,4)
  }

  content = () => {
    if (this.props.loading) { return <Loading /> }
    if (!this.props.quotes.length) { return <Empty /> }
    return this.quotesThatWillFit().map(q => <Quote key={q.id} {...q} />)
  }

  render = () => (
    <div className="quotes-history-container">
      <div className="quotes-history">
        <section>
          <div className="header">
            <h4>Customer History</h4>
          </div>
          <div className="header-rule"></div>
        </section>
        <div className="list">
          <this.content />
        </div>
      </div>
    </div>
  )

}

const mapStateToProps = ({ quotes: { history } }) => (
  history
)

export default connect(mapStateToProps)(History)

import React, { Component } from 'react'

export default class TermsOfUse extends Component {
  
  render = () => (
    <div>
      <h1>Terms of Use</h1>
    </div>
  )
}

import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import Api from '../../api/call'
import { Loading } from '../../generic/Loading'
import { Conditional } from '../../forms'

const HEADERS = [
  'Ref', 'Status', 'Booked', 'Week', 'Cube', 'Kg', 'Rate', 'Rate/Cube'
]

const History = ({ id }) => {
  useEffect(() => {
    Api({ request: 'JOBS_HISTORY', params: { id } })
  }, [id])
  const { details, loading } = useSelector(s => s.jobs.history)

  if (loading) { return <Loading /> }

  return (
    <div className="history">
      <h3>History</h3>
      <Headers />
      {details.map((j, i) => <HistoryJob key={i} {...j} />)}
      <Conditional if={details.length == 0}>
        <div className="no-history">No similar jobs found</div>
      </Conditional>
    </div>
  )
}

const Headers = () => (
  <div className="headers">
    {HEADERS.map((h, i) => <span key={i}>{h}</span>)}
  </div>
)

const HistoryJob = (j) => (
  <div className="history-job">
    <span>{j.reference}</span>
    <span>{j.status}</span>
    <span>{formatDate(j.bookingDate)}</span>
    <span>{j.weekNumber}</span>
    <Rates {...j} />
  </div>
)

const formatDate = (d) => {
  if (!d) { return '' }
  return new Date(d).toLocaleDateString()
}

const Rates = ({ total, grossWeightKg, cubicMetres }) => {
  const kg = parseFloat(grossWeightKg)
  const cube = parseFloat(cubicMetres)
  const rate = parseFloat(total)

  return <>
    <span>{cube.toFixed(2)}m³</span>
    <span>{kg.toFixed(0)}kg</span>
    <span>€{rate.toFixed(2)}</span>
    <span>{(rate / cube).toFixed(2)} €/m³</span>
  </>
}

export default History

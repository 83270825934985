import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'

import { ADDRESSES } from '../root/action-types'
import Api from '../api/call'

import Accordion from '../generic/accordion/Accordion'
import Address, { BLANK_ADDRESS } from './Address'

class AllAddresses extends Component {

  state = {
    addressUnderEdit: '',
  }

  setAddressUnderEdit = (address) => (
    this.setState({ addressUnderEdit: address }, this.getCompanyAddresses)
  )

  getCompanyAddresses = () => (
    Api({
      request: ADDRESSES,
      params: { companyId: get(this.props.companyData, 'id') }
    })
  )

  componentDidMount() {
    this.getCompanyAddresses()
  }

  unpackCompanyAddresses = () => (
    get(this.props.index, 'data', [])
  )

  newAddress = () => (
    this.setAddressUnderEdit('new')
  )

  renderNewAddress = () => (
    <Address
      address={BLANK_ADDRESS}
      companyId={get(this.props.companyData, 'id')}
      editMode={true}
      setAddressUnderEdit={this.setAddressUnderEdit}
      newAddress={true}
      open={true}
    />
  )

  render = () => (
    <Accordion
      title={'All Addresses'}
      newItemLabel={'New Address'}
      newItemOnClick={this.newAddress}
    >
      { this.state.addressUnderEdit === 'new' ? this.renderNewAddress() : null }
      {
        this.unpackCompanyAddresses().map((address)=> (
          <Address
            key={address.id}
            address={address}
            editMode={address.id === this.state.addressUnderEdit}
            companyId={get(this.props.companyData, 'id')}
            setAddressUnderEdit={this.setAddressUnderEdit}
            isPrimary={address.id == get(this.props.companyData, 'primaryAddressId')}
          />
        ))
      }
    </Accordion>
  )
}

const mapStateToProps = ({ companies: { addresses: { index } } }) => (
  { index }
)

export default connect(mapStateToProps)(AllAddresses)


const BLANK_TABLE = [["Location", ''], ['', '']]

const uniqueWeights = (weightSets) => (
  [...new Set(weightSets.map(r => Object.keys(r)).flat())]
    .map(x => parseFloat(x)).sort((a, b) => a - b)
)

const monify = (val) => (
  val ? parseFloat(val).toFixed(2) : ''
)

export const tableFormat = (rates) => {
  if (!rates) { return BLANK_TABLE }

  const weights = uniqueWeights(Object.values(rates))
  const locations = Object.keys(rates)

  return [
    ["Location", ...weights],
    ...locations.map(l => [l, ...weights.map(w => monify(rates[l][w]))])
  ]
}

export const apiFormat = (rates) => {
  const data = {}
  const [[/* title */, ...weights], ...locationRates] = rates
  locationRates.forEach(([location, ...rates]) => {
    data[location] = {}
    weights.forEach((weight, i) => {
      if (rates[i]) { data[location][weight] = parseFloat(rates[i]) }
    })
  })

  return data
}


import React from 'react'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import enGB from 'date-fns/locale/en-GB'

import { Field } from '../Field'

import 'react-datepicker/dist/react-datepicker.css'
import './date-selector.scss'


registerLocale('en-GB', enGB)
setDefaultLocale('en-GB')


export const DateSelector = ({ label, required, value, onChange, isDisabled }) => (
  <Field
    className={'date-selector-field'}
    label={label}
    required={required}
  >
    <DatePicker
      locale='en-GB'
      dateFormat='dd/MM/yyyy'
      selected={value ? new Date(value) : null}
      onChange={onChange}
      disabled={isDisabled}
    />
  </Field>
)

export default DateSelector

import React, { Component } from 'react'

import ModalWindow from '../../layout/ModalWindow'
import { DropdownOption } from '../../generic/Buttons'


export default class ConvertToJobOption extends Component {

  state = {
    modalOpen: false
  }

  toggleModal = () => {
    this.setState(prev => ({ modalOpen: !prev.modalOpen }))
  }

  modalIfOpen = () => (
    this.state.modalOpen ? this.modal() : null
  )

  modal = () => (
    <ModalWindow
      className="convert-to-job-modal"
      isOpen={this.state.modalOpen}
      closeModal={this.toggleModal}
    >
      <h3>Convert To Job</h3>
      <div>Are you sure you want to convert <strong>Quote Reference: {this.props.reference}</strong> to a job?</div>
      <div className="options">
        <button className="delete-button" onClick={this.toggleModal}>
          Cancel
        </button>
        <button className="cancel-button" onClick={this.props.convertAction}>
          Convert
        </button>
      </div>
    </ModalWindow>
  )

  render = () => (
    <React.Fragment>
      {this.modalIfOpen()}
      <DropdownOption
        icon="box-open"
        label={`Convert To Job`}
        onClick={this.toggleModal}
      />
    </React.Fragment>
  )

}

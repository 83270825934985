import React, { Component } from 'react'
import { get } from 'lodash'

import EmailQuoteButton from './EmailQuoteButton'
import Download from '../../api/Download'

import ModelActions from '../../generic/ModelActions'
import ConvertToJobOption from './ConvertToJobOption'

import './Controls.scss'


export default class Controls extends Component {

  state = {
    initialLoad: window.location.search.includes('showEmail'),
  }

  componentDidMount = () => (
    setTimeout(() => { this.setState({ initialLoad: false }) }, 500)
  )

  renderQuoteEmail = () => (
    <EmailQuoteButton
      key={get(this.props, 'email.body')}
      parentId={this.props.id}
      customerId={this.props.customerId}
      email={this.props.email}
      modalOpen={this.state.initialLoad}
    />
  )

  renderDownloadButton = () => (
    <Download type="quote" id={this.props.id}>
      <button className="primary">Download PDF</button>
    </Download>
  )

  render = () => (
    <div className="quote-controls">
      <div className="left">
        <this.renderQuoteEmail />
        <this.renderDownloadButton />
      </div>
      <div className="right">
        <ModelActions
          modelName={'Quote'}
          edit={this.props.edit}
          editAction={this.props.editAction}
          disableEdit={this.props.disableEdit}
          deleteAction={this.props.destroyAction}
        >
          {
            this.props.status == "Converted" ? null :
              <ConvertToJobOption
                reference={this.props.reference}
                convertAction={this.props.convertAction}
              />
          }
        </ModelActions>
      </div>
    </div>
  )
}

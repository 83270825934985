import React from 'react'
import Select from 'react-select'
import { orderBy, startCase } from 'lodash'

import { Field } from '../Field'

import './select.scss'

// This is convenient but currently unoptimized for re-renders. Suggest
// not using with more than a few options.

export const SimpleSelect = ({ label, value, options, onChange, fieldClass, ...props }) => {
  const hash = {}
  options.forEach(o => hash[o] = ({ label: startCase(o), value: o }))
  const selectOptions = orderBy(Object.values(hash), ['label'])

  return <Field label={label} className={fieldClass}>
    <Select
      classNamePrefix="select"
      className="select"
      value={hash[value]}
      options={selectOptions}
      onChange={(o) => onChange(o && o.value)}
      {...props}
    />
  </Field>
}

export default SimpleSelect

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { isEqual, get, omit, pick, startCase } from 'lodash'
import qs from 'qs'

import Api from '../api/call'
import {
  InputGroup, Field, FieldSection, CheckBox, ExpandSection, IncotermType,
} from '../forms/'
import { SimpleSelect, CompanySelect, PackageTypeSelect } from '../forms/select'
import {
  CALCULATOR_LOCATIONS,
  CALCULATOR_RATE,
  CALCULATOR_SAVE_DETAILS,
} from '../root/action-types'
import PackageDimensionLinesCalculator from '../package-dimension-lines/PackageDimensionLinesCalculator'

import CountrySelect from './CountrySelect'
import RestoreDetailsModal from './RestoreDetailsModal'

const DIRECTIONS = ['Import', 'Export']

const REQUIRED = [
  'direction',
  'country',
  'location',
  'customerId',
  'grossWeightKg',
  'cubicMetres'
]

const getCustomerIdParam = (props) => (
  qs.parse(get(props, 'location.search', '').slice(1))['customer']
)

const getUpToDoorParam = (props) => {
  const stringValue = qs.parse(get(props, 'location.search', '').slice(1))['upToDoor']
  if (stringValue === 'true') {
    return true
  }
  return false
}

const SURCHARGE_DEFAULTS = {
  'customs': false,
  'intrastat': false,
  'hazardous': false,
  'tailLift': false,
  'express': false,
  'forkLift': false,
  'insured': false,
}

const SURCHARGES = Object.keys(SURCHARGE_DEFAULTS)

const BLANK_FORM = {
  direction: DIRECTIONS[0],
  incotermType: 'EXW',
  country: null,
  location: null,
  grossWeightKg: '',
  cubicMetres: '',
  loadingMetres: '',
  packageLength: '',
  maxPackageHeight: '',
  maxPackageWeight: '',
  customerId: '',
  customerReference: '',
  packageDimensionLines: undefined,
  packageType: '',
  packageQuantity: '',
  freightMode: '',
  upToDoor: false,
  ...SURCHARGE_DEFAULTS,
}


export class QuoteDetails extends Component {

  state = {
    ...BLANK_FORM,
    customerId: getCustomerIdParam(this.props) || '',
    upToDoor: getUpToDoorParam(this.props),
    modalOpen: false,
  }

  openModal = () => this.setState({ modalOpen: true })
  closeModal = () => this.setState({ modalOpen: false })

  details = () => omit(this.state, 'modalOpen')

  componentWillUnmount() {
    this.props.dispatch({
      type: CALCULATOR_SAVE_DETAILS,
      details: this.details(),
    })
  }

  componentDidMount() {
    if (
      this.props.savedDetails
      && !getCustomerIdParam(this.props)
      && !isEqual(this.details(), this.props.savedDetails)
    ) {
      this.openModal()
    }
  }

  restoreDetails = () => this.setState(this.props.savedDetails)

  clear = () => {
    this.setState({ ...BLANK_FORM })
  }

  submit = () => (
    Api({ request: CALCULATOR_RATE, params: this.formData() })
  )

  formData = () => ({
    ...pick(this.state, [
      'direction', 'location', 'cubicMetres', 'upToDoor', 'country',
      'incotermType', 'grossWeightKg', 'loadingMetres', 'maxPackageLength',
      'maxPackageHeight', 'maxPackageWeight',
      ...SURCHARGES,
    ]),
  })

  setCountry = (country) => {
    this.setState({ country, location: null }, this.fetchLocations)
  }

  fetchLocations = () => {
    Api({
      request: CALCULATOR_LOCATIONS,
      params: pick(this.state, ['country', 'direction'])
    })
  }

  stateSetter = (name) => (
    (val) => this.setState({ [name]: val })
  )

  setDirection = (direction) => {
    const incotermType = direction == 'Export' ? 'DAP' : 'EXW'
    this.setState({ direction, incotermType }, this.fetchLocations)
  }

  setLocation = this.stateSetter('location')
  setCustomer = ({ value }) => {
    this.setState({
      customerId: value,
      upToDoor: this.defaultUpToDoor(value)
    })
  }

  setPackageType = ({ type }) => {
    this.setState({ packageType: type })
  }

  defaultUpToDoor = (customerId) => {
    const { results } = this.props.search
    const customerResult = results.find(r => r.id === customerId)
    const defaultUpToDoor = get(customerResult, 'defaultUpToDoor')
    return defaultUpToDoor
  }

  locationPlaceholder = () => {
    if (!this.state.country) { return 'Country required' }
    if (this.props.locationsLoading) { return 'Loading...' }
    if (this.props.locations.length < 1) { return 'No tariffs available' }
    return 'Select location...'
  }

  hasMissingFields = () => (
    !REQUIRED.every(f => this.state[f])
  )

  setFormValue = ({ target: { name, value } }) => (
    this.setState({ [name]: value })
  )

  setToggle = ({ target: { name } }) => (
    this.setState({ [name]: !this.state[name] })
  )

  setIncoterm = ({ value }) => (
    this.setState({ incotermType: value })
  )

  setPackageDimensionLines = (lines) => (
    this.setState({ packageDimensionLines: [...lines] })
  )

  autoPackageFields = (auto) => {
    this.setState(auto)
  }

  expandDims = () => {
    this.setState(prev => ({ expandDims: !prev.expandDims }))
  }

  expandSurcharges = () => {
    this.setState(prev => ({ expandSurcharges: !prev.expandSurcharges }))
  }

  surchargeToggles = () => (
    SURCHARGES.map(name => this.FormToggle({ name }))
  )

  FormInput = ({ label, suffix, prefix, name, ...props }) => (
    <Field label={label || startCase(name)}>
      <InputGroup suffix={suffix} prefix={prefix}>
        <input
          name={name}
          value={this.state[name]}
          onChange={this.setFormValue}
          type="number"
          min="0"
          {...props}
        />
      </InputGroup>
    </Field>
  )

  FormToggle = ({ label, name, ...props }) => (
    <CheckBox
      key={name}
      label={label || startCase(name)}
      name={name}
      value={this.state[name] || false}
      onChange={this.setToggle}
      {...props}
    />
  )

  toggleDoor = () => {
    const next = !this.state.upToDoor
    this.setState({ upToDoor: next, forkLift: next })
  }

  render = () => (
    <div className="quote-details form">
      <RestoreDetailsModal
        modalIsOpen={this.state.modalOpen}
        restoreDetails={this.restoreDetails}
        closeModal={this.closeModal}
      />
      <FieldSection title="Customer">
        <CompanySelect
          key={`customer-select-${this.state.customerId}`}
          label="Customer"
          value={this.state.customerId}
          onChange={this.setCustomer}
          customer
          allowCreation
        />
        <this.FormInput name="customerReference" label="Customer Reference" type="text" />
      </FieldSection>
      <FieldSection title="Details">
        <SimpleSelect
          label="Direction"
          options={DIRECTIONS}
          onChange={this.setDirection}
          value={this.state.direction}
        />
        <Field label="Country">
          <CountrySelect value={this.state.country} onChange={this.setCountry} />
        </Field>
        <SimpleSelect
          label="Location"
          value={this.state.location}
          onChange={this.setLocation}
          options={this.props.locations}
          placeholder={this.locationPlaceholder()}
          key={this.state.country}
        />
        <IncotermType value={this.state.incotermType} onChange={this.setIncoterm} />
        <div className="toggle-list">
          <this.FormToggle name="upToDoor" label="Up To Door" onChange={this.toggleDoor} />
        </div>
      </FieldSection>
      <FieldSection title="Consignment">
        <this.FormInput
          label="Gross Weight"
          name="grossWeightKg"
          suffix="KG"
        />
        <this.FormInput name="cubicMetres" suffix="m³" />
        <this.FormInput name="loadingMetres" suffix="ldm" />
        <this.FormInput name="packageQuantity" />
        <this.FormInput name="maxPackageLength" suffix="cm" />
        <this.FormInput name="maxPackageHeight" suffix="cm" />
        <this.FormInput name="maxPackageWeight" suffix="kg" />
        <Field label="Package Type">
          <PackageTypeSelect
            onChange={this.setPackageType}
            valueName={this.state.packageType}
          />
        </Field>
      </FieldSection>
      <ExpandSection
        title="Package Dimensions"
        toggleExpanded={this.expandDims}
        expanded={this.state.expandDims}
      >
        <PackageDimensionLinesCalculator
          key={get(this.state, 'packageDimensionLines', []).length}
          measurer={'customer'}
          lines={this.state.packageDimensionLines || []}
          onChange={this.setPackageDimensionLines}
          updateFields={this.autoPackageFields}
          noWrapper
        />
      </ExpandSection>
      <ExpandSection
        title="Surcharges"
        toggleExpanded={this.expandSurcharges}
        expanded={this.state.expandSurcharges}
      >
        <div className="toggle-list">
          <this.surchargeToggles />
        </div>
      </ExpandSection>
      <div className="step-controls">
        <button className="danger" onClick={this.clear}>Clear</button>
        <button
          className="primary"
          onClick={this.submit}
          disabled={this.hasMissingFields()}
        >Calculate</button>
      </div>
    </div>
  )

}

const mapStateToProps = ({ calculator, companies: { search } }) => ({
  ...calculator,
  search
})

export default connect(mapStateToProps)(QuoteDetails)

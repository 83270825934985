import React, { Component } from 'react'
import { capitalize, get } from 'lodash'
import { connect } from 'react-redux'

import Api from '../api/call'
import { COMPANIES_DETAILS } from '../root/action-types'
import { countryName } from '../addresses/countries'
import { Info } from './show/sidebar.js'
import CompanyName from '../companies/Name'

import './sidebar.scss'

export class Sidebar extends Component {

  componentDidMount() {
    const { id } = this.props
    id && Api({ request: COMPANIES_DETAILS, id })
  }

  componentDidUpdate(prevProps) {
    const last = prevProps.id
    const current = this.props.id
    if (current && current != last) {
      this.componentDidMount()
    }
  }

  address = (id) => {
    try {
      const { lines, postcode, countryCode } = this.props.details[id].primaryAddress
      return [...lines, postcode, countryName(countryCode)].filter(x => x).join(', ')
    } catch (e) {
      return ''
    }
  }

  companyStatus = (id) => {
    const status = get(this.props, `details[${id}].status`, '')
    return (
      <span className="status">
        <i className={status} />{capitalize(status)}
      </span>
    )
  }

  render = () => {
    const { title, id, children } = this.props

    return (
      <div className="sidebar">
        <div className="details primary">
          <h1>{title}</h1>
          <Info name="Customer"><CompanyName id={id} /></Info>
        </div>
        <div className="details customer-details secondary">
          <h4>About <CompanyName id={id} /></h4>
          <Info name="Company Status" value={this.companyStatus(id)} />
        </div>
        <div className="details customer-details tertiary">
          <h4>Main Address</h4>
          <Info name="" value={this.address(id)} />
        </div>
        {children}
      </div>
    )
  }
}

const mapStateToProps = ({ companies: { details } }) => ({
  details: get(details, 'details', {}),
})

export default connect(mapStateToProps)(Sidebar)

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { startCase } from 'lodash'

import { Field, Conditional, InputGroup } from '../forms/'
import { PackageTypeSelect } from '../forms/select'
import PackageDimensionLinesCalculator from '../package-dimension-lines/PackageDimensionLinesCalculator'

export class Measurements extends Component {

  updateFields = (change) => {
    this.props.setFields({
      ...this.props.fields,
      ...change,
      measurer: this.props.measurer
    })
  }

  setValue = ({ target: { name, value } }) => {
    this.updateFields({ [name]: value })
  }

  setPackageType = ({ type }) => {
    this.updateFields({ packageType: type })
  }

  inputField = ({ label, prefix, suffix, name, disabled, ...props }) => (
    <Field label={label || startCase(name)}>
      <InputGroup prefix={prefix} suffix={suffix}>
        <input
          name={name}
          value={this.props.fields[name] || ''}
          onChange={this.setValue}
          {...props}
          disabled={disabled}
        />
      </InputGroup>
    </Field>
  )

  renderDimensionsCalculator = () => (
    <PackageDimensionLinesCalculator
      measurer={this.props.measurer}
      disabled={!this.props.edit}
      key={this.props.packageDimensionLines.length}
      lines={this.props.packageDimensionLines}
      onChange={this.props.setPackageDimensionLines}
      updateFields={this.updateFields}
    />
  )

  renderConsignmentDetails = () => (
    <div className="section">
      <h4>Consignment Details</h4>
      <div className="form-fields consignment-details">
        <this.inputField
          name={"packageQuantity"} label={"Quantity"}
          type={"number"} min={"0"}
          disabled={!this.props.edit}
        />
        <Field label="Package Type">
          <PackageTypeSelect
            onChange={this.setPackageType}
            valueName={this.props.fields.packageType}
            disabled={!this.props.edit}
          />
        </Field>
        <this.inputField
          name={"grossWeightKg"} label={"Gross Weight"} suffix={"kg"}
          type={"number"} min={"0"}
          disabled={!this.props.edit}
        />
        <this.inputField
          name={"cubicMetres"} label={"Cubic Metres"} suffix={"m³"}
          type={"number"} min={"0"}
          disabled={!this.props.edit}
        />
        <this.inputField
          name="loadingMetres" suffix="ldm"
          type="number" min="0"
          disabled={!this.props.edit}
        />
        <this.inputField
          name="maxPackageLength" suffix="cm"
          type="number" min="0"
          disabled={!this.props.edit}
        />
        <this.inputField
          name="maxPackageHeight" suffix="cm"
          type="number" min="0"
          disabled={!this.props.edit}
        />
        <this.inputField
          name="maxPackageWeight" lsuffix="kg"
          type="number" min="0"
          disabled={!this.props.edit}
        />
      </div>
    </div>
  )

  render = () => (
    <div className="measurements-container">
      <this.renderDimensionsCalculator />
      <this.renderConsignmentDetails />
      <SplitOptions {...this.props} />
    </div>
  )

}

const SplitOptions = ({ edit, measurer, actions={}, }) => (
  <Conditional if={edit}>
    <div className="section">
      <button onClick={actions.split} className="secondary">
        Split Consignment
      </button>
      <Conditional if={measurer.startsWith('part')}>
        <button onClick={() => actions.autoRemainder(measurer)} className="primary">
          Auto Remainder
        </button>
      </Conditional>
      <button onClick={() => actions.deleteMeasurer(measurer)} className="danger">
        Delete
      </button>
    </div>
  </Conditional>
)

Measurements.propTypes = {
  measurer: PropTypes.string.isRequired,
  packageDimensionLines: PropTypes.array.isRequired,
  fields: PropTypes.object.isRequired,
  setFields: PropTypes.func.isRequired,
  setPackageDimensionLines: PropTypes.func.isRequired,
  edit: PropTypes.bool
}

export default Measurements

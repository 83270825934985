import {
  CALCULATOR_STEP_CHANGE,
  CALCULATOR_SAVE_DETAILS,
  CALCULATOR_RESET,
  CALCULATOR_COUNTRIES_SUCCESS,
  CALCULATOR_COUNTRIES_START,
  CALCULATOR_LOCATIONS_START,
  CALCULATOR_LOCATIONS_SUCCESS,
  CALCULATOR_RATE_START,
  CALCULATOR_RATE_SUCCESS,
  CALCULATOR_RATE_FAILURE,
  QUOTES_CREATE_START,
  QUOTES_CREATE_SUCCESS,
  QUOTES_CREATE_FAILURE,
} from '../root/action-types'

export const defaultState = {
  countries: [],
  locations: [],
  rates: {},
  savedDetails: null
}

function calculator(state = defaultState, action) {
  switch (action.type) {
    case CALCULATOR_STEP_CHANGE:
      return { ...state, quoteStep: action.step, created: false, id: null }
    case CALCULATOR_SAVE_DETAILS:
      return { ...state, savedDetails: action.details }
    case CALCULATOR_RESET:
      return { ...defaultState }
    case CALCULATOR_COUNTRIES_START:
      return { ...state, loadingCountries: true, countries: [] }
    case CALCULATOR_COUNTRIES_SUCCESS:
      return { ...state, loadingCountries: false, countries: action.response.data }
    case CALCULATOR_LOCATIONS_START:
      return { ...state, loadingLocations: true, locations: [] }
    case CALCULATOR_LOCATIONS_SUCCESS:
      return { ...state, loadingLocations: false, locations: action.response.data }
    case CALCULATOR_RATE_START:
      return { ...state, loadingRates: true, rates: {} }
    case CALCULATOR_RATE_SUCCESS:
      return {
        ...state,
        loadingRates: false,
        rates: action.response.data,
        quoteStep: 'rates'
      }
    case CALCULATOR_RATE_FAILURE:
      return { ...state, loadingRates: false, ratesError: action.response.data }
    case QUOTES_CREATE_START:
      return { ...state, loading: true, created: false }
    case QUOTES_CREATE_SUCCESS:
      return { ...state, loading: false, created: true, id: action.response.data.details.id }
    case QUOTES_CREATE_FAILURE:
      return { ...state, loading: false, errors: action.response.data.errors }
    default:
      return state
  }
}

export default {
  calculator
}


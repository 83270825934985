import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import SlateEditor from './SlateEditor'

import { Status } from '../forms/Status'
import { get } from 'lodash'

import { EMAILS_DESTROY, EMAILS_MANAGEMENT_RESET_STORE } from '../root/action-types'
import Api from '../api/call'
import { connect } from 'react-redux'

import ModalWindow from '../layout/ModalWindow'
import { html } from './SlateEditorRules'

class DisplayEmail extends Component {

  state = {
    confirmDeleteModalOpen: false,
  }

  componentDidMount = () => (
    this.dispatchEmailStoreReset()
  )

  componentDidUpdate = (prevProps) => {
    const previous = get(prevProps.emailManagement, 'emailHasUpdated')
    const current = get(this.props.emailManagement, 'emailHasUpdated')
    if (current !== previous && current) {
      this.exitEditMode()
    }
  }

  editThisEmail = () => (
    this.props.setEmailUnderEdit(this.props.email.id)
  )

  renderDisplayMode = () => {
    const { body } = this.props.email
    return (
      <SlateEditor emailBody={html.deserialize(body)} readOnly />
    )
  }

  exitEditMode = () => {
    this.dispatchEmailStoreReset()
    this.props.resetEmailUnderEdit()
  }

  openConfirmDeleteModal = () => (
    this.setState({ confirmDeleteModalOpen: true })
  )

  closeConfirmDeleteModal = () => (
    this.setState({ confirmDeleteModalOpen: false })
  )

  dispatchEmailStoreReset = () => (
    this.props.dispatch({
      type: EMAILS_MANAGEMENT_RESET_STORE,
    })
  )

  destroyEmail = () => (
    Api({
      request: EMAILS_DESTROY,
      id: get(this.props.email, 'id'),
    })
  )

  inError = () => (
    get(this.props.emailManagement, 'errors', []).length != 0
  )

  modalContent = ({error}) => {
    if (error) {
      return (
        <Status isError={this.inError}>
          {get(this.props.emailManagement, 'errors')}
        </Status>
      )
    }

    return (
      <button
        className={"delete-button"}
        onClick={this.destroyEmail}
      >
        Delete
      </button>
    )
  }

  renderDeleteModal = () => (
    <ModalWindow
      className="email-delete-modal"
      isOpen={this.state.confirmDeleteModalOpen}
      closeModal={this.closeConfirmDeleteModal}
    >
      <h3>Confirm Email Deletion</h3>
      <span className={'name'}>
        {get(this.props.email, 'subject')}
      </span>
      <span className={'name'}>
        {get(this.props.email, 'recipient')}
      </span>
      <this.modalContent error={this.inError()} />
    </ModalWindow>
  )

  renderDestroyOption = () => (
    <div className="destroy">
      <span onClick={this.openConfirmDeleteModal}>
        <label>
          <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} />
        </label>
        Delete
      </span>
    </div>
  )

  render = () => (
    <React.Fragment>
      <this.renderDeleteModal />

      <div className="details-email">
        {this.renderDisplayMode()}
      </div>
      <div className="email-details-options">
        <div>
          <span
            onClick={this.editThisEmail}
            hidden={get(this.props.email, 'sent') ? true : false }
          >
            <label>
              <FontAwesomeIcon icon={['fas', 'pencil-alt']} />
            </label>
            Edit
          </span>
        </div>
        {this.renderDestroyOption()}
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = ({ emails: { email } }) => (
  { emailManagement: email }
)

export default connect(mapStateToProps)(DisplayEmail)

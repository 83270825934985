import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'

import { USERS_CREATE, USERS_CREATE_RESET_STORE } from '../../root/action-types'
import Api from '../../api/call'
import { Field } from '../../forms/Field'
import { Status } from '../../forms/Status'

import AssignUserRoles from './AssignUserRoles'

const BLANK_NEW_USER_FORM = {
  email: '',
  firstName: '',
  lastName: '',
  admin: false,
  warehouse: false,
  manager: false,
  accounts: false,
  sales: false,
  operations: false,
  claims: false,
}

export class AddNewUser extends Component {

  state = {
    ...BLANK_NEW_USER_FORM
  }

  componentDidUpdate = (prevProps) => {
    if (this.aNewUserHasBeenCreated(prevProps)) {
      this.setState({ ...BLANK_NEW_USER_FORM })
    }
  }

  componentWillUnmount = () => {
    this.props.dispatch({
      type: USERS_CREATE_RESET_STORE,
    })
  }

  aNewUserHasBeenCreated = (oldProps) => (
    this.props.user.userCreated == true && oldProps.user.userCreated == false
  )

  setField = (e) => {
    this.setState({ [e.currentTarget.name]: e.currentTarget.value})
  }

  setRole = (e) => {
    const { name } = e.target
    this.setState((prevState) => ({ [name]: !prevState[name] }))
  }

  sendInvite = () => (
    Api({ request: USERS_CREATE, data: this.state })
  )

  inError = () => (
    !!get(this.props.user, 'errors.length')
  )

  statusMessage = () => {
    const { loading, errors, userCreated } = this.props.user

    if (loading) {
      return 'Processing...'
    } else if (errors && errors.length) {
      return errors.join(', ')
    } else if (userCreated) {
      return 'Invite Sent'
    } else {
      return ''
    }
  }

  render = () => (
    <div className="user-management-add-new-users">
      <div className="header">
        <h3>Add New Users</h3>
      </div>
      <div className="header-rule" />
      <div className="form-container">
        <Status isError={this.inError()}>
          {this.statusMessage()}
        </Status>
        <Field
          label="Email Address"
          error={this.state.email == ''}
          forceError={this.inError()}
          required={true}
        >
          <input
            name="email"
            value={this.state.email}
            onChange={this.setField}
            type="email"
            placeholder="Enter the new user's email address"
            autoComplete="off"
          />
        </Field>

        <div className="double-input">
          <Field label="First Name">
            <input
              name="firstName"
              value={this.state.firstName}
              onChange={this.setField}
              type="text"
              placeholder="New Users' First Name"
            />
          </Field>
          <Field label="Last Name">
            <input
              name="lastName"
              value={this.state.lastName}
              onChange={this.setField}
              type="text"
              placeholder="New Users' Last Name"
            />
          </Field>
        </div>

        <AssignUserRoles
          setRole={this.setRole}
          state={this.state}
        />

        <div className="button-container">
          <button className="save-details" onClick={this.sendInvite}>
            Add User
          </button>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ users: { user } }) => ({
  user
})

export default connect(mapStateToProps)(AddNewUser)

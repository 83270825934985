import { combineReducers } from 'redux'

import {
  PACKAGE_TYPES,
  PACKAGE_TYPES_NEW,
  PACKAGE_TYPES_SHOW,
  PACKAGE_TYPES_CREATE,
  PACKAGE_TYPES_UPDATE,
  PACKAGE_TYPES_DESTROY,
} from  '../root/action-types'

const INITIAL_INDEX = { details: null, loading: false }

export const index = (state = INITIAL_INDEX, action) => {
  switch (action.type) {
    case `${PACKAGE_TYPES}_START`:
      return {
        ...INITIAL_INDEX,
        loading: true,
      }
    case `${PACKAGE_TYPES}_SUCCESS`:
      return {
        ...state,
        details: action.response.data.details,
        loading: false,
      }
    case `${PACKAGE_TYPES}_FAILURE`:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}

const INITIAL_SHOW = { loading: false, details: {}, status: [] }

export const show = (state = INITIAL_SHOW, action) => {
  switch (action.type) {
    case PACKAGE_TYPES_NEW:
      return { ...INITIAL_SHOW }
    case `${PACKAGE_TYPES_SHOW}_START`:
      return {
        ...INITIAL_SHOW,
        loading: true,
      }
    case `${PACKAGE_TYPES_SHOW}_SUCCESS`:
      return {
        ...state,
        details: action.response.data,
        loading: false,
      }
    case `${PACKAGE_TYPES_CREATE}_START`:
    case `${PACKAGE_TYPES_UPDATE}_START`:
    case `${PACKAGE_TYPES_DESTROY}_START`:
      return {
        ...state,
        loading: true,
        status: [],
        hasErrors: false,
      }
    case `${PACKAGE_TYPES_UPDATE}_SUCCESS`:
      return {
        ...state,
        loading: false,
        details: action.response.data.details,
        status: ['Package type updated successfully!'],
      }
    case `${PACKAGE_TYPES_CREATE}_SUCCESS`:
      return {
        ...state,
        loading: false,
        details: action.response.data.details,
        status: ['Package type created successfully!'],
      }
    case `${PACKAGE_TYPES_SHOW}_FAILURE`:
    case `${PACKAGE_TYPES_CREATE}_FAILURE`:
    case `${PACKAGE_TYPES_UPDATE}_FAILURE`:
    case `${PACKAGE_TYPES_DESTROY}_FAILURE`:
      return {
        ...state,
        loading: false,
        hasErrors: true,
        details: action.response.data.details,
        status: action.response.data.errors,
      }
    case `${PACKAGE_TYPES_DESTROY}_SUCCESS`:
      return {
        ...state,
        loading: false,
        destroyed: true,
      }
    default:
      return state
  }
}

export default combineReducers({
  index,
  show,
})

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { get, omit } from 'lodash'

import Api from '../../api/call'
import { COMPANIES_CREATE, COMPANIES_CREATE_RESET_STORE } from '../../root/action-types'

import { Field } from '../../forms/Field'
import { Status, formatErrors } from '../../forms/Status'

import { BLANK_CONTACT } from '../../contacts/Contact'
import ContactForm from '../../contacts/ContactForm'

import { BLANK_ADDRESS } from '../../addresses/Address'
import AddressForm from '../../addresses/AddressForm'

import Warehouse from '../../addresses/warehouse/Warehouse'

import './NewCompany.scss'


class NewCompany extends Component {

  warehouseDetails = {}

  state = {
    name: '',
    address: omit(BLANK_ADDRESS, 'id'),
    contact: { ...BLANK_CONTACT, }
  }

  setValue = ({ target: { name, value } }) => (
    this.setState({ [name]: value })
  )

  setObjectValue(path, { target: { name, value } }) {
    this.setState(prevState => {
      prevState[path][name] = value
      return prevState
    })
  }
  setAddressValue = (e) => this.setObjectValue('address', e)
  setContactValue = (e) => this.setObjectValue('contact', e)

  toggleObjectValue(path, { target: { name } }) {
    this.setState(prevState => {
      prevState[path][name] = !prevState[path][name]
      return prevState
    })
  }
  toggleAddressValue = (e) => this.toggleObjectValue('address', e)
  toggleContactValue = (e) => this.toggleObjectValue('contact', e)

  setCountryValue = (country) => {
    this.setState(prevState => {
      prevState.address.countryCode = country.value
      return prevState
    })
  }

  fetchWarehouseAttributes = (attributes) => {
    this.warehouseDetails = attributes
  }

  createCompanyApiRequest = (attributes) => (
    Api({
      request: COMPANIES_CREATE,
      data: attributes,
    })
  )

  dispatchApiRequest = () => {
    const address = { ...this.state.address }
    address.warehouseDetails = this.warehouseDetails
    this.createCompanyApiRequest({ ...this.state, address })
  }

  companyCreated = () => {
    if (get(this.props.company, 'companyCreated', false)) {
      this.props.dispatch({
        type: COMPANIES_CREATE_RESET_STORE,
      })
      return true
    }
    return false
  }

  newCompanyRedirect = () => (
    <Redirect to={`/companies/${this.props.company.data.id}`} />
  )

  inError = () => (
    !!get(this.props.company, 'errors')
  )

  renderCompanyDetailsForm = () => (
    <div className="details-pane">
      <div className="header">
        <h3>Company Details</h3>
      </div>
      <div className="header-rule" />
      <Field
        label={'Company Name'}
        error={!this.state.name}
        forceError={this.inError()}
        required
      >
        <input
          name={'name'}
          value={this.state.name || ''}
          onChange={this.setValue}
          type="text"
          placeholder={`Company Name`}
          autoComplete="off"
        />
      </Field>
    </div>
  )

  renderCompanyContactForm = () => (
    <div className="contact-pane">
      <div className="header">
        <h3>Main Contact</h3>
      </div>
      <div className="header-rule" />
      <ContactForm
        contact={this.state.contact}
        onChange={this.setContactValue}
        onChangeToggle={this.toggleContactValue}
        formError={this.inError()}
      />
    </div>
  )

  renderCompanyAddressForm = () => (
    <div className="address-pane">
      <div className="header">
        <h3>Main Address</h3>
      </div>
      <div className="header-rule" />
      <AddressForm
        address={this.state.address}
        onChange={this.setAddressValue}
        onChangeToggle={this.toggleAddressValue}
        onChangeCountry={this.setCountryValue}
        formError={this.inError()}
      />
    </div>
  )

  renderWarehouseForm = () => (
    <div className="warehouse-pane">
      <div className="header">
        <h3>Warehouse Details</h3>
      </div>
      <div className="header-rule" />
      <Warehouse
        passUpData={this.fetchWarehouseAttributes}
        details={this.warehouseDetails}
      />
    </div>
  )

  renderForm = () => (
    <div className="new-company-container">
      <h1>New Company</h1>
      <div className="status-container">
        <Status isError={this.inError()}>
          {formatErrors(get(this.props.company, 'errors', []))}
        </Status>
      </div>
      <div className="pane-container">
        <div className="split">
          <div className="left-panes">
            {this.renderCompanyDetailsForm()}
            {this.renderCompanyAddressForm()}
          </div>
          {this.renderCompanyContactForm()}
        </div>
        {this.state.address.warehouse ? this.renderWarehouseForm() :  null}
      </div>

      <button className="submit primary" onClick={this.dispatchApiRequest}>
        Create Company
      </button>
    </div>
  )

  render = () => (
    this.companyCreated() ? this.newCompanyRedirect() : this.renderForm()
  )

}

const mapStateToProps = ({ companies: { company } }) => (
  { company }
)

export default connect(mapStateToProps)(NewCompany)

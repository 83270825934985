import { combineReducers } from 'redux'

import {
  standardForCollections,
  standardSearch
} from '../api/standard-reducers'
import {
  ADDRESSES_CREATE_START,
  ADDRESSES_CREATE_SUCCESS,
  ADDRESSES_CREATE_FAILURE,
  ADDRESSES_UPDATE_START,
  ADDRESSES_UPDATE_SUCCESS,
  ADDRESSES_UPDATE_FAILURE,
  ADDRESSES_DESTROY_START,
  ADDRESSES_DESTROY_SUCCESS,
  ADDRESSES_DESTROY_FAILURE,
  ADDRESSES_MANAGEMENT_RESET_STORE,
} from '../root/action-types'

export const DEFAULT_STATE = {
  loading: false,
  addressHasUpdated: false
}

const successState = (state, action) => (
  {
    ...state,
    loading: false,
    addressHasUpdated: true,
    errors: action.response.data.errors,
    data: action.response.data,
  }
)

const failureState = (state, action) => (
  {
    ...state,
    loading: false,
    addressHasUpdated: false,
    errors: action.response.data.errors,
  }
)

export function addressManagement(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case ADDRESSES_CREATE_START:
    case ADDRESSES_UPDATE_START:
    case ADDRESSES_DESTROY_START:
      return {
        ...state,
        loading: true,
        addressHasUpdated: false,
      }
    case ADDRESSES_CREATE_SUCCESS:
      return successState(state, action)
    case ADDRESSES_UPDATE_SUCCESS:
      return successState(state, action)
    case ADDRESSES_DESTROY_SUCCESS:
      return successState(state, action)
    case ADDRESSES_CREATE_FAILURE:
      return failureState(state, action)
    case ADDRESSES_UPDATE_FAILURE:
      return failureState(state, action)
    case ADDRESSES_DESTROY_FAILURE:
      return failureState(state, action)
    case ADDRESSES_MANAGEMENT_RESET_STORE:
      return DEFAULT_STATE
    default:
      return state
  }
}

export default combineReducers({
  index: standardForCollections('ADDRESSES'),
  search: standardSearch('ADDRESSES'),
  addressManagement
})

import countryCodes from './country-codes.json'

export const countryName = (code) => (
  countryCodes[code]
)

export const countrySelectOptions = Object.keys(countryCodes).map(code => ({
  value: code,
  label: countryCodes[code]
}))


import React, { Component } from 'react'
import { connect } from 'react-redux'
import { upperCase } from 'lodash'

import BlockWrapper from './BlockWrapper'
import Unit, { columnWidthClass } from '../unit/Unit'
import './unit-block.scss'

const ABBREVIATED_HEADINGS = {
  weekNumber: 'week',
  packageQuantity: 'pkg',
  grossWeightKg: 'kg',
  cubicMetres: 'cube',
  loadingMetres: 'LDM',
  originLocation: 'orig',
  destinationLocation: 'dest',
}

const headingString = (columnName) => (
  upperCase(ABBREVIATED_HEADINGS[columnName] || columnName)
)

class UnitBlock extends Component {

  headings = () => (
    this.props.columns.map(c =>
      <span className={`header ${columnWidthClass(c)}`} key={c}>
        {headingString(c)}
      </span>
    )
  )

  units = () => (
    this.props.units.map(u => <Unit key={u.id} id={u.id} />)
  )

  render = () => (
    <div className="planner-unit-block">
      <BlockWrapper id={this.props.id}>
        <div className="units">
          <div className="headings">
            <span className={`header color ${columnWidthClass('icon')}`} />
            {this.headings()}
          </div>
          {this.units()}
        </div>
      </BlockWrapper>
    </div>
  )
}

const mapStateToProps = ({ planner: { units, config } }, { id }) => ({
  units: units.byBlock[id] || [],
  columns: config.columns,
})

export default connect(mapStateToProps)(UnitBlock)


import React, { Component } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'

import { USERS_SEARCH } from '../../root/action-types'
import Api from '../../api/call'

import { Field } from '../Field'

import './select.scss'

const options = (data) => (
  data.map(u => (
    { value: u.id, label: u.label }
  ))
)

class UserSelect extends Component {

  state = {
    limit: 1000000,
    offset: 0,

    admin: !!this.props.admin || null,
    manager: !!this.props.manager || null,
    accounts: !!this.props.accounts || null,
    operations: !!this.props.operations || null,
    sales: !!this.props.sales || null,
    warehouse: !!this.props.warehouse || null,
    claims: !!this.props.claims || null,
  }

  componentDidMount = () => (
    this.requestUsers()
  )

  requestUsers = () => (
    Api({
      request: USERS_SEARCH,
      params: this.state
    })
  )

  getOption = () => {
    const { search: { results }, value } = this.props
    return options(results || []).find(o => o.value == value)
  }

  render = () => {
    const {
      label,
      search: {
        loading, results
      },
      isDisabled,
      onChange
    } = this.props

    return (
      <Field label={label}>
        <Select
          classNamePrefix="select"
          className="select"
          value={loading ? { label: 'Loading...' }: this.getOption() }
          options={options(results || [])}
          isDisabled={isDisabled}
          onChange={onChange}
        />
      </Field>
    )
  }
}

const mapStateToProps = ({ users: { search } }) => ({
  search,
})

export default connect(mapStateToProps)(UserSelect)

import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import { MARK_FOR_EMAILING } from '../../../root/action-types'
import { CheckMark } from '../../../forms/CheckMark'

import { markedForEmailingSelector, jobSelector } from './redux-selectors'
import { jobPreventedFromEmailingBy } from './email-validation'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


export const JobRow = ({ id }) => {
  const isMarkedForEmailing = useSelector(markedForEmailingSelector)[id]
  const jobData = useSelector(state => jobSelector(state, id))
  const dispatch = useDispatch()

  const {
    reference,
    customerId,
    customerName,
    salesInvoiced,
  } = jobData

  const onClick = () => {
    dispatch({ type: MARK_FOR_EMAILING, payload: { id } })
  }

  const errors = jobPreventedFromEmailingBy(jobData)

  const isDisabled = () => salesInvoiced || errors.length != 0

  const renderDetails = () => (
    <React.Fragment>
      {renderEmailMessage()}
      {renderErrors()}
    </React.Fragment>
  )

  const renderEmailMessage = () => (
    salesInvoiced ? <span className="detail posted">Sales invoice has been emailed.</span> : null
  )

  const renderErrors = () => (
    errors.map((reason, index) =>
      <span
        key={`${id}-${index}-${reason}`}
        className="detail"
      >
        {reason}
      </span>
    )
  )

  const renderIcon = (icon) => <FontAwesomeIcon icon={icon}/>

  const formatInvoiceEmailed = (salesInvoiced) => (
    salesInvoiced ? renderIcon("check") : renderIcon("times")
  )

  return (
    <div onClick={isDisabled() ? undefined : onClick} className={`job-row`}>
      <CheckMark checked={isMarkedForEmailing} disabled={isDisabled()} />
      <span>
        <Link to={`/jobs/${id}`}>{reference}</Link>
      </span>
      <span className="customer-name">
        <Link to={`/companies/${customerId}`}>{customerName}</Link>
      </span>
      <span className="icon">{formatInvoiceEmailed(salesInvoiced)}</span>
      <span className="invoice-details">{renderDetails()}</span>
    </div>
  )
}

export default JobRow

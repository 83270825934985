import { CONVERT_QUOTE_TO_JOB } from '../../root/action-types'

export const CONVERT_QUOTE_TO_JOB_RESET_STORE = 'CONVERT_QUOTE_TO_JOB_RESET_STORE'

const INITIAL_DETAILS = {}

export const convertQuote = (state = INITIAL_DETAILS, action) => {
  switch (action.type) {
    case `${CONVERT_QUOTE_TO_JOB}_START`:
      return {
        ...INITIAL_DETAILS,
        loading: false,
      }
    case `${CONVERT_QUOTE_TO_JOB}_SUCCESS`:
      return {
        ...state,
        details: action.response.data.details,
        errors: action.response.data.errors,
        loading: false,
      }
    case `${CONVERT_QUOTE_TO_JOB}_FAILURE`:
      return {
        ...state,
        errors: action.response.data.errors,
        loading: false,
      }
    case CONVERT_QUOTE_TO_JOB_RESET_STORE:
      return {
        ...INITIAL_DETAILS
      }
    default:
      return state
  }
}

export default convertQuote

import React, { Component } from 'react'
import { Redirect } from 'react-router'

import './Dashboard.scss'

export default class Dashboard extends Component {
  render = () => (
    <div className="dashboard-container">
      <h1>Dashboard</h1>
      <span>{process.env.REACT_APP_FREIGHTSOFT_ENV}</span>
      <Redirect to="/companies" />
    </div>
  )
}

import React, { useState } from 'react'
import { startCase } from 'lodash'

import { Tabs, Tab, TabList, TabPanel } from 'react-tabs'

import { Conditional, Status } from '../../forms/'
import { SimpleSelect } from '../../forms/select'

import Measurements from '../../measurements/Measurements'
import { useMeasurementsManager } from '../../measurements/manager'

import { MEASURERS } from '../constants'

import './measurements-tab.scss'

const MEASURER_LIST = Object.values(MEASURERS)

const MeasurementSelectors = ({ edit, choices, setChoice, warnings }) => (
  <div className="section top">
    <h4>Selected Measurements</h4>
    <div className="form-fields">
      {Object.keys(choices).map(c => (
        <SimpleSelect
          key={c}
          label={startCase(c)}
          value={choices[c]}
          options={MEASURER_LIST}
          onChange={setChoice[c]}
          isDisabled={!edit}
        />
      ))}
    </div>
    <Status messages={warnings} isError />
  </div>
)

const MeasurementsTab = ({ edit, data, updateApiRequest }) => {
  const {
    choices, setChoice,
    getMeasurement, measurementSetter,
    getPackageLines, packageLinesSetter,
    tabs, actions, warnings,
  } = useMeasurementsManager(data, updateApiRequest)
  const [tabIndex, setTabIndex] = useState(0)
  const safeTabIndex = tabIndex < tabs.length ? tabIndex : 0
  const warningFix = (i) => setTabIndex(i) // Tabs adds invalid second arg

  return (
    <div className="measurements-tab">
      <MeasurementSelectors {...{ edit, choices, setChoice, warnings }} />
      <div className="section measurement-sets">
        <h4>Measurement Sets</h4>
        <Tabs selectedIndex={safeTabIndex} onSelect={warningFix}>
          <TabList>
            {tabs.map(t => <Tab key={t}>{startCase(t)}</Tab>)}
          </TabList>
          {tabs.map(t =>
            <TabPanel key={t}>
              <Measurements
                edit={edit}
                measurer={t}
                fields={getMeasurement(t)}
                setFields={measurementSetter(t)}
                packageDimensionLines={getPackageLines(t)}
                setPackageDimensionLines={packageLinesSetter(t)}
                actual={t == choices.actualMeasurementsChoice}
                actions={actions}
              />
            </TabPanel>
          )}
        </Tabs>
      </div>
      <Conditional if={edit}>
        <div className="section controls">
          <button className="submit" onClick={actions.save}>Save</button>
        </div>
      </Conditional>
    </div>
  )
}


export default MeasurementsTab

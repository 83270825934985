import React, { Component } from 'react'
import { connect } from 'react-redux'

import { TARIFFS_CONFIG } from '../../root/action-types'
import Api from '../../api/call'
import { Loading } from '../../generic/Loading'
import ModelActions from '../../generic/ModelActions'

import Form from './Form'

export class Show extends Component {

  state = {
    edit: false
  }

  componentDidMount() {
    Api({ request: TARIFFS_CONFIG })
  }

  toggleEdit = () => {
    this.setState(prev => ({ edit: !prev.edit }))
  }

  formOrLoading = () => (
    this.props.loading ? <Loading /> : <Form edit={this.state.edit} {...this.props} />
  )

  formKey = () => (
    `config-editMode-${this.state.edit}`
  )

  render = () => (
    <div className="generic-show">
      <div className="controls">
        <h1>Tariffs Config</h1>
        <ModelActions
          modelName="Config"
          edit={this.state.edit}
          editAction={this.toggleEdit}
        />
      </div>
      <this.formOrLoading key={this.formKey()}/>
    </div>
  )

}

const mapStateToProps = ({ tariffs: { config: { show } } }) => (
  show
)

export default connect(mapStateToProps)(Show)

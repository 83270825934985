import React, { Component } from 'react'

import ModalWindow from '../../layout/ModalWindow'

import Warehouse from './Warehouse'

import './warehouse-modal.scss'

export default class WarehouseModal extends Component {

  renderModalContent = () => (
    <div className="warehouse-attributes">
      <Warehouse
        details={this.props.warehouse}
        formDisabled={true}
      />
    </div>
  )

  render = () => (
    <ModalWindow
      className={"warehouse-modal"}
      isOpen={this.props.isOpen}
      closeModal={this.props.closeModal}
    >
      <h3>Warehouse Information</h3>
      <span className={'name'}>
        {this.props.addressString || ''}
      </span>
      {this.renderModalContent()}
    </ ModalWindow>
  )
}

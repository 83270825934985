import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { MARK_FOR_INVOICING } from '../../../root/action-types'

import { markedForInvoicingSelector, jobsSelector } from './redux-selectors'
import { jobPreventedFromInvoicingBy } from './invoice-validation'


const SelectAllJobs = () => {
  const marked = useSelector(markedForInvoicingSelector)
  const jobs = useSelector(jobsSelector)
  const dispatch = useDispatch()

  const markInvoices = () => {
    Object.keys(marked).filter(id => !marked[id]).map(markIfValid)
  }

  const markIfValid = (id) => {
    valid(id) && dispatch({ type: MARK_FOR_INVOICING, payload: { id } })
  }

  const valid = (id) => {
    const job = jobs.find(job => job.id == id)
    return !job.postedToXero && isReadyToInvoice(job)
  }

  const isReadyToInvoice = (job) => (
    jobPreventedFromInvoicingBy(job).length === 0
  )

  const noJobsInvoicable = () => (
    jobs.filter(job =>
      !job.postedToXero && isReadyToInvoice(job)
    ).length === 0
  )

  return (
    <button
      disabled={noJobsInvoicable()}
      onClick={markInvoices}
      className="success"
    >
      Select All
    </button>
  )
}

export default SelectAllJobs

const YYYYMMDD = (date) => date && date.toISOString().slice(0,10)

const bookingMeetsCriteria = (booking, params) => {
  const {
    weekNumber,
    filterByDate,
    dateOfUse,
    originCountries,
    destinationCountries,
    statuses
  } = params

  const week = () => weekNumber == booking.weekNumber
  const date = () => YYYYMMDD(dateOfUse) == booking.dateOfUse
  const origin = () => originCountries.includes(booking.originCountryCode)
  const destination = () => destinationCountries.includes(booking.destinationCountryCode)
  const status = () => statuses.includes(booking.status)

  return [
    week(),
    filterByDate ? date() : true,
    originCountries.length ? origin() : true,
    destinationCountries.length ? destination() : true,
    statuses.length ? status() : true
  ].every(Boolean)
}

const filterBookings = (bookings, params) => (
  Object.keys(bookings).filter(key => bookingMeetsCriteria(bookings[key], params))
)

export default filterBookings

export const TEST_ACCESS = {
  YYYYMMDD,
  bookingMeetsCriteria,
}

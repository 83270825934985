import {
  PLANNER_UNASSIGNED_UNITS,
  PLANNER_UNITS_EVENT,
  PLANNER_PREVIOUSLY_ON,
  PLANNER_WEEK,
} from '../../root/action-types'

const INITIAL_UNITS = { byId: {}, byBlock: {} }

/*
  We re-sort all the units whenever any of them change. It should be very
  fast to do this, even with 1000s of units, and makes life simple, since
  we avoid needing to figure where the unit was before.
*/
const sortByBlock = (units) => {
  const blocks = {}
  units.forEach(u => {
    const block = u.groupageId || u.status
    blocks[block] = blocks[block] || []
    blocks[block].push(u)
  })

  return blocks
}

const unitsUpdate = (state, newUnitsById) => {
  const byId = { ...state.byId, ...newUnitsById }

  Object.entries(byId).forEach(([id, data]) =>
    data == 'destroyed' && delete byId[id] // covers split too
  )
  return {
    ...state,
    byId,
    byBlock: sortByBlock(Object.values(byId)),
  }
}

export const units = (state = INITIAL_UNITS, action) => {
  switch (action.type) {
    case `${PLANNER_UNASSIGNED_UNITS}_START`:
      return {
        ...state,
      }
    case `${PLANNER_WEEK}_SUCCESS`:
    case `${PLANNER_UNASSIGNED_UNITS}_SUCCESS`: {
      return unitsUpdate(state, action.response.data.units)
    }
    case PLANNER_UNITS_EVENT: {
      return unitsUpdate(state, action.details)
    }
    case `${PLANNER_PREVIOUSLY_ON}_SUCCESS`: {
      return unitsUpdate(state, action.response.data.units)
    }
    case `${PLANNER_UNASSIGNED_UNITS}_FAILURE`:
      return {
        ...state,
      }
    default:
      return state
  }
}

export const TEST_ACCESS = {
  INITIAL_UNITS,
  sortByBlock,
  unitsUpdate,
}

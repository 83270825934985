import React, { Component } from 'react'

export default class NavSubGroup extends Component {

  isSelected = () => (
    this.props.selectedSubGroup == this.props.id
  )

  render_dropdown = () => (
    <div>
      {this.props.children}
    </div>
  )

  render = () => (
    <React.Fragment>
      <span
        onClick={() => this.props.onClick(this.props.id)}
        className={'navbar-subgroup'}
      >
        <span className="navbar-icon-container">{this.props.icon}</span>
        <span className="navbar-subgroup-label">
          {this.props.text}
        </span>
      </span>
      {this.isSelected() ? this.render_dropdown() : null}
    </React.Fragment>
  )
}

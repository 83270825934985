import React from 'react'
import { startCase } from 'lodash'

import { countryName } from '../addresses/countries'
import CompanyName from '../companies/Name'

import './details-summary.scss'

const Detail = ({ name, value }) => (
  <div className="detail">
    <span className="label">
      {nameFormats[name] || startCase(name)}:
    </span>
    <span className="value">
      {value}
    </span>
  </div>
)

const Details = (props) => (
  <div className="quote-details-summary">
    {Object.keys(props).map(name => props[name] &&
    <Detail name={name} value={props[name]} key={name} />
    )}
  </div>
)

const checkMark = (bool) => (
  String(bool) == 'true' ? '✓' : null
)

const cubed = (val) => val && `${val} m³`
const centimetres = (val) => val && `${val} cm`
const ldm = (val) => val && `${val} ldm`
const kilograms = (val) => val && `${val} kg`
const companyName = (id) => <CompanyName id={id} />
const any = (val) => val

const propFormats = {
  direction: startCase,
  country: countryName,
  location: any,
  customerReference: any,
  incotermType: any,
  hazardous: checkMark,
  nonEU: checkMark,
  nonEu: checkMark,
  unloading: checkMark,
  overlength: checkMark,
  upToDoor: checkMark,
  intrastat: checkMark,
  customs: checkMark,
  tailLift: checkMark,
  forkLift: checkMark,
  express: checkMark,
  cubicMetres: cubed,
  loadingMetres: ldm,
  maxPackageLength: centimetres,
  maxPackageHeight: centimetres,
  maxPackageWeight: kilograms,
  grossWeightKg: kilograms,
  customerId: companyName,
}

const nameFormats = {
  customerId: 'Customer',
  grossWeightKg: 'Gross Weight',
}

const DetailsSummary = (props) => {
  const formatted = {}
  Object.entries(props).forEach(([k, v]) =>
    formatted[k] = propFormats[k] && propFormats[k](v)
  )

  return <Details {...formatted} />
}

export default DetailsSummary

import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'

import { logout } from '../api/call'

import { LOGOUT } from './action-types'
import { reducers } from './reducers'
import saga from './saga'

const sagaMiddleware = createSagaMiddleware()

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const appReducer = combineReducers(reducers)

function rootReducer(state, action) {
  if (action.type === LOGOUT) {
    state = undefined
    logout()
  }

  return appReducer(state, action)
}

export const store = createStore(
  rootReducer,
  composeEnhancer(applyMiddleware(sagaMiddleware))
)

sagaMiddleware.run(saga)

import React from 'react'
import Select from 'react-select'
import { orderBy } from 'lodash'

import countryCodes from '../../addresses/country-codes.json'

import { Control } from './ControlBar'

import '../../forms/select/select.scss'

const options = {}

Object.keys(countryCodes).forEach(code =>
  options[code] = { value: code, label: `${countryCodes[code]} (${code})` }
)

const selectOptions = orderBy(Object.values(options), ['label'])

const findMultiValue = (value) => (
  value && value.map(v => findValue(v))
)

const findValue = (value) => (
  value && Object.values(options).find(o => o.value == value)
)

export const CountriesPicker = (props) => {
  const {
    label,
    value,
    onChange,
  } = props

  const onSelectChange = (value) => onChange((value || []).map(v => v.value))

  return (
    <Control>
      <Select
        isMulti
        classNamePrefix="select"
        className="select multi country-picker"
        placeholder={`All ${label} countries`}
        value={findMultiValue(value)}
        options={selectOptions}
        onChange={onSelectChange}
      />
    </Control>
  )
}

export default CountriesPicker

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'

import GenericShow from '../../generic/Show'
import {
  TARIFFS_CUSTOMERS_SHOW, TARIFFS_CUSTOMERS_DESTROY, TARIFFS_CUSTOMERS_NEW
} from '../../root/action-types'
import Form from './Form'

const actions = {
  show: TARIFFS_CUSTOMERS_SHOW,
  destroy: TARIFFS_CUSTOMERS_DESTROY,
  new: TARIFFS_CUSTOMERS_NEW,
}

export class Show extends Component {

  // Can change when you save record
  id = () => (
    get(this.props.details, 'id') || get(this.props.match, 'params.id', 'new')
  )

  form = (props) => (
    <Form
      fields={this.props.details}
      key={this.id()}
      {...props}
    />
  )

  render = () => (
    <GenericShow
      actions={actions}
      indexPath="/tariffs/customers"
      modelName="Customer Tariff"
      form={this.form}
      {...this.props}
    />
  )

}

const mapStateToProps = ({ tariffs: { customers: { show } } }) => (
  show
)

export default connect(mapStateToProps)(Show)

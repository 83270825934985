import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { startCase } from 'lodash'

import DragSourceWrapper from './DragSourceWrapper'
import UnitIcon from './UnitIcon'

const HALF = 'flex-half'
const X2 = 'flex-2'

const CUSTOM_FLEX_COLUMNS = {
  customer: X2,
  weekNumber: HALF,
  icon: 'unit-icon',
}

export const columnWidthClass = (column) => {
  const columnWidthSpecified = Object.keys(CUSTOM_FLEX_COLUMNS).includes(column)
  return columnWidthSpecified ? CUSTOM_FLEX_COLUMNS[column] : null
}

const Unit = ({ columns, data, id }) => {

  const CUSTOM_COLUMNS = {
    'reference': (reference) => (<Link to={`/jobs/${id}`}>{reference}</Link>),
    'priority': (priority) => (
      <span className={`${priority == 'high' ? 'high-priority' : ''}`}>
        {startCase(priority)}
      </span>
    )
  }

  const columnText = (c) => (
    CUSTOM_COLUMNS[c] ? CUSTOM_COLUMNS[c](data[c]) : data[c]
  )

  return (
    <DragSourceWrapper
      id={id}
    >
      <div className="planner-unit">
        <UnitIcon id={id} color={data.color} key={id} />
        {
          columns.map(c => 
            <span className={`${columnWidthClass(c)} ${c}`} key={c}>
              {columnText(c)}
            </span>
          )
        }
      </div>
    </DragSourceWrapper>
  )
}

const mapStateToProps = ({ planner: { units, config } }, { id }) => ({
  data: units.byId[id],
  columns: config.columns,
})

export default connect(mapStateToProps)(Unit)

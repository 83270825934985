import React, { Component } from 'react'

import Api from '../../api/call'

import { DND_UNIT_ID_KEY } from '../unit/DragSourceWrapper'

export class DropTargetWrapper extends Component {
 
  onDragOver = (e) => {
    // By default, data/elements cannot be dropped in other elements.
    // To allow a drop, we must prevent the default handling of the element.
    e.preventDefault()
  }

  onDrop = (e) => {
    // default must be prevented to allow dropping from chrome into firefox.
    e.preventDefault()
    const unitId = e.dataTransfer.getData(DND_UNIT_ID_KEY)
    unitId && this.updateUnitRequest(unitId)
  }
 
  updateUnitRequest = (unitId) => {
    const { id, status } = this.props

    Api({
      request: 'PLANNER_UNIT_UPDATE',
      id: unitId,
      data: {
        groupageId: id || null ,
        status: status || undefined
      }
    })
  }

  render() {
    const { children } = this.props

    return (
      <div
        onDragOver={this.onDragOver}
        onDrop={this.onDrop}
        className="block-drop-target-wrapper"
      >
        {children}
      </div>
    )
  }
}

export default DropTargetWrapper

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { get, capitalize } from 'lodash'

import Api from '../../api/call'
import { FocusStatus } from '../../forms/Status'
import { Loading } from '../Loading'
import { IconButton } from '../Buttons'
import BasicTable from './BasicTable'

export default class Simple extends Component {

  componentDidMount() {
    Api({ request: this.props.actionType })
  }

  table = () => (
    <BasicTable
      columns={this.props.columns}
      data={this.props.data}
      model={this.props.model}
      linkString={this.props.linkString}
    />
  )

  tableOrLoading = () => (
    this.props.loading ? <Loading /> : <this.table />
  )

  status = () => (
    get(this.props, 'history.location.state.status', [])
  )

  render = () => (
    <div className="simple-index-table">
      <div className="index-controls">
        <h1>{this.props.title}</h1>
        <Link to={`${this.props.linkString}new`}>
          <IconButton className="primary" icon="plus-circle">
            New {capitalize(this.props.model)}
          </IconButton>
        </Link>
      </div>
      <div>
        <FocusStatus messages={this.status()} />
        <this.tableOrLoading />
      </div>
    </div>
  )

}


import React, { Component } from 'react'

// For drag and drop to work between chrome -> firefox, firefox -> chrome
// the key used for data within dataTransfer must be "text/plain"
export const DND_UNIT_ID_KEY = "text/plain"

export class DragSourceWrapper extends Component {

  onDragStart = (e) => {
    e.dataTransfer.setData(DND_UNIT_ID_KEY, this.props.id)
  }

  render() {
    const { children } = this.props

    return (
      <div
        draggable={true}
        onDragStart={this.onDragStart}
        className="unit-drag-source-wrapper"
      >
        {children}
      </div>
    )
  }
}

export default DragSourceWrapper

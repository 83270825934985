import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get, pick } from 'lodash'
import { Redirect } from 'react-router-dom'

import Api from '../api/call'
import {
  CALCULATOR_STEP_CHANGE,
  QUOTES_CREATE,
  CALCULATOR_RESET,
} from '../root/action-types'

import { apiFormat } from './api-format'
import DetailsSummary from './DetailsSummary'
import History from '../quotes/History'
import RatesForm from './RatesForm'

import './rates.scss'


const scrollToTop = () => {
  document.body.scrollTop = document.documentElement.scrollTop = 0
}

export class Rates extends Component {

  state = {}

  componentDidMount = scrollToTop

  componentWillUnmount() {
    if (this.state.creating && this.props.created) {
      this.props.dispatch({ type: CALCULATOR_RESET })
    }
  }

  back = () => (
    this.props.dispatch({ type: CALCULATOR_STEP_CHANGE, step: 'details' })
  )

  registerForm = (getForm) => {
    this.getForm = getForm
  }

  save = () => {
    this.setState({ creating: true })
    Api({
      request: QUOTES_CREATE,
      data: apiFormat({
        ...this.props.stepOne,
        ...this.details(),
        ...this.getForm(),
      })
    })
  }

  render = () => {
    if (this.state.creating && this.props.created) {
      return <Redirect to={`/quotes/${this.props.id}?showEmail`} />
    } else {
      return this.page()
    }
  }

  details = () => ({
    ...get(this.props.rates, 'details'),
    overlength: !!get(this.props.surcharges, 'Overlength'),
    nonEU: !!get(this.props.surcharges, 'Non EU'),
    ...pick(this.props.stepOne, ['customerId', 'customerReference'])
  })

  formProps = () => ({
    ...this.props.rates,
    direction: get(this.props.stepOne, 'direction'),
    upToDoor: !!get(this.props.stepOne, 'upToDoor'),
    insured: !!get(this.props.stepOne, 'insured'),
    registerForm: this.registerForm,
  })

  page = () => (
    <div className="quote-rates-page">
      <div className="quote-rates">
        <DetailsSummary {...this.details()} />
        <RatesForm
          {...this.formProps()}
          customerId={get(this.props.stepOne, 'customerId')}
        />
        <div className="step-controls">
          <button className="danger" onClick={this.back}>Back</button>
          <button
            className="primary"
            onClick={this.save}
          >Create Quote</button>
        </div>
      </div>
      <History details={this.props.stepOne} />
    </div>
  )

}

const mapStateToProps = ({ calculator }) => ({
  rates: calculator.rates,
  created: calculator.created,
  id: calculator.id,
  stepOne: calculator.savedDetails || {},
})

export default connect(mapStateToProps)(Rates)

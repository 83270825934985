import React from 'react'

import './input-group.scss'

export const InputGroup = ({ children, suffix, prefix }) => (
  <div className={`sff-input-group ${type(suffix, prefix)}`}>
    {prefix && Addon(prefix, 'prefix')}
    {children}
    {suffix && Addon(suffix, 'suffix')}
  </div>
)

const type = (suffix, prefix) => {
  if (suffix && prefix) { return 'presuffix' }
  if (suffix) { return 'suffix' }
  if (prefix) { return 'prefix' }
  return "nofix"
}

const Addon = (content, className) => (
  <span className={`input-group-addon ${className}`}>{content}</span>
)


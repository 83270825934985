import React, { Component } from 'react'

import WarehouseForm from './WarehouseForm'

export const BLANK = {
  maxForkLiftWeight: '',
  loadingDoorHeight: '',
  rearLoading: false,
  sideLoading: false,
  topLoading: false,
}

const REQUIREMENTS = [
  'collectionBookingIn',
  'collectionReference',
  'deliveryBookingIn',
  'deliveryReference',
  'forkLifterRequired',
  'labourerRequired',
  'narrowStreet',
  'noBoxTrailers',
  'noCurtainSidedTrailers',
  'nonStackableCargo',
  'palletizerRequired',
  'strictTimeSlots',
  'tailLiftRequired',
  'unloadingOfPallets',
]

const initWarehouseDetails = (props) => {
  const details = { ...BLANK, ...props }
  details.requirements = details.requirements || {}
  REQUIREMENTS.forEach(r => {
    details.requirements[r] = details.requirements[r] || { applies: false, details: '' }
  })
  return details
}

export default class Warehouse extends Component {

  state = initWarehouseDetails(this.props.details)

  setValue = ({ target: { name, value } }) => (
    this.setState({ [name]: parseFloat(value) },
      () => this.props.passUpData(this.state)
    )
  )

  toggleValue = ({ target: { name } }) => {
    this.setState(
      prevState => ({ [name]: !prevState[name] }),
      () => this.props.passUpData(this.state)
    )
  }

  toggleRequirement = ({ target: { name }}) => {
    this.setState(
      prevState => {
        prevState.requirements[name].applies = !prevState.requirements[name].applies
        return prevState
      },
      () => this.props.passUpData(this.state)
    )
    this.setRequirementDetail({ target: { name: name, value: '' }})
  }

  setRequirementDetail = ({ target: { name, value }}) => (
    this.setState(
      prevState => {
        prevState.requirements[name].details = value
        return prevState
      },
      () => this.props.passUpData(this.state)
    )
  )

  render = () => (
    <WarehouseForm
      key={this.state.topLoading}
      warehouse={this.state}
      onChange={this.setValue}
      onChangeToggle={this.toggleValue}
      onRequirementToggle={this.toggleRequirement}
      onRequirementDetailChange={this.setRequirementDetail}
      formDisabled={this.props.formDisabled}
    />
  )
}

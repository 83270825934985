import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import Api from '../../api/call'
import { Loading } from '../../generic/Loading'
import ModalWindow from '../../layout/ModalWindow'
import { FocusStatus, Conditional } from '../../forms'

import Summary from './Summary'
import History from './History'
import './autoline.scss'

export const Content = ({ id, complete }) => {
  const [mode, setMode] = useState('')
  const { loading, details, hasError } = useSelector(s => s.jobs.autoline)
  useEffect(() => {
    Api({ request: 'JOBS_COSTING', params: { id } })
  }, [id])
  useEffect(() => {
    if (mode == 'CUSTOMER') {
      Api({ request: 'AUTOLINE_CUSTOMER_RATE', params: details })
    }
  }, [mode])

  if (details.rate) { complete(details) }

  if (loading) { return <Loading /> }

  return (
    <div className="autoline-content">
      <h1>Autoline Job {details.reference}</h1>
      <FocusStatus isError={hasError} messages={details.errors} />
      <Summary {...details} />
      <Conditional if={mode == 'HISTORY'}>
        <History id={id} />
      </Conditional>
      <div className="controls">
        <button className="primary" onClick={() => setMode('CUSTOMER')}>
          Customer Tariff Rate
        </button>
        <button
          className="secondary"
          onClick={() => setMode('HISTORY')}
          disabled={mode == 'HISTORY'}
        >
          Customer History
        </button>
      </div>
    </div>
  )
}

const Button = ({ id, replaceLineItems }) => {
  const [modal, showModal] = useState(false)

  const complete = ({ customerId, rate }) => {
    replaceLineItems({
      sales: [{
        companyId: customerId,
        value: rate,
        category: 'sales',
        description: 'Transport',
      }]
    })
    showModal(false)
  }

  return <>
    <button className="success" onClick={() => showModal(true)}>
      Autoline
    </button>
    <ModalWindow
      className="job-autoline-modal"
      isOpen={modal}
      closeModal={() => showModal(false)}
    >
      {modal ? <Content id={id} complete={complete} /> : null}
    </ModalWindow>
  </>
}

export default Button

import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import QuickFindQuerySelector from './QuickFindQuerySelector'
import './quick-find-form.scss'


function QuickFindForm(props) {

  const searchIcon = () => (
    <span className="search-icon">
      <FontAwesomeIcon icon={['fas', 'search']} fixedWidth />
    </span>
  )

  const queryStringInput = () => (
    <input
      className="query"
      placeholder="Quick find..."
      type="text"
      maxLength="128"
      value={props.queryString}
      onChange={props.setQueryString}
      onKeyPress={props.onKeyPress}
    />
  )

  const renderQueryInput = () => (
    <div className="query-input">
      {searchIcon()}
      {queryStringInput()}
    </div>
  )

  return (
    <div className="topbar-item quick-find">
      {renderQueryInput()}
      <QuickFindQuerySelector {...props} />
    </div>
  )
}

export default QuickFindForm

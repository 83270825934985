import { combineReducers } from 'redux'

import {
  TEMPLATES_GROUPAGES,
  TEMPLATES_GROUPAGES_NEW,
  TEMPLATES_GROUPAGES_SHOW,
  TEMPLATES_GROUPAGES_CREATE,
  TEMPLATES_GROUPAGES_UPDATE,
  TEMPLATES_GROUPAGES_DESTROY,
} from  '../../root/action-types'
import { countryName } from '../../addresses/countries'

const addCountryNames = (template) => {
  template.originCountry = countryName(template.originCountryCode)
  template.destinationCountry = countryName(template.destinationCountryCode)
  template.ref = `${template.originCountryCode}${template.destinationCountryCode}`

  return template
}

const INITIAL_INDEX = { data: [], total: 0, count: 0, loading: true }

export const index = (state = INITIAL_INDEX, action) => {
  switch (action.type) {
    case `${TEMPLATES_GROUPAGES}_START`:
      return {
        ...INITIAL_INDEX,
      }
    case `${TEMPLATES_GROUPAGES}_SUCCESS`:
      return {
        ...state,
        data: action.response.data.data.map(addCountryNames),
        loading: false,
      }
    case `${TEMPLATES_GROUPAGES}_FAILURE`:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}

const INITIAL_SHOW = { loading: false, details: {}, status: [] }

export const show = (state = INITIAL_SHOW, action) => {
  switch (action.type) {
    case TEMPLATES_GROUPAGES_NEW:
      return { ...INITIAL_SHOW }
    case `${TEMPLATES_GROUPAGES_SHOW}_START`:
      return {
        ...INITIAL_SHOW,
        loading: true,
      }
    case `${TEMPLATES_GROUPAGES_SHOW}_SUCCESS`:
      return {
        ...state,
        details: action.response.data.details,
        loading: false,
      }
    case `${TEMPLATES_GROUPAGES_CREATE}_START`:
    case `${TEMPLATES_GROUPAGES_UPDATE}_START`:
    case `${TEMPLATES_GROUPAGES_DESTROY}_START`:
      return {
        ...state,
        loading: true,
        status: [],
        hasErrors: false,
      }
    case `${TEMPLATES_GROUPAGES_UPDATE}_SUCCESS`:
      return {
        ...state,
        loading: false,
        details: action.response.data.details,
        status: ['Template updated successfully!'],
      }
    case `${TEMPLATES_GROUPAGES_CREATE}_SUCCESS`:
      return {
        ...state,
        loading: false,
        details: action.response.data.details,
        status: ['Template created successfully!'],
      }
    case `${TEMPLATES_GROUPAGES_SHOW}_FAILURE`:
    case `${TEMPLATES_GROUPAGES_CREATE}_FAILURE`:
    case `${TEMPLATES_GROUPAGES_UPDATE}_FAILURE`:
    case `${TEMPLATES_GROUPAGES_DESTROY}_FAILURE`:
      return {
        ...state,
        loading: false,
        hasErrors: true,
        details: action.response.data.details,
        status: action.response.data.errors,
      }
    case `${TEMPLATES_GROUPAGES_DESTROY}_SUCCESS`:
      return {
        ...state,
        loading: false,
        destroyed: true,
      }
    default:
      return state
  }
}

export default combineReducers({
  index,
  show,
})

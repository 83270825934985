import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import { groupBy } from 'lodash'

import Api from '../api/call'
import { Loading } from '../generic/Loading'
import ModalWindow from '../layout/ModalWindow'
import { Field } from '../forms'
import ActionTypes from '../root/action-types'

import './autoline.scss'

const { TEMPLATES_GROUPAGES } = ActionTypes

export const Content = ({ id, replaceLineItems, cancel }) => {
  const { loading, data } = useSelector(r => r.templates.groupages.index)
  const complete = () => {
    replaceLineItems(groupBy(template.value.lineItems, li => li.category))
    cancel()
  }
  useEffect(() => Api({
    request: TEMPLATES_GROUPAGES, params: { line_items: true }
  }), [id])
  const [template, setTemplate] = useState()
  const setOption = o => setTemplate(o)
  const options = selectOptions(data)

  if (loading) { return <Loading /> }

  return (
    <div className="autoline-content">
      <h1>Revise Line Items</h1>
      <Field label="Template">
        <Select
          classNamePrefix="select"
          className="select"
          options={options}
          value={template}
          onChange={setOption}
        />
      </Field>
      <div className="controls">
        <button
          className="primary"
          onClick={complete}
          disabled={!template}
        >
          Update Line Items
        </button>
        <button className="danger" onClick={cancel}>Cancel</button>
      </div>
    </div>
  )
}

const selectOptions = (items) => (
  items.map(i => ({ label: `${i.name} - ${i.ref}`, value: i }))
)

const Button = ({ id, replaceLineItems }) => {
  const [modal, showModal] = useState(false)
  const cancel = () => showModal(false)

  return <>
    <button className="secondary" onClick={() => showModal(true)}>
      Revise From Template
    </button>
    <ModalWindow className="groupage-autoline-modal" isOpen={modal} closeModal={cancel}>
      {modal ? <Content id={id} replaceLineItems={replaceLineItems} cancel={cancel} /> : null}
    </ModalWindow>
  </>
}

export default Button

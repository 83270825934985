import { combineReducers } from 'redux'
import Api from '../../api/call'
import {
  PLANNER_WEEK,
  PLANNER_FILTER_PARAMS,
} from '../../root/action-types'
import { defaultWeekNumber, defaultDate } from '../control-bar/week-day-picker/WeekDayControl'

export const INITIAL_FILTER_PARAMS = {
  weekNumber: defaultWeekNumber(),
  dateOfUse: defaultDate(),
  filterByDate: false,
  originCountries: [],
  destinationCountries: [],
  statuses: [],
}

const requestWeek = (weekNumber) => {
  setTimeout(
    () => Api({ request: PLANNER_WEEK, params: { weekNumber: weekNumber } }),
    1
  )
}

const params = (state = INITIAL_FILTER_PARAMS, action) => {
  switch (action.type) {
    case PLANNER_FILTER_PARAMS:
      if (action.payload.weekNumber && state.weekNumber != action.payload.weekNumber) {
        requestWeek(action.payload.weekNumber)
      }
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export const filter = combineReducers({
  params,
})

export const TEST_ACCESS = {
  INITIAL_FILTER_PARAMS,
  params,
}

import { FILE_DOWNLOAD_SUCCESS } from '../root/action-types'

const INITIAL = {}

export const download = (state = INITIAL, action) => {
  switch (action.type) {
    case FILE_DOWNLOAD_SUCCESS: {
      window.open(action.response.data.url)
      return state
    }
    default:
      return state
  }
}


import React, { Component } from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'

import ProtectedRoute from './ProtectedRoute'

import Dashboard from '../dashboard/Dashboard'
import Login from '../users/Login'
import PasswordReset from '../users/password/PasswordReset'
import PrivacyPolicy from '../terms-of-use/PrivacyPolicy'
import NewCompany from '../companies/new-company/NewCompany'
import Company from '../companies/company/Company'
import TermsOfUse from '../terms-of-use/TermsOfUse'
import ViewAllQuotes from '../quotes/ViewAllQuotes'
import QuotesNew from '../calculator/New'
import Quote from '../quotes/quote/Quote'
import ViewAllJobs from '../jobs/ViewAllJobs'
import NewJob from '../jobs/new-job/NewJob'
import Job from '../jobs/job/Job'
import ViewAllGroupages from '../groupages/ViewAllGroupages'
import GroupageNew from '../groupages/groupage/New'
import Groupage from '../groupages/groupage/Groupage'
import TemplateGroupagesIndex from '../templates/groupages/Index'
import TemplateGroupageShow from '../templates/groupages/Show'
import UserManagement from '../admin/user-management/UserManagement'
import AdminSettings from '../admin/settings/AdminSettings'
import ViewAllCompanies from '../companies/ViewAllCompanies'
import PackageTypesIndex from '../package-types/Index'
import PackageTypesShow from '../package-types/Show'
import TariffsDistributionIndex from '../tariffs/distribution/Index'
import TariffsDistributionShow from '../tariffs/distribution/Show'
import TariffsCustomersIndex from '../tariffs/customers/Index'
import TariffsCustomersShow from '../tariffs/customers/Show'
import TariffsSurchargesIndex from '../tariffs/surcharges/Index'
import TariffsSurchargesShow from '../tariffs/surcharges/Show'
import TariffsFreightIndex from '../tariffs/freight/Index'
import TariffsFreightShow from '../tariffs/freight/Show'
import TariffsConfigShow from '../tariffs/config/Show'
import TrailerPlanner from '../planner/TrailerPlanner'

export default class Routes extends Component {

  render = () => {
    return (
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/users/password/edit" component={PasswordReset} />

        <ProtectedRoute exact path="/" component={Dashboard} />
        <ProtectedRoute exact path="/admin/user-management" component={UserManagement} />
        <ProtectedRoute exact path="/admin/settings" component={AdminSettings} />
        <ProtectedRoute exact path="/companies" component={ViewAllCompanies} />
        <ProtectedRoute exact path="/companies/new" component={NewCompany} />
        <ProtectedRoute exact path="/companies/:id" component={Company} />
        <ProtectedRoute exact path="/privacy-policy" component={PrivacyPolicy} />
        <ProtectedRoute exact path="/quotes" component={ViewAllQuotes} />
        <ProtectedRoute exact path="/quotes/new" component={QuotesNew} />
        <ProtectedRoute exact path="/quotes/:id" component={Quote} />
        <ProtectedRoute exact path="/jobs" component={ViewAllJobs} />
        <ProtectedRoute exact path="/jobs/new" component={NewJob} />
        <ProtectedRoute exact path="/jobs/:id" component={Job} />

        <ProtectedRoute exact path="/groupages" component={ViewAllGroupages} />
        <ProtectedRoute exact path="/groupages/new" component={GroupageNew} />
        <ProtectedRoute exact path="/groupages/:id" component={Groupage} />
        <ProtectedRoute exact path="/templates/groupages" component={TemplateGroupagesIndex} />
        <ProtectedRoute exact path="/templates/groupages/:id" component={TemplateGroupageShow} />

        <ProtectedRoute exact path="/terms-of-use" component={TermsOfUse} />

        <ProtectedRoute exact path="/package-types" component={PackageTypesIndex} />
        <ProtectedRoute exact path="/package-types/:id" component={PackageTypesShow} />

        <ProtectedRoute exact path="/tariffs/distribution" component={TariffsDistributionIndex} />
        <ProtectedRoute exact path="/tariffs/distribution/:id" component={TariffsDistributionShow} />
        <ProtectedRoute exact path="/tariffs/customers" component={TariffsCustomersIndex} />
        <ProtectedRoute exact path="/tariffs/customers/:id" component={TariffsCustomersShow} />
        <ProtectedRoute exact path="/tariffs/surcharges" component={TariffsSurchargesIndex} />
        <ProtectedRoute exact path="/tariffs/surcharges/:id" component={TariffsSurchargesShow} />
        <ProtectedRoute exact path="/tariffs/freight" component={TariffsFreightIndex} />
        <ProtectedRoute exact path="/tariffs/freight/:id" component={TariffsFreightShow} />
        <ProtectedRoute exact path="/tariffs/config" component={TariffsConfigShow} />

        <ProtectedRoute exact path="/planner" component={TrailerPlanner} />

        <Route render={() => <Redirect to="/" />} />
      </Switch>
    )
  }
}

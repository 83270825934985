import React from 'react'

import { FILE_DOWNLOAD } from '../root/action-types'
import Api from './call'

const Download = ({ children, ...params, }) => (
  <span
    onClick={() => Api({ request: FILE_DOWNLOAD, params })}
  >{children}</span>
)

export default Download

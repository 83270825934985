import React, { Component } from 'react'
import { startCase } from 'lodash'

class Columns extends Component {

  renderColumn = column => (
    <span
      className={'table header column flex-' + column.flex || 1}
      key={column.accessor + 'Header'}
    >{column.Header || startCase(column.accessor)}
    </span>
  )

  render = () => (
    <div className='table header'>
      { this.props.columns.map(column => this.renderColumn(column)) }
    </div>
  )
}

export default Columns

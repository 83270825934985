// Reference for the available user roles and
// their associated fa icon within the front-end.

export const userRoleIcons = {
  'admin': ['fas', 'user-shield'],
  'manager': ['fas', 'user-tie'],
  'accounts': ['fas', 'credit-card'],
  'operations': ['fas', 'cog'],
  'sales': ['fas', 'comment-alt'],
  'warehouse': ['fas', 'box-open'],
  'claims': ['fas', 'balance-scale'],
}

import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import Api from '../api/call'
import { Loading, BasicTable } from '../generic/'
import ModalWindow from '../layout/ModalWindow'
import { CheckMark, Status } from '../forms/'
import ActionTypes from '../root/action-types'
import { AccountName } from '../xero/AccountSelect'

import './cost-confirmation.scss'

const { LINE_ITEMS, SUPPLIER_INVOICES_CREATE } = ActionTypes

const fetchData = (companyId) => {
  Api({
    request: LINE_ITEMS,
    params: {
      references: true,
      category: 'cost',
      unconfirmed: true,
      companyId,
    }
  })
}

const CostConfirmation = ({ companyId }) => {
  const [checked, setChecked] = useState({})
  const toggleChecked = (id) => setChecked({ ...checked, [id]: !checked[id] })
  const reset = () => {
    fetchData(companyId)
    setChecked({})
  }
  useEffect(reset, [])

  const { data, loading } = useSelector(r => r.lineItems.collection)

  if (loading) { return <Loading /> }

  return (
    <React.Fragment>
      <BasicTable columns={COLUMNS} data={tableData(data, checked, toggleChecked)} />
      <Controls checked={checked} reset={reset} />
    </React.Fragment>
  )
}

const COLUMNS = [
  { accessor: "reference" },
  { accessor: "parentType" },
  { accessor: "chargeCode", sort: "chargeCodeNumber", flex: 2 },
  { accessor: "description", flex: 2 },
  { accessor: "value" },
  { accessor: "checked" },
]

const tableData = (data, checked, toggleChecked) => (
  data.map(d => ({
    ...d,
    reference: <Link to={`/${URLS[d.parentType]}/${d.parentId}`}>{d.reference}</Link>,
    chargeCodeNumber: d.chargeCode,
    chargeCode: <AccountName code={d.chargeCode} />,
    value: `€${d.value.toFixed(2)}`,
    checked: <CheckMark checked={!!checked[d.id]} onChange={() => toggleChecked(d.id)} />,
  }))
)

const URLS = {
  Job: 'jobs',
  Quote: 'quotes',
  Groupage: 'groupages',
}

const Controls = ({ checked, reset }) => {
  const [modal, showModal] = useState(false)
  const close = () => showModal(false)

  return (
    <div className="line-item-summary">
      <button
        className="submit"
        disabled={!Object.values(checked).some(x => x)}
        onClick={() => showModal(true)}
      >
        Confirm Selected
      </button>
      <ModalWindow isOpen={modal} closeModal={close}>
        <h3>Confirm And Create Xero Bill</h3>
        <ModalContent checked={checked} close={close} reset={reset} />
      </ModalWindow>
    </div>
  )
}

export const ModalContent = ({ checked, close, reset }) => {
  const { loading, xeroInvoiceId, error }  = useSelector(r => r.xero.supplierInvoices)
  const [posted, setPosted] = useState(false)
  const post = () => {
    Api({
      request: SUPPLIER_INVOICES_CREATE,
      data: { lineItemIds: Object.keys(checked).filter(id => checked[id]) },
    })
    setPosted(true)
  }

  if (!posted) { return <Confirm post={post} close={close} /> }
  if (loading) { return <Loading /> }
  if (xeroInvoiceId) {
    setTimeout(reset, 2500)
    return <Result />
  }
  return <Result error={error || 'Something went wrong...'} />
}

const Confirm = ({ post, close }) => (
  <React.Fragment>
    <span>Are you sure you want to confirm these line items?</span>
    <div className="options">
      <button className="danger" onClick={close}>Cancel</button>
      <button className="primary" onClick={post}>Confirm</button>
    </div>
  </React.Fragment>
)

const Result = ({ error }) => (
  <Status isError={!!error}>
    {error || "Xero invoice created successfully!"}
  </Status>
)

export default CostConfirmation

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

import DropTargetWrapper from './DropTargetWrapper'
import BlockFooter from './BlockFooter'
import BlockOptions from './BlockOptions'

import './block-wrapper.scss'

import { columnWidthClass } from '../unit/Unit'

const ICONS = {
  booked: 'truck-moving',
  Pending: 'pause-circle',
  Delayed: 'clock',
  "Delayed-Monday": 'clock',
  Issues: 'exclamation-circle',
  Confirmed: 'check',
  "In Transit": 'arrow-right',
}

const className = ({ details }) => (
  details ? 'booking' : 'status'
)

const Header = ({ details, id, openClose }) => (
  <div className="bw-header" onClick={openClose}>
    {details ? <Booking {...details} /> : <Status name={id} />}
  </div>
)

const groupageLink = (id, reference) => (
  <Link to={`/groupages/${id}`}>{reference}</Link>
)

const Booking = ({ id, reference, dateOfUse, status, ...props }) => (
  <React.Fragment>
    <span className={`icon ${columnWidthClass('icon')}`}><FontAwesomeIcon icon={ICONS.booked} /></span>
    <span className="reference" title="Reference">{groupageLink(id, reference)}</span>
    <span className="date" title="Date of use">{formatDateString(dateOfUse)}</span>
    <ETA {...props} />
    <span className="info" title="Trailer number">{props.trailerNumber}</span>
    <span className="info" title="Vessel name">{props.vesselName}</span>
    <span className="info agent-ref" title="Agent Ref">{props.agentRef}</span>
    <span className="spacer" />
    <span className="booking-status" title="Status">{status}</span>
    <div className="options"><BlockOptions id={id} /></div>
  </React.Fragment>
)

const ETA = ({ expectedArrivalDate, expectedArrivalTime }) => (
  <span className="eta">
    ETA: {formatDateString(expectedArrivalDate)} {expectedArrivalTime}
  </span>
)

const formatDateString = (date) => (
  date ? `${date.slice(8,10)}-${date.slice(5,7)}-${date.slice(0,4)}` : 'No Date'
)

const Status = ({ name }) => (
  <React.Fragment>
    <span className="icon"><FontAwesomeIcon icon={ICONS[name] || 'question'} /></span>
    <span>{name}</span>
  </React.Fragment>
)

const dropTargetProps = ({ details, id }) => (
  { [details ? 'id' : 'status']: id }
)

class BlockWrapper extends Component {

  state = {
    expanded: true,
  }

  toggleExpanded = () => {
    this.setState(prevState => ({ expanded: !prevState.expanded }))
  }

  renderUnits = () => (
    this.state.expanded ? this.props.children : null
  )

  render = () => (
    <DropTargetWrapper {...dropTargetProps(this.props)} >
      <div className={`planner-block-wrapper ${className(this.props)}`}>
        <Header {...this.props} openClose={this.toggleExpanded} />
        {this.renderUnits()}
        <BlockFooter id={this.props.id} />
      </div>
    </DropTargetWrapper>
  )
}

const mapStateToProps = ({ planner: { bookings } }, { id }) => ({
  details: bookings[id],
})

export default connect(mapStateToProps)(BlockWrapper)
